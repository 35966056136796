import React from "react";
import { Link } from "react-router-dom";


export const AddressColumnFormatter = (cellContent, row) => (
  <>
   
      <span style={{ fontWeight: "500", color: "black" }}>

        {row.user?.affiliateRefId}

      </span>
  </>
);
