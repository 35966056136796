import React from "react";

export const DetailsColumnFormatter = (cellContent, row) => (
  <span>
    {row.txHash == "Cancelled" ? (
      row.txHash
    ) : row.txHash !== undefined ? (
      <a href={"https://bscscan.com/tx/" + row.txHash} target="_blank">{row.txHash.slice(0, 6) + "...." + row.txHash.toString().slice(-4)}</a>
    ) : (
      "--"
    )}
  </span>
);
