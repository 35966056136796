import React from "react";
import { Link } from "react-router-dom";

export const PriceColumnFormatter = (cellContent, row) => <>
 <Link
      to={`/cock-boxing/player/${row._id}`}
    >
<span style={{fontWeight:"300",color:"black", textAlign:"left" }} >
{row.firstName}{''}{row.lastName}
</span>
</Link>
</>;
