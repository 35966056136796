import React from "react";

export const AddressColumnFormatter = (cellContent, row) => (
  <span>
  
 <img src={row.assetsId?.previewImage} style={{width:"50px",height:"50px",borderRadius:"50%"}}/>
  


  {/* <>{row.userWalletAddress&&<>
    {row.userWalletAddress.slice(0,7)}...{row.userWalletAddress.slice(-7,row.userWalletAddress.length)}
  </>}</> */}
  </span>
  
);
