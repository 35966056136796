import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/cockPicks/cockPicksActions";
import { CockPickEditBetStatusDialogHeader } from "./CockPickEditBetStatusDialogHeader";
import { CockPickEditBetStatusForm } from "./CockPickEditBetStatusForm";
import { useCockPicksUIContext } from "../CockPicksUIContext";
import { CreateAdmin, EndCockPick, EndStaking } from "../../../../../../services/ApiServices";
import { useToasts } from "react-toast-notifications";
import { useState } from "react";
import * as auth from "../../../../Auth/_redux/authRedux";

export function CockPickEndEditBetStatusDialog({ id, show, onHide }) {
  // CockPicks UI Context
  const cockPicksUIContext = useCockPicksUIContext();
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();
  const cockPicksUIProps = useMemo(() => {
    return {
      queryParams: cockPicksUIContext.queryParams,
      setQueryParams: cockPicksUIContext.setQueryParams,
      initCockPick: cockPicksUIContext.initCockPick,
    };
  }, [cockPicksUIContext]);
  // CockPicks Redux state
  const dispatch = useDispatch();
  const { actionsLoading, cockPickForEdit, cockPick } = useSelector(
    (state) => ({
      actionsLoading: state.cockPicks.actionsLoading,
      cockPickForEdit: state.cockPicks.cockPickForEdit,
      cockPick: state.cockPicks.cockPick,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchCockPick(id));
  }, [id, dispatch]);
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  // server request for saving cockPick
  const editStaking = async () => {
    let body = {
      cockPickId: id,
    };
    setIsLoading(true);
    let result;
    try {
      result = await EndStaking(body, authToken);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: "true",
      });
    }
    try {
      if (result.success) {
        setIsLoading(false);
        dispatch(actions.fetchCockPicks(cockPicksUIProps.queryParams, authToken));
        onHide();
        return addToast(result.message, {
          appearance: "success",
          autoDismiss: "true",
        });
      }
    } catch (error) {
      setIsLoading(false);
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: "true",
      });
    }

    // if (!id) {
    //   // server request for creating cockPick
    //   console.log(cockPick);

    //   dispatch(actions.createCockPick(cockPick)).then(() => onHide().catch(e=>{
    //     console.log(e);
    //   }));
    // } else {
    //   // server request for updating cockPick
    //   dispatch(actions.updateCockPick(cockPick)).then(() => onHide());
    // }
  };

  return (
    <Modal
      // size="sm"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <CockPickEditBetStatusDialogHeader id={id} />
      <CockPickEditBetStatusForm
        editStaking={editStaking}
        actionsLoading={actionsLoading}
        cockPick={cockPick}
        onHide={onHide}
        isLoading={isLoading}
      />
    </Modal>
  );
}
