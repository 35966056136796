import React from "react";

export const AmountColumnFormatter = (
  cellContent,
  row,
  index,
  formatExtraData
) => (
  <>
    {/* {formatExtraData}{cellContent} */}

    {row.declared ? 'Declared' : 'Not Declared'}

  </>
);
