import React, { useState, useEffect, useContext } from "react";
// import { PostCollection } from "../../services/api";
import "./index.css";
import { useToasts } from "react-toast-notifications";
import { PostAssets } from "../../../../../../services/ApiServices";
import {
  FaInfoCircle,
  FaGlobe,
  FaDiscord,
  FaTelegram,
  FaInstagram,
  FaMedium,
} from "react-icons/fa";
import Select from "react-select";
// import { Modal } from "antd";
// import ReactTooltip from "react-tooltip";
// import "antd/dist/antd.css";
// import { UserContext } from "../../context/user-context";
import AWS from "aws-sdk";
// import { toast } from "react-toastify";
// import styles from "../../../src/pages/user/collection.module.sass";
// import { useToasts } from "react-toast-notifications";
// import { WalletContext } from "../../context/wallet-context";
import { useHistory } from "react-router-dom";
// import "react-toastify/dist/ReactToastify.css";
// import ClipLoader from "react-spinners/ClipLoader";
import { TiTimes } from "react-icons/ti";
import { Form } from "react-bootstrap";
const NFTCollection = () => {
  const [blockchain, setBlockChain] = useState("");
  const [category, setCategory] = useState("");
  const [username, setUserName] = useState("");
  const [usernameError, setUserNameError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [inputs, setInputs] = useState({});
  const [sociallinks, setSocialLinks] = useState([]);
  const [fetureimage, setFetureImage] = useState(null);
  const [bannerimage, setBannerImage] = useState(null);
  const [logoimage, setLogoImage] = useState(null);
  const [feturefile, setFetureFile] = useState([]);
  const [bannerfile, setBannerFile] = useState([]);
  const [logofile, setLogoFile] = useState([]);
  const [links_yourLinks, setYourLinks] = useState("");
  const [links_discord, setLinksDiscord] = useState("");
  const [links_instagram, setLinksInstagram] = useState("");
  const [links_medium, setLinksMedium] = useState("");
  const [links_telegram, setLinksTelegram] = useState("");
  const [linksValue, setLinksValue] = useState(false);
  const [categoryvalue, SetCategoryValue] = useState(null);
  const [sensitiveContent, setSensitiveContent] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const { addToast } = useToasts();
  //   const userContext = useContext(UserContext);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [logoawsurl, setLogoAwsUrl] = useState("");
  const [fetureawsurl, setFetureAwsUrl] = useState("");
  const [bannerawsurl, setBannerAwsUrl] = useState("");

  const [myAdd, setMyadd] = useState();
  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const [selectedPolkaBridge, setSelectedPolkaBridge] = useState("");
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [isupply, setIsupply] = useState("");
  const [listing, setListing] = useState("");

  const isupplyfunc = (e) => {
    setIsupply(e.target.value);
  };
  //   const { addToast } = useToasts();
  //   const walletContext = useContext(WalletContext);
  let history = useHistory();

  //   useEffect(() => {
  //     if (
  //       walletContext.state.walletAddress &&
  //       walletContext.state.walletAddress != null
  //     )
  //       setMyadd(walletContext.state.walletAddress);
  //     else setMyadd(null);
  //   }, [walletContext.state.walletAddress]);

  useEffect(() => { }, [
    inputs,
    username,
    sensitiveContent,
    categoryvalue,
    sociallinks,
    links_yourLinks,
    links_discord,
    links_instagram,
    links_medium,
    links_telegram,
  ]);
  const Tag = [
    { value: "Polka Bridge", label: "Polka Bridge" },
    { value: "INO launches", label: "INO launches" },
  ];
  const type = [
    { value: "INO launches", label: "INO launches" },
    { value: "123657", label: "123657" },
  ];
  const handleChangeTag = (selectedTag) => {
    setSelectedTag(selectedTag);
    // setPayload({ ...payload, collectionType: selectedType.value });
  };
  const handleChangeNetwork = (selectedNetwork) => {
    setSelectedNetwork(selectedNetwork);
    // setPayload({ ...payload, collectionType: selectedType.value });
  };
  const ocs = (e) => {
    setListing(e.target.value);
  };
  const handleChangeType = (selectedType) => {
    setSelectedType(selectedType);
    console.log(selectedType);
    // setPayload({ ...payload, collectionType: selectedType.value });
  };

  const network = [
    { value: "ETH", label: "ETH" },

    { value: "polygon", label: "polygon" },
    { value: "BSC", label: "BSC" },
  ];
  //   useEffect(() => {
  //     console.log("check logiing", userContext.state.accessToken);
  //     setLoggedIn(userContext.state.accessToken ? true : false);

  //     if (userContext.state.accessToken) {
  //       // setAccessToken(userContext.state.accessToken)
  //     }
  //   }, [userContext.state.accessToken]);

  let array = [];

  //   const submit = async () => {
  //     setIsLoading(true);

  //     if (links_yourLinks != null) {
  //       array.push({ url: links_yourLinks, type: "yourLinks" });
  //     }
  //     if (links_discord != null) {
  //       array.push({
  //         url: "https://discord.gg/" + links_discord,
  //         type: "discord",
  //       });
  //     }
  //     if (links_instagram != null) {
  //       array.push({
  //         url: "https://www.instagram.com/" + links_instagram,
  //         type: "instagram",
  //       });
  //     }
  //     if (links_medium != null) {
  //       array.push({
  //         url: " https://www.medium.com/" + links_medium,
  //         type: "medium",
  //       });
  //     }
  //     if (links_telegram != null) {
  //       array.push({
  //         url: "https://www.t.me/" + links_telegram,
  //         type: "telegram",
  //       });
  //     }

  const submit = async () => {
    setIsLoading(true);
    var response;

    // if (links_yourLinks != null) {
    //   array.push({ url: links_yourLinks, type: "yourLinks" });
    // }
    // if (links_discord != null) {
    //   array.push({
    //     url: "https://discord.gg/" + links_discord,
    //     type: "discord",
    //   });
    // }
    // if (links_instagram != null) {
    //   array.push({
    //     url: "https://www.instagram.com/" + links_instagram,
    //     type: "instagram",
    //   });
    // }
    // if (links_medium != null) {
    //   array.push({
    //     url: " https://www.medium.com/" + links_medium,
    //     type: "medium",
    //   });
    // }
    // if (links_telegram != null) {
    //   array.push({
    //     url: "https://www.t.me/" + links_telegram,
    //     type: "telegram",
    //   });
    // }
    const body = {
      // userWalletAddress: userContext.state.signAddress,
      previewImage: logoawsurl,
      imageUrl: fetureawsurl,
      // bannerImage: bannerawsurl,
      title: inputs.title,
      tag: selectedTag.value,
      token: inputs.token,
      tokenListing: inputs.tokenListiing,

      type: selectedType.value,
      network: selectedNetwork.value,
      AccessType: inputs.accessType,
      swapRate: inputs.swapRate,
      startEnd: inputs.startEnd,
      rules: inputs.rules,
      NFTutilities: inputs.nftUtilities,
      TotalRaise: inputs.totalRaise,
      totalSupply: inputs.totalSupply,
      tokenListing: inputs.tokenListing,
      initialSupply: isupply,
      categoryId: "62ebb7512cec129dd4c637b7",
      category: "Financial Assets",
      network: selectedNetwork.value,
      TotalRaise: inputs.totalRaise,
      customizedUrl: inputs.customizedUrl,
      description: inputs.description,
      category: inputs.category,
      chain: inputs.chain,
      creatorEarnings: inputs.creatorEarnings,
      sensitiveContent: inputs.sensitiveContent,
      socialLinks: array,
      tokenPrice: inputs.price,
      registration: inputs.registration,
      //   Distribution:{
      distribution: inputs.distribution,
      vesting: inputs.vesting,
      // },

      // subcategoryId:subcategoryId,
      // title: inputs.title,
    };

    // console.log(array);

    console.log(body, "body");

    let result;
    try {
      result = await PostAssets(body);
      setIsLoading(false);
      console.log("result", result);
      history.push("/nft/financialAssets");
      return addToast("Successfully Uploaded", {
        appearance: "success",
        autoDismiss: "true",
      });
    } catch (e) {
      setIsLoading(false);
      console.log("created", e);
      return addToast(" Uploaded failed", {
        appearance: "error",
        autoDismiss: "true",
      });
    }
    // if(result.success){
    //   console.log("toast")
    //   return addToast("Successfully Uploaded",{appearance:"success", autoDismiss:"true"});

    // }
    // else console.log("error")
  };

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_Id,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REIGION,
  });
  const bucketName = process.env.REACT_APP_BUCKET_NAME;
  // const regionName = process.env.REACT_APP_AWS_REIGION;
  const myBucket = new AWS.S3({
    params: { Bucket: bucketName },
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setLinksValue(false);

    if (name === "links_yourLinks") {
      setLinksValue(true);
      setYourLinks(value);
    } else if (name === "links_discord") {
      setLinksValue(true);
      setLinksDiscord(value);
    } else if (name === "links_instagram") {
      setLinksValue(true);
      setLinksInstagram(value);
    } else if (name === "links_medium") {
      setLinksValue(true);
      setLinksMedium(value);
    } else if (name === "links_telegram") {
      setLinksValue(true);
      setLinksTelegram(value);
    }

    if (linksValue !== true) {
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };

  //   function handleCopyNotification() {
  //     addToast("Address copied to clipboard!", {
  //       appearance: "success",
  //       autoDismiss: true,
  //     });
  //   }

  const uploadHandler = async (from) => {
    var image = [];
    if (from === "logoimage") {
      image = logofile;
    } else if (from === "fetureimage") {
      image = feturefile;
    } else {
      image = bannerfile;
    }

    console.log("This is a array image");
    console.log(image);
    // alert("Are you want to Upload it....")
    image.map(async (value) => {
      console.log("value", value);
      console.log("bucketName", bucketName);
      console.log("accessKeyId", process.env.REACT_APP_ACCESS_KEY_Id);
      console.log("SECRETKEY", process.env.REACT_APP_AWS_SECRET_ACCESS_KEY);
      console.log(value["name"]);
      console.log(
        "url:",
        `https://${bucketName}.s3.amazonaws.com/assets/${value["name"]}`
      );
      addToast("Uploaded successfully", {
        appearance: "success",
        autoDismiss: true,
      });
      const params = {
        Body: value,
        Bucket: bucketName,
        Key: `assets/${value["name"]}`,
        ContentType: value["type"],
      };

      myBucket
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          // console.log(putObject);
          setProgress(Math.round((evt.loaded / evt.total) * 100));
          if (evt.loaded === evt.total) {
            console.log("Event");
            if (from === "logoimage") {
              setLogoAwsUrl(
                `https://${bucketName}.s3.amazonaws.com/assets/${value["name"]}`
              );
            } else if (from === "fetureimage") {
              setFetureAwsUrl(
                `https://${bucketName}.s3.amazonaws.com/assets/${value["name"]}`
              );
            } else {
              setBannerAwsUrl(
                `https://${bucketName}.s3.amazonaws.com/assets/${value["name"]}`
              );
            }

            console.log(evt);
            // axios.post('/collection', {
            //     "uploadFormat": 'FromComputer',
            //       "uploaddoc": `https://${bucketName}.s3.${regionName}.aws.amazon.com/assets/${value['name']}`,

            //     "fileName": `${value['name']}`,
            //     "fileSize": (value['size'] / 1000).toFixed(2),
            // }).then((data) => {
            //     console.log(data);
            //     // addToRecent(data.data['collection'], 'create')
            //     setRefresh(true);
            //     toast.success("Successfully Uploaded");
            // }).catch(
            // //     () => {
            // //     console.log("error: somthing went wrong");
            // //     toast.error("Uploaded failed!")
            // // }
            // function (error) {
            //     console.log('Uploaded failed!',error);
            //     toast.error("Uploaded failed!",error)

            //   }

            // );
          }
        })
        .send((err) => {
          if (err) {
            console.log(err);
            // addToast("Upload failed", {
            //   appearance: "error",
            //   autoDismiss: true,
            // });
          }
        });
    });
  };

  const logoimageChangeHandler = (event) => {
    const reader = new FileReader();
    reader.onload = function (onLoadEvent) {
      setLogoImage(onLoadEvent.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
    const name = "logoImage";
    const value = event.target.files[0].name;
    setInputs((values) => ({ ...values, [name]: value }));

    var logofiles = [...logofile];
    logofiles.push(event.target.files[0]);
    setLogoFile(logofiles);
    console.log(event.target.files);
  };

  const feturedimageChangeHandler = (event) => {
    const reader = new FileReader();
    reader.onload = function (onLoadEvent) {
      setFetureImage(onLoadEvent.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
    const name = "featuredImage";
    const value = event.target.files[0].name;
    setInputs((values) => ({ ...values, [name]: value }));

    var featuredfiles = [...feturefile];
    featuredfiles.push(event.target.files[0]);
    setFetureFile(featuredfiles);
    console.log(event.target.files);
  };

  const bannerimageChangeHandler = (event) => {
    const reader = new FileReader();
    reader.onload = function (onLoadEvent) {
      setBannerImage(onLoadEvent.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
    const name = "bannerImage";
    const value = event.target.files[0].name;
    setInputs((values) => ({ ...values, [name]: value }));

    var bannerfiles = [...bannerfile];
    bannerfiles.push(event.target.files[0]);
    setBannerFile(bannerfiles);
    console.log(event.target.files);
  };

  const handleCategory = (category) => {
    setCategory(category);
    const name = "category";
    const value = category.value;
    setInputs((values) => ({ ...values, [name]: value }));
    SetCategoryValue(category.value);
  };
  const handleclose = () => {
    SetCategoryValue(null);
  };

  const toggle = (event) => {
    const name = event.target.name;
    const value = sensitiveContent ? false : true;
    setSensitiveContent(value);
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const categoryValue = [
    { value: "Art", label: "Art" },
    { value: "Collectibles", label: "Collectibles" },
    { value: " Music", label: "Music" },
    { value: "Photography", label: "Photography" },
    { value: "Sports", label: "Sports" },
    { value: "Trading Cards", label: "Trading Cards" },
    { value: "Utility", label: "Utility" },
  ];


  const validation = () => {
    if (!logoimage) {
      addToast("Logo image is required!", {
        appearance: "error",
        autoDismiss: true,
      });
    } else if (logoimage && !logoawsurl) {
      addToast("Please upload your logo image!", {
        appearance: "error",
        autoDismiss: true,
      });
    } else if (!fetureimage) {
      addToast("Featured image is required!", {
        appearance: "error",
        autoDismiss: true,
      });
    } else if (fetureimage && !fetureawsurl) {
      addToast("Please upload your featured image!", {
        appearance: "error",
        autoDismiss: true,
      });

      //   addToast("Please upload your banner image!", {
      //     appearance: "error",
      //     autoDismiss: true,
      //   });
    } else if (!inputs.title) {
      addToast("Collection name is required!", {
        appearance: "error",
        autoDismiss: true,
      });
    } else if (!inputs.description) {
      //   addToast("Description is required!", {
      //     appearance: "error",
      //     autoDismiss: true,
      //   });
      // } else if (!inputs.title) {
      //   addToast("title is required!", {
      //     appearance: "error",
      //     autoDismiss: true,
      //   });
    } else if (!inputs.category) {
      //   addToast("Category is required!", {
      //     appearance: "error",
      //     autoDismiss: true,
      //   });
    } else {
      submit();
    }
  };
  const create = async () => {
    setIsLoading(true);
    var response;

    // if (links_yourLinks != null) {
    //   array.push({ url: links_yourLinks, type: "yourLinks" });
    // }
    // if (links_discord != null) {
    //   array.push({
    //     url: "https://discord.gg/" + links_discord,
    //     type: "discord",
    //   });
    // }
    // if (links_instagram != null) {
    //   array.push({
    //     url: "https://www.instagram.com/" + links_instagram,
    //     type: "instagram",
    //   });
    // }
    // if (links_medium != null) {
    //   array.push({
    //     url: " https://www.medium.com/" + links_medium,
    //     type: "medium",
    //   });
    // }
    // if (links_telegram != null) {
    //   array.push({
    //     url: "https://www.t.me/" + links_telegram,
    //     type: "telegram",
    //   });
    // }
    const body = {
      // userWalletAddress: userContext.state.signAddress,
      previewImage: logoawsurl,
      imageUrl: fetureawsurl,
      // bannerImage: bannerawsurl,
      title: inputs.title,

      Token: inputs.token,
      TokenListing: inputs.tokenListiing,

      AccessType: inputs.accessType,
      SwapRate: inputs.swapRate,
      Startend: inputs.startEnd,

      TotalRaise: inputs.totalRaise,
      TotalSupply: inputs.totalSupply,
      TokenListing: inputs.tokenListing,
      InitialSupply: inputs.initialSupply,
      categoryId: "62fb3e94386a50bdef66cdf2",

      TotalRaise: inputs.totalRaise,
      customizedUrl: inputs.customizedUrl,
      description: inputs.description,
      category: inputs.category,
      chain: inputs.chain,
      creatorEarnings: inputs.creatorEarnings,
      sensitiveContent: inputs.sensitiveContent,
      socialLinks: array,
      tokenPrice: inputs.price,
      registration: inputs.registration,
      //   Distribution:{
      distribution: inputs.distribution,
      vesting: inputs.vesting,
      // },

      // subcategoryId:subcategoryId,
      // title: inputs.title,
    };

    // console.log(array);

    console.log(body, "body");

    let result;
    try {
      result = await PostAssets(body);
      setIsLoading(false);
      console.log("result", result);
      history.push("/nft/InitialNFTofferings/new");
      return addToast("Successfully Uploaded", {
        appearance: "success",
        autoDismiss: "true",
      });

      // history.push("/mycollection/" + userContext.state.signAddress);
    } catch (e) {
      setIsLoading(false);
      console.log("created", e);
      return addToast(" Uploaded failed", {
        appearance: "error",
        autoDismiss: "true",
      });
    }
    // if(result.success){
    //   console.log("toast")
    //   return addToast("Successfully Uploaded",{appearance:"success", autoDismiss:"true"});

    // }
    // else console.log("error")
  };

  //   function showAddConfirm(id) {
  //     Modal.confirm({
  //       title: "Are you ready to Submit?",
  //       onCancel: "No",
  //       onOk() {
  //         submit(id);
  //       },
  //       onCancel() {
  //         console.log("Cancel");
  //       },
  //     });
  //   }

  return (
    <>
      <div>
        <div className="lg:w-9/12 lg:px-0 w-full px-4 mx-auto space-y-2 mt-2">
          <div>
            <p className="text-xs-secondary-500 pb-3 fw-lighter">
              <span className="text-danger">*</span> Required fields
            </p>

            <div>
              <p className="fw-bold">
                Logo image <span className="text-danger">*</span>
              </p>
              <p className="text-xs text-secondary-500">
                This image will also be used for navigation. 350 x 350
                recommended.
              </p>
            </div>
            <div class="d-flex pb-4">
              <div class="d-flex ">
                <main className="d-flex flex-col items-center justify-content-center w-100  flex-1  text-center">
                  {logoimage && (
                    <img
                      src={logoimage}
                      alt="Uploaded Image"
                      className="logoimg border border-4 border-dashed rounded-circle hover:bg-secondary-100  hover:border-secondary-100"
                      width="100"
                      height="100"
                    />
                  )}
                  {!logoimage && (
                    <label
                      className="logoimg d-flex  flex-col align-items-center justify-content-center  border border-4 border-dashed rounded-circle border-secondary-300 hover:bg-secondary-100  hover:border-secondary-100 "
                      style={{ height: "100px", width: "100px" }}
                    >
                      <div className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="logosvg text-secondary-400 group-hover:text-secondary-600 group-hover:p-3"
                          viewBox="0 0 20 20"
                          fill="silver"
                          width="50"
                          height="50"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <input
                          name="logoImage"
                          type="file"
                          accept="image/png, image/jpeg"
                          className="hidden"
                          onChange={logoimageChangeHandler}
                        />
                      </div>
                    </label>
                  )}
                  {logoimage && (
                    <div className="flex w-full   items-center justify-content-center mt-4">
                      <button
                        class="imgbtn bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                        onClick={() => uploadHandler("logoimage")}
                      >
                        Upload
                      </button>
                      <button
                        onClick={() => setLogoImage(null)}
                        className="imgbtn ml-5 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                      >
                        Clear
                      </button>
                    </div>
                  )}
                </main>
              </div>
            </div>

            <div className="">
              <p className="fw-bold">Featured image</p>
              <p className="text-xs text-secondary-500 ">
                This image will be used for featuring your collection on the
                homepage, category pages, or other promotional areas of OpenSea.
                600 x 400 recommended.
              </p>
            </div>
            <div class="d-flex mb-4">
              <div class="d-flex ">
                <main className="d-flex flex-col items-center justify-content-center w-100  flex-1  text-center">
                  {fetureimage && (
                    <img
                      src={fetureimage}
                      alt="Uploaded Image"
                      style={{ width: "280px", height: "200px" }}
                      className="fetureimg sm:w-6/12 sm:h-20 border border-4 border-dashed rounded-lg hover:bg-secondary-100   hover:border-secondary-300"
                    />
                  )}
                  {!fetureimage && (
                    <label
                      className="fetureimg d-flex align-items-center  justify-content-center flex-col w-9/12 h-40 sm:w-6/12 sm:h-20 border border-4 border-dashed rounded-lg border-secondary-300 hover:bg-secondary-100  hover:border-secondary-400 "
                      style={{ width: "280px", height: "200px" }}
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class=" svg w-12 h-12 text-secondary-400 group-hover:text-secondary-600 group-hover:p-3"
                          viewBox="0 0 20 20"
                          fill="silver"
                          width="60"
                          height="50"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <input
                        name="featuredImage"
                        type="file"
                        accept="image/png, image/jpeg"
                        className="hidden"
                        onChange={feturedimageChangeHandler}
                      />
                    </label>
                  )}
                  {fetureimage && (
                    <div className="flex w-100  items-center justify-content-center mt-4">
                      <button
                        class="imgbtn bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                        onClick={() => uploadHandler("fetureimage")}
                      >
                        Upload
                      </button>
                      <button
                        onClick={() => setFetureImage(null)}
                        className="imgbtn ml-5 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                      >
                        Clear
                      </button>
                    </div>
                  )}
                </main>
              </div>
            </div>

            {/* <div className="">
              <p className="fw-bold">Banner Image</p>
              <p className="text-xs text-secondary-500 ">
                This image will appear at the top of your collection page. Avoid
                including too much text in this banner image, as the dimensions
                change on different devices. 1400 x 400 recommended.
              </p>
            </div>
            <div class="flex  mt-2">
              <div class="flex ">
                <main className="d-flex flex-col w-100 flex-1  text-center">
                  {bannerimage && (
                    <img
                      src={bannerimage}
                      alt="Uploaded Image"
                      style={{ width: "580px", height: "200px" }}
                      className="bannerimg w-9/12 h-40 sm:w-6/12 sm:h-20 banner border-4 border-dashed rounded-lg border-secondary-300 hover:bg-secondary-100  hover:border-secondary-300"
                    />
                  )}
                  {!bannerimage && (
                    <label
                      class="bannerimg d-flex flex-col align-items-center justify-content-center border border-4 border-dashed rounded-lg border-secondary-300 hover:bg-secondary-100  hover:border-secondary-400"
                      style={{ width: "580px", height: "200px" }}
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="svg w-12 h-12 text-secondary-400 group-hover:text-secondary-600 group-hover:p-3"
                          viewBox="0 0 20 20"
                          fill="silver"
                          width="150"
                          height="50"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <input
                        name="bannerImage"
                        type="file"
                        accept="image/png, image/jpeg"
                        className="hidden"
                        onChange={bannerimageChangeHandler}
                      />
                    </label>
                  )}
                  {bannerimage && (
                    <div className="flex w-full  items-center justify-content-center mt-4">
                      <button
                        class="imgbtn bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                        onClick={() => uploadHandler("bannerimage")}
                      >
                        Upload
                      </button>
                      <button
                        onClick={() => setBannerImage(null)}
                        className="imgbtn ml-5 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                      >
                        Clear
                      </button>
                    </div>
                  )}
                </main>
              </div>
            </div> */}

            {/* <div>
              <label class="text-base fw-bold d-flex">
                Name
                <span class="text-danger leading-10 text-base pl-1 ">*</span>
              </label>
              <input
                class="shadow text-input appearance-none border border-secondary h-12 rounded  w-100 py-2 px-3 text-secondary-700 leading-tight focus:outline-none focus:shadow-outline focus:outline-none"
                name="name"
                id="username"
                type="text"
                value={inputs.name || ""}
                onChange={handleChange}
                placeholder="Example: Treasures of the sea"
                required
              />
              <div class="text-red-500 text-xs tracking-wide mt-1">
                {usernameError}
              </div>
            </div> */}

            <div className="container">
              <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2">
                <div className="col w-50 p-3">
                  <label class="text-base fw-bold d-flex">
                    Title
                    <span class="text-danger leading-10 text-base pl-1 ">
                      *
                    </span>
                  </label>
                  <input
                    style={{ height: "50px" }}
                    class="shadow text-input appearance-none border border-secondary h-12 rounded  w-100 py-2 px-3 text-secondary-700 leading-tight focus:outline-none focus:shadow-outline focus:outline-none"
                    name="title"
                    id="username"
                    type="text"
                    value={inputs.title || ""}
                    onChange={handleChange}
                    placeholder="Name"
                    required
                  />
                  <div class="text-red-500 text-xs tracking-wide mt-1">
                    {usernameError}
                  </div>
                </div>
                <div className="col w-50 p-3">
                  <label class="text-base fw-bold d-flex">
                    Price
                    <span class="text-danger leading-10 text-base pl-1 ">
                      *
                    </span>
                  </label>
                  <input
                    style={{ height: "50px" }}
                    class="shadow text-input appearance-none border border-secondary h-12 rounded  w-100 py-2 px-3 text-secondary-700 leading-tight focus:outline-none focus:shadow-outline focus:outline-none"
                    name="price"
                    id="username"
                    type="number"
                    value={inputs.price || ""}
                    onChange={handleChange}
                    placeholder="24 ETH"
                    required
                  />
                  <div class="text-red-500 text-xs tracking-wide mt-1">
                    {usernameError}
                  </div>
                </div>{" "}
                <div className=" col w-50 p-3">
                  <label class="text-base fw-bold d-flex">
                    Type
                    <span class="text-danger leading-10 text-base pl-1 ">
                      *
                    </span>
                  </label>
                  <Select
                    className="b-transparent"
                    placeholder=""
                    options={type}
                    value={selectedType}
                    onChange={handleChangeType}
                  />
                </div>{" "}
                <div className="col w-50 p-3">
                  <label class="text-base fw-bold d-flex">
                    Token Listing
                    <span class="text-danger leading-10 text-base pl-1 ">
                      *
                    </span>
                  </label>
                  <input
                    style={{ height: "50px" }}
                    class="shadow text-input appearance-none border border-secondary h-12 rounded  w-100 py-2 px-3 text-secondary-700 leading-tight focus:outline-none focus:shadow-outline focus:outline-none"
                    name="tokenListing"
                    id="username"
                    type="text"
                    value={listing}
                    onChange={ocs}
                    placeholder="Example: UTC"
                    required
                  />
                  <div class="text-red-500 text-xs tracking-wide mt-1">
                    {usernameError}
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2">
                {/* <div className="col w-50 p-3">
                  <label class="text-base fw-bold d-flex">
                    Minimum Purchase
                    <span class="text-danger leading-10 text-base pl-1 ">
                      *
                    </span>
                  </label>
                  <input
                    style={{ height: "50px" }}
                    class="shadow text-input appearance-none border border-secondary h-12 rounded  w-100 py-2 px-3 text-secondary-700 leading-tight focus:outline-none focus:shadow-outline focus:outline-none"
                    name="minimumPurchase"
                    id="username"
                    type="text"
                    value={inputs.minimumPurchase || ""}
                    onChange={handleChange}
                    placeholder="0"
                    required
                  />
                  <div class="text-red-500 text-xs tracking-wide mt-1">
                    {usernameError}
                  </div>
                </div> */}
              </div>
            </div>

            <div className="container">
              <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2">
                <div className="col w-50 p-3">
                  <label class="text-base fw-bold d-flex">
                    Total Supply
                    <span class="text-danger leading-10 text-base pl-1 ">
                      *
                    </span>
                  </label>
                  <input
                    style={{ height: "50px" }}
                    class="shadow text-input appearance-none border border-secondary h-12 rounded  w-100 py-2 px-3 text-secondary-700 leading-tight focus:outline-none focus:shadow-outline focus:outline-none"
                    name="totalSupply"
                    id="username"
                    type="number"
                    value={inputs.totalSupply || ""}
                    onChange={handleChange}
                    placeholder="Example:300000"
                    required
                  />
                  <div class="text-red-500 text-xs tracking-wide mt-1">
                    {usernameError}
                  </div>
                </div>

                <div className="col w-50 p-3">
                  <label class="text-base fw-bold d-flex">
                    Initial Supply
                    <span class="text-danger leading-10 text-base pl-1 ">
                      *
                    </span>
                  </label>
                  <input
                    style={{ height: "50px" }}
                    class="shadow text-input appearance-none border border-secondary h-12 rounded  w-100 py-2 px-3 text-secondary-700 leading-tight focus:outline-none focus:shadow-outline focus:outline-none"
                    name="initialSupply"
                    id="username"
                    type="number"
                    value={isupply}
                    onChange={isupplyfunc}
                    placeholder="Example: 200000"
                    required
                  />
                  <div class="text-red-500 text-xs tracking-wide mt-1">
                    {usernameError}
                  </div>
                </div>
              </div>
            </div>
            {/* <div>
                <label class=" text-base font-semibold ">
                  Title
                  <span class="text-red-600 leading-10 text-base pl-1 ">*</span>
                </label>
                <input
                  class="shadow appearance-none border-secondary-300 h-12 rounded-xl  w-full py-2 px-3 text-secondary-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="title"
                  // id="username"
                  type="text"
                  value={inputs.title || ""}
                  onChange={handleChange}
                  placeholder="Example: Treasures of the sea"
                  required
                />
                <div class="text-red-500 text-xs tracking-wide mt-1">
                  {usernameError}
                </div>
              </div> */}
            {/* <div className="my-4">
              <label className="mt-4 w-100">
                <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium font-bold text-slate-700 fw-bold">
                  URL
                </span>
                <p className="text-xs text-secondary-500 ">
                  Customize your URL on OpenSea. Must only contain lowercase
                  letters,numbers, and hyphens.
                </p>
                <input
                  type="text"
                  name="customizedUrl"
                  value={inputs.customizedUrl || ""}
                  onChange={handleChange}
                  className="mt-1 text-input px-3 py-2 bg-white border shadow-sm border-secondary placeholder-slate-400 focus:outline-none focus:border-secondary-300 focus:shadow-2xl focus:shadow-secondary-200/50 focus:ring-secondary-300 block w-100 rounded sm:text-sm focus:ring-1"
                  placeholder="Https://opensea.io/collection/treasures-of-the-sea"
                  required
                />
                <span className="text-red-500 text-sm">{errorMessage}</span>
              </label>
            </div> */}

            <div className="space-y-3 mt-4">
              <p className="fw-bold mt-2">Description</p>
              <p className="text-xs text-secondary-500 ">
                <span className="text-blue-500">
                  <a href="">Markdown</a>
                </span>{" "}
                syntax is supported. 0 of 1000 characters used.
              </p>
              <textarea
                name="description"
                value={inputs.description || ""}
                onChange={handleChange}
                class="w-100 px-3 text-input text-secondary-700 border border-secondary-300 rounded-lg focus:outline-none focus:shadow-2xl focus:shadow-secondary-200/50 focus:border-secondary-300 focus:ring-secondary-300"
                rows="4"
              ></textarea>
            </div>



            {/* <div>
              <div className="space-y-3">
                <p className="fw-bold">Category</p>
                <p className="text-xs text-secondary-500 ">
                  {" "}
                  Adding a category will help make your item discoverable on
                  OpenSea.
                </p>
                <div class="d-lg-flex ">
                  {/* <button className=" z-10 bg-white rounded-lg divide-y divide-secondary-100 hover:shadow-lg shadow dark:bg-secondary-700 " style={{width:"250px"}}> */}
            {/* <div>
                    {" "}
                    <Select
                      className="category-select d-inline-block bg-white mb-4"
                      placeholder="Add Category"
                      options={categoryValue}
                      name="category"
                      onChange={handleCategory}
                      value={category}
                      isDisabled={categoryvalue != null}
                    />
                  </div>  */}
            {/* </button> */}

            {/* {categoryvalue != null ? (
                    <span
                      class="d-flex align-items-center ml-lg-10 px-4 py-2 rounded-lg text-secondary-500 bg-secondary fw-semibold text-sm flex align-center cursor-pointer active:bg-secondary-300 transition duration-300 ease"
                      style={{ width: "fit-content" }}
                    >
                      {categoryvalue}
                      <button
                        class="bg-transparent border-transparent hover text-input focus:outline-none"
                        isDisabled
                        onClick={handleclose}
                      >
                        <TiTimes
                          style={{ fontSize: "22px" }}
                          onClick={handleclose}
                        /> */}
            {/* <svg
                          onClick={handleclose}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="times"
                          class="w-3 ml-3"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 352 512"
                        >
                          <path
                            fill="currentColor"
                            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                          ></path>
                  //       </svg> */}
            {/* </button>
                     </span>
                   ) : (
                     <span></span>
                   )} */}
            {/* if(categoryvalue!=null){
                <span
              class="ml-10 px-4 py-2 rounded-lg text-secondary-500 bg-secondary-200 font-semibold text-sm flex align-center w-max cursor-pointer active:bg-secondary-300 transition duration-300 ease">
             {categoryvalue}
              <button class="bg-transparent hover focus:outline-none">
                <svg onClick={handleclose} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times"
                  class="w-3 ml-3" role="img" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 352 512">
                  <path fill="currentColor"
                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
                  </path>
                </svg>
              </button>
             </span>
            } */}

            {/* </div> */}
            {/* </div>
              </div>
            </div> */}

            {/* <div className="pt-5">
              <p className="fw-bold ">Links</p>
              <div class="bg-white">
                <div class="divide-y-2 divide-secondary-200 divide-solid">
                  <div className="d-flex flex-row  hover:shadow-lg pt-3 ">
                    <div className="border border-right-0 py-2 pl-2">
                      <FaGlobe className="font-size-h4 text-secondary-400" />
                    </div>
                    <div className="w-100 border border-left-0 d-flex align-items-center">
                      <input
                        name="links_yourLinks"
                        value={links_yourLinks || ""}
                        onChange={handleChange}
                        className="text-input text-secondary-400 pl-3  w-100  hover:border-none border-transparent focus:border-primary focus:ring-0"
                        type="text"
                        placeholder="Yoursite.io"
                      />
                    </div>
                  </div> */}

            {/* <div className="d-flex flex-row hover:shadow-lg pt-3">
                    <div className="border border-right-0 py-2 pl-2">
                      <FaDiscord className="font-size-h4 text-secondary-400" />
                    </div>
                    <div className="w-100 border border-left-0 d-flex align-items-center">
                      &nbsp;&nbsp;https://discord.gg/
                      <input
                        style={{ width: "70%" }}
                        name="links_discord"
                        value={links_discord || ""}
                        onChange={handleChange}
                        className="text-input text-secondary-400 pl-0 hover:border-none border-transparent focus:border-transparent focus:ring-0"
                        type="text"
                        placeholder="abcdef"
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row hover:shadow-lg pt-3">
                    <div className="border border-right-0 py-2 pl-2">
                      <FaInstagram className="font-size-h4 text-secondary-400" />
                    </div>
                    <div className="w-100 border border-left-0 d-flex align-items-center">
                      {" "}
                      &nbsp;&nbsp;https://www.instagram.com/
                      <input
                        style={{ width: "70%" }}
                        name="links_instagram"
                        value={links_instagram || ""}
                        onChange={handleChange}
                        className="text-input text-secondary-400 pl-0 hover:border-none border-transparent focus:border-transparent focus:ring-0"
                        type="text"
                        placeholder="YourInstagramHandle"
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row hover:shadow-lg pt-3">
                    <div className="border border-right-0 py-2 pl-2">
                      <FaMedium className="font-size-h4 text-secondary-400" />
                    </div>
                    <div className="w-100 border border-left-0 d-flex align-items-center">
                      &nbsp;&nbsp;https://www.medium.com/
                      <input
                        style={{ width: "70%" }}
                        name="links_medium"
                        value={links_medium || ""}
                        onChange={handleChange}
                        className="text-input text-secondary-400 pl-0  hover:border-none border-transparent focus:border-transparent focus:ring-0"
                        type="text"
                        placeholder="@YourMediumHandle"
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row hover:shadow-lg pt-3">
                    <div className="border border-right-0 py-2 pl-2">
                      <FaTelegram className="font-size-h4 text-secondary-400" />
                    </div>
                    <div className="w-100 border border-left-0 d-flex align-items-center">
                      &nbsp;&nbsp;https://www.t.me/
                      <input
                        style={{ width: "70%" }}
                        name="links_telegram"
                        value={links_telegram || ""}
                        onChange={handleChange}
                        className="text-input text-secondary-400 pl-0  hover:border-none border-transparent focus:border-transparent focus:ring-0"
                        type="text"
                        placeholder="abcdef"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div>
            <h1 className='font-bold mt-5'>Creator Earnings</h1>
            <p className='text-xs text-secondary-500 mt-4  '>Collect a fee when a user re-sells on item you originally created.
              This is deducted from the final sale price and paid monthly to a payout address of your chossing.</p>
            <a  href="" className='text-xs text-indigo-500 '>Learn more about creator earnings.</a>
            <h4 className='font-bold text-xs mt-5 mb-3'>Percentage fee</h4>
            <input type="text" name="creatorEarnings" value={inputs.creatorEarnings || ""}
              onChange={handleChange} className="mt-1 px-3 py-2 bg-white border shadow-sm border-secondary-300 placeholder-slate-400 focus:outline-none focus:border-secondary-300 focus:shadow-2xl focus:shadow-secondary-200/50 focus:ring-secondary-300 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="e.g. 2.5" required />
          </div> */}

            <div class="leading-8 mt-4">
              {/* <label class=" text-base font-semibold  pt-3">Blockchain</label> */}
              {/* <p className='text-xs text-secondary-500 flex flex-row'>Select the blockchain where you'd like new items from this collection to be added by default.
                   <span className='float-left'>
                     <FaInfoCircle 
                      data-tip
                      data-for="registerTip"
                      class="h-4 text-black-500 ml-1  "
                      />
                    <ReactTooltip id="registerTip" place="top" effect="solid">
                      Moving items to a different collection may{" "}
                      <div>take up to 30 minutes. You can manage</div>{" "}
                      <div class="text-blue-400">your collections here.</div>
                    </ReactTooltip>
                    </span>
                </p> */}

              {/* <Button color="lightBlue" ref={buttonRef} ripple="dark">
                Tooltip bottom
            </Button> */}

              {/* <Tooltips placement="top" ref={buttonRef}>
                <TooltipContent>Tooltip bottom</TooltipContent>
            </Tooltips> */}

              {/* <Select
              placeholder="select blockchain"
              options={blockchainval}
              name='chain'
              onChange={handleBlockChain}
              value={blockchain}
            />
          */}

              {/* <div>
            <h1 className='font-bold'>Payment tokens</h1>
            <p className='text-xs text-secondary-500  flex ' >These tokens can be used to buy and sell your items.

              <FontAwesomeIcon icon={faInfoCircle} className="h-5 text-secondary-400 " /></p>

            <div className='flex flex-row space-x-10 pt-5'>
              <div>
                <div className=" flex flex-row space-x-5  hover:bg-sky-700 p-1 px-6 rounded-lg border hover:shadow-xl ...">
                  <div>icon</div>
                  <div className='font-bold text-sm'>ETH <br /> <span className='text-xs font-normal'>Etherem</span></div>
                </div>
              </div>

              <div>
                <div className=" flex flex-row space-x-5  hover:bg-sky-700 p-1 px-6 rounded-lg border hover:shadow-xl ...">
                  <div>icon</div>
                  <div className='font-bold text-sm'>WETH <br /> <span className='text-xs font-normal'>Etherem</span></div>
                </div>
              </div>
            </div>

            <div class="leading-8 mt-4">
              <label class=" text-base font-semibold  pt-3">Add Tokens</label>

              <Select
                name='tokens'
                placeholder="select blockchain"
                options={options}
                value={selectValue}
                onChange={handleChangeselect}
              />
            </div>

 */}
              {/* <div style={{ marginLeft: buttonWidth, whiteSpace: 'nowrap' }}>
 <Tooltip placement="top" title={text}>
        <Button>Top</Button>
      </Tooltip>
      </div> */}

              {/* <div className="pt-5 flex flex-col lg:mb-0 mb-24">
                <div className="w-100">
                  <p className="fw-bold">Explicit & sensitive content</p>
                  <div className="d-flex w-100">
                    <p className="text-xs text-secondary-500 flex flex-row">
                      <span className="float-left">
                        <FaInfoCircle
                          data-tip
                          data-for="registerTip"
                          class="font-size-h4 text-secondary mr-2"
                        /> */}
              {/* <ReactTooltip
                          id="registerTip"
                          place="top"
                          effect="solid"
                        >
                          Moving items to a different collection may{" "}
                          <div>take up to 30 minutes. You can manage</div>{" "}
                          <div class="text-blue-400">
                            your collections here.
                          </div>
                        </ReactTooltip> */}
              {/* </span>
                      Set this collection as explicit and sensitive content
                    </p>

                    <div class="mx-auto">
                      <label for="toggleB" class="flex  cursor-pointer">
                        {/* <!-- toggle --> */}
              {/* <div class="relative"> */}
              {/* <!-- input --> */}
              {/* <Form>
                            <Form.Check
                              type="switch"
                              label=""
                              name="sensitiveContent"
                              onChange={toggle}
                              value={inputs.sensitiveContent || ""}
                              id="toggleB"
                            />
                          </Form>  */}
              {/* <input
                          name="sensitiveContent"
                          onChange={toggle}
                          type="checkbox"
                          value={inputs.sensitiveContent || ""}
                          id="toggleB"
                          class=" form-check-input"
                          role="switch"
                        /> */}
              {/* <!-- line --> */}
              {/* <div
                            class={`${
                              sensitiveContent
                                ? "bg-blue-600 "
                                : "bg-secondary-600"
                            } block w-12 h-6 rounded-full`}
                          ></div>
                          {/* <!-- dot --> */}
              {/* <div class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div> */}
              {/* </div> */}
              {/* <!-- label --> */}
              {/* <div class="ml-3 text-blue-700 font-medium"> */}
              {/* Toggle Me! */}
              {/* </div>
                      </label>
                    </div>
                  </div>
                </div>  */}
              <div class="lg:mb-0 mb-8">
                <button
                  onClick={() => {
                    validation();

                  }}
                  // className={styles.buttonlarge}
                  type="button"
                  className="btn btn-primary mt-4"
                  style={{ marginLeft: "auto", display: "block" }}
                >
                  Create
                  {/* {isLoading &&
                     {
                       <ClipLoader
                            className=".css-1xcc5ro"
                            size={20}
                            color="black"
                          /> 
                    }} */}
                </button>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NFTCollection;
