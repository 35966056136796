import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/fights/fightsActions";
import { FightEditDialogHeader } from "./FightEditDialogHeader";
import { FightEditForm } from "./FightEditForm";
import { useFightsUIContext } from "../FightsUIContext";
import { CreateAdmin, EndFight } from "../../../../../../services/ApiServices";
import { useToasts } from "react-toast-notifications";
import { useState } from "react";
import * as auth from "../../../../Auth/_redux/authRedux";

export function FightEndEditDialog({ id, show, onHide }) {
  // Fights UI Context
  const fightsUIContext = useFightsUIContext();
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();
  const fightsUIProps = useMemo(() => {
    return {
      queryParams: fightsUIContext.queryParams,
      setQueryParams: fightsUIContext.setQueryParams,
      initFight: fightsUIContext.initFight,
    };
  }, [fightsUIContext]);
  // Fights Redux state
  const dispatch = useDispatch();
  const { actionsLoading, fightForEdit, fight } = useSelector(
    (state) => ({
      actionsLoading: state.fights.actionsLoading,
      fightForEdit: state.fights.fightForEdit,
      fight: state.fights.fight,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchFight(id));
  }, [id, dispatch]);
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  // server request for saving fight
  const editFight = async (winner, forceValue) => {
    if (forceValue.length < 4)
      return addToast("Invalid Newton's force Value", {
        appearance: "error",
        autoDismiss: "true",
      });

    let body = {
      fightId: id,
      winner: winner,
      forceValues:forceValue
    };
    setIsLoading(true);
    let result;
    try {
      result = await EndFight(body, authToken);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: "true",
      });
    }
    try {
      if (result.success) {
        setIsLoading(false);
        dispatch(actions.fetchFights(fightsUIProps.queryParams,authToken));
        onHide();
        return addToast(result.message, {
          appearance: "success",
          autoDismiss: "true",
        });
      }
    } catch (error) {
      setIsLoading(false);
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: "true",
      });
    }

    // if (!id) {
    //   // server request for creating fight
    //   console.log(fight);

    //   dispatch(actions.createFight(fight)).then(() => onHide().catch(e=>{
    //     console.log(e);
    //   }));
    // } else {
    //   // server request for updating fight
    //   dispatch(actions.updateFight(fight)).then(() => onHide());
    // }
  };

  return (
    <Modal
      // size="sm"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <FightEditDialogHeader id={id} />
      <FightEditForm
        editFight={editFight}
        actionsLoading={actionsLoading}
        fight={fight}
        onHide={onHide}
        isLoading={isLoading}
      />
    </Modal>
  );
}
