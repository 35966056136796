// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/referrals/referralsActions";
import * as uiHelpers from "../ReferralsUIHelpers";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useReferralsUIContext } from "../ReferralsUIContext";
import { useToasts } from "react-toast-notifications";
export function ReferralTable() {
  // Referral UI Context
  const referralUIContext = useReferralsUIContext();
  const { addToast } = useToasts();
  const referralUIProps = useMemo(() => {
    return {
      ids: referralUIContext.ids,
      setIds: referralUIContext.setIds,
      queryParams: referralUIContext.queryParams,
      setQueryParams: referralUIContext.setQueryParams,
      openEditReferralPage: referralUIContext.openEditReferralPage,
      openDeleteReferralDialog: referralUIContext.openDeleteReferralDialog,
      openVerifyReferralDialog: referralUIContext.openVerifyReferralDialog,
      openReferralEndEditDialog: referralUIContext.openReferralEndEditDialog,
      openReferralBetEndEditDialog:
        referralUIContext.openReferralBetEndEditDialog,
    };
  }, [referralUIContext]);
  // Getting curret state of referral list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.referrals }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  // Referral Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    referralUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchReferrals(referralUIProps.queryParams, authToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralUIProps.queryParams, dispatch]);
  // Table columns
  const ended = () => {
    addToast("Referral already ended", {
      appearance: "error",
      autoDismiss: "true",
    });
  };
  console.log(entities)
  const serialNumer = (index) => {
    const startIndex = (referralUIProps.queryParams.pageNumber - 1) * referralUIProps.queryParams.pageSize;
    return startIndex + index + 1
  }
  const columns = [
    {

      text: "S.No",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return serialNumer(rowIndex);
      },
    },
    // {
    //   dataField: "createdAt",
    //   text: "Date",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: columnFormatters.DateColumnFormatter,
    // },
    // {
    //   dataField: "referrer.affiliateRefId",
    //   text: "Operator Id",
    //   formatter: columnFormatters.AffiliateColumnFormatter,

    // },
    {
      dataField: "firstName",
      text: "Name",
      style: {
        minWidth: "100px",
        textAlign: "left"
      },
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        const { firstName, lastName } = row;
        return `${firstName} ${lastName}`;
      }
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "100px",
        textAlign: "left"
      },
    },
    {
      dataField: "referrer.firstName",
      text: "Referrer Name",
      style: {
        minWidth: "100px",
        textAlign: "left"
      },
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        const { firstName, lastName } = row.referrer;
        return `${firstName} ${lastName}`;
      }
    },
    {
      dataField: "referrer.email",
      text: "Referrer Email",
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "100px",
        textAlign: "left"
      },
    },
    {
      dataField: "totalStakeAmount",
      text: "Staked Amount",
      formatter: columnFormatters.DetailsColumnFormatter,
      style: {
        minWidth: "100px",
        textAlign: "center"
      },
    },
    {
      dataField: "totalAmount",
      text: "Ref. Commission",
      formatter: columnFormatters.AmountColumnFormatter,
      // formatExtraData: "amountIn",
      style: {
        minWidth: "100px",
        textAlign: "right"
      },
    },



    // {
    //   dataField: "action",
    //   text: "Details",
    //   formatter: columnFormatters.ActionsColumnFormatter,
    //   formatExtraData: {
    //     openVerifyReferralDialog: referralUIProps.openVerifyReferralDialog,
    //     openUpdateReferralStatusDialog: referralUIContext.openUpdateReferralStatusDialog,
    //     ended: ended,
    //     setId: referralUIContext.setId
    //     // openVerifyReferralPage: referralUIProps.openVerifyReferralPage,
    //   },
    //   // classes: "text-right pr-0",
    //   // headerClasses: "text-right pr-3",
    //   style: {
    //     minWidth: "100px",
    //     textAlign: "center",
    //   },
    // },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: referralUIProps.queryParams.pageSize,
    page: referralUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"

                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="_id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  referralUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: referralUIProps.ids,
                //   setIds: referralUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
