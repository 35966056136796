import React from "react";
import { Route } from "react-router-dom";
import { ReportsLoadingDialog } from "./reports-loading-dialog/ReportsLoadingDialog";
import { ReportDeleteDialog } from "./report-delete-dialog/ReportDeleteDialog";
import { ReportsDeleteDialog } from "./reports-delete-dialog/ReportsDeleteDialog";
import { ReportsFetchDialog } from "./reports-fetch-dialog/ReportsFetchDialog";
import { ReportsUpdateStatusDialog } from "./reports-update-status-dialog/ReportsUpdateStatusDialog";
import { ReportsCard } from "./ReportsCard";
import { ReportsUIProvider } from "./ReportsUIContext";

export function ReportsPage({ history }) {
  const reportsUIEvents = {
    newReportButtonClick: () => {
      history.push("/nft/reports/new");
    },
    openEditReportPage: (id) => {
      history.push(`/nft/reports/${id}/edit`);
    },
    openDeleteReportDialog: (id) => {
      history.push(`/nft/reports/${id}/delete`);
    },
    openDeleteReportsDialog: () => {
      history.push(`/nft/reports/deleteReports`);
    },
    openFetchReportsDialog: () => {
      history.push(`/nft/reports/fetch`);
    },
    openUpdateReportsStatusDialog: () => {
      history.push("/nft/reports/updateStatus");
    },
  };

  return (
    <ReportsUIProvider reportsUIEvents={reportsUIEvents}>
      <ReportsLoadingDialog />
      <Route path="/nft/reports/deleteReports">
        {({ history, match }) => (
          <ReportsDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/nft/reports");
            }}
          />
        )}
      </Route>
      <Route path="/nft/reports/:id/delete">
        {({ history, match }) => (
          <ReportDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/nft/reports");
            }}
          />
        )}
      </Route>
      <Route path="/nft/reports/fetch">
        {({ history, match }) => (
          <ReportsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/nft/reports");
            }}
          />
        )}
      </Route>
      <Route path="/nft/reports/updateStatus">
        {({ history, match }) => (
          <ReportsUpdateStatusDialog
            show={match != null}
            onHide={() => {
              history.push("/nft/reports");
            }}
          />
        )}
      </Route>
      <ReportsCard />
    </ReportsUIProvider>
  );
}
