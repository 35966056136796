import React, { useMemo } from "react";
import { useDepositsUIContext } from "../DepositsUIContext";

export function DepositsGrouping() {
  // Deposits UI Context
  const depositsUIContext = useDepositsUIContext();
  const depositsUIProps = useMemo(() => {
    return {
      ids: depositsUIContext.ids,
      setIds: depositsUIContext.setIds,
      openDeleteDepositsDialog: depositsUIContext.openDeleteDepositsDialog,
      openFetchDepositsDialog: depositsUIContext.openFetchDepositsDialog,
      openUpdateDepositsStatusDialog:
        depositsUIContext.openUpdateDepositsStatusDialog,
    };
  }, [depositsUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="-font-bold font-danger-">
                <span>
                  Selected records count: <b>{depositsUIProps.ids.length}</b>
                </span>
              </label>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-danger font-weight-bolder font-size-sm"
                onClick={depositsUIProps.openDeleteDepositsDialog}
              >
                <i className="fa fa-trash"></i> Delete All
              </button>
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={depositsUIProps.openFetchDepositsDialog}
              >
                <i className="fa fa-stream"></i> Fetch Selected
              </button>
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={depositsUIProps.openUpdateDepositsStatusDialog}
              >
                <i className="fa fa-sync-alt"></i> Update Status
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
