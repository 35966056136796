// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/subOperatorIncome/subOperatorIncomesActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../SubOperatorIncomesUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useSubOperatorsUIContext } from "../SubOperatorIncomesUIContext";

export function SubOperatorsTable() {
  // Affiliates UI Context
  const subOperatorsUIContext = useSubOperatorsUIContext();
  const subOperatorsUIProps = useMemo(() => {
    return {
      ids: subOperatorsUIContext.ids,
      setIds: subOperatorsUIContext.setIds,
      queryParams: subOperatorsUIContext.queryParams,
      setQueryParams: subOperatorsUIContext.setQueryParams,
      openEditAffiliateDialog: subOperatorsUIContext.openEditAffiliateDialog,
      openDeleteAffiliateDialog: subOperatorsUIContext.openDeleteAffiliateDialog,
    };
  }, [subOperatorsUIContext]);
  const { authToken, user } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
      user: auth.user,
    }),
    shallowEqual
  );

  // Getting curret state of subOperators list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.subOperatorIncomes }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // Affiliates Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    subOperatorsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchOperatorIncomes(subOperatorsUIProps.queryParams, authToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subOperatorsUIProps.queryParams, dispatch]);
  // Table columns

  const serialNumer = (index) => {
    const startIndex = (subOperatorsUIProps.queryParams.pageNumber - 1) * subOperatorsUIProps.queryParams.pageSize;
    return startIndex + index + 1
  }
  const columns = [
    {
      text: "S.No",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return serialNumer(rowIndex);
      },
    },

    {
      dataField: "_id.date",
      text: "DATE",
      sort: true,
      headerAlign: 'left',
      classes: "text-left pl-3 ",
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.DateColumnFormatter,

    },
    {
      dataField: "refId",
      text: "Operator Id",
      // sort: true,
      headerAlign: 'left',
      classes: "text-left pl-3 ",

      formatter: columnFormatters.AddressColumnFormatter,

    },
    {
      dataField: "country",
      text: "Staking",
      // sort: true,
      headerAlign: 'left',
      formatter: columnFormatters.TypeColumnFormatter,

    },
    {
      dataField: "totalCockpickAmount",
      text: "Last 2 Digit",
      headerAlign: 'left',
      // sort: true,
      // headerSortingClasses,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return <span>{Math.abs(row?.totalCockpickAmount).toFixed(2)} $</span>;
      },
    }, {
      dataField: "percentage",
      text: "(%)",
      headerAlign: 'left',
      // sort: true,
      // headerSortingClasses,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return <span>{row?._id?.affiliate?.parent?.percentage - row?._id?.affiliate?.percentage} %</span>;
      },
    },
  
  ];
  if (user?.affiliate?.referral_commision) {
    columns.push({
      dataField: "totalReferAmount",
      text: "Ref Com",
      headerAlign: "left",
      formatter: (cell, row) => (
        <span>{Math.abs(row?.totalReferAmount).toFixed(2)} $</span>
      ),
    });
  }

  // Continue defining the remaining columns
  columns.push(
    {
      dataField: "revenue",
      headerAlign: "left",
      text: "Revenue",
      formatter: columnFormatters.ImageColumnFormatter,
    },
    {
      dataField: "revenue",
      headerAlign: "left",
      text: "Total",
      formatter: columnFormatters.TotalColumnFormatter,
    }
  );

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: subOperatorsUIProps.queryParams.pageSize,
    page: subOperatorsUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-left overflow-hidden"
                bootstrap4
                remote
                keyField="_id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  subOperatorsUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: subOperatorsUIProps.ids,
                //   setIds: subOperatorsUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
