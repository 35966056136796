/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { GoUnverified, GoVerified, IconName } from "react-icons/go";
import { Link } from "react-router-dom";
export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { openVerifyCollectionDialog }
) => (
  <div className="d-flex justify-content-center align-items-center gap">
    {row.verified ? (
      <>
        <button className="btn btn-icon btn-light btn-hover-secondary btn-sm">
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <GoUnverified
              className="text-primary"
              onClick={() => openVerifyCollectionDialog(row._id)}
            />
            {/* <button type="button" className="btn btn-primary d-flex mx-auto"  onClick={() => openVerifyCollectionDialog(row._id)}>Un-Verify</button> */}
          </span>
        </button>
      </>
    ) : (
      <>
        {/* <button type="button" className="btn btn-outline-primary d-flex mx-auto"  onClick={() => openVerifyCollectionDialog(row._id)}>Verify</button> */}

        <button
          className="btn btn-icon btn-light btn-hover-secondary btn-sm"
          // onClick={() => openDeleteCollectionDialog(row.id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-info">
            {/* <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} /> */}
            <GoVerified
              className="text-info"
              onClick={() => openVerifyCollectionDialog(row._id)}
            />
          </span>
        </button>
      </>
    )}
    <Link to={"/nft/collection/edit/"+row._id}
      className="btn btn-icon btn-light btn-hover-primary btn-sm"
      // onClick={() => openDeleteCollectionDialog(row.id)}
    >
      <span className="svg-icon svg-icon-md svg-icon-primary">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
      </span>
    </Link>

    <button
      className="btn btn-icon btn-light btn-hover-primary btn-sm"
      // onClick={() => openDeleteCollectionDialog(row.id)}
    >
      <span className="svg-icon svg-icon-md svg-icon-primary">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Play.svg")} />
      </span>
    </button>
  </div>
);
