import React from "react";
import { Route } from "react-router-dom";
import { ReferralLoadingDialog } from "./referrals-loading-dialog/ReferralsLoadingDialog";
import { ReferralDeleteDialog } from "./referral-delete-dialog/referralDeleteDialog";
// import { ReferralDeleteDialog } from "./referrals-delete-dialog/ReferralDeleteDialog";
import { ReferralsFetchDialog } from "./referrals-fetch-dialog/ReferralsFetchDialog";
import { ReferralUpdateStatusDialog } from "./referrals-update-status-dialog/ReferralsUpdateStatusDialog";
// import { ReferralVerifyDialog } from "./referrals-verify-dialog/referralVerifyDialog";
import { ReferralCard } from "./ReferralsCard";
import { ReferralUIProvider } from "./ReferralsUIContext";
// import { ReferralEndEditBetStatusDialog } from "./referrals-edit-betstatus-dialog/ReferralEditBetStatusDialog";

export function ReferralsPage({ history }) {
  const referralUIEvents = {
    newReferralButtonClick: () => {
      history.push("/cock-boxing/referrals/new");
    },
    openReferralBetEndEditDialog: (id) => {
      history.push(`/cock-boxing/referrals/betend/${id}`);
    },
    openEditReferralPage: (id) => {
      history.push(`/cock-boxing/referrals/${id}/edit`);
    },
    openVerifyReferralDialog: (id) => {
      history.push(`/cock-boxing/referrals/${id}/verify`);
    },
    openVerifyReferralDialog: (id) => {
      history.push(`/cock-boxing/referrals/${id}/verify`);
    },
    openDeleteReferralDialog: (id) => {
      history.push(`/cock-boxing/referrals/${id}/delete`);
    },
    openDeleteReferralDialog: () => {
      history.push(`/cock-boxing/referrals/deleteReferral`);
    },
    openFetchReferralDialog: () => {
      history.push(`/cock-boxing/referrals/fetch`);
    },
    openUpdateReferralStatusDialog: () => {
      history.push("/cock-boxing/referrals/updateStatus");
    },
  };

  return (
    <ReferralUIProvider referralUIEvents={referralUIEvents}>
      <ReferralLoadingDialog />
      {/* <Route path="/cock-boxing/referral/deleteReferral">
        {({ history, match }) => (
          <ReferralDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/referral");
            }}
          />
        )}
      </Route> */}
      {/* <Route path="/cock-boxing/referral/:id/verify">
        {({ history, match }) => (
          <ReferralVerifyDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/referral");
            }}
          />
        )}
      </Route> */}
      <Route path="/cock-boxing/referrals/:id/delete">
        {({ history, match }) => (
          <ReferralDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/referrals");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/referrals/fetch">
        {({ history, match }) => (
          <ReferralsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/referrals");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/referrals/updateStatus">
        {({ history, match }) => (
          <ReferralUpdateStatusDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/referrals");
            }}
          />
        )}
      </Route>
      {/* <Route path='/cock-boxing/referral/betend/:id'>
        {({ history, match }) => (
          <ReferralEndEditBetStatusDialog
            id={match && match.params.id}
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/referral");
            }}
          />
        )}
      </Route> */}
      <ReferralCard />
    </ReferralUIProvider>
  );
}
