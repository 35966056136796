import React from "react";
import { Route } from "react-router-dom";
import { DepositsLoadingDialog } from "./deposits-loading-dialog/DepositsLoadingDialog";
import { DepositDeleteDialog } from "./deposit-delete-dialog/depositDeleteDialog";
import { DepositsDeleteDialog } from "./deposits-delete-dialog/DepositsDeleteDialog";
import { DepositsFetchDialog } from "./deposits-fetch-dialog/DepositsFetchDialog";
import { DepositsUpdateStatusDialog } from "./deposits-update-status-dialog/DepositsUpdateStatusDialog";
import { DepositsVerifyDialog } from "./deposit-verify-dialog/depositVerifyDialog";
import { DepositsCard } from "./DepositsCard";
import { DepositsUIProvider } from "./DepositsUIContext";
import { DepositEndEditBetStatusDialog } from "./deposit-edit-betstatus-dialog/DepositEditBetStatusDialog";

export function DepositsPage({ history }) {
  const depositsUIEvents = {
    newDepositButtonClick: () => {
      history.push("/cock-boxing/deposits/new");
    },
    openDepositBetEndEditDialog: (id) => {
      history.push(`/cock-boxing/deposits/betend/${id}`);
    },
    openEditDepositPage: (id) => {
      history.push(`/cock-boxing/deposits/${id}/edit`);
    },
    openVerifyDepositDialog: (id) => {
      history.push(`/cock-boxing/deposits/${id}/verify`);
    },
    openVerifyDepositDialog: (id) => {
      history.push(`/cock-boxing/deposits/${id}/verify`);
    },
    openDeleteDepositDialog: (id) => {
      history.push(`/cock-boxing/deposits/${id}/delete`);
    },
    openDeleteDepositsDialog: () => {
      history.push(`/cock-boxing/deposits/deleteDeposits`);
    },
    openFetchDepositsDialog: () => {
      history.push(`/cock-boxing/deposits/fetch`);
    },
    openUpdateDepositsStatusDialog: () => {
      history.push("/cock-boxing/deposits/updateStatus");
    },
  };

  return (
    <DepositsUIProvider depositsUIEvents={depositsUIEvents}>
      <DepositsLoadingDialog />
      <Route path="/cock-boxing/deposits/deleteDeposits">
        {({ history, match }) => (
          <DepositsDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/deposits");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/deposits/:id/verify">
        {({ history, match }) => (
          <DepositsVerifyDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/deposits");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/deposits/:id/delete">
        {({ history, match }) => (
          <DepositDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/deposits");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/deposits/fetch">
        {({ history, match }) => (
          <DepositsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/deposits");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/deposits/updateStatus">
        {({ history, match }) => (
          <DepositsUpdateStatusDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/deposits");
            }}
          />
        )}
      </Route>
      <Route path='/cock-boxing/deposits/betend/:id'>
        {({ history, match }) => (
          <DepositEndEditBetStatusDialog
            id={match && match.params.id}
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/deposits");
            }}
          />
        )}
      </Route>
      <DepositsCard />
    </DepositsUIProvider>
  );
}
