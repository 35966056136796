import React from "react";
import { Route } from "react-router-dom";
import { CockPicksLoadingDialog } from "./cockPicks-loading-dialog/CockPicksLoadingDialog";
import { CockPickDeleteDialog } from "./cockPick-delete-dialog/cockPickDeleteDialog";
import { CockPicksDeleteDialog } from "./cockPicks-delete-dialog/CockPicksDeleteDialog";
import { CockPicksFetchDialog } from "./cockPicks-fetch-dialog/CockPicksFetchDialog";
import { CockPicksUpdateStatusDialog } from "./cockPicks-update-status-dialog/CockPicksUpdateStatusDialog";
import { CockPicksVerifyDialog } from "./cockPick-verify-dialog/cockPickVerifyDialog";
import { CockPicksCard } from "./CockPicksCard";
import { CockPicksUIProvider } from "./CockPicksUIContext";
import { CockPickEndEditBetStatusDialog } from "./cockPick-edit-betstatus-dialog/CockPickEditBetStatusDialog";

export function CockPicksPage({ history }) {
  const cockPicksUIEvents = {
    newCockPickButtonClick: () => {
      history.push("/cock-boxing/cockPicks/new");
    },
    openCockPickBetEndEditDialog: (id) => {
      history.push(`/cock-boxing/cockPicks/betend/${id}`);
    },
    openEditCockPickPage: (id) => {
      history.push(`/cock-boxing/cockPicks/${id}/edit`);
    },
    openVerifyCockPickDialog: (id) => {
      history.push(`/cock-boxing/cockPicks/${id}/verify`);
    },
    openVerifyCockPickDialog: (id) => {
      history.push(`/cock-boxing/cockPicks/${id}/verify`);
    },
    openDeleteCockPickDialog: (id) => {
      history.push(`/cock-boxing/cockPicks/${id}/delete`);
    },
    openDeleteCockPicksDialog: () => {
      history.push(`/cock-boxing/cockPicks/deleteCockPicks`);
    },
    openFetchCockPicksDialog: () => {
      history.push(`/cock-boxing/cockPicks/fetch`);
    },
    openUpdateCockPicksStatusDialog: () => {
      history.push("/cock-boxing/cockPicks/updateStatus");
    },
  };

  return (
    <CockPicksUIProvider cockPicksUIEvents={cockPicksUIEvents}>
      <CockPicksLoadingDialog />
      <Route path="/cock-boxing/cockPicks/deleteCockPicks">
        {({ history, match }) => (
          <CockPicksDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/cockPicks");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/cockPicks/:id/verify">
        {({ history, match }) => (
          <CockPicksVerifyDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/cockPicks");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/cockPicks/:id/delete">
        {({ history, match }) => (
          <CockPickDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/cockPicks");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/cockPicks/fetch">
        {({ history, match }) => (
          <CockPicksFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/cockPicks");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/cockPicks/updateStatus">
        {({ history, match }) => (
          <CockPicksUpdateStatusDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/cockPicks");
            }}
          />
        )}
      </Route>
      <Route path='/cock-boxing/cockPicks/betend/:id'>
        {({ history, match }) => (
          <CockPickEndEditBetStatusDialog
            id={match && match.params.id}
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/cockPicks");
            }}
          />
        )}
      </Route>
      <CockPicksCard />
    </CockPicksUIProvider>
  );
}
