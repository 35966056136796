import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./StakingUIHelpers";

const EventsUIContext = createContext();

export function useEventsUIContext() {
  return useContext(EventsUIContext);
}

export const EventsUIConsumer = EventsUIContext.Consumer;

export function EventsUIProvider({ eventsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    newEventButtonClick: eventsUIEvents.newEventButtonClick,
    openEditEventPage: eventsUIEvents.openEditEventPage,
    openDeleteEventDialog: eventsUIEvents.openDeleteEventDialog,
    openDeleteEventsDialog: eventsUIEvents.openDeleteEventsDialog,
    openFetchEventsDialog: eventsUIEvents.openFetchEventsDialog,
    openUpdateEventsStatusDialog:
      eventsUIEvents.openUpdateEventsStatusDialog,
      openVerifyEventDialog:eventsUIEvents.openVerifyEventDialog
  };

  return (
    <EventsUIContext.Provider value={value}>
      {children}
    </EventsUIContext.Provider>
  );
}
