/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../../_redux/products/productsActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
// import { ProductEditForm } from "./ProductEditForm";
// import { ProductDetail } from "./ProductDetail";
import { Specifications } from "../product-specifications/Specifications";
import { SpecificationsUIProvider } from "../product-specifications/SpecificationsUIContext";
import { useSubheader } from "../../../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import { RemarksUIProvider } from "../product-remarks/RemarksUIContext";
import { Remarks } from "../product-remarks/Remarks";
import { useParams } from "react-router-dom";
import { ProductDetail } from "../../collectionProducts/product-details/ProductDetail";
import { ProductDetailIno } from "./ProductDetailino";

const initProduct = {
  id: undefined,
  model: "",
  manufacture: "Pontiac",
  modelYear: 2020,
  mileage: 0,
  description: "",
  color: "Red",
  price: 10000,
  condition: 1,
  status: 0,
  VINCode: "",
  attachments:[
    {
      "url": "https://c.tenor.com/waSNZA4y2qsAAAAM/cmyk-ae.gif",
      "fileType": "IMAGE",
      }
  ]
};

export function Productdet({
  history}) {
  // Subheader
  const suhbeader = useSubheader();

  // Tabs
  const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  const {id} = useParams('')
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, product } = useSelector(
    (state) => ({
      actionsLoading: state.products.actionsLoading,
      product: state.products.product
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(actions.fetchIdo(id));
    console.log(actions.fetchIdo(id),"errs")
  }, [id, dispatch]);
console.log(id,"doods")
  useEffect(() => {
    let _title = id ? "Product Details" : "Product Details";
    if (product && id) {
      _title = `Product [${product?.title} ]`;
      // _title = `Product [${product?.title} - ${product?.displayName}]`;
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, id]);

  const saveProduct = (values) => {
    // if (!id) {
    //   dispatch(actions.createProduct(values)).then(() => backToProductsList());
    // } else {
    //   dispatch(actions.updateProduct(values)).then(() => backToProductsList());
    // }
  };

  const btnRef = useRef();
  const saveProductClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToProductsList = () => {
      history.push(`/nft/financialAssets`);
    // history.push(`/nft/nftcollection/products/${product.collectionId._id}`);
  };
  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToProductsList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Back
          </button>
          {/* {`  `}
          <button className="btn btn-light ml-2">
            <i className="fa fa-redo"></i>
            Reset
          </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveProductClick}
          >
            Save
          </button> */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ul className="nav nav-tabs nav-tabs-line " role="tablist">
          <li className="nav-item" onClick={() => setTab("basic")}>
            <a
              className={`nav-link ${tab === "basic" && "active"}`}
              data-toggle="tab"
              role="tab"
              aria-selected={(tab === "basic").toString()}
            >
              Basic info
            </a>
          </li>
          {/* {id && (
            <>
              {" "}
              <li className="nav-item" onClick={() => setTab("remarks")}>
                <a
                  className={`nav-link ${tab === "remarks" && "active"}`}
                  data-toggle="tab"
                  role="button"
                  aria-selected={(tab === "remarks").toString()}
                >
                  Comments
                </a>
              </li>
              <li className="nav-item" onClick={() => setTab("specs")}>
                <a
                  className={`nav-link ${tab === "specs" && "active"}`}
                  data-toggle="tab"
                  role="tab"
                  aria-selected={(tab === "specs").toString()}
                >
                  Product specifications
                </a>
              </li>
            </>
          )} */}
        </ul>
        <div className="mt-5">
          {tab === "basic" && (
            <ProductDetailIno
              actionsLoading={actionsLoading}
              product={product || initProduct}
              btnRef={btnRef}
              saveProduct={saveProduct}
            />
          )}
          {tab === "remarks" && id && (
            <RemarksUIProvider currentProductId={id}>
              <Remarks />
            </RemarksUIProvider>
          )}
          {tab === "specs" && id && (
            <SpecificationsUIProvider currentProductId={id}>
              <Specifications />
            </SpecificationsUIProvider>
          )}
        </div>
      </CardBody>
    </Card>
  );
}
