// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as actions from "../../../_redux/fights/fightsActions";
import { ActionsColumnFormatter } from "./column-formatters/ActionsColumnFormatter";
import { AmountColumnFormatter } from "./column-formatters/AmountColumnFormatter";
import * as uiHelpers from "./SpecificationsUIHelper";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import { useSpecificationsUIContext } from "./SpecificationsUIContext";

export function SpecificationsTable({id}) {
  // Specs UI Context
  const specsUIContext = useSpecificationsUIContext();
  const specsUIProps = useMemo(() => {
    return {
      queryParams: specsUIContext.queryParams,
      setQueryParams: specsUIContext.setQueryParams,
      openEditSpecificationDialog: specsUIContext.openEditSpecificationDialog,
      openDeleteSpecificationDialog:
        specsUIContext.openDeleteSpecificationDialog,
      ids: specsUIContext.ids,
      setIds: specsUIContext.setIds,
      productId: specsUIContext.productId,
    };
  }, [specsUIContext]);

  // Specs Redux state
  // Getting curret state of products list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.fights }),
    shallowEqual
  );
  const { rewardCount, reward, listLoading } = currentState;
  const dispatch = useDispatch();
  useEffect(() => {
    specsUIProps.setIds([]);
    dispatch(
      actions.fetchRewards(specsUIProps.queryParams, id)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specsUIProps.queryParams, dispatch, id]);
  const columns = [
    {
      dataField: "user[0].firstName",
      text: "First Name",
    },
    {
      dataField: "user[0].lastName",
      text: "Last Name",
    },
    {
      dataField: "user[0].email",
      text: "Email",
    },
    {
      dataField: "amount",
      text: "Amount",
      sort: true,
      sortCaret: sortCaret,
      formatter: AmountColumnFormatter,
    },
    // {
    //   dataField: "action",
    //   text: "Actions",
    //   formatter: ActionsColumnFormatter,
    //   formatExtraData: {
    //     openEditSpecificationDialog: specsUIProps.openEditSpecificationDialog,
    //     openDeleteSpecificationDialog:
    //       specsUIProps.openDeleteSpecificationDialog,
    //   },
    //   classes: "text-right pr-0",
    //   headerClasses: "text-right pr-3",
    //   style: {
    //     minWidth: "100px",
    //   },
    // },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: rewardCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: specsUIProps.queryParams.pageSize,
    page: specsUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bordered={false}
                bootstrap4
                remote
                keyField="id"
                data={reward === null ? [] : reward}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  specsUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   reward,
                //   ids: specsUIProps.ids,
                //   setIds: specsUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={reward} />
                <NoRecordsFoundMessage entities={reward} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
