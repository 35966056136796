import { createSlice } from "@reduxjs/toolkit";

const initialAffiliatesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  affiliateForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const affiliatesSlice = createSlice({
  name: "affiliates",
  initialState: initialAffiliatesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getAffiliateById
    affiliateFetched: (state, action) => {
      state.actionsLoading = false;
      state.affiliateForEdit = action.payload.affiliateForEdit;
      state.error = null;
    },
    // findAffiliates
    affiliatesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createAffiliate
    affiliateCreated: (state, action) => {
      state.ewactionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.affiliate);
    },
    // updateAffiliate
    affiliateUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.affiliate.id) {
          return action.payload.affiliate;
        }
        return entity;
      });
    },
    // deleteAffiliate
    affiliateDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteAffiliates
    affiliatesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // affiliatesUpdateState
    affiliatesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
