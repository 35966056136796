import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useProductsUIContext } from "../ProductsUIContext";
import * as actions from "../../../_redux/collections/collectionsActions";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

export function CollectionDetails() {
  // Products UI Context
  const productsUIContext = useProductsUIContext();
  const { id } = useParams();
  const productsUIProps = useMemo(() => {
    return {
      ids: productsUIContext.ids,
      setIds: productsUIContext.setIds,
      openDeleteProductsDialog: productsUIContext.openDeleteProductsDialog,
      openFetchProductsDialog: productsUIContext.openFetchProductsDialog,
      openUpdateProductsStatusDialog:
        productsUIContext.openUpdateProductsStatusDialog,
    };
  }, [productsUIContext]);

  const dispatch = useDispatch();
  const { actionsLoading, collection } = useSelector(
    (state) => ({
      actionsLoading: state.collections.actionsLoading,
      collection: state.collections.collection,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchCollection(id));
  }, [id, dispatch]);
  return (
    <>
      {actionsLoading ? (
        <div className="pr-4 text-center m-5">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div>
          <img
            style={{
              boxShadow: "0 5px 30px -3px #262525",
              borderRadius: "20px",
              width: "100%",
              objectFit: "cover",
              height: "250px",
            }}
            src={collection?.bannerImage}
          />
          <img
            style={{
              borderRadius: "50%",
              height: "150px",
              objectFit: "cover",
              width:"150px",
              display: "flex",
              margin: "-100px auto 0 auto",
              boxShadow: "0 5px 30px -3px #262525",
            }}
            src={collection?.logo}
          />
          <div
            style={{ fontSize: "32px", textAlign: "center", fontWeight: 700 }}
          >
            {collection?.title}
          </div>
          <div>{collection?.description}</div>
          <hr />
        </div>
      )}
    </>
  );
}
