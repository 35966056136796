/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/deposits/depositsActions";
import { useDepositsUIContext } from "../DepositsUIContext";

export function DepositsDeleteDialog({ show, onHide }) {
  // Deposits UI Context
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  const depositsUIContext = useDepositsUIContext();
  const depositsUIProps = useMemo(() => {
    return {
      ids: depositsUIContext.ids,
      setIds: depositsUIContext.setIds,
      queryParams: depositsUIContext.queryParams,
    };
  }, [depositsUIContext]);

  // Deposits Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.deposits.actionsLoading }),
    shallowEqual
  );

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  // if there weren't selected deposits we should close modal
  useEffect(() => {
    if (!depositsUIProps.ids || depositsUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depositsUIProps.ids]);

  const deleteDeposits = () => {
    // server request for deleting deposit by seleted ids
    dispatch(actions.deleteDeposits(depositsUIProps.ids)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchDeposits(depositsUIProps.queryParams,authToken)).then(() => {
        // clear selections list
        depositsUIProps.setIds([]);
        // closing delete modal
        onHide();
      });
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {isLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Deposits Delete
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>Are you sure to permanently delete selected deposits?</span>
        )}
        {isLoading && <span>Deposits are deleting...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteDeposits}
            className="btn btn-primary btn-elevate"
          >
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
