import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./SolanasUIHelpers";

const SolanaUIContext = createContext();

export function useSolanasUIContext() {
  return useContext(SolanaUIContext);
}

export const SolanaUIConsumer = SolanaUIContext.Consumer;

export function SolanaUIProvider({ solanaUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [id, setId] = useState('');
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);
  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setId,
    id,
    setQueryParams,
    newSolanaButtonClick: solanaUIEvents.newSolanaButtonClick,
    openEditSolanaPage: solanaUIEvents.openEditSolanaPage,
    openDeleteSolanaDialog: solanaUIEvents.openDeleteSolanaDialog,
    openSolanaEndEditDialog: solanaUIEvents.openSolanaEndEditDialog,
    openDeleteSolanaDialog: solanaUIEvents.openDeleteSolanaDialog,
    openFetchSolanaDialog: solanaUIEvents.openFetchSolanaDialog,
    openSolanaBetEndEditDialog: solanaUIEvents.openSolanaBetEndEditDialog,
    openUpdateSolanaStatusDialog:
      solanaUIEvents.openUpdateSolanaStatusDialog,
    openVerifySolanaDialog: solanaUIEvents.openVerifySolanaDialog,
    initSolana: solanaUIEvents.initSolana,

  };

  return (
    <SolanaUIContext.Provider value={value}>
      {children}
    </SolanaUIContext.Provider>
  );
}
