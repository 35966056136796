// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../../../../_metronic/_partials/controls";
import {
  AVAILABLE_COLORS,
  AVAILABLE_MANUFACTURES,
  ProductStatusTitles,
  ProductConditionTitles,
} from "../ProductsUIHelpers";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { SvgIcon, Grid, Typography, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Accordion } from "@material-ui/core";
import { AccordionSummary } from "@material-ui/core";
import { AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
// Validation schema
const ProductEditSchema = Yup.object().shape({
  model: Yup.string()
    .min(2, "Minimum 2 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Model is required"),
  manufacture: Yup.string()
    .min(2, "Minimum 2 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Manufacture is required"),
  modelYear: Yup.number()
    .min(1950, "1950 is minimum")
    .max(2020, "2020 is maximum")
    .required("Model year is required"),
  mileage: Yup.number()
    .min(0, "0 is minimum")
    .max(1000000, "1000000 is maximum")
    .required("Mileage is required"),
  color: Yup.string().required("Color is required"),
  price: Yup.number()
    .min(1, "$1 is minimum")
    .max(1000000, "$1000000 is maximum")
    .required("Price is required"),
  VINCode: Yup.string().required("VINCode is required"),
});

export function ProductDetailIno({ product, btnRef, saveProduct }) {
  console.log(product, "popins");
  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <img src={product?.assetsId?.imageUrl} className="w-100" alt="" />
        </div>
        <div className="col-lg-6">
          <div className="row">
            <h3 className="col">{product?.displayName}</h3>
            {/* <div className="col">
              <p
                className="border border-info px-3 py-1"
                style={{ height: "fit-content", width: "fit-content" }}
              >
                {product?.tag}
              </p>
            </div> */}
          </div>
          <div className="d-flex">
            {/* <div>
              <span className="pr-4">
                Views <RemoveRedEyeIcon color="secondary" /> :{" "}
                {product?.views?.length}{" "}
              </span>
              <span className="pr-4">
                Likes <FavoriteIcon color="error" /> : {product?.likes}
              </span>
              <span>
                Items <ShoppingCartIcon color="disabled" /> :{" "}
                {product?.collectionId?.items}
              </span>
            </div> */}
            <div className="h4 mb-0">Description</div>
          </div>
          <hr />
          <p className="my-1">{product?.assetsId?.description}</p>

          <div className="my-4">
            <Accordion square>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="primary" />}
                aria-controls="contract_details"
                id="contract_details"
                className="bg-dark text-white rounded-top"
              >
                <div className="h4 mb-0">Token</div>
              </AccordionSummary>
              <AccordionDetails className="rounded-bottom d-block w-100 p-4">
                <div className="row mb-5">
                  <div className="col-6 h6 mb-0">Token</div>
                  <div className="col-6">
                    <p
                      className=" text-info font-weight-bolder mb-0"
                      style={{ fontSize: "13px" }}
                    >
                      {product?.title}
                      {/* || {product?.tokenDetails?.Token} */}
                    </p>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-6 h6 mb-0">Price</div>
                  <div className="col-6">
                    <p
                      className=" text-secondary mb-0"
                      style={{ fontSize: "13px" }}
                    >
                      {product?.NFTdetails?.Price}
                      {/* {product?.tokenDetails?.InitialSupply}  */}
                    </p>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-6 h6 mb-0">TotalRaise</div>
                  <div className="col-6">
                    <p
                      className=" text-info mb-0 font-weight-bolder"
                      style={{ fontSize: "13px" }}
                    >
                      {product?.totalRaise}
                    </p>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-6 h6 mb-0">Network</div>
                  <div className="col-6">
                    <p
                      className=" text-secondary mb-0"
                      style={{ fontSize: "13px" }}
                    >
                      {product?.network}
                    </p>
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-6 h6 mb-0">UpdatedAt</div>
                  <div className="col-6">
                    <p
                      className=" text-secondary mb-0"
                      style={{ fontSize: "13px" }}
                    >
                      {moment(product?.updatedAt).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-6 h6 mb-0">Total Supply</div>
                  <div className="col-6">
                    <p
                      className=" text-secondary mb-0"
                      style={{ fontSize: "13px" }}
                    >
                      {product?.tokenDetails?.totalSupply}
                    </p>
                  </div>
                </div> */}
              </AccordionDetails>
            </Accordion>
          </div>
          {/* <div className="my-4">
            <Accordion square>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="primary" />}
                aria-controls="contract_details"
                id="contract_details"
                className="bg-dark text-white rounded-top"
              >
                <div className="h4 mb-0">Details</div>
              </AccordionSummary>
              <AccordionDetails className="rounded-bottom d-block w-100 p-4">

                <div className="row mb-5">
                  <div className="col-6 h6 mb-0">Price</div>
                  <div className="col-6">
                    <p
                      className=" text-secondary mb-0"
                      style={{ fontSize: "13px" }}
                    >
                      {product?.assetsId?.tokenPrice}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 h6 mb-0">Tag</div>
                  <div className="col-6">
                    <p
                      className=" text-secondary mb-0"
                      style={{ fontSize: "13px" }}
                    >
                      {product?.tag}
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div> */}
          <div className="my-4">
            <Accordion square>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="primary" />}
                aria-controls="attributes"
                id="attributes"
                className="bg-dark text-white rounded-top"
              >
                <div className="h4 mb-0">Rules</div>
              </AccordionSummary>
              <AccordionDetails className="rounded-bottom d-block w-100 p-4">
                <div className="row mb-5">
                  <div className="col-6 h6 mb-0">Rules</div>
                  <div className="col-6">
                    <p
                      className=" text-info font-weight-bolder mb-0"
                      style={{ fontSize: "13px" }}
                    >
                      {product?.rules}
                      {/* || {product?.tokenDetails?.Token} */}
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="my-4">
            <Accordion square>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="primary" />}
                aria-controls="attributes"
                id="attributes"
                className="bg-dark text-white rounded-top"
              >
                <div className="h4 mb-0">NFT Utilities</div>
              </AccordionSummary>
              <AccordionDetails className="rounded-bottom d-block w-100 p-4">
                <div className="row mb-5">
                  <div className="col-6 h6 mb-0">NFT Utilities</div>
                  <div className="col-6">
                    <p
                      className=" text-info font-weight-bolder mb-0"
                      style={{ fontSize: "13px" }}
                    >
                      {product?.NFTutilities}
                      {/* || {product?.tokenDetails?.Token} */}
                    </p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
}
