import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./CockPicksUIHelpers";

const CockPicksUIContext = createContext();

export function useCockPicksUIContext() {
  return useContext(CockPicksUIContext);
}

export const CockPicksUIConsumer = CockPicksUIContext.Consumer;

export function CockPicksUIProvider({ cockPicksUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);
  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    newCockPickButtonClick: cockPicksUIEvents.newCockPickButtonClick,
    openEditCockPickPage: cockPicksUIEvents.openEditCockPickPage,
    openDeleteCockPickDialog: cockPicksUIEvents.openDeleteCockPickDialog,
    openCockPickEndEditDialog: cockPicksUIEvents.openCockPickEndEditDialog,
    openDeleteCockPicksDialog: cockPicksUIEvents.openDeleteCockPicksDialog,
    openFetchCockPicksDialog: cockPicksUIEvents.openFetchCockPicksDialog,
    openCockPickBetEndEditDialog: cockPicksUIEvents.openCockPickBetEndEditDialog,
    openUpdateCockPicksStatusDialog:
      cockPicksUIEvents.openUpdateCockPicksStatusDialog,
    openVerifyCockPickDialog: cockPicksUIEvents.openVerifyCockPickDialog,
    initCockPick: cockPicksUIEvents.initCockPick,

  };

  return (
    <CockPicksUIContext.Provider value={value}>
      {children}
    </CockPicksUIContext.Provider>
  );
}
