import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../../_redux/users/eventsActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  ModalProgressBar,
} from "../../../../../../_metronic/_partials/controls";
import { GetPlayerTranscationbyid, GetPlayerbalancebyid } from "../../../../../../services/ApiServices";
import { useParams } from "react-router-dom";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { Pagination } from "../../../../../../_metronic/_partials/controls";

export function UserDetails({ history }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  // Fetch event details
  useEffect(() => {
    dispatch(actions.fetchEvent(id));
  }, [id, dispatch]);

  const [balance, setBalance] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const { actionsLoading, event } = useSelector(
    (state) => ({
      actionsLoading: state.events.actionsLoading,
      event: state.events.event,
    }),
    shallowEqual
  );

  const getByid = useCallback(async () => {
    const uid = event?.uid;
    if (!uid) return;
    setLoading(true);
    try {
      const result = await GetPlayerTranscationbyid(uid);
      if (result.success) {
        console.log(result, "result");
        setTransactions(result?.transactions.docs);
      }
    } catch (e) {
      console.error("Error fetching transaction by id:", e);
    } finally {
      setLoading(false);
    }
  }, [event?.uid]);

  useEffect(() => {
    if (event?.uid) {
      getByid();
    }
  }, [event?.uid, getByid]);

  const getbalanceByid = useCallback(async () => {
    const uid = event?.uid;
    if (!uid) return;
    setLoading(true);
    try {
      const result = await GetPlayerbalancebyid(uid);
      if (result.success) {
        console.log(result, "result");
        setBalance(result?.balance);
      }
    } catch (e) {
      console.error("Error fetching transaction by id:", e);
    } finally {
      setLoading(false);
    }
  }, [event?.uid]);

  useEffect(() => {
    if (event?.uid) {
      getbalanceByid();
    }
  }, [event?.uid, getbalanceByid]);

  const columns = [
    {
      dataField: "id",
      text: "S.NO",
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      style: { textAlign: "center" }
    },
    {
      dataField: "createdAt",
      text: "Date and Time",
      formatter: (cell, row) => moment(row.createdAt).utc().format("MM-DD-YYYY hh:mm") + " UTC",
      style: { textAlign: "center" }
    },
    {
      dataField: "type",
      text: "Description",
      formatter: (cell, row) => row.type === "cockpick" ? "Last 2 Digit bet on match no 2" : row.type,
      style: { textAlign: "center" }
    },
    {
      dataField: "amountIn",
      text: "Amount In",
      formatter: (cell, row) => {
        return row.amount > 0 ? (
          <button className="btn btn-secondary">
            ${parseFloat(row.amount).toFixed(2)}
          </button>
        ) : "-";
      },
      style: { textAlign: "center" }
    },
    {
      dataField: "amountOut",
      text: "Amount Out",
      formatter: (cell, row) => {
        return row.amount < 0 ? (
          <button className="btn btn-danger">
            ${Math.abs(parseFloat(row.amount)).toFixed(2)}
          </button>
        ) : "-";
      },
      style: { textAlign: "center" }
    },

    {
      dataField: "txHash",
      text: "Details",
      formatter: (cell, row) => row.txHash ? `${row.txHash.substring(0, 5)}....${row.txHash.substring(row.txHash.length - 5)}` : "-",
      style: { textAlign: "center", fontWeight: "bold" }
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: transactions.length,
    sizePerPageList: [{ text: "50", value: 50 }, { text: "100", value: 100 }, { text: "200", value: 200 }],
    sizePerPage: 50,
    page: 1,
  };

  const backToEventsList = () => {
    history.push(`/cock-boxing/players`);
  };

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title="Player Details">
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToEventsList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i> Back
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: "1 1 60%", paddingLeft: "20px" }}>
            <h4>{event?.firstName}{event?.lastName} &nbsp;<span style={{ fontSize: "14px" }} >({event?.email})</span> -<span style={{ fontSize: "14px" }} >{event?.country}</span></h4>
            <span style={{ fontSize: "16px" }} > Balance :  ${Math.abs(parseFloat(balance)).toFixed(2)}</span>
            <hr />
          </div>
        </div>
      </CardBody>
      <CardBody>
        <h4>Transaction Details</h4>
        {transactions.length > 0 ? (
          <>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
              {({ paginationProps, paginationTableProps }) => (
                <Pagination isLoading={loading} paginationProps={paginationProps}>
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bordered={false}
                    bootstrap4
                    keyField="id"
                    data={transactions}
                    columns={columns}
                    {...paginationTableProps}
                  />
                </Pagination>
              )}
            </PaginationProvider>
          </>
        ) : (
          <p>No transactions found</p>
        )}
      </CardBody>
    </Card>
  );
}
