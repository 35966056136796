import * as requestFromServer from "./arenasCrud";
import { arenasSlice, callTypes } from "./arenasSlice";
import {
  GetArenas,
  GetRewards,
  GetStake,
  VerifyArenas,
} from "../../../../../services/ApiServices";
const { actions } = arenasSlice;

export const fetchArenas = (queryParams) => async (dispatch) => {
  let query = "?";
  let body = { sort: {} };
  for (const key in queryParams) {
    switch (key) {
      case "sortOrder":
        body.sort[queryParams.sortField] = queryParams.sortOrder;
        // query = query.concat(queryParams.sortField+"=" + queryParams.sortField);
        break;
      case "pageNumber":
        query = query.concat("&page=" + queryParams.pageNumber);
        break;
      case "pageSize":
        query = query.concat("&limit=" + queryParams.pageSize);
        break;
      case "filter":
        if (queryParams.filter) body.filter = queryParams.filter;
        break;
      case "filterField":
        if (queryParams.filterField) body.filterField = queryParams.filterField;
        break;
      default:
        break;
    }
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer
  let result;
  try {
    result = await GetArenas(query, body);
  } catch (error) {
    error.clientMessage = "Can't find arenas";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      let totalCount = result.arena.totalDocs;
      let entities = result.arena.docs;
      dispatch(actions.arenasFetched({ totalCount, entities }));
    }
  } catch (error) {
    error.clientMessage = "Can't find arenas";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const fetchArenasById = (id) => async (dispatch) => {
  if (!id) {
    return dispatch(actions.fightFetched({ fightForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));

  let result;
  try {
    result = await GetArenas(id);
  } catch (error) {
    error.clientMessage = "Can't find arenas";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      const arenas = result.arenas;
      dispatch(actions.fightFetched({ arenas: arenas }));
    }
  } catch (error) {
    error.clientMessage = "Can't find arenas";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};
export const verifyArenas = (id, token) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  // return requestFromServer
  let result;
  try {
    result = await VerifyArenas(id, token);
  } catch (error) {
    error.clientMessage = "Can't verify arenas";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      dispatch(actions.fightDeleted({ id }));
    }
  } catch (error) {
    error.clientMessage = "Can't verify arenas";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }

  // .deleteArenas(id)
  // .then((response) => {
  //   dispatch(actions.fightDeleted({ id }));
  // })
  // .catch((error) => {
  //   error.clientMessage = "Can't delete arenas";
  //   dispatch(actions.catchError({ error, callType: callTypes.action }));
  // });
};
export const deleteArenas = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteArenas(id)
    .then((response) => {
      dispatch(actions.fightDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete arenas";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createArenas = (fightForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createArenas(fightForCreation)
    .then((response) => {
      const { arenas } = response.data;
      dispatch(actions.fightCreated({ arenas }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create arenas";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateArenasStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForArenas(ids, status)
    .then(() => {
      dispatch(actions.arenasStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update arenas status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteArenasByIds = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteArenas(ids)
    .then(() => {
      dispatch(actions.arenasDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete arenas";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchStake = (queryParams,id,name) => async (dispatch) => {
  let query = "?";
  let body = { sort: {} };
  for (const key in queryParams) {
    switch (key) {
      case "sortOrder":
        body.sort[queryParams.sortField] = queryParams.sortOrder=="asc"?1:-1;
        // query = query.concat(queryParams.sortField+"=" + queryParams.sortField);
        break;
      case "pageNumber":
        query = query.concat("&page=" + queryParams.pageNumber);
        break;
      case "pageSize":
        query = query.concat("&limit=" + queryParams.pageSize);
        break;
      case "filter":
        if (queryParams.filter) body.filter = queryParams.filter;
        break;
      case "filterField":
        if (queryParams.filterField) body.filterField = queryParams.filterField;
        break;
      default:
        break;
    }
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer
  let result;
  try {
    result = await GetStake(id,name,query,body);
  } catch (error) {
    error.clientMessage = "Can't find arenas";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      let totalCount = result.stake.totalDocs;
      let entities = result.stake.docs;
      dispatch(actions.stakeFetched({ totalCount, entities }));
    }
  } catch (error) {
    error.clientMessage = "Can't find arenas";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const fetchRewards = (queryParams,id) => async (dispatch) => {
  let query = "?";
  let body = { sort: {} };
  for (const key in queryParams) {
    switch (key) {
      case "sortOrder":
        body.sort[queryParams.sortField] = queryParams.sortOrder=="asc"?1:-1;
        // query = query.concat(queryParams.sortField+"=" + queryParams.sortField);
        break;
      case "pageNumber":
        query = query.concat("&page=" + queryParams.pageNumber);
        break;
      case "pageSize":
        query = query.concat("&limit=" + queryParams.pageSize);
        break;
      case "filter":
        if (queryParams.filter) body.filter = queryParams.filter;
        break;
      case "filterField":
        if (queryParams.filterField) body.filterField = queryParams.filterField;
        break;
      default:
        break;
    }
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer
  let result;
  try {
    result = await GetRewards(id,query,body);
  } catch (error) {
    error.clientMessage = "Can't find arenas";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      let totalCount = result.rewards.totalDocs;
      let entities = result.rewards.docs;
      dispatch(actions.rewardFetched({ totalCount, entities }));
    }
  } catch (error) {
    error.clientMessage = "Can't find arenas";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};