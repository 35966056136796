import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { EventStatusCssClasses } from "../EventsUIHelpers";
import * as actions from "../../../_redux/events/eventsActions";
import { useEventsUIContext } from "../EventsUIContext";
import { GetEvent } from "../../../../../../services/ApiServices";
import moment from "moment";


// const selectedEvents = () => {
//   let _events;
//   if (entities?.length) {
//     _events = entities.find((el) => el._ids === ids);
//   }
//   return _events;
// };

export function EventsUpdateStatusDialog({ id, show, onHide }) {
  const [detail, setDetail] = useState(0);
  const [loading, setLoading] = useState(false);
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  const getEvent = useCallback(async () => {
    if (!id) return;
    setLoading(true);
    try {
      const result = await GetEvent(id, authToken);
      if (result.success) {
        setDetail(result?.event);
      }
    } catch (e) {
      console.error("Error fetching transaction by id:", e);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      getEvent();
    }
  }, [id, getEvent]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton className="form">
        <Modal.Title id="example-modal-sizes-title-lg">
          Event Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default"
        style={{ width: '90%' }}
      >
        {/* {isLoading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-warning" />
          </div>
        )} */}
        <div className="list-timeline list-timeline-skin-light" style={{ padding: '0px' }}>
          <div className="list-timeline-items">
            <div className="list-timeline-item mb-3" key={detail?._id}>
              <div className="list-timeline-text" style={{ textAlign: 'center' }}>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Event Name </div>
                  <div style={{ fontWeight: '18px', width: "40%", textAlign: 'left' }}  > {detail?.name}</div>
                </div>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Date and Time</div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }}  >
                    <b>{moment(detail?.dateTime).format("MMM,DD YYYY")}</b>
                    <br />{moment(detail?.dateTime).format("hh:mm A")}
                  </div>
                </div>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Arena Name </div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }}  >{detail?.arena?.name}</div>
                </div>

                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>MediaServer</div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }} >{detail?.mediaServer}</div>
                </div>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>CreatedAt</div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }}  >
                    <b>{moment(detail?.createdAt).format("MMM,DD YYYY")}</b>
                    <br />{moment(detail?.createdAt).format("hh:mm A")}
                  </div>
                </div>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>RecordId</div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }}  >{detail?.arena?.recordId}</div>
                </div>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%", }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Status </div>
                  <div
                    style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }}
                    className={`label label-lg padding-8 label-light-${EventStatusCssClasses[detail?.status]} label-inline`}
                  >
                    {detail?.status}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer >

        <div className="form-group">
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Close
          </button>
          <> </>

        </div>
      </Modal.Footer>
    </Modal>
  );
}
