// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/deposits/depositsActions";
import * as uiHelpers from "../DepositsUIHelpers";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useDepositsUIContext } from "../DepositsUIContext";
import { useToasts } from "react-toast-notifications";
export function DepositsTable() {
  // Deposits UI Context
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  const depositsUIContext = useDepositsUIContext();
  const { addToast } = useToasts();
  const depositsUIProps = useMemo(() => {
    return {
      ids: depositsUIContext.ids,
      setIds: depositsUIContext.setIds,
      queryParams: depositsUIContext.queryParams,
      setQueryParams: depositsUIContext.setQueryParams,
      openEditDepositPage: depositsUIContext.openEditDepositPage,
      openDeleteDepositDialog: depositsUIContext.openDeleteDepositDialog,
      openVerifyDepositDialog: depositsUIContext.openVerifyDepositDialog,
      openDepositEndEditDialog: depositsUIContext.openDepositEndEditDialog,
      openDepositBetEndEditDialog:
        depositsUIContext.openDepositBetEndEditDialog,
    };
  }, [depositsUIContext]);
  // Getting curret state of deposits list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.deposits }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  // Deposits Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    depositsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchDeposits(depositsUIProps.queryParams,authToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depositsUIProps.queryParams, dispatch]);
  // Table columns
  const ended = () => {
    addToast("Deposit already ended", {
      appearance: "error",
      autoDismiss: "true",
    });
  };

  const serialNumer = (index) => {
    const startIndex = (depositsUIProps.queryParams.pageNumber - 1) * depositsUIProps.queryParams.pageSize;
    return startIndex + index + 1
  }
  const columns = [
    {
      text: "S.No",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return serialNumer(rowIndex);
      },
    },
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.DateColumnFormatter,
    },
    {
      dataField: "affiliate.name",
      text: "Operator Id",
      formatter: columnFormatters.AffiliateColumnFormatter,

    },
    {
      dataField: "user.firstName",
      text: "First Name",
      style: {
        textAlign: "left"
      },
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "user.lastName",
      text: "Last Name",
      style: {
        textAlign: "left"
      },

    },
    {
      dataField: "user.email",
      text: "Email",
      style: {
        textAlign: "left"
      },
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: columnFormatters.AmountColumnFormatter,
      formatExtraData: "amountIn",
      style: {
        textAlign: "right"
      },
    },
    // {
    //   dataField: "amount",
    //   text: "Amount Out",
    //   sort: true,
    //   formatter: columnFormatters.AmountColumnFormatter,
    //   formatExtraData: "amountOut"

    // },
    {
      dataField: "txHash",
      text: "Tx Hash",
      formatter: columnFormatters.DetailsColumnFormatter,
    },
    // {
    //   dataField: "status",
    //   text: "Status",
    //   formatter: columnFormatters.StatusColumnFormatter,
    // },
    // {
    //   dataField: "betStatus",
    //   text: "Bet Status",
    //   style:{
    //     textTransform: "capitalize",
    //   }
    // },
    // {

    // {
    //   dataField: "ticketNumber",
    //   text: "Ticket Number",
    // },

    // {
    //   dataField: "declareTime",
    //   text: "Declare Time",
    //   formatter: columnFormatters.DateColumnFormatter,
    // },

    // {
    //   dataField: "recordId",
    //   text: "Record Id",
    // },

    // {
    //   dataField: "active",
    //   text: "Active",
    //   sort: true,
    //   sortCaret: sortCaret,
    // },

    // {
    //   dataField: "action",
    //   text: "Actions",
    //   formatter: columnFormatters.ActionsColumnFormatter,
    //   formatExtraData: {
    //     openVerifyDepositDialog: depositsUIProps.openVerifyDepositDialog,
    //     openDepositBetEndEditDialog: depositsUIContext.openDepositBetEndEditDialog,
    //     ended:ended
    //     // openVerifyDepositPage: depositsUIProps.openVerifyDepositPage,
    //   },
    //   // classes: "text-right pr-0",
    //   // headerClasses: "text-right pr-3",
    //   style: {
    //     minWidth: "100px",
    //     textAlign: "center",
    //   },
    // },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: depositsUIProps.queryParams.pageSize,
    page: depositsUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"

                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="_id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  depositsUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: depositsUIProps.ids,
                //   setIds: depositsUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
