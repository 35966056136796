import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { ReferralFilter } from "./referrals-filter/ReferralsFilter";
import { ReferralTable } from "./referrals-table/ReferralsTable";
import { ReferralGrouping } from "./referrals-grouping/ReferralsGrouping";
import { useReferralsUIContext } from "./ReferralsUIContext";
// import { Link } from "react-router-dom";
// import { DropdownMenu5, DropdownCustomToggler } from "../../dropdowns";
import { Dropdown } from "react-bootstrap";
import { useRef } from 'react';
import { DropdownMenu6 } from "../../../../../_metronic/_partials/dropdowns/DropdownMenu6";

export function ReferralCard() {
  const referralUIContext = useReferralsUIContext();
  const targetRef = useRef();

  const referralUIProps = useMemo(() => {
    return {
      ids: referralUIContext.ids,
      queryParams: referralUIContext.queryParams,
      setQueryParams: referralUIContext.setQueryParams,
      newReferralButtonClick: referralUIContext.newReferralButtonClick,
      openDeleteReferralDialog: referralUIContext.openDeleteReferralDialog,
      openEditReferralPage: referralUIContext.openEditReferralPage,
      openUpdateReferralStatusDialog:
        referralUIContext.openUpdateReferralStatusDialog,
      openFetchReferralDialog: referralUIContext.openFetchReferralDialog,
      openVerifyReferralDialog: referralUIContext.openVerifyReferralDialog,
      openreferralShowsite: referralUIContext.openreferralShowsite,
      openreferralHidesite: referralUIContext.openreferralHidesite


    };
  }, [referralUIContext]);

  return (
    <Card>
      <CardHeader title="Referral list">
        <CardHeaderToolbar>
          {/* <Dropdown className="dropdown-inline" drop="down" alignRight>
            <Dropdown.Toggle
              className="btn btn-clean btn-hover-light-primary"
              id="dropdown-toggle-top">
              Export
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <DropdownMenu6 targetRef={targetRef} />
            </Dropdown.Menu>
          </Dropdown> */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ReferralFilter />
        {/* {referralUIProps.ids.length > 0 && (
          <>
            <ReferralGrouping />
          </>
        )} */}
        <div ref={targetRef}>
          <ReferralTable />
        </div>
      </CardBody>
    </Card>
  );
}
