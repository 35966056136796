import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/customers/customersActions";
import { CustomerEditDialogHeader } from "./CustomerEditDialogHeader";
import { CustomerEditForm } from "./CustomerEditForm";
import { useCustomersUIContext } from "../CustomersUIContext";
import { CreateAdmin, UpdateAdmin } from "../../../../../../services/ApiServices";
import { useToasts } from "react-toast-notifications";
import { useState } from "react";

export function CustomerEditDialog({ id, show, onHide }) {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const [isLoading, setIsLoading] = useState(false)
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  const { addToast } = useToasts();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      initCustomer: customersUIContext.initCustomer,
    };
  }, [customersUIContext]);

  // Customers Redux state
  const dispatch = useDispatch();
  const { actionsLoading, customerForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.customers.actionsLoading,
      customerForEdit: state.customers.customerForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Customer by id
    dispatch(actions.fetchCustomer(id, authToken));
  }, [id, dispatch]);

  // server request for saving customer
  const saveCustomer = async (customer) => {
    setIsLoading(true)
    let result;
    let body = {
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
      password: customer.password,
      role: customer.role.value,
      affiliateRefId: customer.affiliateId,
    }
    try {
      if (id)
        result = await UpdateAdmin(id, body, authToken);
      else
        result = await CreateAdmin(body, authToken);
    } catch (error) {
      setIsLoading(false)
      console.log(error);
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: "true",
      });
    }
    try {
      if (result.success) {
        setIsLoading(false)
        dispatch(actions.fetchCustomers(customersUIProps.queryParams));
        onHide();
        return addToast(result.message, {
          appearance: "success",
          autoDismiss: "true",
        });
      }
    } catch (error) {
      setIsLoading(false)
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: "true",
      });
    }

    // if (!id) {
    //   // server request for creating customer
    //   console.log(customer);

    //   dispatch(actions.createCustomer(customer)).then(() => onHide().catch(e=>{
    //     console.log(e);
    //   }));
    // } else {
    //   // server request for updating customer
    //   dispatch(actions.updateCustomer(customer)).then(() => onHide());
    // }
  };


  return (
    <Modal
      // size="sm"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <CustomerEditDialogHeader id={id} />
      <CustomerEditForm
        saveCustomer={saveCustomer}
        actionsLoading={actionsLoading}
        customer={customerForEdit || customersUIProps.initCustomer}
        onHide={onHide}
        isLoading={isLoading}
        id={id}
      />
    </Modal>
  );
}
