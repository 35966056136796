// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  Select,
  DatePickerField,
} from "../../../../../../_metronic/_partials/controls";
import Web3 from "web3";

// Validation schema
const DepositEditSchema = Yup.object().shape({
  winner: Yup.string().required("Winner is required"),
  userName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("User Name is required"),
  // lastName: Yup.string()
  //   .min(3, "Minimum 3 symbols")
  //   .max(50, "Maximum 50 symbols")
  //   .required("Lastname is required"),
  // email: Yup.string()
  //   .email("Invalid email")
  //   .required("Email is required"),
  // userName: Yup.string().required("Username is required"),
  // dateOfBbirth: Yup.mixed()
  //   .nullable(false)
  //   .required("Date of Birth is required"),
  address: Yup.string()
    .required("Wallet Address is required")
    .test("address", "Invalid wallet Address", (value) =>
      Web3.utils.isAddress(value)
    ),
  // .matches(Web3.utils.isAddress(address),
  // ,"Invalid wallet Address"),
});

export function DepositEditBetStatusForm({
  editStaking,
  deposit,
  actionsLoading,
  onHide,
  isLoading,
}) {
  return (
    <>
      {/* <Formik
        enableReinitialize={true}
        initialValues={deposit}
        validationSchema={DepositEditSchema}
        onSubmit={(values) => {
          editDeposit(values);
        }}
      >
        {({ handleSubmit }) => ( */}
      <>
        <Modal.Body className="overlay overlay-block cursor-default">
          {actionsLoading && (
            <div className="overlay-layer bg-transparent">
              <div className="spinner spinner-lg spinner-success" />
            </div>
          )}
          {/* <form className="form form-label-right"> */}
          {!actionsLoading && (
            <>
              <div className="row mb-2">
                <div className="col-lg-5 font-weight-bold">Deposit Number</div>
                <div className="col-lg-1">:</div>
                <div className="col-lg-6">{deposit?.depositNumber}</div>
              </div>
              <div className="row mb-2">
                <div className="col-lg-5 font-weight-bold">Red</div>
                <div className="col-lg-1">:</div>
                <div className="col-lg-6">
                  {deposit?.redName} ({deposit?.redStaked} CUSD)
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-lg-5 font-weight-bold">White</div>
                <div className="col-lg-1">:</div>
                <div className="col-lg-6">
                  {deposit?.whiteName} ({deposit?.whiteStaked} CUSD)
                </div>
              </div>
              <div className="form-group row mb-0 mt-2">
                {/* First Name */}
                {/* <div className="col-lg-12">
                  Select the Winner
                  <select
                    name="winner"
                    label="Winner"
                    class="border border-primary rounded py-2 px-4"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setWinner(e.target.value);
                    }}
                  >
                    <option value="cancel">Cancel</option>
                    <option value="red">Red</option>
                    <option value="white">White</option>
                  </select>
                </div> */}
                {/* Last Name */}
                {/* <div className="col-lg-12">
                    <Field
                      name="address"
                      component={Input}
                      placeholder="0x00000000000000000000000000000000"
                      label="Wallet Address"
                      customFeedbackLabel=" "
                    />
                  </div> */}
                {/* Login */}
                {/* <div className="col-lg-4">
                    <Field
                      name="userName"
                      component={Input}
                      placeholder="Login"
                      label="Login"
                    />
                  </div> */}
              </div>
            </>
          )}

          {/* Email */}
          {/* </form> */}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate justify-content-center"
            style={{ width: "100px" }}
          >
            Cancel
          </button>
          <> </>
          <button
            type="submit"
            onClick={() => editStaking()}
            className="btn btn-primary btn-elevate d-flex align-items-center justify-content-center"
            style={{ width: "100px" }}
          >
            {isLoading ? "Ending" : "End"}
            {isLoading && (
              <span
                className="ml-3 spinner spinner-white"
                style={{ width: "inherit" }}
              ></span>
            )}
          </button>
        </Modal.Footer>
      </>
      {/* )}
      </Formik> */}
    </>
  );
}

{
  /* <div className="form-group row">
<div className="col-lg-4">
  <Field
    type="email"
    name="email"
    component={Input}
    placeholder="Email"
    label="Email"
  />
</div>
// Date of birth 
<div className="col-lg-4">
  <DatePickerField
    name="dateOfBbirth"
    label="Date of Birth"
  />
</div>
// IP Address 
<div className="col-lg-4">
  <Field
    name="ipAddress"
    component={Input}
    placeholder="IP Address"
    label="IP Address"
    customFeedbackLabel="We'll never share deposit IP Address with anyone else"
  />
</div>
</div>
<div className="form-group row">
// Gender 
<div className="col-lg-4">
  <Select name="Gender" label="Gender">
    <option value="Female">Female</option>
    <option value="Male">Male</option>
  </Select>
</div>
// Type 
<div className="col-lg-4">
  <Select name="type" label="Type">
    <option value="0">Business</option>
    <option value="1">Individual</option>
  </Select>
</div>
</div> */
}
