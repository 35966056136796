import React from "react";
import { Link } from "react-router-dom";

export const TitleColumnFormatter = (cellContent, row) => (
  <>
    
      <span style={{fontWeight:"300",color:"black"}} >
      {row?.user?.email}
      </span>
  </>
);
