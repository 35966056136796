import React from "react";
import {
  ReferralStatusCssClasses,
} from "../../ReferralsUIHelpers";

export const StatusColumnFormatter = (cellContent, row) => (
  <span
    className={`label label-lg label-light-${ReferralStatusCssClasses[row.status === "initialized" ? 0 : 1]
      } label-inline`}
  >
    {row.status === "initialized" ? "Initialized" : "Completed"}
  </span>
);
