// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/collections/collectionsActions";
import * as uiHelpers from "../CollectionsUIHelpers";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses
} from "../../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useCollectionsUIContext } from "../CollectionsUIContext";

export function CollectionsTable() {
  // Collections UI Context
  const collectionsUIContext = useCollectionsUIContext();
  const collectionsUIProps = useMemo(() => {
    return {
      ids: collectionsUIContext.ids,
      setIds: collectionsUIContext.setIds,
      queryParams: collectionsUIContext.queryParams,
      setQueryParams: collectionsUIContext.setQueryParams,
      openEditCollectionPage: collectionsUIContext.openEditCollectionPage,
      openDeleteCollectionDialog:
        collectionsUIContext.openDeleteCollectionDialog,
      openVerifyCollectionDialog:
        collectionsUIContext.openVerifyCollectionDialog,
    };
  }, [collectionsUIContext]);
  // Getting curret state of collections list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.collections }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  // Collections Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    collectionsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchCollections(collectionsUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionsUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
      sortCaret: sortCaret,
      // headerSortingClasses,headerSortingClasses,
      formatter: columnFormatters.TitleColumnFormatter,
    },
    {
      dataField: "category",
      text: "Category",
      sortCaret: sortCaret,
    },
    {
      dataField: "floorPrice",
      text: "Floor  Price",
      sortCaret: sortCaret,
    },
    {
      dataField: "items",
      text: "Items",
      sortCaret: sortCaret,
      // formatter: columnFormatters.PriceColumnFormatter,
      // formatter: columnFormatters.StatusColumnFormatter,
    },
    {
      dataField: "volume",
      text: "Volume",
      sortCaret: sortCaret,
      formatter: columnFormatters.VolumeColumnFormatter,
    },
    {
      dataField: "totalVolume",
      text: "Total Volume",
      sortCaret: sortCaret,
      // formatter: columnFormatters.PriceColumnFormatter,
      // formatter: columnFormatters.StatusColumnFormatter,
    },
    // {
    //   dataField: "active",
    //   text: "Active",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: columnFormatters.StatusColumnFormatter,
    // },

    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openVerifyCollectionDialog:
          collectionsUIProps.openVerifyCollectionDialog,
        // openVerifyCollectionPage: collectionsUIProps.openVerifyCollectionPage,
      },
      // classes: "text-right pr-0",
      // headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
        textAlign:"center"
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: collectionsUIProps.queryParams.pageSize,
    page: collectionsUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="_id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  collectionsUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: collectionsUIProps.ids,
                //   setIds: collectionsUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
