/* eslint-disable no-unused-vars */
import React, {useMemo} from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { useCollectionsUIContext } from "../CollectionsUIContext";
import NFTCollection from "./IDOform";

export function ManagedFundsAdd() {
  const collectionsUIContext = useCollectionsUIContext();


  return (
    <Card>
      <CardHeader title="Create Asset">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            // onClick={collectionsUIProps.newCollectionButtonClick}
          >
            Clear All
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
     <NFTCollection />
      </CardBody>
    </Card>
  );
}
