import axios from "axios";

export const ReportS_URL = "api/Reports";

// CREATE =>  POST: add a new Report to the server
export function createReport(Report) {
  return axios.post(ReportS_URL, { Report });
}

// READ
export function getAllReports() {
  return axios.get(ReportS_URL);
}

export function getReportById(ReportId) {
  return axios.get(`${ReportS_URL}/${ReportId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findReports(queryParams) {
  return axios.post(`${ReportS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateReport(Report) {
  return axios.put(`${ReportS_URL}/${Report.id}`, { Report });
}

// UPDATE Status
export function updateStatusForReports(ids, status) {
  return axios.post(`${ReportS_URL}/updateStatusForReports`, {
    ids,
    status
  });
}

// DELETE => delete the Report from the server
export function deleteReport(ReportId) {
  return axios.delete(`${ReportS_URL}/${ReportId}`);
}

// DELETE Reports by ids
export function deleteReports(ids) {
  return axios.post(`${ReportS_URL}/deleteReports`, { ids });
}
