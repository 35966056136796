/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { Dropdown } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../../layout";
import { DropdownMenu2 } from "../../dropdowns";
import {
  fightcount,
  stackcount,
  affiliates,
  eventcount,
  usercount,
} from "../../../../services/ApiServices";
import { shallowEqual, useSelector } from "react-redux";

export function MixedWidget1({ className }) {
  const uiService = useHtmlClassService();
  const [loading, setLoading] = useState(false);
  const [fight, setFight] = useState({});
  const [stack, setStack] = useState({});
  const [affiliate, setaffiliate] = useState({});
  const [user, setUser] = useState({});

  const [event, setEvent] = useState({});

  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseDanger: objectPath.get(
        uiService.config,
        "js.colors.theme.base.danger"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);
  const getEvent = async () => {
    setLoading(true);
    let result;
    try {
      result = await eventcount(authToken);
      if (result.success) {
        setEvent(result);
        setLoading(false)


      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getEvent();

  }, []);
  const getUser = async () => {
    setLoading(true);
    let result;
    try {
      result = await usercount(authToken);
      if (result.success) {
        setUser(result);
        setLoading(false)


      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getUser();

  }, []);
  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_1_chart");
    if (!element) {
      return;
    }

    const options = getChartOptions(layoutProps);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps]);

  const getfight = async () => {
    setLoading(true);
    let result;
    try {
      result = await fightcount(authToken);
      if (result.success) {
        setFight(result);
        setLoading(false)


      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getfight();

  }, []);

  const getstack = async () => {
    setLoading(true);
    let result;
    try {
      result = await stackcount(authToken);
      if (result.success) {
        setStack(result);
        setLoading(false)


      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getstack();

  }, []);

  const getaffiliates = async () => {
    setLoading(true);
    let result;
    try {
      result = await affiliates(authToken);
      if (result.success) {
        setaffiliate(result);
        setLoading(false)


      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getaffiliates();

  }, []);

  return (
    <div className={`card card-custom bg-gray-100 ${className}`}>
      {/* Header */}
      {/* <div className="card-header border-0 bg-danger py-3">
        <div className="card-toolbar">

        </div>
      </div> */}
      {/* <div className="card-body p-0 position-relative overflow-hidden"> */}
      {/* <div
          id="kt_mixed_widget_1_chart"
          className="card-rounded-bottom bg-danger"
          style={{ height: "200px" }}
        ></div> */}

      {/* Stat */}
      <div className="card-spacer mt-n5 px-8">
        <div className="row m-0 mb-4">
          <div className="col bg-light-success px-6 py-8 rounded-xl mr-7 mt-4">
            <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2 mr-7">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Difference.svg")}></SVG>
            </span>
            <a href="#" className="text-success font-weight-bold font-size-h6 mt-2">
              Total Events
            </a>
            <div className="font-size-h4 font-weight-bolder">{event?.overallCount}</div>
          </div>
          <div className="col bg-light-warning px-6 py-8 rounded-xl mt-4">
            <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}
              ></SVG>
            </span>
            <a
              href="#"
              className="text-warning font-weight-bold font-size-h6"
            >
              Total Fights
            </a>
            <div className="font-size-h4 font-weight-bolder">{fight?.overallCount}</div>
          </div>

        </div>
        <div className="row m-0">
          <div className="col bg-light-danger px-6 py-8 rounded-xl mr-7">
            <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
              ></SVG>
            </span>
            <a
              href="#"
              className="text-danger font-weight-bold font-size-h6 mt-2"
            >
              Today Fight
            </a>
            <div className="font-size-h4 font-weight-bolder">{fight?.todayCount}</div>
          </div>
          <div className="col bg-light-success px-6 py-8 rounded-xl">
            <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Design/Layers.svg"
                )}
              ></SVG>
            </span>
            <a
              href="#"
              className="text-success font-weight-bold font-size-h6 mt-2"
            >
              Today Staking
            </a>
            <div className="font-size-h4 font-weight-bolder">${stack?.todayAvg?.toFixed(2)}</div>

          </div>

        </div>
        {''}
        <div className="row m-0 ">
          <div className="col bg-light-success px-6 py-8 rounded-xl mr-7 mt-4">
            <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Difference.svg")}></SVG>
            </span>
            <a href="#" className="text-success font-weight-bold font-size-h6 mt-2">
              Operators
            </a>
            <div className="font-size-h4 font-weight-bolder">{affiliate?.overallCount}</div>
          </div>
          <div className="col bg-light-primary px-6 py-8 rounded-xl mt-4">
            <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Media/Equalizer.svg"
                )}
              ></SVG>
            </span>
            <a
              href="#"
              className="text-primary font-weight-bold font-size-h6 mt-2"
            >
              Total Players
            </a>
            <div className="font-size-h4 font-weight-bolder">{user?.overallCount}</div>

          </div>

        </div>
      </div>
      {/* Resize */}
      {/* <div className="resize-triggers">
          <div className="expand-trigger">
            <div style={{ width: "411px", height: "461px" }} />
          </div>
          <div className="contract-trigger" />
        </div> */}
      {/* </div> */}
    </div>
  );
}

function getChartOptions(layoutProps) {
  const strokeColor = "#D13647";

  const options = {
    series: [
      {
        name: "",
        data: [35, 65, 75, 55, 45, 60, 55]
      }
    ],
    chart: {
      type: "area",
      height: 200,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      sparkline: {
        enabled: true
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5
      }
    },
    plotOptions: {},
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: "solid",
      opacity: 0
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [strokeColor]
    },
    xaxis: {
      categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3
        }
      }
    },
    yaxis: {
      min: 0,
      max: 80,
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily
        }
      }
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0
        }
      },
      hover: {
        filter: {
          type: "none",
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0
        }
      }
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily
      },
      y: {
        formatter: function (val) {
          return "$" + val + " thousands";
        }
      },
      marker: {
        show: false
      }
    },
    colors: ["transparent"],
    markers: {
      colors: layoutProps.colorsThemeBaseDanger,
      strokeColor: [strokeColor],
      strokeWidth: 3
    }
  };
  return options;
}
