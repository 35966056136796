import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { CockPicksFilter } from "./cockPicks-filter/CockPicksFilter";
import { CockPicksTable } from "./cockPicks-table/CockPicksTable";
import { CockPicksGrouping } from "./cockPicks-grouping/CockPicksGrouping";
import { useCockPicksUIContext } from "./CockPicksUIContext";
// import { Link } from "react-router-dom";
// import { DropdownMenu5, DropdownCustomToggler } from "../../dropdowns";
import { Dropdown } from "react-bootstrap";
import { DropdownMenu5 } from "../../../../../_metronic/_partials/dropdowns/DropdownMenu5";
import { useRef } from 'react';

export function CockPicksCard() {
  const cockPicksUIContext = useCockPicksUIContext();
  const targetRef = useRef();

  const cockPicksUIProps = useMemo(() => {
    return {
      ids: cockPicksUIContext.ids,
      queryParams: cockPicksUIContext.queryParams,
      setQueryParams: cockPicksUIContext.setQueryParams,
      newCockPickButtonClick: cockPicksUIContext.newCockPickButtonClick,
      openDeleteCockPicksDialog: cockPicksUIContext.openDeleteCockPicksDialog,
      openEditCockPickPage: cockPicksUIContext.openEditCockPickPage,
      openUpdateCockPicksStatusDialog:
        cockPicksUIContext.openUpdateCockPicksStatusDialog,
      openFetchCockPicksDialog: cockPicksUIContext.openFetchCockPicksDialog,
      openVerifyCockPickDialog: cockPicksUIContext.openVerifyCockPickDialog,
      opencockPickShowsite: cockPicksUIContext.opencockPickShowsite,
      opencockPickHidesite: cockPicksUIContext.opencockPickHidesite


    };
  }, [cockPicksUIContext]);

  return (
    <Card>
      <CardHeader title="Last 2 Digit list">
        {/* <CardHeaderToolbar>
          <Dropdown className="dropdown-inline" drop="down" alignRight>
            <Dropdown.Toggle
              className="btn btn-clean btn-hover-light-primary"
              id="dropdown-toggle-top">
              Export
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <DropdownMenu5 targetRef={targetRef} />
            </Dropdown.Menu>
          </Dropdown>
        </CardHeaderToolbar> */}
      </CardHeader>
      <CardBody>
        <CockPicksFilter />
        {cockPicksUIProps.ids.length > 0 && (
          <>
            <CockPicksGrouping />
          </>
        )}
        <div ref={targetRef}>
          <CockPicksTable />
        </div>
      </CardBody>
    </Card>
  );
}
