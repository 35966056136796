import { useSubheader } from "../../_metronic/layout";
import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
// import { Input } from "../controls";
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
// import { auth } from "../../../Config/FirebaseConfig";
import { useToasts } from "react-toast-notifications";
import { auth } from "../../Config/FirebaseConfig";
import {
  Card, Input, CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import TFA from "../../_metronic/_partials/TFA";
import { shallowEqual, useSelector } from "react-redux";
import { Operator } from "../../_metronic/_partials";

const changePasswordSchema = Yup.object().shape({

  oldPassword: Yup.string()
    .min(8, "Minimum 8 symbols")
    .max(50, "Maximum 50 symbols")
  ,
  newPassword: Yup.string()
    .min(8, "Minimum 8 symbols")
    .max(50, "Maximum 50 symbols")
  ,

});
export const Profile = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Profile");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { addToast } = useToasts();
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );
  const changePassword = async (values, resetForm) => {
    setLoading(true);
    const user = auth.currentUser;

    if (user) {
      const credential = EmailAuthProvider.credential(
        user.email,
        values.oldPassword
      );
      try {
        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, values.newPassword);
        setLoading(false);
        resetForm()
        return addToast("Password changed successfully!", {
          appearance: "success",
          autoDismiss: "true",
        });
      } catch (error) {
        setLoading(false);
        return addToast(error.message, {
          appearance: "error",
          autoDismiss: "true",
        });
      }
    } else {
      setLoading(false);
      setError("No user is signed in.");
    }
  };
  return (<>
    {user.role === "operator" && <Card>
      <CardHeader title="Operator">
        <CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Operator />

      </CardBody>
    </Card>}
    <Card>
      <CardHeader title="Change Password">
        <CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Formik
          enableReinitialize={true}
          initialValues={{ oldPassword: '', newPassword: '' }}
          validationSchema={changePasswordSchema}
          onSubmit={(values, { resetForm }) => {
            changePassword(values, resetForm);
          }}
        >
          {({ handleSubmit, values, setFieldValue }) => (

            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-12">
                  <Field
                    name="oldPassword"
                    component={Input}
                    className="form-control w-50"
                    placeholder="Old Password"
                    label="Old Password"
                    customFeedbackLabel=" "
                    value={values.oldPassword}
                  />
                  <ErrorMessage
                    name="oldpassword"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="col-lg-12">
                  <Field
                    name="newPassword"
                    component={Input}
                    className="form-control w-50"
                    placeholder="New Password"
                    label="New Password"
                    customFeedbackLabel=" "
                    value={values.newPassword}

                  />
                  <ErrorMessage
                    name="newpassword"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div>
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="btn btn-primary btn-elevate d-flex align-items-center justify-content-center"
                >
                  Change
                  {loading && (
                    <span
                      className="ml-3 spinner spinner-white"
                      style={{ width: "inherit" }}
                    ></span>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
    <Card>
      <CardHeader title="2FA - Authentication">
        <CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <TFA />
      </CardBody>
    </Card>
  </>);
};
