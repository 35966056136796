import React from "react";

export const StakedColumnFormatter = (cellContent, row) => (
  <span>
  
    {cellContent.toFixed(2)}
  
  </span>
  
);
