import React from "react";

export const AddressColumnFormatter = (cellContent, row) => (
  <span>
    {row?.txHash && <>
      <a href={`https://solscan.io/tx/${row?.txHash}`} target='_blank' rel="noreferrer">
        {row.txHash.slice(0, 7)}...{row.txHash.slice(-7, row.txHash.length)}
      </a>
    </>}
  </span>

);
