// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/events/eventsActions";
import * as uiHelpers from "../EventsUIHelpers";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses
} from "../../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useEventsUIContext } from "../EventsUIContext";

export function EventsTable() {
  // Events UI Context
  const eventsUIContext = useEventsUIContext();

  const eventsUIProps = useMemo(() => {
    return {
      ids: eventsUIContext.ids,
      setIds: eventsUIContext.setIds,
      queryParams: eventsUIContext.queryParams,
      setQueryParams: eventsUIContext.setQueryParams,
      openEditEventPage: eventsUIContext.openEditEventPage,
      openDeleteEventDialog:
        eventsUIContext.openDeleteEventDialog,
      openVerifyEventDialog:
        eventsUIContext.openVerifyEventDialog,

    };
  }, [eventsUIContext]);
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  // Getting curret state of events list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.events }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  // Events Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    eventsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchEvents(eventsUIProps.queryParams, authToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsUIProps.queryParams, dispatch]);



  // Table columns
  const columns = [
    {
      dataField: "name",
      text: "EVENT NAME",
      sort: true,
      sortCaret: sortCaret,

    },
    {
      dataField: "dateTime",
      text: "DATE & TIME",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.DateColumnFormatter

    },
    {
      dataField: "status",
      text: "STATUS",
      sortCaret: sortCaret,

    },
    {
      dataField: "arena.name",
      text: "ARENA",
    },
    {
      dataField: "mediaServer",
      text: "MEDIA SERVER",
    },
    {
      dataField: "createdAt",
      text: "CREATED",
      formatter: columnFormatters.DateColumnFormatter

    },
    {
      dataField: "arena.recordId",
      text: "RECORD ID",
    },
    // {
    //   dataField: "active",
    //   text: "Active",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: columnFormatters.StatusColumnFormatter,
    // },

    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openVerifyEventDialog: eventsUIContext.openVerifyEventDialog,
        openUpdateEventsStatusDialog: eventsUIContext.openUpdateEventsStatusDialog,
      },
      // classes: "text-right pr-0",
      // headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
        textAlign: "center"
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: eventsUIProps.queryParams.pageSize,
    page: eventsUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="_id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  eventsUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: eventsUIProps.ids,
                //   setIds: eventsUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
