import * as requestFromServer from "./solanasCrud";
import { solanasSlice, callTypes } from "./solanasSlice";
import {
  GetAsset,
  GetSolana,
  GetSolanas,
  UpdateSolana,
  VerifySolana,
} from "../../../../../services/ApiServices";
const { actions } = solanasSlice;

export const fetchSolanas = (queryParams, token) => async (dispatch) => {
  let query = "?";
  let body = { sort: {} };

  for (const key in queryParams) {
    switch (key) {
      case "sortOrder":
        body.sort[queryParams.sortField] = queryParams.sortOrder === "desc" ? -1 : 1;
        // query = query.concat(queryParams.sortField+"=" + queryParams.sortField);
        break;
      case "pageNumber":
        query = query.concat("&page=" + queryParams.pageNumber);
        break;
      case "pageSize":
        query = query.concat("&limit=" + queryParams.pageSize);
        break;
      case "filter":
        if (queryParams.filter) body.searchFields = queryParams.filter;
        break;
      case "filterField":
        if (queryParams.filterField) body.filterField = queryParams.filterField;
        break;
      case "filterFields":
        if (queryParams.filterFields) body.filterFields = queryParams.filterFields;
        break;
      default:
        break;
    }
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer

  let result;
  try {
    result = await GetSolanas(query, body, token);
  } catch (error) {
    error.clientMessage = "Can't find solanas";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      let totalCount = result.solanas.totalDocs;
      let entities = result.solanas.docs;
      dispatch(actions.solanasFetched({ totalCount, entities }));
    }
  } catch (error) {
    error.clientMessage = "Can't find solanas";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const fetchSolana = (id) => async (dispatch) => {
  if (!id) {
    return dispatch(actions.solanaFetched({ solanaForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));

  let result;
  try {
    result = await GetSolana(id);
  } catch (error) {
    error.clientMessage = "Can't find solana";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      const solana = result.solana;
      dispatch(actions.solanaFetched({ solana: solana }));
    }
  } catch (error) {
    error.clientMessage = "Can't find solana";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};
export const verifySolana = (id, token) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  // return requestFromServer
  let result;
  try {
    result = await VerifySolana(id, token);
  } catch (error) {
    error.clientMessage = "Can't verify solana";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      dispatch(actions.solanaDeleted({ id }));
    }
  } catch (error) {
    error.clientMessage = "Can't verify solana";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }

  // .deleteSolana(id)
  // .then((response) => {
  //   dispatch(actions.solanaDeleted({ id }));
  // })
  // .catch((error) => {
  //   error.clientMessage = "Can't delete solana";
  //   dispatch(actions.catchError({ error, callType: callTypes.action }));
  // });
};
export const deleteSolana = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSolana(id)
    .then((response) => {
      dispatch(actions.solanaDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete solana";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createSolana = (solanaForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createSolana(solanaForCreation)
    .then((response) => {
      const { solana } = response.data;
      dispatch(actions.solanaCreated({ solana }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create solana";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSolana = (solana) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSolana(solana)
    .then(() => {
      dispatch(actions.solanaUpdated({ solana }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update solana";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSolanasStatus = (ids, status) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  let result;
  try {
    result = await UpdateSolana(ids, status);
  } catch (error) {
    error.clientMessage = "Can't verify solana";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }

  try {
    if (result.success)
      dispatch(actions.solanasStatusUpdated({ ids, status }));
  }
  catch (error) {
    error.clientMessage = "Can't update solanas status";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  };
};

export const deleteSolanas = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteSolanas(ids)
    .then(() => {
      dispatch(actions.solanasDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete solanas";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
