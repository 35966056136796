/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Input } from "../controls";
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { auth } from "../../../Config/FirebaseConfig";
import { useToasts } from "react-toast-notifications";
const changePasswordSchema = Yup.object().shape({

    oldPassword: Yup.string()
        .min(8, "Minimum 8 symbols")
        .max(50, "Maximum 50 symbols")
    ,
    newPassword: Yup.string()
        .min(8, "Minimum 8 symbols")
        .max(50, "Maximum 50 symbols")
    ,

});
export function DropdownMenu7() {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const { addToast } = useToasts();

    const handleClose = () => {
        setShow(false)
    }
    const changePassword = async (values) => {
        setLoading(true);
        const user = auth.currentUser;

        if (user) {
            const credential = EmailAuthProvider.credential(
                user.email,
                values.oldPassword
            );

            try {
                await reauthenticateWithCredential(user, credential);
                await updatePassword(user, values.newPassword);
                setLoading(false);
                handleClose();
                return addToast("Password changed successfully!", {
                    appearance: "success",
                    autoDismiss: "true",
                });
            } catch (error) {
                setLoading(false);
                return addToast(error.message, {
                    appearance: "error",
                    autoDismiss: "true",
                });
            }
        } else {
            setLoading(false);
            setError("No user is signed in.");
        }
    };
    return <>
        {/*begin::Navigation*/}
        <ul className="navi navi-hover">

            <li className="navi-item">
                <div onClick={() => { setShow(true) }} className="navi-link">
                    <span className="navi-icon"><i className="flaticon-lock"></i></span>
                    <span className="navi-text">Change Password</span>
                </div>
            </li>
            {/* <li className="navi-item">
                <div onClick={() => { setShow(true) }} className="navi-link">
                    <span className="navi-icon"><i className="flaticon-security"></i></span>
                    <span className="navi-text">2FA</span>
                </div>
            </li> */}
        </ul>
        <Formik
            enableReinitialize={true}
            initialValues={{ oldPassword: '', newPassword: '' }}
            validationSchema={changePasswordSchema}
            onSubmit={(values) => {
                changePassword(values);
            }}
        >
            {({ handleSubmit, values, setFieldValue }) => (

                <Modal
                    show={show}
                    onHide={handleClose}
                    aria-labelledby="example-modal-sizes-title-lg"
                >

                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Change Password
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        <Form className="form form-label-right">
                            <div className="form-group row">



                                <div className="col-lg-12">
                                    <Field
                                        name="oldPassword"
                                        component={Input}
                                        className="form-control"
                                        placeholder="Old Password"
                                        label="Old Password"
                                        customFeedbackLabel=" "
                                    />
                                    <ErrorMessage
                                        name="password"
                                        component="div"
                                        className="text-danger"
                                    />
                                </div>
                                <div className="col-lg-12">
                                    <Field
                                        name="newPassword"
                                        component={Input}
                                        className="form-control"
                                        placeholder="New Password"
                                        label="New Password"
                                        customFeedbackLabel=" "
                                    />
                                    <ErrorMessage
                                        name="password"
                                        component="div"
                                        className="text-danger"
                                    />
                                </div>

                            </div>
                        </Form>


                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <button
                                type="button"
                                onClick={handleClose}
                                className="btn btn-light btn-elevate"
                            >
                                Cancel
                            </button>
                            <> </>
                            <button
                                type="button"
                                onClick={handleSubmit}
                                className="btn btn-primary btn-elevate"
                            >
                                Change
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            )}
        </Formik>

    </>
}
