// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/fights/fightsActions";
import * as uiHelpers from "../FightsUIHelpers";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useFightsUIContext } from "../FightsUIContext";
import { useToasts } from "react-toast-notifications";

export function FightsTable() {
  // Fights UI Context
  const fightsUIContext = useFightsUIContext();
  const { addToast } = useToasts();

  const fightsUIProps = useMemo(() => {
    return {
      ids: fightsUIContext.ids,
      setIds: fightsUIContext.setIds,
      queryParams: fightsUIContext.queryParams,
      setQueryParams: fightsUIContext.setQueryParams,
      openEditFightPage: fightsUIContext.openEditFightPage,
      openDeleteFightDialog: fightsUIContext.openDeleteFightDialog,
      openVerifyFightDialog: fightsUIContext.openVerifyFightDialog,
      openFightEndEditDialog: fightsUIContext.openFightEndEditDialog,
      openFightBetEndEditDialog: fightsUIContext.openFightBetEndEditDialog,
      openDetailsDialog: fightsUIContext.openDetailsDialog
    };
  }, [fightsUIContext]);
  // Getting curret state of fights list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.fights }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  // Fights Redux state
  const dispatch = useDispatch();
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  useEffect(() => {
    // clear selections list
    fightsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchFights(fightsUIProps.queryParams,authToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fightsUIProps.queryParams, dispatch]);
  // Table columns
  const ended = () => {
    addToast("Fight already ended", {
      appearance: "error",
      autoDismiss: "true",
    });
  };
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );
  const columns = [
    {
      dataField: "fightNumber",
      text: "F.No",
      sortCaret: sortCaret,
      sort: true,
    },
    {
      dataField: "matchDate",
      text: "Fight Date",
      sortCaret: sortCaret,
      sort: true,
      // formatter: columnFormatters.DateColumnFormatter,
    },
    {
      dataField: "winner",
      text: "Winner",
      // sort: true,
      // sortCaret: sortCaret,
      style: {
        textTransform: "uppercase",
        fontWeight: "600",
      },
      // headerSortingClasses,headerSortingClasses,
    },
    {
      dataField: "whiteName",
      text: "White",
    },
    {
      dataField: "whiteStaked",
      text: "Staked",
      formatter: columnFormatters.StakedColumnFormatter,
    },
    {
      dataField: "redName",
      text: "Red",
    },
    {
      dataField: "redStaked",
      text: "Staked",
      formatter: columnFormatters.StakedColumnFormatter,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: columnFormatters.StatusColumnFormatter,
    },

    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openDetailsDialog: fightsUIProps.openDetailsDialog,
        openFightEndEditDialog: fightsUIContext.openFightEndEditDialog,
        openFightBetEndEditDialog: fightsUIContext.openFightBetEndEditDialog,
        ended: ended,
        user: user
        // openVerifyFightPage: fightsUIProps.openVerifyFightPage,
      },
      // classes: "text-right pr-0",
      // headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
        textAlign: "center",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: fightsUIProps.queryParams.pageSize,
    page: fightsUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="_id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  fightsUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: fightsUIProps.ids,
                //   setIds: fightsUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
