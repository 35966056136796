// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { Input, Select } from "../../../../../../_metronic/_partials/controls";
import {
  AVAILABLE_COLORS,
  AVAILABLE_MANUFACTURES,
  FightStatusTitles,
  FightConditionTitles,
} from "../FightsUIHelpers";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { SvgIcon, Grid, Typography, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Accordion } from "@material-ui/core";
import { AccordionSummary } from "@material-ui/core";
import { AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ImTrophy } from "react-icons/im";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
// Validation schema
const FightEditSchema = Yup.object().shape({
  model: Yup.string()
    .min(2, "Minimum 2 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Model is required"),
  manufacture: Yup.string()
    .min(2, "Minimum 2 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Manufacture is required"),
  modelYear: Yup.number()
    .min(1950, "1950 is minimum")
    .max(2020, "2020 is maximum")
    .required("Model year is required"),
  mileage: Yup.number()
    .min(0, "0 is minimum")
    .max(1000000, "1000000 is maximum")
    .required("Mileage is required"),
  color: Yup.string().required("Color is required"),
  price: Yup.number()
    .min(1, "$1 is minimum")
    .max(1000000, "$1000000 is maximum")
    .required("Price is required"),
  VINCode: Yup.string().required("VINCode is required"),
});

export function FightDetail({ actionsLoading, fight, btnRef, saveFight }) {
  return (
    <>
      {!actionsLoading && (
        <>
       
          <div className="row justify-content-center">
            <div
              className="col-lg-5 rounded card p-5"
              style={{
                backgroundColor: "#F3F6F9",
                border: "5px solid white",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
            >
              <div className="row m-0">
                <div className="col-lg-3 p-0">
                  <img
                    src={fight?.redImageUrl}
                    width="90%"
                    className="d-flex my-auto"
                  />
                  <h5 className="text-center text-primary">WHITE</h5>
                </div>
                <div className="col-lg-9 p-0">
                  <div className="row mb-2 ">
                    <div className="col-lg-1 p-0"></div>
                    <div className="col-lg-5 font-weight-bold p-0">Name</div>
                    <div className="col-lg-1 p-0">:</div>
                    <div className="col-lg-5 p-0">{fight?.whiteName}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-lg-1 p-0"></div>
                    <div className="col-lg-5 font-weight-bold p-0">Weight</div>
                    <div className="col-lg-1 p-0">:</div>
                    <div className="col-lg-5 p-0">{fight?.whiteWeight} gms</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-lg-1 p-0"></div>
                    <div className="col-lg-5 font-weight-bold p-0">Staked</div>
                    <div className="col-lg-1 p-0">:</div>
                    <div className="col-lg-5 p-0">
                      {fight?.whiteStaked} CUSD
                    </div>
                  </div>
                  {fight?.white && (
                    <div className="row mb-2">
                      <div className="col-lg-1 p-0"></div>
                      <div className="col-lg-5 font-weight-bold p-0">
                      Players
                      </div>
                      <div className="col-lg-1 p-0">:</div>
                      <div className="col-lg-5 p-0">{fight?.white}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div
              className="col-lg-5 rounded card p-5"
              style={{
                backgroundColor: "#F3F6F9",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                border: "5px solid #e11d1d",
              }}
            >
              <div className="row m-0">
                <div className="col-lg-3 p-0">
                  <img
                    src={fight?.redImageUrl}
                    width="90%"
                    className="d-flex my-auto"
                  />
                  <h5 className="text-center text-primary">RED</h5>
                </div>
                <div className="col-lg-9 p-0">
                  <div className="row mb-2 ">
                    <div className="col-lg-1 p-0"></div>
                    <div className="col-lg-5 font-weight-bold p-0">Name</div>
                    <div className="col-lg-1 p-0">:</div>
                    <div className="col-lg-5 p-0">{fight?.redName}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-lg-1 p-0"></div>
                    <div className="col-lg-5 font-weight-bold p-0">Weight</div>
                    <div className="col-lg-1 p-0">:</div>
                    <div className="col-lg-5 p-0">{fight?.redWeight} gms</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-lg-1 p-0"></div>
                    <div className="col-lg-5 font-weight-bold p-0">Staked</div>
                    <div className="col-lg-1 p-0">:</div>
                    <div className="col-lg-5 p-0">{fight?.redStaked} CUSD</div>
                  </div>
                  {fight?.red && (
                    <div className="row mb-2">
                      <div className="col-lg-1 p-0"></div>

                      <div className="col-lg-5 font-weight-bold p-0">
                        Players
                      </div>
                      <div className="col-lg-1 p-0">:</div>
                      <div className="col-lg-5 p-0">{fight?.red}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center my-5">
            {fight?.betStatus == "open" ? (
              <div className="col-lg-4 badge badge-primary">
                <div className="h5 my-2">BET OPEN</div>
              </div>
            ) : (
              <div className="col-lg-4 badge badge-danger">
                <div className=" h5 my-2">BET CLOSED</div>
              </div>
            )}
            <div className="col-lg-2"></div>
            {fight?.status == "soon" ? (
              <div className="col-lg-4 badge badge-info">
                <div className=" h5 my-2">MATCH UPCOMING</div>
              </div>
            ) : fight?.status == "finished" ? (
              <div className="col-lg-4 badge badge-danger">
                <div className="h5 my-2">MATCH FNISHED</div>
              </div>
            ) : (
              <div className="col-lg-4 badge badge-primary ">
                <div className="h5 my-2">MATCH LIVE</div>
              </div>
            )}
          </div>
          {fight?.winner !== "--" && (
            <>
              {fight?.winner == "white" ? (
                <div className="badge badge-primary d-flex align-items-center w-fit mx-auto px-8 py-5">
                  <span className="svg-icon svg-icon-md svg-icon-light">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")}
                    />
                  </span>
                  <div className="h5 mb-0 mr-2">WHITE</div> Won
                  <span className="svg-icon svg-icon-md svg-icon-warning svgIcon ml-3">
                    <ImTrophy className="text-warning svgIcon" />
                  </span>
                </div>
              ) : fight?.winner == "red" ? (
                <div className="badge badge-primary d-flex align-items-center w-fit mx-auto px-8 py-5">
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")}
                    />
                  </span>
                  <div className="h5 mb-0 mr-2">RED</div> Won
                  <span className="svg-icon svg-icon-md svg-icon-warning svgIcon ml-3">
                    <ImTrophy className="text-warning svgIcon" />
                  </span>
                </div>
              ) : (
                <div className="badge badge-secondary d-flex align-items-center w-fit mx-auto px-8 py-5">
                  <div className="h5 mb-0 mr-2">CANCEL</div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
