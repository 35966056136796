import React, { useRef, useEffect, useState } from "react";
import { shallowEqual, useSelector, connect, useDispatch } from "react-redux";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "./authRedux";
import { getUserByToken } from "./authCrud";
import { useHistory } from "react-router-dom";

import {
  onAuthStateChanged,
} from 'firebase/auth';
import { auth as firebaseAuth } from '../../../../Config/FirebaseConfig'
function AuthInit(props) {
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const { authToken, user, tfa } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
      user: auth.user,
      tfa: auth.tfa
    }),
    shallowEqual
  );
  useEffect(() => {
    if (!tfa) return setShowSplashScreen(false);

    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        props.login(user.accessToken);
      }
      else {
        setShowSplashScreen(false);

        dispatch(props.logout());
      }
    });
    return () => unsubscribe();
  }, [tfa]);
  useEffect(() => {
    if (authToken && !user)
      getUser()
  }, [user, authToken])
  const getUser = async () => {
    try {
      const user = await getUserByToken(authToken);
      if (user.success) {

        dispatch(props.fulfillUser(user.admin));
        dispatch(props.setUser(user.admin));
      } else {

        dispatch(props.logout());
        setShowSplashScreen(false);
      }
    }
    catch (error) {
      console.error(error);
      setShowSplashScreen(false);

      if (!didRequest.current) {
        dispatch(props.logout());
      }
    } finally {
      setShowSplashScreen(false);
    }

  }
  // useEffect(() => {
  //   const requestUser = async (authToken) => {
  //     try {
  //       if (!didRequest.current) {
  //         const user = await getUserByToken(authToken);
  //         if (user.success) {
  //           dispatch(props.fulfillUser(user.admin));
  //         } else {
  //           dispatch(props.logout());
  //         }
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       if (!didRequest.current) {
  //         dispatch(props.logout());
  //       }
  //     } finally {
  //       setShowSplashScreen(false);
  //     }

  //     return () => (didRequest.current = true);
  //   };

  //   if (authToken && authToken !== undefined) {
  //     requestUser(authToken);
  //   } else {
  //     dispatch(props.fulfillUser(undefined));
  //     setShowSplashScreen(false);
  //   }
  //   // eslint-disable-next-line
  // }, [authToken]);

  return (showSplashScreen) ? <LayoutSplashScreen /> : <>{props.children}</>;
}

export default connect(null, auth.actions)(AuthInit);
