import React from "react";
import { Route } from "react-router-dom";
import { EventsLoadingDialog } from "./events-loading-dialog/EventsLoadingDialog";
import { EventDeleteDialog } from "./event-delete-dialog/eventDeleteDialog";
import { EventsDeleteDialog } from "./events-delete-dialog/EventsDeleteDialog";
import { EventsFetchDialog } from "./events-fetch-dialog/EventsFetchDialog";
import { EventsUpdateStatusDialog } from "./events-update-status-dialog/EventsUpdateStatusDialog";
import { EventsVerifyDialog } from "./event-verify-dialog/eventVerifyDialog";
import { EventsCard } from "./EventsCard";
import { EventsUIProvider } from "./EventsUIContext";
import { EventAddDialog } from "./event-new/EventAddModal";

export function EventsPage({ history }) {
  const eventsUIEvents = {
    newEventButtonClick: () => {
      history.push("/cock-boxing/events/new");
    },
    openEditEventPage: (id) => {
      history.push(`/cock-boxing/events/${id}/edit`);
    },
    openVerifyEventDialog: (id) => {
      history.push(`/cock-boxing/events/${id}/verify`);
    },
    openVerifyEventDialog: (id) => {
      history.push(`/cock-boxing/events/${id}/verify`);
    },
    openDeleteEventDialog: (id) => {
      history.push(`/cock-boxing/events/${id}/delete`);
    },
    openDeleteEventsDialog: () => {
      history.push(`/cock-boxing/events/deleteEvents`);
    },
    openFetchEventsDialog: () => {
      history.push(`/cock-boxing/events/fetch`);
    },
    openUpdateEventsStatusDialog: (id) => {
      history.push(`/cock-boxing/events/updateStatus/${id}`);
    },
  };

  return (
    <EventsUIProvider eventsUIEvents={eventsUIEvents}>
      <EventsLoadingDialog />
      <Route path="/cock-boxing/events/deleteEvents">
        {({ history, match }) => (
          <EventsDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/events");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/events/:id/verify">
        {({ history, match }) => (
          <EventsVerifyDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/events");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/events/:id/delete">
        {({ history, match }) => (
          <EventDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/events");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/events/fetch">
        {({ history, match }) => (
          <EventsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/events");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/events/updateStatus/:id">
        {({ history, match }) => (
          <EventsUpdateStatusDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/events");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/events/new">
        {({ history, match }) => (
          <EventAddDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/events");
            }}
          />
        )}
      </Route>
      <EventsCard />
    </EventsUIProvider>
  );
}
