import React from "react";
import { Route } from "react-router-dom";
import { OperatorsLoadingDialog } from "./operators-loading-dialog/OperatorsLoadingDialog";
import { OperatorEditDialog } from "./operator-edit-dialog/OperatorEditDialog";
import { OperatorDeleteDialog } from "./operator-delete-dialog/OperatorDeleteDialog";
import { OperatorsDeleteDialog } from "./operators-delete-dialog/OperatorsDeleteDialog";
import { OperatorsFetchDialog } from "./operators-fetch-dialog/OperatorsFetchDialog";
import { OperatorsUpdateStateDialog } from "./operators-update-status-dialog/OperatorsUpdateStateDialog";
import { OperatorsUIProvider } from "./OperatorsUIContext";
import { OperatorsCard } from "./OperatorsCard";
import { SubOperatorsCard } from "../subOperatorIncome/SubOperatorIncomesCard";
import { SubOperatorsUIProvider } from "../subOperatorIncome/SubOperatorIncomesUIContext";
import { shallowEqual, useSelector } from "react-redux";

export function OperatorsPage({ history }) {
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );
  const operatorsUIEvents = {
    // newOperatorButtonClick: () => {
    //   history.push("/cock-boxing/operatorincome/new");
    // },
    // openEditOperatorDialog: (id) => {
    //   history.push(`/cock-boxing/operatorincome/${id}/edit`);
    // },
    // openDeleteOperatorDialog: (id) => {
    //   history.push(`/cock-boxing/operatorincome/${id}/delete`);
    // },
    // openDeleteOperatorsDialog: () => {
    //   history.push(`/cock-boxing/operatorincome/deleteAffiliates`);
    // },
    // openFetchOperatorsDialog: () => {
    //   history.push(`/cock-boxing/operatorincome/fetch`);
    // },
    // openUpdateOperatorsStatusDialog: () => {
    //   history.push("/cock-boxing/operatorincome/updateStatus");
    // },
  };
  const subOperatorsUIEvents = {};
  return (
    <>
      <OperatorsUIProvider operatorsUIEvents={operatorsUIEvents}>
        <OperatorsLoadingDialog />
        {/* <Route path="/cock-boxing/operatorincome/new">
        {({ history, match }) => (
          <OperatorEditDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/operatorincome");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/operatorincome/:id/edit">
        {({ history, match }) => (
          <OperatorEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/operatorincome");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/operatorincome/deleteAffiliates">
        {({ history, match }) => (
          <OperatorsDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/operatorincome");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/operatorincome/:id/delete">
        {({ history, match }) => (
          <OperatorDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/operatorincome");
            }}
          />
        )}
      </Route>  
      <Route path="/cock-boxing/operatorincome/fetch">
        {({ history, match }) => (
          <OperatorsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/operatorincome");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/operatorincome/updateStatus">
        {({ history, match }) => (
          <OperatorsUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/operatorincome");
            }}
          />
        )}
      </Route> */}
        <OperatorsCard />
      </OperatorsUIProvider>
      {user.role === 'operator' && !user?.affiliate?.parent_operator && <SubOperatorsUIProvider subOperatorsUIEvents={subOperatorsUIEvents}>
        <SubOperatorsCard />

      </SubOperatorsUIProvider>}
    </>
  );
}
