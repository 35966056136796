import React from "react";
import { Link } from "react-router-dom";

export const TitleColumnFormatter = (cellContent, row) => (
  <>
    <Link
      to={`/cock-boxing/player/${row._id}`}
    >
      <span style={{fontWeight:"300",color:"black"}} >
        {row.email}

      </span>
    </Link>
  </>
);
