// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/affiliates/affiliatesActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../AffiliatesUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useAffiliatesUIContext } from "../AffiliatesUIContext";

export function AffiliatesTable() {
  // Affiliates UI Context
  const affiliatesUIContext = useAffiliatesUIContext();
  const affiliatesUIProps = useMemo(() => {
    return {
      ids: affiliatesUIContext.ids,
      setIds: affiliatesUIContext.setIds,
      queryParams: affiliatesUIContext.queryParams,
      setQueryParams: affiliatesUIContext.setQueryParams,
      openEditAffiliateDialog: affiliatesUIContext.openEditAffiliateDialog,
      openDeleteAffiliateDialog: affiliatesUIContext.openDeleteAffiliateDialog,
    };
  }, [affiliatesUIContext]);

  // Getting curret state of affiliates list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.affiliates }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // Affiliates Redux state
  const dispatch = useDispatch();
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  useEffect(() => {
    // clear selections list
    affiliatesUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchAffiliates(affiliatesUIProps.queryParams,authToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliatesUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.DateColumnFormatter,
    },
    {
      dataField: "refId",
      text: "Operator Id",
      // sort: true,
      headerAlign: 'left',
      classes: "text-left pl-3 ",
      // sortCaret: sortCaret,
      // headerSortingClasses,
      formatter: columnFormatters.AddressColumnFormatter,

    },
    {
      dataField: "name",
      text: "name",
      sort: true,
      sortCaret: sortCaret,
      headerAlign: 'left',
      // headerSortingClasses,
      // formatter: columnFormatters.AddressColumnFormatter,

    },
    {
      dataField: "percentage",
      text: "Percentage",
      // sort: true,
      headerAlign: 'left',
      // headerSortingClasses,
      formatter: columnFormatters.ImageColumnFormatter,

    },
    {
      dataField: "parent_operator",
      text: "Main Operator",
      // sort: true,
      headerAlign: 'left',
      // sortCaret: sortCaret,
      // headerSortingClasses,
    },
    {
      dataField: "country",
      text: "country",
      // sort: true,
      headerAlign: 'left',
      // sortCaret: sortCaret,
      // headerSortingClasses,
    },
    {
      dataField: "action",
      text: "Actions",
      headerAlign: 'left',
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditAffiliateDialog: affiliatesUIProps.openEditAffiliateDialog,
        openDeleteAffiliateDialog: affiliatesUIProps.openDeleteAffiliateDialog,
      },
      classes: "text-left pr-3",
      headerClasses: "text-left pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: affiliatesUIProps.queryParams.pageSize,
    page: affiliatesUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-left overflow-hidden"
                bootstrap4
                remote
                keyField="_id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  affiliatesUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: affiliatesUIProps.ids,
                //   setIds: affiliatesUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
