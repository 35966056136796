import axios from "axios";

export const CockPickS_URL = "api/CockPicks";

// CREATE =>  POST: add a new CockPick to the server
export function createCockPick(CockPick) {
  return axios.post(CockPickS_URL, { CockPick });
}

// READ
export function getAllCockPicks() {
  return axios.get(CockPickS_URL);
}

export function getCockPickById(CockPickId) {
  return axios.get(`${CockPickS_URL}/${CockPickId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCockPicks(queryParams) {
  return axios.post(`${CockPickS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateCockPick(CockPick) {
  return axios.put(`${CockPickS_URL}/${CockPick.id}`, { CockPick });
}

// UPDATE Status
export function updateStatusForCockPicks(ids, status) {
  return axios.post(`${CockPickS_URL}/updateStatusForCockPicks`, {
    ids,
    status
  });
}

// DELETE => delete the CockPick from the server
export function deleteCockPick(CockPickId) {
  return axios.delete(`${CockPickS_URL}/${CockPickId}`);
}

// DELETE CockPicks by ids
export function deleteCockPicks(ids) {
  return axios.post(`${CockPickS_URL}/deleteCockPicks`, { ids });
}
