import * as requestFromServer from "./withdrawsCrud";
import { withdrawsSlice, callTypes } from "./withdrawsSlice";
import {
  GetAsset,
  GetWithdraw,
  GetWithdraws,
  UpdateWithdraw,
  VerifyWithdraw,
} from "../../../../../services/ApiServices";
const { actions } = withdrawsSlice;

export const fetchWithdraws = (queryParams, token) => async (dispatch) => {
  let query = "?";
  let body = { sort: {} };

  for (const key in queryParams) {
    switch (key) {
      case "sortOrder":
        body.sort[queryParams.sortField] = queryParams.sortOrder === "desc" ? -1 : 1;
        // query = query.concat(queryParams.sortField+"=" + queryParams.sortField);
        break;
      case "pageNumber":
        query = query.concat("&page=" + queryParams.pageNumber);
        break;
      case "pageSize":
        query = query.concat("&limit=" + queryParams.pageSize);
        break;
      case "filter":
        if (queryParams.filter) body.searchFields = queryParams.filter;
        break;
      case "filterField":
        if (queryParams.filterField) body.filterField = queryParams.filterField;
        break;
      case "filterFields":
        if (queryParams.filterFields) body.filterFields = queryParams.filterFields;
        break;
      default:
        break;
    }
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer

  let result;
  try {
    result = await GetWithdraws(query, body, token);
  } catch (error) {
    error.clientMessage = "Can't find withdraws";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      let totalCount = result.withdraws.totalDocs;
      let entities = result.withdraws.docs;
      dispatch(actions.withdrawsFetched({ totalCount, entities }));
    }
  } catch (error) {
    error.clientMessage = "Can't find withdraws";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const fetchWithdraw = (id) => async (dispatch) => {
  if (!id) {
    return dispatch(actions.withdrawFetched({ withdrawForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));

  let result;
  try {
    result = await GetWithdraw(id);
  } catch (error) {
    error.clientMessage = "Can't find withdraw";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      const withdraw = result.withdraw;
      dispatch(actions.withdrawFetched({ withdraw: withdraw }));
    }
  } catch (error) {
    error.clientMessage = "Can't find withdraw";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};
export const verifyWithdraw = (id, token) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  // return requestFromServer
  let result;
  try {
    result = await VerifyWithdraw(id, token);
  } catch (error) {
    error.clientMessage = "Can't verify withdraw";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      dispatch(actions.withdrawDeleted({ id }));
    }
  } catch (error) {
    error.clientMessage = "Can't verify withdraw";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }

  // .deleteWithdraw(id)
  // .then((response) => {
  //   dispatch(actions.withdrawDeleted({ id }));
  // })
  // .catch((error) => {
  //   error.clientMessage = "Can't delete withdraw";
  //   dispatch(actions.catchError({ error, callType: callTypes.action }));
  // });
};
export const deleteWithdraw = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteWithdraw(id)
    .then((response) => {
      dispatch(actions.withdrawDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete withdraw";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createWithdraw = (withdrawForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createWithdraw(withdrawForCreation)
    .then((response) => {
      const { withdraw } = response.data;
      dispatch(actions.withdrawCreated({ withdraw }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create withdraw";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateWithdraw = (withdraw) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateWithdraw(withdraw)
    .then(() => {
      dispatch(actions.withdrawUpdated({ withdraw }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update withdraw";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateWithdrawsStatus = (ids, status, token) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  let result;
  try {
    result = await UpdateWithdraw(ids, status, token);
  } catch (error) {
    error.clientMessage = "Can't verify withdraw";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }

  try {
    if (result.success)
      dispatch(actions.withdrawsStatusUpdated({ ids, status }));
  }
  catch (error) {
    error.clientMessage = "Can't update withdraws status";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  };
};

export const deleteWithdraws = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteWithdraws(ids)
    .then(() => {
      dispatch(actions.withdrawsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete withdraws";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
