import React from "react";
import { Link } from "react-router-dom";

export const TitleColumnFormatter = (cellContent, row) => (
  <Link to={"/nft/nftcollection/product/" + row._id} className="d-flex align-items-center gap">
    {row.imageUrl !== "" ? (
      <img
        alt=""
        src={
          row.imageUrl
            ? row.imageUrl
            : row.attachments[0].fileType === "IMAGE" && row.attachments[0].url
        }
        style={{ borderRadius: "5px", objectFit: "cover" }}
        height="65px" 
      />
    ) : (
      <video
        autoPlay
        muted
        loop
        height="65px" 
        src={
          row.videoUrl
            ? row.videoUrl
            : row?.attachments[0]?.fileType === "VIDEO" &&
              row.attachments[0].url
        }
        type="video/mp4"
        style={{ borderRadius: "5px", objectFit: "cover" }}
      />
    )}
    <div className="text-primary">
    {row.tokenName ? row.tokenName : row.title ? row.title : row.displayName}</div>
  </Link>
);
