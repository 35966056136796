import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/deposits/depositsActions";
import { DepositEditBetStatusDialogHeader } from "./DepositEditBetStatusDialogHeader";
import { DepositEditBetStatusForm } from "./DepositEditBetStatusForm";
import { useDepositsUIContext } from "../DepositsUIContext";
import { CreateAdmin, EndDeposit, EndStaking } from "../../../../../../services/ApiServices";
import { useToasts } from "react-toast-notifications";
import { useState } from "react";
import * as auth from "../../../../Auth/_redux/authRedux";

export function DepositEndEditBetStatusDialog({ id, show, onHide }) {
  // Deposits UI Context
  const depositsUIContext = useDepositsUIContext();
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();
  const depositsUIProps = useMemo(() => {
    return {
      queryParams: depositsUIContext.queryParams,
      setQueryParams: depositsUIContext.setQueryParams,
      initDeposit: depositsUIContext.initDeposit,
    };
  }, [depositsUIContext]);
  // Deposits Redux state
  const dispatch = useDispatch();
  const { actionsLoading, depositForEdit, deposit } = useSelector(
    (state) => ({
      actionsLoading: state.deposits.actionsLoading,
      depositForEdit: state.deposits.depositForEdit,
      deposit: state.deposits.deposit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchDeposit(id));
  }, [id, dispatch]);
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  // server request for saving deposit
  const editStaking = async () => {
    let body = {
      depositId: id,
    };
    setIsLoading(true);
    let result;
    try {
      result = await EndStaking(body, authToken);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: "true",
      });
    }
    try {
      if (result.success) {
        setIsLoading(false);
        dispatch(actions.fetchDeposits(depositsUIProps.queryParams, authToken));
        onHide();
        return addToast(result.message, {
          appearance: "success",
          autoDismiss: "true",
        });
      }
    } catch (error) {
      setIsLoading(false);
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: "true",
      });
    }

    // if (!id) {
    //   // server request for creating deposit
    //   console.log(deposit);

    //   dispatch(actions.createDeposit(deposit)).then(() => onHide().catch(e=>{
    //     console.log(e);
    //   }));
    // } else {
    //   // server request for updating deposit
    //   dispatch(actions.updateDeposit(deposit)).then(() => onHide());
    // }
  };

  return (
    <Modal
      // size="sm"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <DepositEditBetStatusDialogHeader id={id} />
      <DepositEditBetStatusForm
        editStaking={editStaking}
        actionsLoading={actionsLoading}
        deposit={deposit}
        onHide={onHide}
        isLoading={isLoading}
      />
    </Modal>
  );
}
