/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/collections/collectionsActions";
import { useCollectionsUIContext } from "../CollectionsUIContext";
import { Idodelete } from "../../../../../../services/ApiServices";
import * as actionss from "../../../_redux/IDOtoken/collectionsActions";
import { useHistory } from "react-router-dom";





export function CollectionDeleteDialog({ id, show, onHide }) {
  // Collections UI Context

  const collectionsUIContext = useCollectionsUIContext();
  const collectionsUIProps = useMemo(() => {
    return {
      setIds: collectionsUIContext.setIds,
      queryParams: collectionsUIContext.queryParams,
    };
  }, [collectionsUIContext]);

  // Collections Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.collections.actionsLoading }),
    shallowEqual
  );
  let history = useHistory();
  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  // const deleteItem = async (id) => {
  //   // setIsLoading(true);

  //   let result;

  //   try {
  //     result = await Idodelete(id);
  //     console.log("delete check")
  //   } catch (e) {
  //     console.log(e, id,"delete");
  //   }
  //   try {
  //     if (result.success) {
  //       // setIsLoading(false);
  //       // dispatch(actions.fetchCollections());
  //       dispatch(
  //         actionss.fetchIdoCollections(
  //           "62e8b2b70e72ed8bc55a6983",
  //           collectionsUIProps.queryParams
  //         )
  //       );

  //       onHide();
  //       console.log(result);
  //       history.push("/nft/InitialNFTofferings");
  //     }
  //   } catch (e) {
  //     console.log(e, id);
  //   }
  // };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Collection Delete
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>Are you sure to permanently delete this collection?</span>
        )}
        {isLoading && <span>Collection is deleting...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            // onClick={deleteItem(id)}
            className="btn btn-delete btn-elevate"
          >
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
