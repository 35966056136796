import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { AffiliatesFilter } from "./affiliates-filter/AffiliatesFilter";
import { AffiliatesTable } from "./affiliates-table/AffiliatesTable";
import { AffiliatesGrouping } from "./affiliates-grouping/AffiliatesGrouping";
import { useAffiliatesUIContext } from "./AffiliatesUIContext";
import { shallowEqual, useSelector } from "react-redux";

export function AffiliatesCard() {
  const affiliatesUIContext = useAffiliatesUIContext();
  const affiliatesUIProps = useMemo(() => {
    return {
      ids: affiliatesUIContext.ids,
      newAffiliateButtonClick: affiliatesUIContext.newAffiliateButtonClick,
    };
  }, [affiliatesUIContext]);
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );
  return (
    <Card>
      <CardHeader title={user.role === "operator" ? "Sub-Operator List" : "Operator list"}>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={affiliatesUIProps.newAffiliateButtonClick}
          >
            {user.role === "operator" ? "Add Sub-Operator" : "Add Operator"}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <AffiliatesFilter />
        {affiliatesUIProps.ids.length > 0 && <AffiliatesGrouping />}
        <AffiliatesTable />
      </CardBody>
    </Card>
  );
}
