import moment from "moment";
import React from "react";

export const DateColumnFormatter = (cellContent, row) => (
  <span>
    {/* <b>{moment(cellContent).format("hh:mm A")}</b> */}
    {/* <br /> */}
    {moment(cellContent).format("MMM,DD YYYY")}
  </span>
);
