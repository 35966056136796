import axios from "axios";
import { GetAdmin, Login, SignatureLogin } from "../../../../services/ApiServices";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  setPersistence,
  browserSessionPersistence,
} from 'firebase/auth';
import { auth } from "../../../../Config/FirebaseConfig";

export const LOGIN_URL = `http://localhost:3200/v1/admin/login`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;

export async function login(email, password) {
  return setPersistence(auth, browserSessionPersistence).then(() => {
    return signInWithEmailAndPassword(auth, email, password);
  });
}
export async function logout() {
  return await signOut(auth);
}
export async function signatureLogin(signature) {
  let result;
  try {
    result = await SignatureLogin(signature)
  }
  catch (e) {
    console.log(e);
    return e;
  }
  try {
    if (result.success) {
      return result
    }
  }
  catch (e) {
    console.log(e);
    return e
  }

  // return axios.post(LOGIN_URL, { email, password });
}
export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export async function getUserByToken(token) {
  // Authorization head should be fulfilled in interceptor.
  // return axios.get(ME_URL);

  let result;
  try {
    result = await GetAdmin(token)
  }
  catch (e) {
    console.log(e);
  }
  try {
    if (result.success) {
      return result
    }
  }
  catch (e) {
    console.log(e);
    return e
  }
}
