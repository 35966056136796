/* eslint-disable jsx-a11y/alt-text */
import { useCallback, useEffect, useState } from 'react';
import TFAModal from './TFAModal';
import { shallowEqual, useSelector } from "react-redux";
import { GetAuthenticatorQr, Getis2FAEnabled, PostEnable2FA } from '../../../services/ApiServices';
import { useToasts } from 'react-toast-notifications';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from '../../_helpers';

const TFA = () => {
  const { user, authToken } = useSelector(
    ({ auth }) => ({
      user: auth.user,
      authToken: auth.authToken,

    }),
    shallowEqual
  );
  const [tfaLoader, setTFALoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [tokenNumber, setTokenNumber] = useState('');
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [qr, setQr] = useState({});
  const { addToast } = useToasts();

  const handleToken = (value) => {
    if (value === '') return setTokenNumber('');
    setTokenNumber(value)
  };

  const getIsEnabled = async () => {
    let resultTwo;
    try {
      resultTwo = await Getis2FAEnabled(user.email);
      if (resultTwo.success) {
        setIs2FAEnabled(resultTwo.enabled);
        if (!resultTwo.enabled)
          getAuthenticatorQr()
      }
    } catch (e) {
      console.log('get is enable exception', e);
    }
  };

  const getAuthenticatorQr = useCallback(async () => {
    setTFALoader(true);

    let result;
    try {
      result = await GetAuthenticatorQr(authToken, user.uid);
      if (result.success) {
        setQr(result);
        setTFALoader(false);
      } else {
        setTFALoader(false);
      }
    } catch (e) {
      setTFALoader(false);
      console.log(e);
    }
  }, []);

  const enable2FA = async () => {
    if (tokenNumber.toString().length < 6) {
      return addToast('Verification code should not be less than 6 digit', {
        appearance: "error",
        autoDismiss: "true",
      });
    }
    setButtonLoader(true);

    let result;
    try {
      result = await PostEnable2FA(authToken, user.uid, tokenNumber);
      if (result.success) {
        // getIsEnabled();
        setIs2FAEnabled(true);
        setTokenNumber("")
        addToast('2FA Enabled Successfully', {
          appearance: "success",
          autoDismiss: "true",
        });
        setButtonLoader(false);
      } else {
        addToast(result.error, {
          appearance: "error",
          autoDismiss: "true",
        });
        setButtonLoader(false);
      }
    } catch (e) {
      setTokenNumber("")
      setButtonLoader(false);
      console.log(e);
      addToast(e.message, {
        appearance: "error",
        autoDismiss: "true",
      });
      setButtonLoader(false);
    }
  };

  useEffect(() => {
    getIsEnabled();
  }, []);

  const handleModalClose = () => {
    setOpenModal(false);
  };
  return (
    <>
      {tfaLoader ? (
        <div
          className='d-flex align-items-center justify-content-center'
          style={{ height: "200px" }}
        >
          <span
            className="ml-3 spinner spinner-black"
            style={{ width: "inherit" }}
          ></span>
        </div>
      ) : (
        <>
          {is2FAEnabled ? (
            <div
              className='d-flex flex-col padding-2 align-items-center justify-content-center'
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                p: 2,
                minHeight: '200px',
                borderRadius: '10px',
                //   maxWidth: '350px',
                margin: '0 auto',
              }}
            >
              <p >
                {' '}
                Two-factor authentication is currently enabled for your account
                {/* <span className={classes.recovery}> {''}Get your recovery codes here</span> */}
              </p>

              <div >
                <button
                  className="btn btn-primary"
                  label={''}
                  onClick={() => setOpenModal(true)}
                > Disable 2-factor authentication</button>
              </div>
            </div>
          ) : (
            <div >
              <div className="row">
                <div className="col-lg-6">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: 2,
                      minHeight: '350px',
                      borderRadius: '10px',
                    }}
                  >
                    <p > Scan the Qr Code</p>
                    <img src={qr.Qrcode} width={220} />
                    <p >OR</p>
                    <div className='d-flex flex-column align-items-center'>
                      <p >{qr.baseKey}</p>
                      <button
                        className='btn btn-outline-primary mt-2 mx-auto'
                        onClick={() => {
                          navigator.clipboard.writeText(qr.baseKey)
                          addToast('Copied', {
                            appearance: "success",
                            autoDismiss: "true",
                          })
                        }}>
                        copy
                        <span
                          className="svg-icon svg-icon-sm svg-icon-primary ml-1 svg-icon-sort cursor-pointer">
                          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")} />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: '350px',
                      padding: 2,
                      borderRadius: '10px',
                    }}
                  >
                    {' '}
                    <b >Connect an Authenticator app</b>
                    <ul>
                      <li >
                        Download and install Google authenticator app on your mobile device.
                      </li>
                      <li >Use the app to scan this QR code.</li>
                      <li >
                        Enter the verification code generated by the app.
                      </li>
                    </ul>
                    <b >Enter Verification Code</b>
                    <input
                      className="form-control"
                      type='number'
                      onChange={(e) => {
                        handleToken(e.target.value);
                      }}
                      value={tokenNumber}
                    />
                    <div className="mt-2 mx-auto">
                      {buttonLoader ? (
                        <button
                          className="btn btn-primary btn-elevate d-flex align-items-center justify-content-center"
                          style={{ width: "81px", height: "40px" }}
                        >
                          <span
                            className="ml-3 spinner spinner-white"
                            style={{ width: "inherit" }}
                          ></span>
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary" onClick={() => enable2FA()} >Enable 2FA </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )
      }

      <TFAModal
        open={openModal}
        handleConnectClose={() => handleModalClose()}
        // getIsEnabled={() => getIsEnabled()}
        getAuthenticatorQr={() => getAuthenticatorQr()}
        setIs2FAEnabled={setIs2FAEnabled}
      />
    </>
  );
};

export default TFA;
