import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { SolanaStatusCssClasses } from "../SolanasUIHelpers";
import * as actions from "../../../_redux/solanas/solanasActions";
import { useSolanasUIContext } from "../SolanasUIContext";

const selectedSolana = (entities, id) => {
  let solana;
  // ids.forEach((id) => {
  if (entities?.length)
    solana = entities.find((el) => el._id === id);
  // if (solana) {
  //   _solana.push(solana);
  // }
  // });
  return solana;
};

export function SolanaUpdateStatusDialog({ show, onHide }) {
  // Solana UI Context
  const { authToken, user } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
      user: auth.user
    }),
    shallowEqual
  );
  const solanaUIContext = useSolanasUIContext();
  const solanaUIProps = useMemo(() => {
    return {
      id: solanaUIContext.id,
      setId: solanaUIContext.setId,
      queryParams: solanaUIContext.queryParams,
    };
  }, [solanaUIContext]);

  // Solana Redux state
  const { solana, isLoading } = useSelector(
    (state) => ({
      solana: selectedSolana(state.solanas?.entities, solanaUIProps.id),
      isLoading: state.solanas.actionsLoading,
    }),
    shallowEqual
  );
  console.log(solana, solanaUIProps.id);
  // if there weren't selected solana we should close modal
  useEffect(() => {
    if (!solanaUIProps.id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solanaUIProps.id]);


  const dispatch = useDispatch();
  const updateStatus = () => {
    // server request for updateing solana by ids
    dispatch(actions.updateSolanasStatus(solanaUIProps.id, { status: 'completed' })).then(
      () => {
        // refresh list after deletion
        dispatch(actions.fetchSolanas(solanaUIProps.queryParams, authToken)).then(
          () => {
            // clear selections list
            solanaUIProps.setId([]);
            // closing delete modal
            onHide();
          }
        );
      }
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton className="form">
        <Modal.Title id="example-modal-sizes-title-lg">
          Solana Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default"
        style={{ width: '90%' }}
      >
        {isLoading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-warning" />
          </div>
        )}
        <div className="list-timeline list-timeline-skin-light" style={{ padding: '0px' }}>
          <div className="list-timeline-items">
            <div className="list-timeline-item mb-3" key={solana?._id}>
              <div className="list-timeline-text" style={{ textAlign: 'center' }}>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%", }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Status </div>
                  <div
                    style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }}
                    className={`label label-lg padding-8 label-light-${SolanaStatusCssClasses[solana?.status]} label-inline`}
                  >
                    {solana?.status}
                  </div>
                </div>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>First Name </div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }}  > {solana?.user?.firstName}</div>

                </div>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Last Name </div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }}  >{solana?.user?.lastName}</div>
                </div>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Email</div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }} > {solana?.user?.email}</div>
                </div>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Amount </div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }} > $ {solana?.amount.toFixed(2)}</div>
                </div>

                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }} >
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Tx Hash</div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }} >

                    {solana?.txHash && <a href={`https://solscan.io/tx/${solana?.txHash}`} target='_blank' rel="noreferrer">
                      {solana?.txHash.slice(0, 20)}...{solana?.txHash.slice(-20, solana?.txHash.length)}
                    </a>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer >

        <div className="form-group">
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Close
          </button>
          <> </>
          {(solana?.status !== 'completed' && user.role !== 'operator') && <button
            type="button"
            onClick={updateStatus}
            className="btn btn-primary btn-elevate"
          >
            Mark as Completed
          </button>}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
