//------------------------------------- LOGO -------------------------------------
export const LOGO = "/media/logos/cockboxing.png";

export const MOBILE_LOGO = "/media/logos/cockboxing.png";

export const AUTH_BACKGROUND = "/media/bg/authbg.png";

export const PRODUCT_NAME = "Cockboxing Live";

export const PRODUCT_YEAR = "2024"

export const WELCOME_HEADER = "WELCOME TO Cockboxing Live!";

export const WELCOME_TEXT = "The Cockboxing Live admin dashboard helps to create events and fights, manage users and manage reported collections here.";

export const SIGNATURE_MESSAGE_KEY = "kGFCxvda2GsWxe7DG6XW";
