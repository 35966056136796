import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { EventStatusCssClasses } from "../EventsUIHelpers";
import * as actions from "../../../_redux/events/eventsActions";
import { useEventsUIContext } from "../EventsUIContext";
import { EventsAddCard } from "./EventAddCard";
import CreateEvent from "./EventCreateForm";

const selectedEvents = (entities, ids) => {
  const _events = [];
  ids.forEach((id) => {
    const event = entities.find((el) => el.id === id);
    if (event) {
      _events.push(event);
    }
  });
  return _events;
};

export function EventAddDialog({ show, onHide }) {
  // Events UI Context
  const eventsUIContext = useEventsUIContext();
  const eventsUIProps = useMemo(() => {
    return {
      ids: eventsUIContext.ids,
      setIds: eventsUIContext.setIds,
      queryParams: eventsUIContext.queryParams,
    };
  }, [eventsUIContext]);

  // Events Redux state
  const { events, isLoading } = useSelector(
    (state) => ({
      events: selectedEvents(state.events.entities, eventsUIProps.ids),
      isLoading: state.events.actionsLoading,
    }),
    shallowEqual
  );

  // if there weren't selected events we should close modal
  useEffect(() => {
    if (eventsUIProps.ids || eventsUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsUIProps.ids]);

  const [status, setStatus] = useState(0);
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const refresh = () => {
 
      dispatch(actions.fetchEvents(eventsUIProps.queryParams,authToken)).then(() => {
        eventsUIProps.setIds([]);
        onHide();
      });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Create Event
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default">
        <CreateEvent onHide={onHide} refresh={refresh} />
      </Modal.Body>
    </Modal>
  );
}
