// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Input,
  DatePickerField,
} from "../../../../../../_metronic/_partials/controls";
import Select from 'react-select'
import countryList from 'react-select-country-list'

// Validation schema
const AffiliateEditSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("User Name is required"),
  refId: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 10 symbols")
    .required("Operator Id is required"),
  country: Yup.string()
    .required("Country is required"),
});

export function OperatorEditForm({
  saveAffiliate,
  affiliate,
  actionsLoading,
  onHide,
  isLoading,
}) {
  const options = useMemo(() => countryList().getData(), []);
  
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={affiliate}
        validationSchema={AffiliateEditSchema}
        onSubmit={(values) => {
          saveAffiliate(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-12">
                    <label>Operator Id</label><br />
                    <Field
                      name="refId"
                      placeholder="Operator Id"
                      className="form-control"
                    />
                    <ErrorMessage name="refId" component="div" className="text-danger" />
                  </div>
                  <div className="col-lg-12">
                    <label>Enter Name</label><br />
                    <Field
                      name="name"
                      placeholder="Name"
                      className="form-control"
                    />
                    <ErrorMessage name="name" component="div" className="text-danger" />
                  </div>
                  <div className="col-lg-12 mt-2">
                    <label>Select Country</label><br />
                    <Select 
                      options={options} 
                      name="country" 
                      value={values.country}
                      onChange={(e) => setFieldValue('country', e)} 
                    />
                    <ErrorMessage name="country" component="div" className="text-danger" />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate justify-content-center"
                style={{ width: "81px" }}
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate d-flex align-items-center justify-content-center"
                style={{ width: "81px" }}
              >
                Save
                {isLoading && (
                  <span
                    className="ml-3 spinner spinner-white"
                    style={{ width: "inherit" }}
                  ></span>
                )}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
