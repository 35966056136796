/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { GoUnverified, GoVerified, IconName } from "react-icons/go";
import { Link } from "react-router-dom";
export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { openVerifyCollectionDialog, openUpdateEventsStatusDialog,  }
) => (
  <div className="d-flex justify-content-center align-items-center gap">
    {/* {row.verified ? (
      <>
        <button className="btn btn-icon btn-light btn-hover-secondary btn-sm">
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <GoUnverified
              className="text-primary"
            />
          </span>
        </button>
      </>
    ) : (
      <>

        <button
          className="btn btn-icon btn-light btn-hover-secondary btn-sm"
        >
          <span className="svg-icon svg-icon-md svg-icon-info">
            <GoVerified
              className="text-info"
            />
          </span>
        </button>
      </>
    )} */}

    {/* <button
      className="btn btn-icon btn-light btn-hover-secondary btn-sm"
    >
      <span className="svg-icon svg-icon-md svg-icon-primary">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
      </span>
    </button> */}
      <button
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={() => {
          // setIds(row._id);
          openUpdateEventsStatusDialog(row._id);
        }}
      >
        <span className="svg-icon svg-icon-md svg-icon-info">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
        </span>
      </button>
  </div>
);
