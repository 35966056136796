import React from "react";

export const AmountColumnFormatter = (
  cellContent,
  row,
  index,
  formatExtraData
) => (
  <>
    <span className="badge badge-primary">{cellContent.toFixed(3)}</span>
  </>
);
