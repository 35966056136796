import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./OperatorsUIHelpers";

const OperatorsUIContext = createContext();

export function useOperatorsUIContext() {
  return useContext(OperatorsUIContext);
}

export const OperatorsUIConsumer = OperatorsUIContext.Consumer;

export function OperatorsUIProvider({ operatorsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initOperator = {

    name: "",
    country: "",
    refId: ''

  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initOperator,
    newOperatorButtonClick: operatorsUIEvents.newOperatorButtonClick,
    openEditOperatorDialog: operatorsUIEvents.openEditOperatorDialog,
    openDeleteOperatorDialog: operatorsUIEvents.openDeleteOperatorDialog,
    openDeleteOperatorsDialog: operatorsUIEvents.openDeleteOperatorsDialog,
    openFetchOperatorsDialog: operatorsUIEvents.openFetchOperatorsDialog,
    openUpdateOperatorsStatusDialog: operatorsUIEvents.openUpdateOperatorsStatusDialog
  };

  return <OperatorsUIContext.Provider value={value}>{children}</OperatorsUIContext.Provider>;
}