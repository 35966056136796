import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { DepositStatusCssClasses } from "../DepositsUIHelpers";
import { useDepositsUIContext } from "../DepositsUIContext";

const selectedDeposits = (entities, ids) => {
  const _deposits = [];
  ids.forEach((id) => {
    const deposit = entities.find((el) => el.id === id);
    if (deposit) {
      _deposits.push(deposit);
    }
  });
  return _deposits;
};

export function DepositsFetchDialog({ show, onHide }) {
  // Deposits UI Context
  const depositsUIContext = useDepositsUIContext();
  const depositsUIProps = useMemo(() => {
    return {
      ids: depositsUIContext.ids,
      queryParams: depositsUIContext.queryParams,
    };
  }, [depositsUIContext]);

  // Deposits Redux state
  const { deposits } = useSelector(
    (state) => ({
      deposits: selectedDeposits(state.deposits.entities, depositsUIProps.ids),
    }),
    shallowEqual
  );

  // if there weren't selected ids we should close modal
  useEffect(() => {
    if (!depositsUIProps.ids || depositsUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depositsUIProps.ids]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Fetch selected elements
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="list-timeline list-timeline-skin-light padding-30">
          <div className="list-timeline-items">
            {deposits.map((deposit) => (
              <div className="list-timeline-item mb-3" key={deposit.id}>
                <span className="list-timeline-text">
                  <span
                    className={`label label-lg label-light-${
                      DepositStatusCssClasses[deposit.status]
                    } label-inline`}
                    style={{ width: "60px" }}
                  >
                    ID: {deposit.id}
                  </span>{" "}
                  <span className="ml-5">
                    {deposit.manufacture}, {deposit.model}
                  </span>
                </span>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-primary btn-elevate"
          >
            Ok
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
