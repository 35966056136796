import React from "react";
import { Link } from "react-router-dom";

export const TitleColumnFormatter = (cellContent, row) => (
  <Link
    to={"/nft/IDOTokenLaunches/product/" + row._id}
    className="d-flex justify-content-start align-items-center gap text-primary"
    styles={{ paddingLeft: "10px" }}
  >
    {row.asstesId?.attachments?.map((row) => (
      <img
        src={row.url}
        height="65px"
        width="65px"
        style={{ borderRadius: "50%", objectFit: "cover" }}
      />
    ))}
    <div className="text-primary"> {row.title}</div>
  </Link>
  // <Link to={"/nft/IDOTokenLaunches/token/" + row._id}>  {row.title}  </Link>
  // <Link to={"/nft/IDOTokenLaunches/product/" + row._id}>
  //     {row.asstesId?.attachments?.map((row) => (
  // <img src={row.url} height="65px" width="65px"  style={{borderRadius:"50%",objectFit:"cover"}} />
  // ))}
  // <div className="text-primary">  {row.title}</div></Link>
);
