import React from "react";

export const AffiliateColumnFormatter = (
  cellContent,
  row,
  index,
  formatExtraData
) => (
  <>
    {/* {formatExtraData}{cellContent} */}

    <b className="">{row?.affiliate?.refId ? row?.affiliate?.refId : '--'}</b>

  </>
);
