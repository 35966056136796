/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
// import generatePDF from 'react-to-pdf';
import ReactExport from "react-export-excel";
import moment from "moment";
import { GetAllWithdraw } from "../../../services/ApiServices";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
export function DropdownMenu6({ targetRef }) {
    const [withdraw, setWithdraw] = useState([]);

    const { authToken } = useSelector(
        ({ auth }) => ({
            authToken: auth.authToken,
        }),
        shallowEqual
    );

    const getWithdraw = useCallback(async () => {
        let result;
        try {
            result = await GetAllWithdraw(authToken);
            if (result.success)
                setWithdraw(result.withdraws);
        }
        catch (e) {
            console.log(e);
        }
    }, [authToken])
    useEffect(() => {
        getWithdraw()
    }, [getWithdraw])
    const generatePdf = () => {
        const doc = new jsPDF();

        // Example data format
        const tableColumn = ["Operator Id", "Name", "Email", "Date", "Wallet Address", "Chain", "Amount", "Status"];
        const tableRows = [];

        withdraw.forEach(item => {
            const itemData = [
                item.operatorId, // assuming 'name' is a key in your data object
                item.name, // assuming 'description' is another key in your data object
                item.email,
                moment(item.createdAt).format('DD/MM/YYYY'),
                item.walletAddress,
                item.chain,
                '$ ' + item.amount,
                item.status
            ];
            tableRows.push(itemData);
        });

        // Start the table
        doc.autoTable(tableColumn, tableRows, {
            startY: 20,
            styles: { fontSize: 6 },
        });

        // Save the generated PDF
        doc.save('withdraws.pdf');
    };
    return <>
        {/*begin::Navigation*/}
        <ul className="navi navi-hover py-5">
            <li className="navi-item">
                <ExcelFile element={<div href="#" className="navi-link">
                    <span className="navi-icon"><i className="flaticon2-file"></i></span>
                    <span className="navi-text">Excel</span>
                </div>}>
                    <ExcelSheet data={withdraw} name="withdraws">
                        <ExcelColumn label="Name" value={(col) => {
                            return col.name
                        }} />
                        <ExcelColumn label="Email" value={(col) => col?.email} />
                        <ExcelColumn label="Operator" value={(col) => col?.operatorId} />
                        <ExcelColumn label="Wallet Address" value={(col) => col.walletAddress} />
                        <ExcelColumn label="Amount" value={(col) => '$' + col.amount?.toFixed(2)} />
                        <ExcelColumn label="Date" value={(col) => moment(col.date).format('DD/MM/YYYY')} />
                        <ExcelColumn label="Status" value={(col) => col.status} />

                    </ExcelSheet>

                </ExcelFile>

            </li>
            <li className="navi-item">
                <div href="#" className="navi-link"
                    onClick={() => generatePdf()}
                >
                    <span className="navi-icon"><i className="flaticon2-crisp-icons"></i></span>
                    <span className="navi-text">PDF</span>
                </div>
            </li>

        </ul>
        {/*end::Navigation*/}

    </>
}
