import { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";

import { Link } from "react-router-dom";
import { createMediaServices, GetMediaServices } from "../../../../../services/ApiServices";
import { shallowEqual, useSelector } from "react-redux";
import { useToasts } from 'react-toast-notifications';
import moment from "moment";

export function MediaServices() {
  const [streamUrl, setStreamUrl] = useState('');
  const [stream, setStream] = useState('');
  const [streamData, setStreamDate] = useState({});
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();

  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  const getStream = async () => {
    setLoading(true);
    let result;
    try {
      result = await GetMediaServices();
      if (result.success)
        setStream(result.mediaServices.stream_url);
      setStreamDate(result.mediaServices)
      setLoading(false);

    }
    catch (e) {
      setLoading(false);
      return console.log(e);
    }
  }
  useEffect(() => {
    getStream()
  }, [])
  const createStream = async () => {
    if (!streamUrl)
      return addToast('Please enter valid Stream url', {
        appearance: "error",
        autoDismiss: "true",
      });

    setIsLoading(true);
    let result;
    let body = {
      stream_url: streamUrl
    }
    try {
      result = await createMediaServices(body, authToken);
      if (result.success)
        setStream(result.mediaServices.stream_url);
      setStreamUrl('')
      setIsLoading(false);
      return addToast('Stream Url Updated', {
        appearance: "success",
        autoDismiss: "true",
      });
    }
    catch (e) {
      setIsLoading(false);
      return console.log(e);
    }
  }
  return (
    <Card>
      <CardHeader title="Media Services">
        {/* <CardHeaderToolbar>
          <Link
            to="/cock-boxing/fights/import"
            // type="button"
            className="btn btn-primary"
          // onClick={fightsUIProps.newFightButtonClick}
          >
            Import Fight
          </Link>
        </CardHeaderToolbar> */}
      </CardHeader>
      <CardBody>
        <h3>Last updated</h3>
        <span><b>Stream URL : </b><a href={stream}> {stream}</a></span>
        <div className="d-flex"><b>Date : </b>{stream && <p> {moment(streamData?.createdAt).utc().format("MMM,DD YYYY hh:mm A")}</p>}</div>
        <b className="mt-5">Enter Stream Server Url</b>
        <input
          className="form-control mb-3"
          type='text'
          onChange={(e) => {
            setStreamUrl(e.target.value);

          }}
          value={streamUrl}
        />
        {isLoading ? (
          <button
            className="btn btn-primary btn-elevate d-flex align-items-center justify-content-center"
            style={{ width: "85px", height: "40px" }}
          >Saving
            <span
              className="ml-3 spinner spinner-white"
              style={{ width: "inherit" }}
            ></span>
          </button>
        ) : (
          <button
            className="btn btn-primary" onClick={() => createStream()} >Save </button>
        )}
        <h4 className="mt-5">Preview</h4>
        <iframe
          title="vid"
          width="560"
          height="315"
          src={streamUrl ? streamUrl : stream}
          className="d-flex justify-content-center mx-auto mb-8  border border-primary"
          style={{ borderWidth: "3px !important" }}
        ></iframe>


      </CardBody>
    </Card>
  );
}
