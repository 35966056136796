import axios from "axios";

export const DepositS_URL = "api/Deposits";

// CREATE =>  POST: add a new Deposit to the server
export function createDeposit(Deposit) {
  return axios.post(DepositS_URL, { Deposit });
}

// READ
export function getAllDeposits() {
  return axios.get(DepositS_URL);
}

export function getDepositById(DepositId) {
  return axios.get(`${DepositS_URL}/${DepositId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findDeposits(queryParams) {
  return axios.post(`${DepositS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateDeposit(Deposit) {
  return axios.put(`${DepositS_URL}/${Deposit.id}`, { Deposit });
}

// UPDATE Status
export function updateStatusForDeposits(ids, status) {
  return axios.post(`${DepositS_URL}/updateStatusForDeposits`, {
    ids,
    status
  });
}

// DELETE => delete the Deposit from the server
export function deleteDeposit(DepositId) {
  return axios.delete(`${DepositS_URL}/${DepositId}`);
}

// DELETE Deposits by ids
export function deleteDeposits(ids) {
  return axios.post(`${DepositS_URL}/deleteDeposits`, { ids });
}
