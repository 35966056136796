import React from "react";

export const ImageColumnFormatter = (cellContent, row) => {
  const calculatedResult = ((Math.abs(row?.totalStakeAmount) * 0.1) + (Math.abs(row?.totalCockpickAmount) * 0.5))* (row?.affiliate?.percentage / 100);
  return (
    <>
      <b>
        {calculatedResult.toFixed(2)} $
      </b>
    </>
  );
};
