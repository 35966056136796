import React from "react";
import {
  EventConditionCssClasses,
  EventConditionTitles
} from "../../StakingUIHelpers";

export const ConditionColumnFormatter = (cellContent, row) => (
  <>
    <span>${parseFloat(row?.amount).toFixed(2)}</span>

  </>
);
