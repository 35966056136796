import axios from "axios";

export const CUSTOMERS_URL = "api/affiliates";

// CREATE =>  POST: add a new affiliate to the server
export function createAffiliate(affiliate) {
  return axios.post(CUSTOMERS_URL, { affiliate });
}

// READ
export function getAllAffiliates() {
  return axios.get(CUSTOMERS_URL);
}

export function getAffiliateById(affiliateId) {
  return axios.get(`${CUSTOMERS_URL}/${affiliateId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findAffiliates(queryParams) {
  return axios.post(`${CUSTOMERS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the affiliate on the server
export function updateAffiliate(affiliate) {
  return axios.put(`${CUSTOMERS_URL}/${affiliate.id}`, { affiliate });
}

// UPDATE Status
export function updateStatusForAffiliates(ids, status) {
  return axios.post(`${CUSTOMERS_URL}/updateStatusForAffiliates`, {
    ids,
    status
  });
}

// DELETE => delete the affiliate from the server
export function deleteAffiliate(affiliateId) {
  return axios.delete(`${CUSTOMERS_URL}/${affiliateId}`);
}

// DELETE Affiliates by ids
export function deleteAffiliates(ids) {
  return axios.post(`${CUSTOMERS_URL}/deleteAffiliates`, { ids });
}
