/* eslint-disable no-unused-vars */
import React, {useMemo} from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { ReportsFilter } from "./reports-filter/ReportsFilter";
import { ReportsTable } from "./reports-table/ReportsTable";
import { ReportsGrouping } from "./reports-grouping/ReportsGrouping";
import { useReportsUIContext } from "./ReportsUIContext";

export function ReportsCard() {
  const reportsUIContext = useReportsUIContext();
  const reportsUIProps = useMemo(() => {
    return {
      ids: reportsUIContext.ids,
      queryParams: reportsUIContext.queryParams,
      setQueryParams: reportsUIContext.setQueryParams,
      newReportButtonClick: reportsUIContext.newReportButtonClick,
      openDeleteReportsDialog: reportsUIContext.openDeleteReportsDialog,
      openEditReportPage: reportsUIContext.openEditReportPage,
      openUpdateReportsStatusDialog:
        reportsUIContext.openUpdateReportsStatusDialog,
      openFetchReportsDialog: reportsUIContext.openFetchReportsDialog,
    };
  }, [reportsUIContext]);

  return (
    <Card>
      <CardHeader title="Reported NFT list">
        {/* <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={reportsUIProps.newReportButtonClick}
          >
            New Report
          </button>
        </CardHeaderToolbar> */}
      </CardHeader>
      <CardBody>
        <ReportsFilter />
        {reportsUIProps.ids.length > 0 && (
          <>
            <ReportsGrouping />
          </>
        )}
        <ReportsTable />
      </CardBody>
    </Card>
  );
}
