/* eslint-disable no-unused-vars */
// /* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
// import React from "react";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import SVG from "react-inlinesvg";
// import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
// import { Link } from "react-router-dom";


// export const ActionsColumnFormatter = (
//   cellContent,
//   row,
//   rowIndex,
//   { openVerifyCollectionDialog,  openEditCollectionPage,opencollectionHidesite ,opencollectionShowsite}
// ) => (
//   <>
//     <>
//     <OverlayTrigger
//       overlay={<Tooltip id="products-edit-tooltip">Edit product</Tooltip>}
//     >
//       <a
//         className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
//         href={"/nft/IDOlists/"+ row._id+"/edit" } 
//         // onClick={() => openEditProductPage(row.id)}
//       >
//         <span className="svg-icon svg-icon-md svg-icon-primary">
//           <SVG
//             src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
//           />
//         </span>
//       </a>
//     </OverlayTrigger>

//     <> </>
//     <OverlayTrigger
//       overlay={<Tooltip id="products-delete-tooltip">Delete product</Tooltip>}
//     >
//       <a
//         className="btn btn-icon btn-light btn-hover-danger btn-sm"
//         onClick={() => openVerifyCollectionDialog(row._id)}
//       >
//         <span className="svg-icon svg-icon-md svg-icon-danger">
//           <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
//         </span>
//       </a>
//     </OverlayTrigger>
//     <>
//   {row.enabled === false ? <OverlayTrigger
//       overlay={<Tooltip id="products-delete-tooltip">Show in App</Tooltip>}
//     >
//       <a
//         className="btn btn-icon btn-light btn-hover-danger btn-sm"
//         onClick={() => opencollectionShowsite(row._id)}
//       >
//         <span className="svg-icon svg-icon-md svg-icon-danger">
//           <SVG src={toAbsoluteUrl("/media/svg/icons/General/Hidden.svg")} />
//         </span>
//       </a>
//     </OverlayTrigger> :  <OverlayTrigger
//       overlay={<Tooltip id="products-delete-tooltip">Hide in App</Tooltip>}
//     >
//       <a
//         className="btn btn-icon btn-light btn-hover-info btn-sm"
//         onClick={() => opencollectionHidesite(row._id)}
//       >
//         <span className="svg-icon svg-icon-md svg-icon-info">
//           <SVG src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")} />
//         </span>
//       </a>
//     </OverlayTrigger>}
//   </>
//   </>

//   </>
// );


/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { GoUnverified, GoVerified, IconName } from "react-icons/go";
export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { openVerifyCollectionDialog,  openEditCollectionPage,opencollectionHidesite ,opencollectionShowsite }
) => (
  <div className="d-flex justify-content-center align-items-center gap">
    {row.enabled === false ? (
      <>
        <OverlayTrigger
      overlay={<Tooltip id="products-delete-tooltip">Show in App</Tooltip>}
    >
      <a
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={() => opencollectionShowsite(row._id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Hidden.svg")} />
        </span>
      </a>
    </OverlayTrigger>
      </>
    ) : (
      <>
        <OverlayTrigger
      overlay={<Tooltip id="products-delete-tooltip">Hide in App</Tooltip>}
    >
      <a
        className="btn btn-icon btn-light btn-hover-info btn-sm"
        onClick={() => opencollectionHidesite(row._id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-info">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")} />
        </span>
      </a>
    </OverlayTrigger>
      </>
    )}
      <OverlayTrigger
   overlay={<Tooltip id="products-edit-tooltip">Edit product</Tooltip>}
   >
    <a
       className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
       href={"/nft/IDOlists/"+ row._id+"/edit" } 
        // onClick={() => openEditProductPage(row.id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
        />
        </span>
       </a>
     </OverlayTrigger>

     <OverlayTrigger
       overlay={<Tooltip id="products-delete-tooltip">Delete product</Tooltip>}
     >
      <a
         className="btn btn-icon btn-light btn-hover-danger btn-sm"
         onClick={() => openVerifyCollectionDialog(row._id)}
       >
        <span className="svg-icon svg-icon-md svg-icon-danger">
           <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
      </a>
     </OverlayTrigger>
  </div>
);
