import React, { useMemo } from "react";
import { useAffiliatesUIContext } from "../AffiliatesUIContext";

export function AffiliatesGrouping() {
  // Affiliates UI Context
  const affiliatesUIContext = useAffiliatesUIContext();
  const affiliatesUIProps = useMemo(() => {
    return {
      ids: affiliatesUIContext.ids,
      setIds: affiliatesUIContext.setIds,
      openDeleteAffiliatesDialog: affiliatesUIContext.openDeleteAffiliatesDialog,
      openFetchAffiliatesDialog: affiliatesUIContext.openFetchAffiliatesDialog,
      openUpdateAffiliatesStatusDialog:
        affiliatesUIContext.openUpdateAffiliatesStatusDialog,
    };
  }, [affiliatesUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger">
                <span>
                  Selected records count: <b>{affiliatesUIProps.ids.length}</b>
                </span>
              </label>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-danger font-weight-bolder font-size-sm"
                onClick={affiliatesUIProps.openDeleteAffiliatesDialog}
              >
                <i className="fa fa-trash"></i> Delete All
              </button>
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={affiliatesUIProps.openFetchAffiliatesDialog}
              >
                <i className="fa fa-stream"></i> Fetch Selected
              </button>
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={affiliatesUIProps.openUpdateAffiliatesStatusDialog}
              >
                <i className="fa fa-sync-alt"></i> Update Status
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
