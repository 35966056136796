import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./ReferralsUIHelpers";

const ReferralUIContext = createContext();

export function useReferralsUIContext() {
  return useContext(ReferralUIContext);
}

export const ReferralUIConsumer = ReferralUIContext.Consumer;

export function ReferralUIProvider({ referralUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [id, setId] = useState('');
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);
  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setId,
    id,
    setQueryParams,
    newReferralButtonClick: referralUIEvents.newReferralButtonClick,
    openEditReferralPage: referralUIEvents.openEditReferralPage,
    openDeleteReferralDialog: referralUIEvents.openDeleteReferralDialog,
    openReferralEndEditDialog: referralUIEvents.openReferralEndEditDialog,
    openDeleteReferralDialog: referralUIEvents.openDeleteReferralDialog,
    openFetchReferralDialog: referralUIEvents.openFetchReferralDialog,
    openReferralBetEndEditDialog: referralUIEvents.openReferralBetEndEditDialog,
    openUpdateReferralStatusDialog:
      referralUIEvents.openUpdateReferralStatusDialog,
    openVerifyReferralDialog: referralUIEvents.openVerifyReferralDialog,
    initReferral: referralUIEvents.initReferral,

  };

  return (
    <ReferralUIContext.Provider value={value}>
      {children}
    </ReferralUIContext.Provider>
  );
}
