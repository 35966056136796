import React, { useState, useEffect, useContext } from "react";
import "./index.css";
import {
  FaInfoCircle,
  FaGlobe,
  FaDiscord,
  FaTelegram,
  FaInstagram,
  FaMedium,
} from "react-icons/fa";
import Select from "react-select";
import { message, Modal, Progress } from "antd";
import AWS from "aws-sdk";
import { useHistory } from "react-router-dom";
import { TiTimes } from "react-icons/ti";
import { Form } from "react-bootstrap";
import { CreateCollection } from "../../../../../../services/ApiServices";
import { LoadingOutlined, SyncOutlined } from "@ant-design/icons";
const NFTCollection = () => {
  const [blockchain, setBlockChain] = useState("");
  const [category, setCategory] = useState("");
  const [username, setUserName] = useState("");
  const [usernameError, setUserNameError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [inputs, setInputs] = useState({});
  const [sociallinks, setSocialLinks] = useState([]);
  const [fetureimage, setFetureImage] = useState(null);
  const [bannerimage, setBannerImage] = useState(null);
  const [logoimage, setLogoImage] = useState(null);
  const [feturefile, setFetureFile] = useState([]);
  const [bannerfile, setBannerFile] = useState([]);
  const [logofile, setLogoFile] = useState([]);
  const [links_yourLinks, setYourLinks] = useState("");
  const [links_discord, setLinksDiscord] = useState("");
  const [links_instagram, setLinksInstagram] = useState("");
  const [links_medium, setLinksMedium] = useState("");
  const [links_telegram, setLinksTelegram] = useState("");
  const [linksValue, setLinksValue] = useState(false);
  const [categoryvalue, SetCategoryValue] = useState(null);
  const [sensitiveContent, setSensitiveContent] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [progressLogo, setProgressLogo] = useState(0);
  const [progressFeature, setProgressFeature] = useState(0);
  const [progressBanner, setProgressBanner] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [logoawsurl, setLogoAwsUrl] = useState("");
  const [fetureawsurl, setFetureAwsUrl] = useState("");
  const [bannerawsurl, setBannerAwsUrl] = useState("");
  const [myAdd, setMyadd] = useState();
  const [signInModalOpen, setSignInModalOpen] = useState(false);
  let history = useHistory();

  useEffect(() => { }, [
    inputs,
    username,
    sensitiveContent,
    categoryvalue,
    sociallinks,
    links_yourLinks,
    links_discord,
    links_instagram,
    links_medium,
    links_telegram,
  ]);

  const success = (msg) => {
    message.success(msg);
  };

  const error = (msg) => {
    message.error(msg);
  };

  const warning = (msg) => {
    message.warning(msg);
  };

  const submit = async () => {
    setIsLoading(true);
    let array = [];

    if (links_yourLinks != null) {
      array.push({ url: links_yourLinks, type: "yourLinks" });
    }
    if (links_discord != null) {
      array.push({
        url: "https://discord.gg/" + links_discord,
        type: "discord",
      });
    }
    if (links_instagram != null) {
      array.push({
        url: "https://www.instagram.com/" + links_instagram,
        type: "instagram",
      });
    }
    if (links_medium != null) {
      array.push({
        url: " https://www.medium.com/" + links_medium,
        type: "medium",
      });
    }
    if (links_telegram != null) {
      array.push({
        url: "https://www.t.me/" + links_telegram,
        type: "telegram",
      });
    }
    const post = {
      userWalletAddress: "0xE6A303e9AD8F10a7Cfbc0ed9ccD20316f5635cCD",
      logo: logoawsurl,
      featuredImage: fetureawsurl,
      bannerImage: bannerawsurl,
      name: inputs.name,
      customizedUrl: inputs.customizedUrl,
      description: inputs.description,
      category: inputs.category,
      chain: inputs.chain,
      creatorEarnings: inputs.creatorEarnings,
      sensitiveContent: inputs.sensitiveContent,
      socialLinks: array,
      title: inputs.title,
    };

    // console.log(array);

    // console.log(post);

    // setSocialLinks(values => ({ ...values, array }));

    // const socialLinksStr = "socialLinks";
    // setInputs(values => ({ ...values, [socialLinksStr]: sociallinks }));

    let result;
    try {
      result = await CreateCollection(post);
      setIsLoading(false);
      history.push("/nft/collections");
    } catch (e) {
      setIsLoading(false);
      console.log("create data pool exception", e);
    }
    try {
      if (result.success) {
        console.log("result", result);
      }
    } catch (e) {
      setIsLoading(false);
      console.log("create data pool exception", e);
    }
  };

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_Id,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REIGION,
  });
  const bucketName = process.env.REACT_APP_BUCKET_NAME;
  // const regionName = process.env.REACT_APP_AWS_REIGION;
  const myBucket = new AWS.S3({
    params: { Bucket: bucketName },
  });
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setLinksValue(false);

    if (name === "links_yourLinks") {
      setLinksValue(true);
      setYourLinks(value);
    } else if (name === "links_discord") {
      setLinksValue(true);
      setLinksDiscord(value);
    } else if (name === "links_instagram") {
      setLinksValue(true);
      setLinksInstagram(value);
    } else if (name === "links_medium") {
      setLinksValue(true);
      setLinksMedium(value);
    } else if (name === "links_telegram") {
      setLinksValue(true);
      setLinksTelegram(value);
    }

    if (linksValue !== true) {
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };

  //   function handleCopyNotification() {
  //     addToast("Address copied to clipboard!", {
  //       appearance: "success",
  //       autoDismiss: true,
  //     });
  //   }

  const uploadHandler = async (from) => {
    var image = [];
    let folder = "";
    if (from === "logoimage") {
      image = logofile;
      folder = "logo";
    } else if (from === "fetureimage") {
      image = feturefile;
      folder = "feture";
    } else {
      image = bannerfile;
      folder = "banner";
    }

    console.log("This is a array image");
    console.log(image);
    image.map(async (value) => {
      console.log("value", value);
      console.log("bucketName", bucketName);
      console.log("accessKeyId", process.env.REACT_APP_ACCESS_KEY_Id);
      console.log("SECRETKEY", process.env.REACT_APP_AWS_SECRET_ACCESS_KEY);
      console.log(value["name"]);

      console.log(
        "url:",
        `https://${bucketName}.s3.amazonaws.com/digitalemporium/collection/${folder}/${value["name"]}`
      );
      const params = {
        Body: value,
        Bucket: bucketName,
        Key: `digitalemporium/collection/${folder}/${value["name"]}`,
        ContentType: value["type"],
      };

      myBucket
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          // console.log(putObject);
          if (from === "logoimage")
            setProgressLogo(Math.round((evt.loaded / evt.total) * 100));
          else if (from === "fetureimage")
            setProgressFeature(Math.round((evt.loaded / evt.total) * 100));
          else setProgressBanner(Math.round((evt.loaded / evt.total) * 100));

          if (evt.loaded === evt.total) {
            success("Uploaded successfully");

            if (from === "logoimage") {
              setLogoAwsUrl(
                `https://${bucketName}.s3.amazonaws.com/digitalemporium/collection/${folder}/${value["name"]}`
              );
            } else if (from === "fetureimage") {
              setProgressFeature(Math.round((evt.loaded / evt.total) * 100));

              setFetureAwsUrl(
                `https://${bucketName}.s3.amazonaws.com/digitalemporium/collection/${folder}/${value["name"]}`
              );
            } else {
              setProgressBanner(Math.round((evt.loaded / evt.total) * 100));

              setBannerAwsUrl(
                `https://${bucketName}.s3.amazonaws.com/digitalemporium/collection/${folder}/${value["name"]}`
              );
            }

            console.log(evt);
          }
        })
        .send((err) => {
          if (err) {
            console.log(err);
            error("Upload failed");
          }
        });
    });
  };

  const logoimageChangeHandler = (event) => {
    const reader = new FileReader();
    reader.onload = function (onLoadEvent) {
      setLogoImage(onLoadEvent.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
    const name = "logoImage";
    const value = event.target.files[0].name;
    setInputs((values) => ({ ...values, [name]: value }));

    var logofiles = [...logofile];
    logofiles.push(event.target.files[0]);
    setLogoFile(logofiles);
    console.log(event.target.files);
  };

  const feturedimageChangeHandler = (event) => {
    const reader = new FileReader();
    reader.onload = function (onLoadEvent) {
      setFetureImage(onLoadEvent.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
    const name = "featuredImage";
    const value = event.target.files[0].name;
    setInputs((values) => ({ ...values, [name]: value }));

    var featuredfiles = [...feturefile];
    featuredfiles.push(event.target.files[0]);
    setFetureFile(featuredfiles);
    console.log(event.target.files);
  };

  const bannerimageChangeHandler = (event) => {
    const reader = new FileReader();
    reader.onload = function (onLoadEvent) {
      setBannerImage(onLoadEvent.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
    const name = "bannerImage";
    const value = event.target.files[0].name;
    setInputs((values) => ({ ...values, [name]: value }));

    var bannerfiles = [...bannerfile];
    bannerfiles.push(event.target.files[0]);
    setBannerFile(bannerfiles);
    console.log(event.target.files);
  };

  const handleCategory = (category) => {
    setCategory(category);
    const name = "category";
    const value = category.value;
    setInputs((values) => ({ ...values, [name]: value }));
    SetCategoryValue(category.value);
  };
  const handleclose = () => {
    SetCategoryValue(null);
  };

  const toggle = (event) => {
    const name = event.target.name;
    const value = sensitiveContent ? false : true;
    setSensitiveContent(value);
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const categoryValue = [
    { value: "Art", label: "Art" },
    { value: "Collectibles", label: "Collectibles" },
    { value: " Music", label: "Music" },
    { value: "Photography", label: "Photography" },
    { value: "Sports", label: "Sports" },
    { value: "Trading Cards", label: "Trading Cards" },
    { value: "Utility", label: "Utility" },
  ];

  const validation = () => {
    if (!logoimage) {
      setErrorMessage("Logo image is required!");
    } else if (logoimage && !logoawsurl) {
      setErrorMessage("Please upload your logo image!");
    } else if (!fetureimage) {
      setErrorMessage("Featured image is required!");
    } else if (fetureimage && !fetureawsurl) {
      setErrorMessage("Please upload your featured image!");
    } else if (!bannerimage) {
      setErrorMessage("Banner image is required!");
    } else if (bannerimage && !bannerawsurl) {
      setErrorMessage("Please upload your banner image!");
    } else if (!inputs.name) {
      setErrorMessage("Collection name is required!");
    } else if (!inputs.title) {
      setErrorMessage("Collection title is required!");
    } else if (!inputs.customizedUrl) {
      setErrorMessage("URL is required!");
    } else if (!inputs.description) {
      setErrorMessage("Description is required!");
    } else if (!inputs.category) {
      setErrorMessage("Category is required!");
    } else {
      showAddConfirm();
      // jsonUpload()
    }
  };

  const jsonUpload = () => {
    var obj = {
      name: inputs.name,
      description: inputs.description,
      image: logoawsurl,
      external_link: inputs.customizedUrl
    };
    var buf = Buffer.from(JSON.stringify(obj));
    var data = {
      Bucket: bucketName,
      Key: "digitalemporium/json/" + inputs.name + "/" + "contract.json",
      Body: buf,
      ContentEncoding: 'base64',
      ContentType: 'application/json',
    };
    myBucket.putObject(data)
      .on("httpUploadProgress", (evt) => {
        if (evt.loaded === evt.total) {
          console.log("success");
          submit();

        }
      })
      .send((err) => {
        if (err) {
          console.log(err);
          error("Upload failed");
        }
      });
  };
  function showAddConfirm() {
    Modal.confirm({
      title: "Are you ready to Submit?",
      onCancel: "No",
      onOk() {
        jsonUpload()
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  return (
    <>
      <div>
        <div className="lg:w-9/12 lg:px-0 w-full px-4 mx-auto space-y-2 mt-2">
          <div>
            <p className="text-xs-secondary-500 pb-3 fw-lighter">
              <span className="text-danger">*</span> Required fields
            </p>

            <div>
              <p className="fw-bold">
                Logo image <span className="text-danger">*</span>
              </p>
              <p className="text-xs text-secondary-500">
                This image will also be used for navigation. 350 x 350
                recommended.
              </p>
            </div>
            <div class="d-flex pb-4">
              <div class="d-flex ">
                <main className="d-flex flex-col align-items-center justify-content-center w-100  flex-1  text-center">
                  {logoimage && (
                    <img
                      src={logoimage}
                      alt="Uploaded Image"
                      className="logoimg border border-4 border-dashed rounded-circle hover:bg-secondary-100  hover:border-secondary-100"
                      width="100"
                      height="100"
                    />
                  )}
                  {!logoimage && (
                    <label
                      className="logoimg d-flex  flex-col align-items-center justify-content-center  border border-4 border-dashed rounded-circle border-secondary-300 hover:bg-secondary-100  hover:border-secondary-100 "
                      style={{ height: "100px", width: "100px" }}
                    >
                      <div className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="logosvg text-secondary-400 group-hover:text-secondary-600 group-hover:p-3"
                          viewBox="0 0 20 20"
                          fill="silver"
                          width="50"
                          height="50"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                            clip-rule="evenodd"
                          />
                        </svg>
                        <input
                          name="logoImage"
                          type="file"
                          accept="image/png, image/jpeg"
                          className="hidden"
                          onChange={logoimageChangeHandler}
                        />
                      </div>
                    </label>
                  )}
                  {logoimage && (
                    <div className="d-flex w-full   align-items-center justify-content-center m-4">
                      <button
                        class="imgbtn bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                        onClick={() => uploadHandler("logoimage")}
                      >
                        Upload
                      </button>
                      <button
                        onClick={() => setLogoImage(null)}
                        className="imgbtn ml-5 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                      >
                        Clear
                      </button>
                      <div style={{ width: 170 }} className="ml-4">
                        {progressLogo != 0 && progressLogo != 100 && (
                          <Progress
                            percent={progressLogo}
                            size="small"
                            status="active"
                          />
                        )}
                      </div>
                    </div>
                  )}
                </main>
              </div>
            </div>

            <div className="">
              <p className="fw-bold">Featured image</p>
              <p className="text-xs text-secondary-500 ">
                This image will be used for featuring your collection on the
                homepage, category pages, or other promotional areas of OpenSea.
                600 x 400 recommended.
              </p>
            </div>
            <div class="d-flex mb-4">
              <div class="d-flex ">
                <main className="d-flex flex-col align-items-center justify-content-center w-100  flex-1  text-center">
                  {fetureimage && (
                    <img
                      src={fetureimage}
                      alt="Uploaded Image"
                      style={{ width: "280px", height: "200px" }}
                      className="fetureimg sm:w-6/12 sm:h-20 border border-4 border-dashed rounded-lg hover:bg-secondary-100   hover:border-secondary-300"
                    />
                  )}
                  {!fetureimage && (
                    <label
                      className="fetureimg d-flex align-items-center  justify-content-center flex-col w-9/12 h-40 sm:w-6/12 sm:h-20 border border-4 border-dashed rounded-lg border-secondary-300 hover:bg-secondary-100  hover:border-secondary-400 "
                      style={{ width: "280px", height: "200px" }}
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class=" svg w-12 h-12 text-secondary-400 group-hover:text-secondary-600 group-hover:p-3"
                          viewBox="0 0 20 20"
                          fill="silver"
                          width="60"
                          height="50"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <input
                        name="featuredImage"
                        type="file"
                        accept="image/png, image/jpeg"
                        className="hidden"
                        onChange={feturedimageChangeHandler}
                      />
                    </label>
                  )}
                  {fetureimage && (
                    <div className="d-flex w-100  align-items-center justify-content-center m-4">
                      <button
                        class="imgbtn bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                        onClick={() => uploadHandler("fetureimage")}
                      >
                        Upload
                      </button>
                      <button
                        onClick={() => setFetureImage(null)}
                        className="imgbtn ml-5 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                      >
                        Clear
                      </button>
                      <div style={{ width: 170 }} className="ml-4">
                        {progressFeature != 0 && progressFeature != 100 && (
                          <Progress
                            percent={progressFeature}
                            size="small"
                            status="active"
                          />
                        )}
                      </div>
                    </div>
                  )}
                </main>
              </div>
            </div>

            <div className="">
              <p className="fw-bold">Banner Image</p>
              <p className="text-xs text-secondary-500 ">
                This image will appear at the top of your collection page. Avoid
                including too much text in this banner image, as the dimensions
                change on different devices. 1400 x 400 recommended.
              </p>
            </div>
            <div class="flex  mt-2">
              <div class="d-flex ">
                <main className="d-flex flex-col w-100 flex-1  text-center">
                  {bannerimage && (
                    <img
                      src={bannerimage}
                      alt="Uploaded Image"
                      style={{ width: "580px", height: "200px" }}
                      className="bannerimg w-9/12 h-40 sm:w-6/12 sm:h-20 banner border border-4 border-dashed rounded-lg hover:bg-secondary-100   hover:border-secondary-300"
                    />
                  )}
                  {!bannerimage && (
                    <label
                      class="bannerimg d-flex flex-col align-items-center justify-content-center border border-4 border-dashed rounded-lg border-secondary-300 hover:bg-secondary-100  hover:border-secondary-400"
                      style={{ width: "580px", height: "200px" }}
                    >
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="svg w-12 h-12 text-secondary-400 group-hover:text-secondary-600 group-hover:p-3"
                          viewBox="0 0 20 20"
                          fill="silver"
                          width="150"
                          height="50"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <input
                        name="bannerImage"
                        type="file"
                        accept="image/png, image/jpeg"
                        className="hidden"
                        onChange={bannerimageChangeHandler}
                      />
                    </label>
                  )}
                  {bannerimage && (
                    <div className="d-flex w-full  align-items-center justify-content-center m-4">
                      <button
                        class="imgbtn bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                        onClick={() => uploadHandler("bannerimage")}
                      >
                        Upload
                      </button>
                      <button
                        onClick={() => setBannerImage(null)}
                        className="imgbtn ml-5 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                      >
                        Clear
                      </button>
                      <div style={{ width: 170 }} className="ml-4">
                        {progressBanner != 0 && progressBanner != 100 && (
                          <Progress
                            percent={progressBanner}
                            size="small"
                            status="active"
                          />
                        )}
                      </div>
                    </div>
                  )}
                </main>
              </div>
            </div>

            <div>
              <label class="text-base fw-bold d-flex mx-2">
                Name
                <span class="text-danger leading-10 text-base pl-1 ">*</span>
              </label>
              <input
                class="shadow text-input appearance-none border border-secondary h-12 rounded  w-100 py-2 px-3 text-secondary-700 leading-tight focus:outline-none focus:shadow-outline focus:outline-none"
                name="name"
                id="username"
                type="text"
                value={inputs.name || ""}
                onChange={handleChange}
                placeholder="Example: Treasures of the sea"
                required
              />
              <div class="text-red-500 text-xs tracking-wide mt-1">
                {usernameError}
              </div>
            </div>
            <div>
              <label class="text-base fw-bold d-flex mx-2">
                Title
                <span class="text-danger leading-10 text-base pl-1 ">*</span>
              </label>
              <input
                class="shadow text-input appearance-none border border-secondary h-12 rounded  w-100 py-2 px-3 text-secondary-700 leading-tight focus:outline-none focus:shadow-outline focus:outline-none"
                name="title"
                // id="username"
                type="text"
                value={inputs.title || ""}
                onChange={handleChange}
                placeholder="Example: Treasures of the sea"
                required
              />
              <div class="text-red-500 text-xs tracking-wide mt-1">
                {usernameError}
              </div>
            </div>
            <div className="my-4">
              <label className="mt-4 w-100">
                <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium font-bold text-slate-700 fw-bold">
                  URL
                </span>
                <p className="text-xs text-secondary-500 ">
                  Customize your URL on OpenSea. Must only contain lowercase
                  letters,numbers, and hyphens.
                </p>
                <input
                  type="text"
                  name="customizedUrl"
                  value={inputs.customizedUrl || ""}
                  onChange={handleChange}
                  className="mt-1 text-input px-3 py-2 bg-white border shadow-sm border-secondary placeholder-slate-400 focus:outline-none focus:border-secondary-300 focus:shadow-2xl focus:shadow-secondary-200/50 focus:ring-secondary-300 block w-100 rounded sm:text-sm focus:ring-1"
                  placeholder="Https://opensea.io/collection/treasures-of-the-sea"
                  required
                />
                {/* <span className="text-red-500 text-sm">{errorMessage}</span> */}
              </label>
            </div>

            <div className="space-y-3 mt-4">
              <p className="fw-bold mt-2">Description</p>
              <p className="text-xs text-secondary-500 ">
                <span className="text-blue-500">
                  <a href="">Markdown</a>
                </span>{" "}
                syntax is supported. 0 of 1000 characters used.
              </p>
              <textarea
                name="description"
                value={inputs.description || ""}
                onChange={handleChange}
                class="w-100 px-3 text-input text-secondary-700 border border-secondary-300 rounded-lg focus:outline-none focus:shadow-2xl focus:shadow-secondary-200/50 focus:border-secondary-300 focus:ring-secondary-300"
                rows="4"
              ></textarea>
            </div>

            <div>
              <div className="space-y-3">
                <p className="fw-bold">Category</p>
                <p className="text-xs text-secondary-500 ">
                  {" "}
                  Adding a category will help make your item discoverable on
                  OpenSea.
                </p>
                <div class="d-lg-flex ">
                  {/* <button className=" z-10 bg-white rounded-lg divide-y divide-secondary-100 hover:shadow-lg shadow dark:bg-secondary-700 " style={{width:"250px"}}> */}
                  <div>
                    {" "}
                    <Select
                      className="category-select d-inline-block bg-white mb-4"
                      placeholder="Add Category"
                      options={categoryValue}
                      name="category"
                      onChange={handleCategory}
                      value={category}
                      isDisabled={categoryvalue != null}
                    />
                  </div>
                  {/* </button> */}

                  {categoryvalue != null ? (
                    <span
                      class="d-flex align-items-center ml-lg-10 px-4 py-2 rounded-lg text-secondary-500 bg-secondary fw-semibold text-sm flex align-center cursor-pointer active:bg-secondary-300 transition duration-300 ease"
                      style={{ width: "fit-content" }}
                    >
                      {categoryvalue}
                      <button
                        class="bg-transparent border-transparent hover text-input focus:outline-none"
                        isDisabled
                        onClick={handleclose}
                      >
                        <TiTimes
                          style={{ fontSize: "22px" }}
                          onClick={handleclose}
                        />
                        {/* <svg
                          onClick={handleclose}
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="times"
                          class="w-3 ml-3"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 352 512"
                        >
                          <path
                            fill="currentColor"
                            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                          ></path>
                        </svg> */}
                      </button>
                    </span>
                  ) : (
                    <span></span>
                  )}
                  {/* if(categoryvalue!=null){
                <span
              class="ml-10 px-4 py-2 rounded-lg text-secondary-500 bg-secondary-200 font-semibold text-sm flex align-center w-max cursor-pointer active:bg-secondary-300 transition duration-300 ease">
             {categoryvalue}
              <button class="bg-transparent hover focus:outline-none">
                <svg onClick={handleclose} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times"
                  class="w-3 ml-3" role="img" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 352 512">
                  <path fill="currentColor"
                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
                  </path>
                </svg>
              </button>
             </span>
            } */}

                  {/* </div> */}
                </div>
              </div>
            </div>

            <div className="pt-5">
              <p className="fw-bold ">Links</p>
              <div class="bg-white">
                <div class="divide-y-2 divide-secondary-200 divide-solid">
                  <div className="d-flex flex-row  hover:shadow-lg pt-3 ">
                    <div className="border border-right-0 py-2 pl-2">
                      <FaGlobe className="font-size-h4 text-secondary-400" />
                    </div>
                    <div className="w-100 border border-left-0 d-flex align-items-center">
                      <input
                        name="links_yourLinks"
                        value={links_yourLinks || ""}
                        onChange={handleChange}
                        className="text-input text-secondary-400 pl-3  w-100  hover:border-none border-transparent focus:border-primary focus:ring-0"
                        type="text"
                        placeholder="Yoursite.io"
                      />
                    </div>
                  </div>

                  <div className="d-flex flex-row hover:shadow-lg pt-3">
                    <div className="border border-right-0 py-2 pl-2">
                      <FaDiscord className="font-size-h4 text-secondary-400" />
                    </div>
                    <div className="w-100 border border-left-0 d-flex align-items-center">
                      &nbsp;&nbsp;https://discord.gg/
                      <input
                        style={{ width: "70%" }}
                        name="links_discord"
                        value={links_discord || ""}
                        onChange={handleChange}
                        className="text-input text-secondary-400 pl-0 hover:border-none border-transparent focus:border-transparent focus:ring-0"
                        type="text"
                        placeholder="abcdef"
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row hover:shadow-lg pt-3">
                    <div className="border border-right-0 py-2 pl-2">
                      <FaInstagram className="font-size-h4 text-secondary-400" />
                    </div>
                    <div className="w-100 border border-left-0 d-flex align-items-center">
                      {" "}
                      &nbsp;&nbsp;https://www.instagram.com/
                      <input
                        style={{ width: "70%" }}
                        name="links_instagram"
                        value={links_instagram || ""}
                        onChange={handleChange}
                        className="text-input text-secondary-400 pl-0 hover:border-none border-transparent focus:border-transparent focus:ring-0"
                        type="text"
                        placeholder="YourInstagramHandle"
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row hover:shadow-lg pt-3">
                    <div className="border border-right-0 py-2 pl-2">
                      <FaMedium className="font-size-h4 text-secondary-400" />
                    </div>
                    <div className="w-100 border border-left-0 d-flex align-items-center">
                      &nbsp;&nbsp;https://www.medium.com/
                      <input
                        style={{ width: "70%" }}
                        name="links_medium"
                        value={links_medium || ""}
                        onChange={handleChange}
                        className="text-input text-secondary-400 pl-0  hover:border-none border-transparent focus:border-transparent focus:ring-0"
                        type="text"
                        placeholder="@YourMediumHandle"
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row hover:shadow-lg pt-3">
                    <div className="border border-right-0 py-2 pl-2">
                      <FaTelegram className="font-size-h4 text-secondary-400" />
                    </div>
                    <div className="w-100 border border-left-0 d-flex align-items-center">
                      &nbsp;&nbsp;https://www.t.me/
                      <input
                        style={{ width: "70%" }}
                        name="links_telegram"
                        value={links_telegram || ""}
                        onChange={handleChange}
                        className="text-input text-secondary-400 pl-0  hover:border-none border-transparent focus:border-transparent focus:ring-0"
                        type="text"
                        placeholder="abcdef"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div>
            <h1 className='font-bold mt-5'>Creator Earnings</h1>
            <p className='text-xs text-secondary-500 mt-4  '>Collect a fee when a user re-sells on item you originally created.
              This is deducted from the final sale price and paid monthly to a payout address of your chossing.</p>
            <a  href="" className='text-xs text-indigo-500 '>Learn more about creator earnings.</a>
            <h4 className='font-bold text-xs mt-5 mb-3'>Percentage fee</h4>
            <input type="text" name="creatorEarnings" value={inputs.creatorEarnings || ""}
              onChange={handleChange} className="mt-1 px-3 py-2 bg-white border shadow-sm border-secondary-300 placeholder-slate-400 focus:outline-none focus:border-secondary-300 focus:shadow-2xl focus:shadow-secondary-200/50 focus:ring-secondary-300 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="e.g. 2.5" required />
          </div> */}

            <div class="leading-8 mt-4">
              {/* <label class=" text-base font-semibold  pt-3">Blockchain</label> */}
              {/* <p className='text-xs text-secondary-500 flex flex-row'>Select the blockchain where you'd like new items from this collection to be added by default.
                   <span className='float-left'>
                     <FaInfoCircle 
                      data-tip
                      data-for="registerTip"
                      class="h-4 text-black-500 ml-1  "
                      />
                    <ReactTooltip id="registerTip" place="top" effect="solid">
                      Moving items to a different collection may{" "}
                      <div>take up to 30 minutes. You can manage</div>{" "}
                      <div class="text-blue-400">your collections here.</div>
                    </ReactTooltip>
                    </span>
                </p> */}

              {/* <Button color="lightBlue" ref={buttonRef} ripple="dark">
                Tooltip bottom
            </Button> */}

              {/* <Tooltips placement="top" ref={buttonRef}>
                <TooltipContent>Tooltip bottom</TooltipContent>
            </Tooltips> */}

              {/* <Select
              placeholder="select blockchain"
              options={blockchainval}
              name='chain'
              onChange={handleBlockChain}
              value={blockchain}
            />
          */}

              {/* <div>
            <h1 className='font-bold'>Payment tokens</h1>
            <p className='text-xs text-secondary-500  flex ' >These tokens can be used to buy and sell your items.

              <FontAwesomeIcon icon={faInfoCircle} className="h-5 text-secondary-400 " /></p>

            <div className='flex flex-row space-x-10 pt-5'>
              <div>
                <div className=" flex flex-row space-x-5  hover:bg-sky-700 p-1 px-6 rounded-lg border hover:shadow-xl ...">
                  <div>icon</div>
                  <div className='font-bold text-sm'>ETH <br /> <span className='text-xs font-normal'>Etherem</span></div>
                </div>
              </div>

              <div>
                <div className=" flex flex-row space-x-5  hover:bg-sky-700 p-1 px-6 rounded-lg border hover:shadow-xl ...">
                  <div>icon</div>
                  <div className='font-bold text-sm'>WETH <br /> <span className='text-xs font-normal'>Etherem</span></div>
                </div>
              </div>
            </div>

            <div class="leading-8 mt-4">
              <label class=" text-base font-semibold  pt-3">Add Tokens</label>

              <Select
                name='tokens'
                placeholder="select blockchain"
                options={options}
                value={selectValue}
                onChange={handleChangeselect}
              />
            </div>

 */}
              {/* <div style={{ marginLeft: buttonWidth, whiteSpace: 'nowrap' }}>
 <Tooltip placement="top" title={text}>
        <Button>Top</Button>
      </Tooltip>
      </div> */}

              <div className="pt-5 flex flex-col lg:mb-0 mb-24">
                <div className="w-100">
                  <p className="fw-bold">Explicit & sensitive content</p>
                  <div className="d-flex w-100">
                    <p className="text-xs text-secondary-500 flex flex-row">
                      <span className="float-left">
                        <FaInfoCircle
                          data-tip
                          data-for="registerTip"
                          class="font-size-h4 text-secondary mr-2"
                        />
                        {/* <ReactTooltip
                          id="registerTip"
                          place="top"
                          effect="solid"
                        >
                          Moving items to a different collection may{" "}
                          <div>take up to 30 minutes. You can manage</div>{" "}
                          <div class="text-blue-400">
                            your collections here.
                          </div>
                        </ReactTooltip> */}
                      </span>
                      Set this collection as explicit and sensitive content
                    </p>

                    <div class="mx-auto">
                      <label for="toggleB" class="flex  cursor-pointer">
                        {/* <!-- toggle --> */}
                        <div class="relative">
                          {/* <!-- input --> */}
                          <Form>
                            <Form.Check
                              type="switch"
                              label=""
                              name="sensitiveContent"
                              onChange={toggle}
                              value={inputs.sensitiveContent || ""}
                              id="toggleB"
                            />
                          </Form>
                          {/* <input
                          name="sensitiveContent"
                          onChange={toggle}
                          type="checkbox"
                          value={inputs.sensitiveContent || ""}
                          id="toggleB"
                          class=" form-check-input"
                          role="switch"
                        /> */}
                          {/* <!-- line --> */}
                          <div
                            class={`${sensitiveContent
                                ? "bg-blue-600 "
                                : "bg-secondary-600"
                              } block w-12 h-6 rounded-full`}
                          ></div>
                          {/* <!-- dot --> */}
                          <div class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
                        </div>
                        {/* <!-- label --> */}
                        <div class="ml-3 text-blue-700 font-medium">
                          {/* Toggle Me! */}
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="text-danger">{errorMessage}</div>
                <div class="lg:mb-0 mb-8">
                  {!isLoading ? (
                    <button
                      onClick={validation}
                      type="button"
                      className="btn btn-primary mt-4"
                    >
                      Create
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary mt-4 d-flex align-items-center justify-content-center"
                    >
                      <SyncOutlined spin style={{ marginRight: "5px" }} />
                      Create
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NFTCollection;
