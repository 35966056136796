/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fightdetail
} from "../../../../services/ApiServices";
import moment from "moment";


export function BaseTablesWidget6({ className }) {
  const tabs = {
    tab1: "kt_tab_pane_3_1",
    tab2: "kt_tab_pane_3_2",
    tab3: "kt_tab_pane_3_3",
  };
  const [activeTab, setActiveTab] = useState(tabs.tab1);
  const [loading, setLoading] = useState(false);
  const [detail, setdetail] = useState({});

  const { currentState } = useSelector(
    (state) => ({ currentState: state.collections }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;




  const getfightdetail = async () => {
    setLoading(true);
    let result;
    const body = {
      sort: {
        fightNumber
          :
          "asc"
      },
    };

    try {
      result = await fightdetail(body);
      if (result.success) {
        setdetail(result.fights.docs);
        setLoading(false)


      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getfightdetail();

  }, []);
  const sortedDetail = Array.isArray(detail) ? detail.slice().sort((a, b) => new Date(b.matchDate) - new Date(a.matchDate)) : [];

  return (
    <>
      {/* begin::Base Table Widget 6 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Recent Fights
            </span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">
              {/* More than 400+ new members */}
            </span>
          </h3>
          {/* <div className="card-toolbar">
            <ul className="nav nav-pills nav-pills-sm nav-dark-75">
              <li className="nav-item">
                <a
                  className={`nav-link py-2 px-4 ${activeTab === tabs.tab1 &&
                    "active"}`}
                  data-toggle="tab"
                  href={`#${tabs.tab1}`}
                  onClick={() => setActiveTab(tabs.tab1)}
                >
                  Month
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link py-2 px-4 ${activeTab === tabs.tab2 &&
                    "active"}`}
                  data-toggle="tab"
                  href={`#${tabs.tab2}`}
                  onClick={() => setActiveTab(tabs.tab2)}
                >
                  Week
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link py-2 px-4 ${activeTab === tabs.tab3 &&
                    "active"}`}
                  data-toggle="tab"
                  href={`#${tabs.tab3}`}
                  onClick={() => setActiveTab(tabs.tab3)}
                >
                  Day
                </a>
              </li>
            </ul>
          </div> */}
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-2 pb-0">
          {/* begin::Table */}
          <div className="table-responsive">
            <table className="table table-borderless table-vertical-center">
              <thead>
                <tr>
                  <th className="p-0" style={{ width: "50px" }}>F.No</th>
                  <th className="p-0" style={{ minWidth: "50px" }}>Fight Date</th>
                  <th className="p-0" style={{ minWidth: "50px" }}>Winner</th>
                  <th className="p-0" style={{ minWidth: "50px" }}>White</th>
                  <th className="p-0" style={{ minWidth: "50px" }}>Staked</th>
                  <th className="p-0" style={{ minWidth: "50px" }}>Red</th>
                  <th className="p-0" style={{ minWidth: "50px" }}>Staked</th>
                  <th className="p-0" style={{ minWidth: "50px" }}>Status</th>
                </tr>
              </thead>
              <tbody>
                {sortedDetail?.length && sortedDetail.slice(0, 5).map((collection, index) => (
                  <tr key={index}>
                    <td className="pl-0">
                      {collection?.fightNumber}
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bold d-block">
                        <br />{moment(collection?.matchDate).format("DD-MM-YYYY")}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bold d-block">
                        {collection?.winner}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bold d-block">
                        {collection?.whiteName}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bold d-block">
                        {collection?.whiteStaked}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bold d-block">
                        {collection?.redName}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bold d-block">
                        {collection?.redStaked}
                      </span>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bold d-block">
                        {collection?.status}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* end::Table */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Base Table Widget 6 */}

    </>
  );
}
