import React, { useEffect, useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useFightsUIContext } from "../FightsUIContext";
import * as actions from "../../../_redux/category/categoriesActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";

const prepareFilter = (queryParams, values) => {
  const { winner, status, searchText, matchDate } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  const filterField = {};
  // Filter by status
  filterField.status = status !== "" ? status : undefined;
  filterField.winner = winner !== "" ? winner : undefined;
  filterField.matchDate =
    matchDate !== ""
      ? moment()
          .utc()
          .format("DD-MMM-YYYY")
      : undefined;

  // Filter by category
  // filter.category = category !== "" ? category : "";
  // Filter by all fields
  filter.winner = searchText;
  if (searchText) {
    filter.redName = searchText;
    filter.whiteName = searchText;
  }
  newQueryParams.filter = filter;
  newQueryParams.filterField = filterField;
  return newQueryParams;
};

export function FightsFilter({}) {
  // Fights UI Context
  const fightsUIContext = useFightsUIContext();
  const fightsUIProps = useMemo(() => {
    return {
      setQueryParams: fightsUIContext.setQueryParams,
      queryParams: fightsUIContext.queryParams,
    };
  }, [fightsUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.fights }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(actions.fetchCategories());
  // }, [ dispatch]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(fightsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, fightsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      fightsUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          winner: "",
          status: "", // values => All=""/Selling=0/Sold=1
          searchText: "",
          matchDate: "today",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-2">
                <select
                  className="form-control"
                  name="winner"
                  placeholder="Filter by Winner"
                  onChange={(e) => {
                    setFieldValue("winner", e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.winner}
                >
                  <option value="">All</option>
                  <option value="red">Red</option>
                  <option value="white">White</option>
                  <option value="cancel">Cancel</option>
                </select>
                <small className="form-text text-muted ml-1">
                  <b>Filter</b> by Winner
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Status"
                  name="status"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("status", e.target.value);
                    handleSubmit();
                  }}
                  value={values.status}
                >
                  <option value="">All</option>
                  <option value="live">Live</option>
                  <option value="finished">Ended</option>
                  <option value="soon">Upcoming</option>
                </select>
                <small className="form-text text-muted ml-1">
                  <b>Filter</b> by Status
                </small>
              </div>
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted ml-1">
                  <b>Search</b> in all fields
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Date"
                  name="matchDate"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("matchDate", e.target.value);
                    handleSubmit();
                  }}
                  value={values.matchDate}
                >
                  <option value="">All</option>
                  <option value="today">Today</option>
                </select>
                <small className="form-text text-muted ml-1">
                  <b>Filter</b> by Date
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
