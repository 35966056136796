import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { collectionsSlice } from "../app/modules/ECommerce/_redux/collections/collectionsSlice";
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { eventsSlice } from "../app/modules/ECommerce/_redux/events/eventsSlice";
import { fightsSlice } from "../app/modules/ECommerce/_redux/fights/fightsSlice";
import { depositsSlice } from "../app/modules/ECommerce/_redux/deposits/depositsSlice";
import { reportsSlice } from "../app/modules/ECommerce/_redux/reports/reportsSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import { arenasSlice } from "../app/modules/ECommerce/_redux/arenas/arenasSlice";
import { withdrawsSlice } from "../app/modules/ECommerce/_redux/withdraws/withdrawsSlice";
import { affiliatesSlice } from "../app/modules/ECommerce/_redux/affiliates/affiliatesSlice";
import { cockPicksSlice } from "../app/modules/ECommerce/_redux/cockPicks/cockPicksSlice";
import { operatorIncomesSlice } from "../app/modules/ECommerce/_redux/operator/operatorIncomesSlice";
import { subOperatorIncomesSlice } from "../app/modules/ECommerce/_redux/subOperatorIncome/subOperatorIncomesSlice";
import { solanasSlice } from "../app/modules/ECommerce/_redux/solanas/solanasSlice";
import { referralsSlice } from "../app/modules/ECommerce/_redux/referrals/referralsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  collections: collectionsSlice.reducer,
  products: productsSlice.reducer,
  events: eventsSlice.reducer,
  fights: fightsSlice.reducer,
  deposits: depositsSlice.reducer,
  reports: reportsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  arenas: arenasSlice.reducer,
  withdraws: withdrawsSlice.reducer,
  affiliates: affiliatesSlice.reducer,
  cockPicks: cockPicksSlice.reducer,
  operatorIncomes: operatorIncomesSlice.reducer,
  solanas: solanasSlice.reducer,
  referrals: referralsSlice.reducer,
  subOperatorIncomes: subOperatorIncomesSlice.reducer,

});

export function* rootSaga() {
  yield all([auth.saga()]);
}
