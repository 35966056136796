import moment from "moment";
import React from "react";

export const DateColumnFormatter = (cellContent, row) => (
  <span>
    {/* <span style={{fontWeight:"600",color:"grey"}}>{moment(cellContent).utc().format("hh:mm A")} UTC</span> */}
    {/* <br /> */}
    {moment(cellContent).utc().format("MMM,DD YYYY")}
  </span>
);
