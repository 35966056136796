import { CreateAdmin, GetSubOperators, GetUsers } from "../../../../../services/ApiServices";
import * as requestFromServer from "./subOperatorIncomesCrud";
import { subOperatorIncomesSlice, callTypes } from "./subOperatorIncomesSlice";

const { actions } = subOperatorIncomesSlice;

export const fetchOperatorIncomes = (queryParams, token) => async dispatch => {
  let query = "?";
  let body = {};

  for (const key in queryParams) {
    switch (key) {
      case "pageNumber":
        query = query.concat("page=" + queryParams.pageNumber);
        break;
      case "pageSize":
        query = query.concat("&limit=" + queryParams.pageSize);
        break;
      case "filter":
        if (queryParams.filter) body.searchFields = queryParams.filter;
        break;
      case "filterField":
        if (queryParams.filterField) body.filterField = queryParams.filterField;
        break;
      case "filterFields":
        if (queryParams.filterFields) body.filterFields = queryParams.filterFields;
        break;
      case "sortField":
        if (queryParams.sortField) body.sort = queryParams.sortOrder;
        break;
      default:
        break;
    }
  }

  dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer
  let result;
  try {

    result = await GetSubOperators(query, body, token);
  } catch (error) {
    error.clientMessage = "Can't find operatorIncomes";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      let totalCount = result.subOperators.totalDocs;
      let entities = result.subOperators.docs;
      await dispatch(actions.reSet());
      dispatch(actions.operatorIncomesFetched({ totalCount, entities }));
    }
  }
  catch (error) {
    error.clientMessage = "Can't find operatorIncomes";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  };
};

export const fetchOperatorIncome = id => dispatch => {
  if (!id) {
    return dispatch(actions.operatorIncomeFetched({ operatorIncomeForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getOperatorIncomeById(id)
    .then(response => {
      const operatorIncome = response.data;
      dispatch(actions.operatorIncomeFetched({ operatorIncomeForEdit: operatorIncome }));
    })
    .catch(error => {
      error.clientMessage = "Can't find operatorIncome";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteOperatorIncome = id => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let result;
  try {

    // result = await DeleteOperatorIncome(id);
  } catch (error) {
    error.clientMessage = "Can't Delete operatorIncomes";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      dispatch(actions.operatorIncomeDeleted({ id }));

    }
  }
  catch (error) {
    error.clientMessage = "Can't delete operatorIncome";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  };


};

export const createOperatorIncome = operatorIncomeForCreation => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  let result;
  try {
    result = await CreateAdmin(operatorIncomeForCreation);
  } catch (error) {
    error.clientMessage = "Can't create operatorIncome";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      let user = result.user
      // dispatch(actions.operatorIncomeCreated({ user }));
    }
  }
  catch (error) {
    error.clientMessage = "Can't create admin";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  };

};

// export const createOperatorIncome = operatorIncomeForCreation => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .createOperatorIncome(operatorIncomeForCreation)
//     .then(response => {
//       const { operatorIncome } = response.data;
//       dispatch(actions.operatorIncomeCreated({ operatorIncome }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't create operatorIncome";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

export const updateOperatorIncome = operatorIncome => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateOperatorIncome(operatorIncome)
    .then(() => {
      dispatch(actions.operatorIncomeUpdated({ operatorIncome }));
    })
    .catch(error => {
      error.clientMessage = "Can't update operatorIncome";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateOperatorIncomesStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForOperatorIncomes(ids, status)
    .then(() => {
      dispatch(actions.operatorIncomesStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update operatorIncomes status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteOperatorIncomes = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteOperatorIncomes(ids)
    .then(() => {
      dispatch(actions.operatorIncomesDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete operatorIncomes";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
