import React from "react";
import { Route } from "react-router-dom";
import { UsersLoadingDialog } from "./users-loading-dialog/UsersLoadingDialog";
import { UserDeleteDialog } from "./user-delete-dialog/userDeleteDialog";
import { UsersDeleteDialog } from "./users-delete-dialog/UsersDeleteDialog";
import { UsersFetchDialog } from "./users-fetch-dialog/UsersFetchDialog";
import { UsersUpdateStatusDialog } from "./user-update-status-dialog/UsersUpdateStatusDialog";
import { UsersVerifyDialog } from "./user-verify-dialog/userVerifyDialog";
import { UsersCard } from "./UsersCard";
import { EventsUIProvider } from "./UsersUIContext";
import { UserAddDialog } from "./user-new/UserAddModal";

export function UsersPage({ history }) {
  const eventsUIEvents = {
    newEventButtonClick: () => {
      history.push("/cock-boxing/players/new");
    },
    openEditEventPage: (id) => {
      history.push(`/cock-boxing/players/${id}/edit`);
    },
    openVerifyEventDialog: (id) => {
      history.push(`/cock-boxing/players/${id}/verify`);
    },

    openDeleteEventDialog: (id) => {
      history.push(`/cock-boxing/players/${id}/delete`);
    },
    openDeleteEventsDialog: () => {
      history.push(`/cock-boxing/players/deleteEvents`);
    },
    openFetchEventsDialog: () => {
      history.push(`/cock-boxing/players/fetch`);
    },
    openUpdateEventsStatusDialog: () => {
      history.push("/cock-boxing/players/updateStatus");
    },
  };

  return (
    <EventsUIProvider eventsUIEvents={eventsUIEvents}>
      <UsersLoadingDialog />
      <Route path="/cock-boxing/players/deleteEvents">
        {({ history, match }) => (
          <UsersDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/players");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/players/:id/verify">
        {({ history, match }) => (
          <UsersVerifyDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/players");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/players/:id/delete">
        {({ history, match }) => (
          <UserDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/players");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/players/fetch">
        {({ history, match }) => (
          <UsersFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/players");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/players/updateStatus">
        {({ history, match }) => (
          <UsersUpdateStatusDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/players");
            }}
          />
        )}
      </Route>
      {/* <Route path="/cock-boxing/players/:id/edit">
        {({ history, match }) => (
          <UserDetails
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/players");
            }}
          />
        )}
      </Route> */}

      <Route path="/cock-boxing/players/new">
        {({ history, match }) => (
          <UserAddDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/players");
            }}
          />
        )}
      </Route>
      <UsersCard />
    </EventsUIProvider>
  );
}
