/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../../_redux/events/eventsActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { EventEditForm } from "./EventEditForm";
import { EventDetail } from "./EventDetail";
import { Specifications } from "../event-specifications/Specifications";
import { SpecificationsUIProvider } from "../event-specifications/SpecificationsUIContext";
import { useSubheader } from "../../../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import { RemarksUIProvider } from "../event-remarks/RemarksUIContext";
import { Remarks } from "../event-remarks/Remarks";
import { useParams } from "react-router-dom";

const initEvent = {
  id: undefined,
  model: "",
  manufacture: "Pontiac",
  modelYear: 2020,
  mileage: 0,
  description: "",
  color: "Red",
  price: 10000,
  condition: 1,
  status: 0,
  VINCode: "",
  attachments: [
    {
      "url": "https://c.tenor.com/waSNZA4y2qsAAAAM/cmyk-ae.gif",
      "fileType": "IMAGE",
    }
  ]
};

export function EventDetails({
  history }) {
  // Subheader
  const suhbeader = useSubheader();

  // Tabs
  const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  const { id } = useParams()
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, event } = useSelector(
    (state) => ({
      actionsLoading: state.events.actionsLoading,
      event: state.events.event
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(actions.fetchEvent(id, authToken));
    // console.log(actions.fetchEvent(id),"errs")

  }, [id, dispatch]);

  useEffect(() => {
    let _title = id ? "Event Details" : "Event Details";
    if (event && id) {
      _title = `Event [${event?.collectionId?.title} - ${event.displayName}]`;
      // _title = `Event [${event?.title} ]`;

    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, id]);

  const saveEvent = (values) => {
    if (!id) {
      dispatch(actions.createEvent(values)).then(() => backToEventsList());
    } else {
      dispatch(actions.updateEvent(values)).then(() => backToEventsList());
    }
  };

  const btnRef = useRef();
  const saveEventClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToEventsList = () => {
    history.push(`/nft/nftcollection/events/${event.collectionId._id}`);
  };
  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToEventsList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Back
          </button>
          {/* {`  `}
          <button className="btn btn-light ml-2">
            <i className="fa fa-redo"></i>
            Reset
          </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveEventClick}
          >
            Save
          </button> */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ul className="nav nav-tabs nav-tabs-line " role="tablist">
          <li className="nav-item" onClick={() => setTab("basic")}>
            <a
              className={`nav-link ${tab === "basic" && "active"}`}
              data-toggle="tab"
              role="tab"
              aria-selected={(tab === "basic").toString()}
            >
              Basic info
            </a>
          </li>
          {id && (
            <>
              {" "}
              <li className="nav-item" onClick={() => setTab("remarks")}>
                <a
                  className={`nav-link ${tab === "remarks" && "active"}`}
                  data-toggle="tab"
                  role="button"
                  aria-selected={(tab === "remarks").toString()}
                >
                  Comments
                </a>
              </li>
              <li className="nav-item" onClick={() => setTab("specs")}>
                <a
                  className={`nav-link ${tab === "specs" && "active"}`}
                  data-toggle="tab"
                  role="tab"
                  aria-selected={(tab === "specs").toString()}
                >
                  Event specifications
                </a>
              </li>
            </>
          )}
        </ul>
        <div className="mt-5">
          {tab === "basic" && (
            <EventDetail
              actionsLoading={actionsLoading}
              event={event || initEvent}
              btnRef={btnRef}
              saveEvent={saveEvent}
            />
          )}
          {tab === "remarks" && id && (
            <RemarksUIProvider currentEventId={id}>
              <Remarks />
            </RemarksUIProvider>
          )}
          {tab === "specs" && id && (
            <SpecificationsUIProvider currentEventId={id}>
              <Specifications />
            </SpecificationsUIProvider>
          )}
        </div>
      </CardBody>
    </Card>
  );
}
