import React from "react";
import { Link } from "react-router-dom";


export const AddressColumnFormatter = (cellContent, row) => (
  <>
    <Link
      to={`/cock-boxing/player/${row._id}`}
    >
      <span style={{ fontWeight: "500", color: "black" }}>

        {row.affiliate?.refId}

      </span>
    </Link>
  </>
);
