/* eslint-disable no-unused-vars */
import React from "react";
import { Route } from "react-router-dom";
import { CollectionsLoadingDialog } from "./collections-loading-dialog/CollectionsLoadingDialog";
import { CollectionDeleteDialog } from "./collection-delete-dialog/collectionDeleteDialog";
import { CollectionsDeleteDialog } from "./collections-delete-dialog/CollectionsDeleteDialog";
import { CollectionsFetchDialog } from "./collections-fetch-dialog/CollectionsFetchDialog";
import { CollectionsUpdateStatusDialog } from "./collections-update-status-dialog/CollectionsUpdateStatusDialog";
import { CollectionsVerifyDialog } from "./collection-verify-dialog/collectionVerifyDialog";
import { CollectionsCard } from "./CollectionsCard";
import { CollectionsAddCard } from "./collection-new/CollectionAddCard";
import { CollectionsUIProvider } from "./CollectionsUIContext";
import { CollectionShowsite } from "../initaialNFTOfferings/collection-show-site/collectionShowsite";
import { CollectionHide } from  "../initaialNFTOfferings/collection-hide/collectionHide";

export function InoPage({ history }) {
  const collectionsUIEvents = {
    newCollectionButtonClick: () => {
      history.push("/nft/InitialNFTofferings");
    },
    openEditCollectionPage: (id) => {
      history.push(`/nft/InitialNFTofferings/${id}/edit`);
    },
    openVerifyCollectionDialog: (id) => {
      history.push(`/nft/InitialNFTofferings/${id}/verify`);
    },
    opencollectionShowsite: (id) => {
      history.push(`/nft/InitialNFTofferings/${id}/show`);
    },
    opencollectionHidesite: (id) => {
      history.push(`/nft/InitialNFTofferings/${id}/hide`);
    },
    openDeleteCollectionDialog: (id) => {
      history.push(`/nft/InitialNFTofferings/${id}/delete`);
    },
    openDeleteCollectionsDialog: () => {
      history.push(`/nft/InitialNFTofferings/deleteCollections`);
    },
    openFetchCollectionsDialog: () => {
      history.push(`/nft/InitialNFTofferings/fetch`);
    },
    openUpdateCollectionsStatusDialog: () => {
      history.push("/nft/InitialNFTofferings/updateStatus");
    },
  };

  return (
    <CollectionsUIProvider collectionsUIEvents={collectionsUIEvents}>
      <CollectionsLoadingDialog />
      <Route path="/nft/InitialNFTofferings/deleteCollections">
        {({ history, match }) => (
          <CollectionsDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/nft/InitialNFTofferings");
            }}
          />
        )}
      </Route>
      <Route path="/nft/InitialNFTofferings/:id/verify">
        {({ history, match }) => (
          <CollectionsVerifyDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/nft/InitialNFTofferings");
            }}
          />
        )}
      </Route>
      <Route path="/nft/InitialNFTofferings/:id/delete">
        {({ history, match }) => (
          <CollectionDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/nft/InitialNFTofferings");
            }}
          />
        )}
      </Route>
      <Route path="/nft/InitialNFTofferings/fetch">
        {({ history, match }) => (
          <CollectionsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/nft/InitialNFTofferings");
            }}
          />
        )}
      </Route>
      <Route path="/nft/InitialNFTofferings/:id/show">
        {({ history, match }) => (
          <CollectionShowsite
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/nft/InitialNFTofferings");
            }}
          />
        )}
      </Route>
      <Route path="/nft/InitialNFTofferings/:id/hide">
        {({ history, match }) => (
          <CollectionHide
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/nft/InitialNFTofferings");
            }}
          />
        )}
      </Route>
      <Route path="/nft/InitialNFTofferings/updateStatus">
        {({ history, match }) => (
          <CollectionsUpdateStatusDialog
            show={match != null}
            onHide={() => {
              history.push("/nft/InitialNFTofferings");
            }}
          />
        )}
      </Route>
      <CollectionsCard />
    </CollectionsUIProvider>
  );
}
