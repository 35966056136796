import * as requestFromServer from "./productsCrud";
import { productsSlice, callTypes } from "./productsSlice";
import {
  GetAsset,
  GetAssets,
  GetAssetsByCollection,
  GetComments,
  GetIdoTokenById,
  GetInitialNFTofferings
} from "../../../../../services/ApiServices";
const { actions } = productsSlice;

export const fetchProducts = (queryParams) => async (dispatch) => {
  let query = "?";
  let body={};
  for (const key in queryParams) {
    switch (key) {
      case "pageNumber":
        query = query.concat("page=" + queryParams.pageNumber);
        break;
      case "pageSize":
        query = query.concat("&limit=" + queryParams.pageSize);
        break;
        case "filter":
          if(queryParams.filter.model)
          body={name:queryParams.filter.model};
          break;
      default:
        break;
    }
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer
  let result;
  try {
    result = await GetAssets(query,body);
  } catch (error) {
    error.clientMessage = "Can't find products";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      let totalCount = result.assets.totalDocs;
      let entities = result.assets.docs;
      dispatch(actions.productsFetched({ totalCount, entities }));
    }
  } catch (error) {
    error.clientMessage = "Can't find products";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};
export const fetchProductsByCategory = (id, queryParams) => async (
  dispatch
) => {
  let query = "&";
  let body = {};
  for (const key in queryParams) {
    switch (key) {
      case "pageNumber":
        query = query.concat("page=" + queryParams.pageNumber);
        console.log(query);
        break;
      case "pageSize":
        query = query.concat("&limit=" + queryParams.pageSize);
        console.log(query);
        break;
      case "filter":
        if (queryParams.filter.model) body = { name: queryParams.filter.model };
        break;
      default:
        break;
    }
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer
  let result;
  try {
    result = await GetInitialNFTofferings(id, query,body);
  } catch (error) {
    error.clientMessage = "Can't find products";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      let totalCount = result.asset.totalDocs;
      let entities = result.asset.docs;
      dispatch(actions.productsFetchedByCollection({ totalCount, entities }));
    }
  } catch (error) {
    error.clientMessage = "Can't find products";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};
export const fetchTokenById = (id) => async(dispatch) => {
  if (!id) {
    return dispatch(actions.productFetched({ product: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  // return requestFromServer
  let result;
  try {
    result = await  GetIdoTokenById(id);
  } catch (error) {
    error.clientMessage = "Can't find product";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      const product = result.asset;
      dispatch(actions.productFetched({ product: product }));
    }
  } catch (error) {
    error.clientMessage = "Can't find product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
  }

    
};
export const fetchComments = (id) => async(dispatch) => {
  if (!id) {
    return dispatch(actions.commentsFetched({ comments: null }));
  }

  dispatch(actions.startCall({ callType: callTypes.comment }));
  // return requestFromServer
  let result;
  try {
    result = await GetComments(id);
  } catch (error) {
    error.clientMessage = "Can't find comments";
    dispatch(actions.catchError({ error, callType: callTypes.comment }));
  }
  try {
    if (result.success) {
      console.log(result);
      const comments = result.comments.docs;
      let totalCount = result.comments.totalDocs;
      dispatch(actions.commentsFetched({ comments: comments,totalCount:totalCount }));
    }
  } catch (error) {
    error.clientMessage = "Can't find comments";
      dispatch(actions.catchError({ error, callType: callTypes.comment }));
  }

    
};
export const deleteProduct = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteProduct(id)
    .then((response) => {
      dispatch(actions.productDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createProduct = (productForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createProduct(productForCreation)
    .then((response) => {
      const { product } = response.data;
      dispatch(actions.productCreated({ product }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateProduct = (product) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateProduct(product)
    .then(() => {
      dispatch(actions.productUpdated({ product }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update product";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateProductsStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForProducts(ids, status)
    .then(() => {
      dispatch(actions.productsStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update products status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteProducts = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteProducts(ids)
    .then(() => {
      dispatch(actions.productsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete products";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
