import * as requestFromServer from "./reportsCrud";
import { reportsSlice, callTypes } from "./reportsSlice";
import {
  GetAsset,
  GetReport,
  GetReports,
} from "../../../../../services/ApiServices";
const { actions } = reportsSlice;

export const fetchReports = (queryParams) => async (dispatch) => {
  let query = "?";
  let body = {};
  for (const key in queryParams) {
    switch (key) {
      case "pageNumber":
        query = query.concat("page=" + queryParams.pageNumber);
        break;
      case "pageSize":
        query = query.concat("&limit=" + queryParams.pageSize);
        break;
      case "filter":
        if (queryParams.filter.model) body = { name: queryParams.filter.model };
        break;
      default:
        break;
    }
  }

  dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer
  let result;
  try {
    result = await GetReports(query, body);
  } catch (error) {

    error.clientMessage = "Can't find reports";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      let entities = result.reports.docs;
      let totalCount = result.reports.totalDocs;
      dispatch(actions.reportsFetched({ totalCount, entities }));
    }
  } catch (error) {
    error.clientMessage = "Can't find reports";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const fetchReport = (id) => async (dispatch) => {
  if (!id) {
    return dispatch(actions.reportFetched({ reportForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));

  let result;
  try {
    result = await GetReport(id);
  } catch (error) {
    error.clientMessage = "Can't find report";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      const report = result.report;
      dispatch(actions.reportFetched({ report: report }));
    }
  } catch (error) {
    error.clientMessage = "Can't find report";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};

export const deleteReport = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteReport(id)
    .then((response) => {
      dispatch(actions.reportDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete report";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createReport = (reportForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createReport(reportForCreation)
    .then((response) => {
      const { report } = response.data;
      dispatch(actions.reportCreated({ report }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create report";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateReport = (report) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateReport(report)
    .then(() => {
      dispatch(actions.reportUpdated({ report }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update report";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateReportsStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForReports(ids, status)
    .then(() => {
      dispatch(actions.reportsStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update reports status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteReports = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteReports(ids)
    .then(() => {
      dispatch(actions.reportsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete reports";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
