import React from "react";
import {
  FightStatusCssClasses,
  FightStatusTitles
} from "../../FightsUIHelpers";

export const StatusColumnFormatter = (cellContent, row) => (
  <span
    className={`label label-lg label-light-${
      FightStatusCssClasses[cellContent=="live"?0:cellContent=="soon"?1:2]
    } label-inline`}
  >
    {cellContent=="live"?"Live":cellContent=="soon"?"Upcoming":"Ended"}
  </span>
);
