import { CreateAdmin, DeleteAdmin, GetAdmin, GetAdminById, GetUsers, UpdateAdmin } from "../../../../../services/ApiServices";
import * as requestFromServer from "./customersCrud";
import { customersSlice, callTypes } from "./customersSlice";

const { actions } = customersSlice;

export const fetchCustomers = (queryParams, token) => async dispatch => {
  let query = "?";
  let body = { sort: {} };

  for (const key in queryParams) {
    switch (key) {
      case "sortField":
        body.sort[queryParams.sortField] = queryParams.sortOrder === "desc" ? -1 : 1;
        // query = query.concat(queryParams.sortField+"=" + queryParams.sortField);
        break;
      case "pageNumber":
        query = query.concat("page=" + queryParams.pageNumber);
        break;
      case "pageSize":
        query = query.concat("&limit=" + queryParams.pageSize);
        break;
      case "filter":
        if (queryParams.filter.firstName)
          body = { name: queryParams.filter.firstName };
        break;
      default:
        break;
    }
  }

  dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer
  let result;
  try {

    result = await GetUsers(query, body, token);
  } catch (error) {
    error.clientMessage = "Can't find customers";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      let totalCount = result.admin.totalDocs;
      let entities = result.admin.docs;
      dispatch(actions.customersFetched({ totalCount, entities }));
    }
  }
  catch (error) {
    error.clientMessage = "Can't find customers";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  };
};

export const fetchCustomer = (id, token) => async dispatch => {
  if (!id) {
    return dispatch(actions.customerFetched({ customerForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  let result;
  try {

    result = await GetAdminById(id, token);
  } catch (error) {
    error.clientMessage = "Can't find  Admin";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      dispatch(actions.customerFetched({ customerForEdit: result.admin }));
    }
  }
  catch (error) {
    error.clientMessage = "Can't find  Admin";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }

};

export const deleteCustomer = (id, token) => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let result;
  try {

    result = await DeleteAdmin(id, token);
  } catch (error) {
    error.clientMessage = "Can't Delete Admin";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      dispatch(actions.customerDeleted({ id }));

    }
  }
  catch (error) {
    error.clientMessage = "Can't delete Admin";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};

export const createCustomer = customerForCreation => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  console.log(customerForCreation);
  let result;
  try {
    result = await CreateAdmin(customerForCreation);
  } catch (error) {
    error.clientMessage = "Can't create customer";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      let user = result.user
      // dispatch(actions.customerCreated({ user }));
    }
  }
  catch (error) {
    error.clientMessage = "Can't create admin";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  };

};

// export const createCustomer = customerForCreation => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .createCustomer(customerForCreation)
//     .then(response => {
//       const { customer } = response.data;
//       dispatch(actions.customerCreated({ customer }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't create customer";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

export const updateCustomer = async (id, customer, token) => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let result;
  try {

    result = await UpdateAdmin(id, customer, token);
  } catch (error) {
    error.clientMessage = "Can't update customer";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      dispatch(actions.customerUpdated({ customer }));

    }
  }
  catch (error) {
    error.clientMessage = "Can't update customer";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }

};

export const updateCustomersStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForCustomers(ids, status)
    .then(() => {
      dispatch(actions.customersStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update customers status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCustomers = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCustomers(ids)
    .then(() => {
      dispatch(actions.customersDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete customers";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
