/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../../_redux/fights/fightsActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { FightEditForm } from "./FightEditForm";
import { FightDetail } from "./FightDetail";
import { Specifications } from "../fight-specifications/Specifications";
import { SpecificationsUIProvider } from "../fight-specifications/SpecificationsUIContext";
import { useSubheader } from "../../../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import { RemarksUIProvider } from "../fight-remarks/RemarksUIContext";
import { Remarks } from "../fight-remarks/Remarks";
import { useParams } from "react-router-dom";

const initFight = {
  id: undefined,
  model: "",
  manufacture: "Pontiac",
  modelYear: 2020,
  mileage: 0,
  description: "",
  color: "Red",
  price: 10000,
  condition: 1,
  status: 0,
  VINCode: "",
  attachments: [
    {
      url: "https://c.tenor.com/waSNZA4y2qsAAAAM/cmyk-ae.gif",
      fileType: "IMAGE",
    },
  ],
};

export function FightDetails({ history }) {
  // Subheader
  const suhbeader = useSubheader();

  // Tabs
  const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  const { id } = useParams();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);

  const { actionsLoading, fight } = useSelector(
    (state) => ({
      actionsLoading: state.fights.actionsLoading,
      fight: state.fights.fight,
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(actions.fetchFight(id));
  }, [id, dispatch]);



  useEffect(() => {
    let _title = id ? "Fight Details" : "Fight Details";
    if (fight && id) {
      _title = `Fight [${fight?.collectionId?.title} - ${fight.displayName}]`;
      // _title = `Fight [${fight?.title} ]`;
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fight, id]);

  const saveFight = (values) => {
    if (!id) {
      dispatch(actions.createFight(values)).then(() => backToFightsList());
    } else {
      dispatch(actions.updateFight(values)).then(() => backToFightsList());
    }
  };

  const btnRef = useRef();
  const saveFightClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToFightsList = () => {
    history.push(`/nft/nftcollection/fights/${fight.collectionId._id}`);
  };
  return (
    <div>
      {actionsLoading && <ModalProgressBar />}
      {/* <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToFightsList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Back
          </button>
           {`  `}
          <button className="btn btn-light ml-2">
            <i className="fa fa-redo"></i>
            Reset
          </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveFightClick}
          >
            Save
          </button> 
        </CardHeaderToolbar>
      </CardHeader> */}
      <ul className="nav nav-tabs nav-tabs-line " role="tablist">
        <li className="nav-item" onClick={() => setTab("basic")}>
          <a
            className={`nav-link ${tab === "basic" && "active"}`}
            data-toggle="tab"
            role="tab"
            aria-selected={(tab === "basic").toString()}
          >
            Details
          </a>
        </li>
        {id && (
          <>
            {" "}
            <li className="nav-item" onClick={() => setTab("white")}>
              <a
                className={`nav-link ${tab === "white" && "active"}`}
                data-toggle="tab"
                role="button"
                aria-selected={(tab === "white").toString()}
              >
                White
              </a>
            </li>
            <li className="nav-item" onClick={() => setTab("red")}>
              <a
                className={`nav-link ${tab === "red" && "active"}`}
                data-toggle="tab"
                role="button"
                aria-selected={(tab === "red").toString()}
              >
                Red
              </a>
            </li>
            <li className="nav-item" onClick={() => setTab("specs")}>
              <a
                className={`nav-link ${tab === "specs" && "active"}`}
                data-toggle="tab"
                role="tab"
                aria-selected={(tab === "specs").toString()}
              >
                Winners
              </a>
            </li>
          </>
        )}
      </ul>
      <div className="mt-5">
        {tab === "basic" && (
          <FightDetail
            actionsLoading={actionsLoading}
            fight={fight || initFight}
            btnRef={btnRef}
            saveFight={saveFight}
          />
        )}
        {tab === "white" && id && (
          <RemarksUIProvider currentFightId={id}>
            <Remarks id={id} tab="white" />
          </RemarksUIProvider>
        )}
          {tab === "red" && id && (
          <RemarksUIProvider currentFightId={id}>
            <Remarks id={id} tab="red" />
          </RemarksUIProvider>
        )}
        {tab === "specs" && id && (
          <SpecificationsUIProvider currentFightId={id}>
            <Specifications id={id} />
          </SpecificationsUIProvider>
        )}
      </div>
    </div>
  );
}
