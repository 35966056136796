import React from "react";

export const ImageColumnFormatter = (cellContent, row) => {
  const calculatedResult = ((Math.abs(row?.totalStakeAmount) * 0.1) + (Math.abs(row?.totalCockpickAmount) * 0.5)) * ((row?._id?.affiliate?.parent?.percentage - row?._id?.affiliate?.percentage) / 100);
  return (
    <>
      <b>
        {calculatedResult.toFixed(2)} $
      </b>
    </>
  );
};
