import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/affiliates/affiliatesActions";
import { AffiliateEditDialogHeader } from "./AffiliateEditDialogHeader";
import { AffiliateEditForm } from "./AffiliateEditForm";
import { useAffiliatesUIContext } from "../AffiliatesUIContext";
import { CreateAffiliate, UpdateAffiliate } from "../../../../../../services/ApiServices";
import { useToasts } from "react-toast-notifications";
import { useState } from "react";

export function AffiliateEditDialog({ id, show, onHide }) {
  // Affiliates UI Context
  const affiliatesUIContext = useAffiliatesUIContext();
  const [isLoading, setIsLoading] = useState(false)
  const { addToast } = useToasts();
  const affiliatesUIProps = useMemo(() => {
    return {
      queryParams: affiliatesUIContext.queryParams,
      setQueryParams: affiliatesUIContext.setQueryParams,
      initAffiliate: affiliatesUIContext.initAffiliate,
    };
  }, [affiliatesUIContext]);
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  // Affiliates Redux state
  const dispatch = useDispatch();
  const { actionsLoading, affiliateForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.affiliates.actionsLoading,
      affiliateForEdit: state.affiliates.affiliateForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Affiliate by id
    dispatch(actions.fetchAffiliate(id, authToken));
  }, [id, dispatch]);

  // server request for saving affiliate
  const saveAffiliate = async (affiliate) => {
    if (id)
      return updateAffiliate(affiliate);
    setIsLoading(true)
    let result;
    let body = {
      name: affiliate.name,
      country: affiliate.country.label,
      refId: affiliate.refId,
      percentage: affiliate.percentage,

    }
    try {
      result = await CreateAffiliate(body, authToken);
    } catch (error) {
      setIsLoading(false)
      console.log(error);
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: "true",
      });
    }
    try {
      if (result.success) {
        setIsLoading(false)
        dispatch(actions.fetchAffiliates(affiliatesUIProps.queryParams,authToken));
        onHide();
        return addToast(result.message, {
          appearance: "success",
          autoDismiss: "true",
        });
      }
    } catch (error) {
      setIsLoading(false)
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: "true",
      });
    }

    // if (!id) {
    //   // server request for creating affiliate
    //   console.log(affiliate);

    //   dispatch(actions.createAffiliate(affiliate)).then(() => onHide().catch(e=>{
    //     console.log(e);
    //   }));
    // } else {
    //   // server request for updating affiliate
    //   dispatch(actions.updateAffiliate(affiliate)).then(() => onHide());
    // }
  };
  const updateAffiliate = async (affiliate) => {
    setIsLoading(true)
    let result;
    let body = {
      name: affiliate.name,
      country: affiliate.country.label,
      refId: affiliate.refId,
      percentage: affiliate.percentage,
    }
    try {
      result = await UpdateAffiliate(id, body, authToken);
    } catch (error) {
      setIsLoading(false)
      console.log(error);
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: "true",
      });
    }
    try {
      if (result.success) {
        setIsLoading(false)
        dispatch(actions.fetchAffiliates(affiliatesUIProps.queryParams,authToken));
        onHide();
        return addToast(result.message, {
          appearance: "success",
          autoDismiss: "true",
        });
      }
    } catch (error) {
      setIsLoading(false)
      return addToast(error.message, {
        appearance: "error",
        autoDismiss: "true",
      });
    }

    // if (!id) {
    //   // server request for creating affiliate
    //   console.log(affiliate);

    //   dispatch(actions.createAffiliate(affiliate)).then(() => onHide().catch(e=>{
    //     console.log(e);
    //   }));
    // } else {
    //   // server request for updating affiliate
    //   dispatch(actions.updateAffiliate(affiliate)).then(() => onHide());
    // }
  };
console.log(affiliateForEdit)
  return (
    <Modal
      // size="sm"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <AffiliateEditDialogHeader id={id} />
      <AffiliateEditForm
        saveAffiliate={saveAffiliate}
        actionsLoading={actionsLoading}
        affiliate={affiliateForEdit || affiliatesUIProps.initAffiliate}
        onHide={onHide}
        isLoading={isLoading}
        id={id}
      />
    </Modal>
  );
}
