
import React, { useEffect, useState } from 'react';

import { Modal } from "react-bootstrap";
import { useToasts } from 'react-toast-notifications';
import { shallowEqual, useSelector } from "react-redux";
import { PostDisable2FA } from '../../../../services/ApiServices';


const TFAModal = (props) => {
    const { user, authToken } = useSelector(
        ({ auth }) => ({
            user: auth.user,
            authToken: auth.authToken,

        }),
        shallowEqual
    );
    const [msg, setMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const { addToast } = useToasts();

    const [tokenNumber, setTokenNumber] = useState('');
    const [buttonLoader, setButtonLoader] = useState(false);
    const handleToken = (value) => {
        if (value === '') return setTokenNumber('');
        setTokenNumber(value);
    };

    const disable2FA = async () => {
        if (tokenNumber.toString().length < 6) {
            return addToast('Verification code should be greater than or equal to 6', {
                appearance: "error",
                autoDismiss: "true",
            });
        }
        setButtonLoader(true);
        setMsg('');
        setError(false);
        setSuccess(false);

        let result;
        try {
            result = await PostDisable2FA(authToken, user.uid, tokenNumber);
            if (result.success) {
                // props.getIsEnabled();
                props.getAuthenticatorQr();
                props.setIs2FAEnabled(false);
                addToast('2FA Disabled Successfully', {
                    appearance: "success",
                    autoDismiss: "true",
                });
                setButtonLoader(false);
                setMsg('2FA Disabled successfully');
                return setSuccess(true);
            } else {
                addToast(result.message, {
                    appearance: "error",
                    autoDismiss: "true",
                });
                setButtonLoader(false);
                setError(true);
                setMsg(result.message);
            }
        } catch (e) {
            setButtonLoader(false);
            console.log(e);
            addToast(e.message, {
                appearance: "error",
                autoDismiss: "true",
            });
            setButtonLoader(false);
            setError(true);
            setMsg(e.message);
        }
    };
    useEffect(() => {
        setSuccess(false)
        setError(false)
        setMsg('')
        setTokenNumber('')
        setButtonLoader(false)
    }, [props.open])
    return (


        <Modal
            // size="sm"
            show={props.open}
            onHide={props.handleConnectClose}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg"> 2FA Disable</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div
                    style={{ marginTop: '2px', color: 'black', display: 'flex', justifyContent: 'center' }}
                >
                    {buttonLoader ? (
                        <img src="/media/gif/loader.gif" width={70} alt='loaderGif' />
                    ) : success ? (
                        <img src="/media/gif/success.gif" width={70} alt='successGif' />
                    ) : error ? (
                        <img src="/media/gif/error.gif" width={70} alt='errorGif' />
                    ) : null}
                </div>
                <h3 style={{ marginBottom: msg !== '' ? 5 : 0, textAlign: 'center' }} gutterBottom>
                    {msg}
                </h3>
                {!msg &&
                    <> <b className="">Enter Verification Code</b>
                        <input
                            type='text'
                            className="form-control"
                            onChange={(e) => {
                                handleToken(e.target.value);
                            }}
                            value={tokenNumber}
                        />
                        <div className="mt-5 mx-auto" style={{ width: '100%' }}>
                            {buttonLoader ? (
                                <button
                                    className="btn btn-primary mx-auto btn-elevate d-flex align-items-center justify-content-center"
                                    style={{ width: "81px", height: "40px" }}

                                >
                                    <span
                                        className="ml-3 spinner spinner-white"
                                        style={{ width: "inherit" }}
                                    ></span>
                                </button>
                            ) : (
                                <button
                                    className="btn btn-primary mx-auto btn-elevate d-flex align-items-center justify-content-center"
                                    onClick={() => disable2FA()} >Disable 2FA</button>
                            )}
                        </div></>}
            </Modal.Body>
        </Modal>
    );
};

export default TFAModal;