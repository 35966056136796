import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { CollectionStatusCssClasses } from "../CollectionsUIHelpers";
import { useCollectionsUIContext } from "../CollectionsUIContext";

const selectedCollections = (entities, ids) => {
  const _collections = [];
  ids.forEach((id) => {
    const collection = entities.find((el) => el.id === id);
    if (collection) {
      _collections.push(collection);
    }
  });
  return _collections;
};

export function CollectionsFetchDialog({ show, onHide }) {
  // Collections UI Context
  const collectionsUIContext = useCollectionsUIContext();
  const collectionsUIProps = useMemo(() => {
    return {
      ids: collectionsUIContext.ids,
      queryParams: collectionsUIContext.queryParams,
    };
  }, [collectionsUIContext]);

  // Collections Redux state
  const { collections } = useSelector(
    (state) => ({
      collections: selectedCollections(state.collections.entities, collectionsUIProps.ids),
    }),
    shallowEqual
  );

  // if there weren't selected ids we should close modal
  useEffect(() => {
    if (!collectionsUIProps.ids || collectionsUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionsUIProps.ids]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Fetch selected elements
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="list-timeline list-timeline-skin-light padding-30">
          <div className="list-timeline-items">
            {collections.map((collection) => (
              <div className="list-timeline-item mb-3" key={collection.id}>
                <span className="list-timeline-text">
                  <span
                    className={`label label-lg label-light-${
                      CollectionStatusCssClasses[collection.status]
                    } label-inline`}
                    style={{ width: "60px" }}
                  >
                    ID: {collection.id}
                  </span>{" "}
                  <span className="ml-5">
                    {collection.manufacture}, {collection.model}
                  </span>
                </span>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-primary btn-elevate"
          >
            Ok
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
