/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { GoUnverified, GoVerified, IconName } from "react-icons/go";
import { Link } from "react-router-dom";
export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { openEditEventPage }
) => (
  <div className="d-flex justify-content-center align-items-center gap">

    <>
      {/* <button type="button" className="btn btn-outline-primary d-flex mx-auto"  onClick={() => openVerifyEventDialog(row._id)}>Verify</button> */}

      <Link
        to={`/cock-boxing/player/${row._id}`}
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")} />
        </span>
      </Link>
    </>

    {/* <button
          className="btn btn-icon btn-light btn-hover-secondary btn-sm"
          // onClick={() => openDeleteEventDialog(row.id)}
        >
        <span className="svg-icon svg-icon-md svg-icon-primary">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
      </span>
        </button> */}

    {/* <button
          className="btn btn-icon btn-light btn-hover-secondary btn-sm"
          // onClick={() => openDeleteEventDialog(row.id)}
        >
        <span className="svg-icon svg-icon-md svg-icon-primary">
        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")} />
      </span>
        </button> */}
  </div>
);
