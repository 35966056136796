import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { SubOperatorsFilter } from "./subOperatorIncomes-filter/SubOperatorIncomesFilter";
import { SubOperatorsTable } from "./subOperatorIncome-table/SubOperatorIncomesTable";
import { SubOperatorsGrouping } from "./subOperatorIncomes-grouping/SubOperatorIncomesGrouping";
import { useSubOperatorsUIContext } from "./SubOperatorIncomesUIContext";

export function SubOperatorsCard() {
  const subOperatorsUIContext = useSubOperatorsUIContext();
  const subOperatorsUIProps = useMemo(() => {
    return {
      ids: subOperatorsUIContext.ids,
      newAffiliateButtonClick: subOperatorsUIContext.newAffiliateButtonClick,
    };
  }, [subOperatorsUIContext]);

  return (
    <>
      <Card>
        <CardHeader title="Income from Sub-Operator Players">
          <CardHeaderToolbar>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <SubOperatorsFilter />
          <SubOperatorsTable />
        </CardBody>
      </Card>

    </>
  );
}
