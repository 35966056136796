import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { Profile } from "./pages/Profile";
import { DashboardPage } from "./pages/DashboardPage";
import CockBoxingPage from "./modules/ECommerce/pages/cockBoxing";
import ECommercePage from "./modules/ECommerce/pages/eCommercePage";
import { shallowEqual, useSelector } from "react-redux";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
// const ECommercePage = lazy(() =>
//   import("./modules/ECommerce/pages/eCommercePage")
// );
// const CockBoxingPage = lazy(() =>
//   import("./modules/ECommerce/pages/cockBoxing")
// );
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>

        {
          user.role === 'operator' ?
            <Redirect exact from="/" to="/cock-boxing/players" /> :
            user.role === 'withdraw' ?
              <Redirect exact from="/" to="/cock-boxing/withdraw" /> :
              user.role === 'declarator' ?
                <Redirect exact from="/" to="/cock-boxing/events" /> :
                <Redirect exact from="/" to="/dashboard" />
        }

        {user.role === 'admin' && <ContentRoute path="/dashboard" component={DashboardPage} />}
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/profile" component={Profile} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/nft" component={ECommercePage} />
        <Route path="/cock-boxing" component={CockBoxingPage} />
        <Route path="/user-profile" component={UserProfilepage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
