import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { FightStatusCssClasses } from "../FightsUIHelpers";
import { useFightsUIContext } from "../FightsUIContext";
import { FightDetails } from "../fight-details/FightDetails";
const selectedFights = (entities, ids) => {
  const _fights = [];
  ids.forEach((id) => {
    const fight = entities.find((el) => el.id === id);
    if (fight) {
      _fights.push(fight);
    }
  });
  return _fights;
};

export function FightsFetchDialog({ id,show, onHide }) {
  // Fights UI Context
  const fightsUIContext = useFightsUIContext();
  const fightsUIProps = useMemo(() => {
    return {
      ids: fightsUIContext.ids,
      queryParams: fightsUIContext.queryParams,
    };
  }, [fightsUIContext]);

  // Fights Redux state
  const { fights } = useSelector(
    (state) => ({
      fights: selectedFights(state.fights.entities, fightsUIProps.ids),
    }),
    shallowEqual
  );
  const { actionsLoading, fight } = useSelector(
    (state) => ({
      actionsLoading: state.fights.actionsLoading,
      fight: state.fights.fight,
    }),
    shallowEqual
  );
  // if there weren't selected ids we should close modal
  useEffect(() => {
    if (!fightsUIProps.ids || fightsUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fightsUIProps.ids]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {/* Match Details */}
          <div className="d-flex">
            <div>
              <span className="font-weight-bold h6 text-secondary">Fight Number : </span>
              <span className="h3"> {fight?.fightNumber}</span>
            </div>
            <div className="ml-5">
              <span className="font-weight-bold h6">Date :</span>
              <span className="h3"> {fight?.matchDate}</span>
            </div>
            <div className="ml-5">
              <span className="font-weight-bold h6">Winner :</span>
              <span className="h3"> {fight?.winner}</span>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FightDetails />
        {/* <div className="list-timeline list-timeline-skin-light padding-30">
          <div className="list-timeline-items">
            {fights.map((fight) => (
              <div className="list-timeline-item mb-3" key={fight.id}>
                <span className="list-timeline-text">
                  <span
                    className={`label label-lg label-light-${
                      FightStatusCssClasses[fight.status]
                    } label-inline`}
                    style={{ width: "60px" }}
                  >
                    ID: {fight.id}
                  </span>{" "}
                  <span className="ml-5">
                    {fight.manufacture}, {fight.model}
                  </span>
                </span>
              </div>
            ))}
          </div>
        </div> */}
      </Modal.Body>
      <Modal.Footer>
        <div>
          {/* <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button> */}
          <> </>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-primary btn-elevate"
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
