import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./WithdrawsUIHelpers";

const WithdrawUIContext = createContext();

export function useWithdrawsUIContext() {
  return useContext(WithdrawUIContext);
}

export const WithdrawUIConsumer = WithdrawUIContext.Consumer;

export function WithdrawUIProvider({ withdrawUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const [id, setId] = useState('');
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);
  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setId,
    id,
    setQueryParams,
    newWithdrawButtonClick: withdrawUIEvents.newWithdrawButtonClick,
    openEditWithdrawPage: withdrawUIEvents.openEditWithdrawPage,
    openDeleteWithdrawDialog: withdrawUIEvents.openDeleteWithdrawDialog,
    openWithdrawEndEditDialog: withdrawUIEvents.openWithdrawEndEditDialog,
    openDeleteWithdrawDialog: withdrawUIEvents.openDeleteWithdrawDialog,
    openFetchWithdrawDialog: withdrawUIEvents.openFetchWithdrawDialog,
    openWithdrawBetEndEditDialog: withdrawUIEvents.openWithdrawBetEndEditDialog,
    openUpdateWithdrawStatusDialog:
      withdrawUIEvents.openUpdateWithdrawStatusDialog,
    openVerifyWithdrawDialog: withdrawUIEvents.openVerifyWithdrawDialog,
    initWithdraw: withdrawUIEvents.initWithdraw,

  };

  return (
    <WithdrawUIContext.Provider value={value}>
      {children}
    </WithdrawUIContext.Provider>
  );
}
