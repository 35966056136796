import { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { FightsFilter } from "./fights-filter/FightsFilter";
import { FightsTable } from "./fights-table/FightsTable";
import { FightsGrouping } from "./fights-grouping/FightsGrouping";
import { useFightsUIContext } from "./FightsUIContext";
import { Link } from "react-router-dom";

export function FightsCard() {
  const fightsUIContext = useFightsUIContext();
  const fightsUIProps = useMemo(() => {
    return {
      ids: fightsUIContext.ids,
      queryParams: fightsUIContext.queryParams,
      setQueryParams: fightsUIContext.setQueryParams,
      newFightButtonClick: fightsUIContext.newFightButtonClick,
      openDeleteFightsDialog: fightsUIContext.openDeleteFightsDialog,
      openEditFightPage: fightsUIContext.openEditFightPage,
      openUpdateFightsStatusDialog:
        fightsUIContext.openUpdateFightsStatusDialog,
      openFetchFightsDialog: fightsUIContext.openFetchFightsDialog,
      openVerifyFightDialog: fightsUIContext.openVerifyFightDialog,
      openfightShowsite: fightsUIContext.openfightShowsite,
      openfightHidesite: fightsUIContext.openfightHidesite,
    };
  }, [fightsUIContext]);

  return (
    <Card>
      <CardHeader title="Fighters list">
        <CardHeaderToolbar>
          <Link
            to="/cock-boxing/fights/import"
            // type="button"
            className="btn btn-primary"
            // onClick={fightsUIProps.newFightButtonClick}
          >
            Import Fight
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <iframe
          title="vid"
          width="560"
          height="315"
          src="https://stream.cockboxing.space:5443/cxlive/play.html?id=cxlivestream"
          className="d-flex justify-content-center mx-auto mb-8 border border-primary"
          style={{ borderWidth: "3px !important" }}
        ></iframe>
         */}
        <FightsFilter />
        {fightsUIProps.ids.length > 0 && (
          <>
            <FightsGrouping />
          </>
        )}
        <FightsTable />
      </CardBody>
    </Card>
  );
}
