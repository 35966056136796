import React from "react";
import { Route } from "react-router-dom";
import { WithdrawLoadingDialog } from "./withdraws-loading-dialog/WithdrawsLoadingDialog";
import { WithdrawDeleteDialog } from "./withdraw-delete-dialog/withdrawDeleteDialog";
// import { WithdrawDeleteDialog } from "./withdraws-delete-dialog/WithdrawDeleteDialog";
import { WithdrawsFetchDialog } from "./withdraws-fetch-dialog/WithdrawsFetchDialog";
import { WithdrawUpdateStatusDialog } from "./withdraws-update-status-dialog/WithdrawsUpdateStatusDialog";
// import { WithdrawVerifyDialog } from "./withdraws-verify-dialog/withdrawVerifyDialog";
import { WithdrawCard } from "./WithdrawsCard";
import { WithdrawUIProvider } from "./WithdrawsUIContext";
// import { WithdrawEndEditBetStatusDialog } from "./withdraws-edit-betstatus-dialog/WithdrawEditBetStatusDialog";

export function WithdrawsPage({ history }) {
  const withdrawUIEvents = {
    newWithdrawButtonClick: () => {
      history.push("/cock-boxing/withdraw/new");
    },
    openWithdrawBetEndEditDialog: (id) => {
      history.push(`/cock-boxing/withdraw/betend/${id}`);
    },
    openEditWithdrawPage: (id) => {
      history.push(`/cock-boxing/withdraw/${id}/edit`);
    },
    openVerifyWithdrawDialog: (id) => {
      history.push(`/cock-boxing/withdraw/${id}/verify`);
    },
    openVerifyWithdrawDialog: (id) => {
      history.push(`/cock-boxing/withdraw/${id}/verify`);
    },
    openDeleteWithdrawDialog: (id) => {
      history.push(`/cock-boxing/withdraw/${id}/delete`);
    },
    openDeleteWithdrawDialog: () => {
      history.push(`/cock-boxing/withdraw/deleteWithdraw`);
    },
    openFetchWithdrawDialog: () => {
      history.push(`/cock-boxing/withdraw/fetch`);
    },
    openUpdateWithdrawStatusDialog: () => {
      history.push("/cock-boxing/withdraw/updateStatus");
    },
  };

  return (
    <WithdrawUIProvider withdrawUIEvents={withdrawUIEvents}>
      <WithdrawLoadingDialog />
      {/* <Route path="/cock-boxing/withdraw/deleteWithdraw">
        {({ history, match }) => (
          <WithdrawDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/withdraw");
            }}
          />
        )}
      </Route> */}
      {/* <Route path="/cock-boxing/withdraw/:id/verify">
        {({ history, match }) => (
          <WithdrawVerifyDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/withdraw");
            }}
          />
        )}
      </Route> */}
      <Route path="/cock-boxing/withdraw/:id/delete">
        {({ history, match }) => (
          <WithdrawDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/withdraw");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/withdraw/fetch">
        {({ history, match }) => (
          <WithdrawsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/withdraw");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/withdraw/updateStatus">
        {({ history, match }) => (
          <WithdrawUpdateStatusDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/withdraw");
            }}
          />
        )}
      </Route>
      {/* <Route path='/cock-boxing/withdraw/betend/:id'>
        {({ history, match }) => (
          <WithdrawEndEditBetStatusDialog
            id={match && match.params.id}
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/withdraw");
            }}
          />
        )}
      </Route> */}
      <WithdrawCard />
    </WithdrawUIProvider>
  );
}
