import { CreateAdmin, DeleteAffiliate, GetAffiliate, GetAffiliates, GetUsers } from "../../../../../services/ApiServices";
import * as requestFromServer from "./affiliatesCrud";
import { affiliatesSlice, callTypes } from "./affiliatesSlice";

const { actions } = affiliatesSlice;

export const fetchAffiliates = (queryParams, token) => async dispatch => {
  let query = "?";
  let body = { sort: {} };
  for (const key in queryParams) {
    switch (key) {
      case "sortOrder":
        body.sort[queryParams.sortField] = queryParams.sortOrder === "desc" ? -1 : 1;
        // query = query.concat(queryParams.sortField+"=" + queryParams.sortField);
        break;
      case "pageNumber":
        query = query.concat("page=" + queryParams.pageNumber);
        break;
      case "pageSize":
        query = query.concat("&limit=" + queryParams.pageSize);
        break;
      case "filter":
        if (queryParams.filter) body.searchFields = queryParams.filter;
        break;
      case "filterField":
        if (queryParams.filterField) body.filterField = queryParams.filterField;
        break;
      case "filterFields":
        if (queryParams.filterFields) body.filterFields = queryParams.filterFields;
        break;
      default:
        break;
    }
  }

  dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer
  let result;
  try {

    result = await GetAffiliates(query, body, token);
  } catch (error) {
    error.clientMessage = "Can't find affiliates";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      let totalCount = result.affiliates.totalDocs;
      let entities = result.affiliates.docs;
      dispatch(actions.affiliatesFetched({ totalCount, entities }));
    }
  }
  catch (error) {
    error.clientMessage = "Can't find affiliates";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  };
};

export const fetchAffiliate = (id, token) => async dispatch => {
  if (!id) {
    return dispatch(actions.affiliateFetched({ affiliateForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  let result;
  try {

    result = await GetAffiliate(id, token);
  } catch (error) {
    error.clientMessage = "Can't find  affiliate";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      dispatch(actions.affiliateFetched({ affiliateForEdit: result.affiliate }));
    }
  }
  catch (error) {
    error.clientMessage = "Can't find  affiliate";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }






  // return requestFromServer
  //   .getAffiliateById(id)
  //   .then(response => {
  //     const affiliate = response.data;
  //     dispatch(actions.affiliateFetched({ affiliateForEdit: affiliate }));
  //   })
  //   .catch(error => {
  //     error.clientMessage = "Can't find affiliate";
  //     dispatch(actions.catchError({ error, callType: callTypes.action }));
  //   });
};

export const deleteAffiliate = (id, token) => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  let result;
  try {

    result = await DeleteAffiliate(id, token);
  } catch (error) {
    error.clientMessage = "Can't Delete affiliates";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      dispatch(actions.affiliateDeleted({ id }));

    }
  }
  catch (error) {
    error.clientMessage = "Can't delete affiliate";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  };


};

export const createAffiliate = affiliateForCreation => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  console.log(affiliateForCreation);
  let result;
  try {
    result = await CreateAdmin(affiliateForCreation);
  } catch (error) {
    error.clientMessage = "Can't create affiliate";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      let user = result.user
      // dispatch(actions.affiliateCreated({ user }));
    }
  }
  catch (error) {
    error.clientMessage = "Can't create admin";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  };

};

// export const createAffiliate = affiliateForCreation => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .createAffiliate(affiliateForCreation)
//     .then(response => {
//       const { affiliate } = response.data;
//       dispatch(actions.affiliateCreated({ affiliate }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't create affiliate";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

export const updateAffiliate = affiliate => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateAffiliate(affiliate)
    .then(() => {
      dispatch(actions.affiliateUpdated({ affiliate }));
    })
    .catch(error => {
      error.clientMessage = "Can't update affiliate";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateAffiliatesStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForAffiliates(ids, status)
    .then(() => {
      dispatch(actions.affiliatesStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update affiliates status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteAffiliates = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteAffiliates(ids)
    .then(() => {
      dispatch(actions.affiliatesDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete affiliates";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
