import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ReferralStatusCssClasses } from "../ReferralsUIHelpers";
import * as actions from "../../../_redux/referrals/referralsActions";
import { useReferralsUIContext } from "../ReferralsUIContext";
import { useToasts } from 'react-toast-notifications';

const selectedReferral = (entities, id) => {
  let referral;
  // ids.forEach((id) => {
  if (entities?.length)
    referral = entities.find((el) => el._id === id);
  // if (referral) {
  //   _referral.push(referral);
  // }
  // });
  return referral;
};

export function ReferralUpdateStatusDialog({ show, onHide }) {
  // Referral UI Context
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  const referralUIContext = useReferralsUIContext();
  const referralUIProps = useMemo(() => {
    return {
      id: referralUIContext.id,
      setId: referralUIContext.setId,
      queryParams: referralUIContext.queryParams,
    };
  }, [referralUIContext]);

  // Referral Redux state
  const { referral, isLoading } = useSelector(
    (state) => ({
      referral: selectedReferral(state.referrals?.entities, referralUIProps.id),
      isLoading: state.referrals.actionsLoading,
    }),
    shallowEqual
  );
  console.log(referral, referralUIProps.id);
  // if there weren't selected referral we should close modal
  useEffect(() => {
    if (!referralUIProps.id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralUIProps.id]);

  const [text, setText] = useState('');
  const { addToast } = useToasts();

  const dispatch = useDispatch();
  const updateStatus = () => {
    if (!text)
      return addToast('Please enter TX hash', {
        appearance: "error",
        autoDismiss: "true",
      });

    // server request for updateing referral by ids
    dispatch(actions.updateReferralsStatus(referralUIProps.id, { status: 'completed', txHash: text }, authToken)).then(
      () => {
        // refresh list after deletion
        dispatch(actions.fetchReferrals(referralUIProps.queryParams, authToken)).then(
          () => {
            // clear selections list
            referralUIProps.setId([]);
            // closing delete modal
            addToast('Referral completed', {
              appearance: "success",
              autoDismiss: "true",
            });
            onHide();
          }
        );
      }
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton className="form">
        <Modal.Title id="example-modal-sizes-title-lg">
          Referral Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default"
        style={{ width: '90%' }}
      >
        {isLoading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-warning" />
          </div>
        )}
        <div className="list-timeline list-timeline-skin-light" style={{ padding: '0px' }}>
          <div className="list-timeline-items">
            <div className="list-timeline-item mb-3" key={referral?._id}>
              <div className="list-timeline-text" style={{ textAlign: 'center' }}>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%", }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Status </div>
                  <div
                    style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }}
                    className={`label label-lg padding-8 label-light-${ReferralStatusCssClasses[referral?.status]} label-inline`}
                  >
                    {referral?.status}
                  </div>
                </div>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>First Name </div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }}  > {referral?.user?.firstName}</div>

                </div>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Last Name </div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }}  >{referral?.user?.lastName}</div>
                </div>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Email</div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }} > {referral?.user?.email}</div>
                </div>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Address</div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }} >{referral?.walletAddress}</div>
                </div>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Chain </div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }} > {referral?.chain}</div>
                </div>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Amount </div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }} > {referral?.amount.toFixed(2)}</div>
                </div>

                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }} >
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Tx Hash</div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }} >
                    <input
                      type="text"
                      className="form-control"
                      name="searchText"
                      placeholder="Enter TX Hash"
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                      style={{ width: '320px' }}

                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer >

        <div className="form-group">
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Close
          </button>
          <> </>
          <button
            type="button"
            onClick={updateStatus}
            className="btn btn-primary btn-elevate"
          >
            Mark as Completed
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
