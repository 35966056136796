/* eslint-disable no-unused-vars */
// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/managesFunds/collectionsActions";
import * as uiHelpers from "../CollectionsUIHelpers";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useCollectionsUIContext } from "../CollectionsUIContext";

export function CollectionsTable() {
  // Collections UI Context
  const collectionsUIContext = useCollectionsUIContext();
  console.log("collectionsUIContext",collectionsUIContext)
  const collectionsUIProps = useMemo(() => {
    return {
      ids: collectionsUIContext.ids,
      setIds: collectionsUIContext.setIds,
      queryParams: collectionsUIContext.queryParams,
      setQueryParams: collectionsUIContext.setQueryParams,
      openEditCollectionPage: collectionsUIContext.openEditCollectionPage,
      openDeleteCollectionDialog: collectionsUIContext.openDeleteCollectionDialog,
      openVerifyCollectionDialog: collectionsUIContext.openVerifyCollectionDialog,
    };
  }, [collectionsUIContext]);

  // Getting curret state of collections list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.collections }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  // Collections Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    collectionsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchManagedFunds("62e8b10e0e72ed8bc55a6964",collectionsUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    console.log(collectionsUIProps)
  }, [collectionsUIProps.queryParams, dispatch]);
  // Table columns
  // const columns = [
  //   {
  //     dataField: "title",
  //     text: "Title",
  //     sort: true,
  //     sortCaret: sortCaret,
  //     formatter: columnFormatters.TitleColumnFormatter,

  //   },
  //   // {
  //   //   dataField: "logo",
  //   //   text: "Logo",
  //   //   sort: true,
  //   //   sortCaret: sortCaret,
  //   //   formatter: columnFormatters.ImageColumnFormatter,

  //   // },
  //   {
  //     dataField: "creator.displayName",
  //     text: "logo",
  //     sort: true,
  //     sortCaret: sortCaret,
  //     formatter: columnFormatters.AddressColumnFormatter

  //   },

  //   {
  //     dataField: "category",
  //     text: "Category",
  //     sort: true,
  //     sortCaret: sortCaret,
  //   },
  //   {
  //     dataField: "floorPrice",
  //     text: "Token Price",
  //     sort: true,
  //     sortCaret: sortCaret,
  //     formatter: columnFormatters.PriceColumnFormatter
  //   },
  //   {
  //     dataField: "owners",
  //     text: "Type",
  //     sort: true,
  //     sortCaret: sortCaret,
  //     formatter: columnFormatters.ConditionColumnFormatter,
  //   },
  //   {
  //     // dataField: "items",
  //     // text: "Items",
  //     sort: true,
  //     sortCaret: sortCaret,
  //     // formatter: columnFormatters.PriceColumnFormatter,
  //     // formatter: columnFormatters.StatusColumnFormatter,


  //   },
  //   {
  //     dataField: "volume",
  //     text: "collectionId",
  //     sort: true,
  //     sortCaret: sortCaret,
  //     formatter: columnFormatters.VolumeColumnFormatter,


  //   },
  //   {
  //     dataField: "totalVolume",
  //     text: "Total Supply",
  //     sort: true,
  //     sortCaret: sortCaret,
  //     formatter: columnFormatters.TotalColumnFormatters,
  //     // formatter: columnFormatters.StatusColumnFormatter,


  //   },
  //   // {
  //   //   dataField: "active",
  //   //   text: "Active",
  //   //   sort: true,
  //   //   sortCaret: sortCaret,
  //   //   formatter: columnFormatters.StatusColumnFormatter,
  //   // },
 
  //   {
  //     dataField: "action",
  //     text: "Actions",
  //     formatter: columnFormatters.ActionsColumnFormatter,
  //     formatExtraData: {
  //       openVerifyCollectionDialog: collectionsUIProps.openVerifyCollectionDialog,
  //       // openVerifyCollectionPage: collectionsUIProps.openVerifyCollectionPage,
  //     },
  //     classes: "text-right pr-0",
  //     headerClasses: "text-right pr-3",
  //     style: {
  //       minWidth: "100px",
  //     },
  //   },
  // ];
  const columns = [
    {
  // dataField: "creator.displayName",
  // text: "logo",
  // sort: true,
  // sortCaret: sortCaret,
  formatter: columnFormatters.AddressColumnFormatter,

},
{
  dataField: "title",
  text: "Title",
  sort: true,
  sortCaret: sortCaret,
  formatter: columnFormatters.TitleColumnFormatter,

},
// {
//   dataField: "logo",
//   text: "Logo",
//   sort: true,
//   sortCaret: sortCaret,
//   formatter: columnFormatters.ImageColumnFormatter,

// },
// {
//   dataField: "creator.displayName",
//   text: "logo",
//   // sort: true,
//   // sortCaret: sortCaret,
//   formatter: columnFormatters.AddressColumnFormatter,

// },

// {
//   dataField: "category",
//   text: "Category",
//   // sort: true,
//   // sortCaret: sortCaret,
//   formatter: columnFormatters.CategoryFormatters
// },
// {
//   dataField: "floorPrice",
//   text: "Token Price",
//   // sort: true,
//   // sortCaret: sortCaret,
//   formatter: columnFormatters.PriceColumnFormatter
// },
{
  dataField: "owners",
  text: "Type",
  // sort: true,
  // sortCaret: sortCaret,
  // formatter: columnFormatters.ConditionColumnFormatter,
  formatter: columnFormatters.TotalColumnFormatters
},
{
  // dataField: "items",
  // text: "Items",
  // sort: true,
  // sortCaret: sortCaret,
  // formatter: columnFormatters.PriceColumnFormatter,
  // formatter: columnFormatters.StatusColumnFormatter,


},

{
  dataField: "totalVolume",
  text: "Total Supply",
  // sort: true,
  // sortCaret: sortCaret,
  formatter: columnFormatters.PriceColumnFormatter,
  // formatter: columnFormatters.StatusColumnFormatter,


},
// {
//   dataField: "volume",
//   text: "CreatedAt",
//   // sort: true,
//   // sortCaret: sortCaret,
//   formatter: columnFormatters.VolumeColumnFormatter,


// },
// {
//   dataField: "active",
//   text: "Active",
//   sort: true,
//   sortCaret: sortCaret,
//   formatter: columnFormatters.StatusColumnFormatter,
// },

{
  dataField: "action",
  text: "Actions",
  formatter: columnFormatters.ActionsColumnFormatter,
  formatExtraData: {
    openVerifyCollectionDialog: collectionsUIProps.openVerifyCollectionDialog,
    opencollectionShowsite:collectionsUIContext.opencollectionShowsite,
    opencollectionHidesite:collectionsUIContext.opencollectionHidesite
    // openVerifyCollectionPage: collectionsUIProps.openVerifyCollectionPage,
  },
  classes: "text-right pr-0",
  headerClasses: "text-right pr-3",
  style: {
    minWidth: "100px",
  },
},
// {
//   // dataField: "action",
//   text: "Show / Hide",
//   formatter: columnFormatters.ShowsiteFormatter,
//   formatExtraData: {
//       opencollectionShowsite:collectionsUIContext.opencollectionShowsite,
//       opencollectionHidesite:collectionsUIContext.opencollectionHidesite

//   },
//   classes: "text-right pr-0",
//   headerClasses: "text-right pr-3",
//   style: {
//     minWidth: "100px",
//   },
// },
];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: collectionsUIProps.queryParams.pageSize,
    page: collectionsUIProps.queryParams.pageNumber,
  };  
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="_id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  collectionsUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: collectionsUIProps.ids,
                //   setIds: collectionsUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
                
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
      
    </>
  );
}
