import moment from "moment";
import React from "react";

export const DateColumnFormatter = (cellContent, row) => (
  <span> 
  {/* <span style={{fontWeight:"600",color:"grey"}}>{moment(cellContent).format("hh:mm A")}</span> */}
  {/* <br /> */}
  {moment(cellContent).format("MMM,DD YYYY")}
  </span>
);
