import React from "react";

export const AddressColumnFormatter = (cellContent, row) => (
  <span>
  <>{row.ownerAddress&&<>
    {row.ownerAddress.slice(0,7)}...{row.ownerAddress.slice(-7,row.ownerAddress.length)}
  </>}</>

  </span>
);
