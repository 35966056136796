import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./FightsUIHelpers";

const FightsUIContext = createContext();

export function useFightsUIContext() {
  return useContext(FightsUIContext);
}

export const FightsUIConsumer = FightsUIContext.Consumer;

export function FightsUIProvider({ fightsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);
  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    newFightButtonClick: fightsUIEvents.newFightButtonClick,
    openEditFightPage: fightsUIEvents.openEditFightPage,
    openDeleteFightDialog: fightsUIEvents.openDeleteFightDialog,
    openFightEndEditDialog: fightsUIEvents.openFightEndEditDialog,
    openDeleteFightsDialog: fightsUIEvents.openDeleteFightsDialog,
    openFetchFightsDialog: fightsUIEvents.openFetchFightsDialog,
    openFightBetEndEditDialog: fightsUIEvents.openFightBetEndEditDialog,
    openDetailsDialog: fightsUIEvents.openDetailsDialog,
    openUpdateFightsStatusDialog:
      fightsUIEvents.openUpdateFightsStatusDialog,
      openVerifyFightDialog:fightsUIEvents.openVerifyFightDialog,
      initFight:fightsUIEvents.initFight,

  };

  return (
    <FightsUIContext.Provider value={value}>
      {children}
    </FightsUIContext.Provider>
  );
}
