import * as requestFromServer from "./cockPicksCrud";
import { cockPicksSlice, callTypes } from "./cockPicksSlice";
import {
  GetAsset,
  GetCockPick,
  GetCockPicks,
  GetIdoToken,
  VerifyCockPick,
} from "../../../../../services/ApiServices";
const { actions } = cockPicksSlice;

export const fetchCockPicks = (queryParams, token) => async (dispatch) => {
  let query = "?";
  let body = { sort: {} };
  for (const key in queryParams) {
    switch (key) {
      case "sortOrder":
        body.sort[queryParams.sortField] = queryParams.sortOrder === "desc" ? -1 : 1;
        // query = query.concat(queryParams.sortField+"=" + queryParams.sortField);
        break;
      case "pageNumber":
        query = query.concat("&page=" + queryParams.pageNumber);
        break;
      case "pageSize":
        query = query.concat("&limit=" + queryParams.pageSize);
        break;
      case "filter":
        if (queryParams.filter) body.searchFields = queryParams.filter;
        break;
      case "filterField":
        if (queryParams.filterField) body.filterField = queryParams.filterField;
        break;
      case "filterFields":
        if (queryParams.filterFields) body.filterFields = queryParams.filterFields;
        break;
      default:
        break;
    }
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer
  let result;
  try {
    result = await GetCockPicks(query, body, token);
  } catch (error) {
    error.clientMessage = "Can't find cockPicks";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      let totalCount = result.cockPicks.totalDocs;
      let entities = result.cockPicks.docs;
      dispatch(actions.cockPicksFetched({ totalCount, entities }));
    }
  } catch (error) {
    error.clientMessage = "Can't find cockPicks";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const fetchCockPick = (id) => async (dispatch) => {
  if (!id) {
    return dispatch(actions.cockPickFetched({ cockPickForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));

  let result;
  try {
    result = await GetCockPick(id);
  } catch (error) {
    error.clientMessage = "Can't find cockPick";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      const cockPick = result.cockPick;
      dispatch(actions.cockPickFetched({ cockPick: cockPick }));
    }
  } catch (error) {
    error.clientMessage = "Can't find cockPick";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};
export const verifyCockPick = (id, token) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  // return requestFromServer
  let result;
  try {
    result = await VerifyCockPick(id, token);
  } catch (error) {
    error.clientMessage = "Can't verify cockPick";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      dispatch(actions.cockPickDeleted({ id }));
    }
  } catch (error) {
    error.clientMessage = "Can't verify cockPick";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }

  // .deleteCockPick(id)
  // .then((response) => {
  //   dispatch(actions.cockPickDeleted({ id }));
  // })
  // .catch((error) => {
  //   error.clientMessage = "Can't delete cockPick";
  //   dispatch(actions.catchError({ error, callType: callTypes.action }));
  // });
};
export const deleteCockPick = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCockPick(id)
    .then((response) => {
      dispatch(actions.cockPickDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete cockPick";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createCockPick = (cockPickForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCockPick(cockPickForCreation)
    .then((response) => {
      const { cockPick } = response.data;
      dispatch(actions.cockPickCreated({ cockPick }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create cockPick";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCockPick = (cockPick) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCockPick(cockPick)
    .then(() => {
      dispatch(actions.cockPickUpdated({ cockPick }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update cockPick";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCockPicksStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForCockPicks(ids, status)
    .then(() => {
      dispatch(actions.cockPicksStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update cockPicks status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCockPicks = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCockPicks(ids)
    .then(() => {
      dispatch(actions.cockPicksDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete cockPicks";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
