// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
// import * as actions from "../../../_redux/remarks/remarksActions";
import * as actions from "../../../_redux/products/productsActions";

import { ActionsColumnFormatter } from "./column-formatters/ActionsColumnFormatter";
import { ImageColumnFormatter } from "./column-formatters/ImageColumnFormatter";
import * as uiHelpers from "./RemarksUIHelper";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import { useRemarksUIContext } from "./RemarksUIContext";
import { useParams } from "react-router-dom";

export function RemarksTable() {
  // Remarks UI Context
  const remarksUIContext = useRemarksUIContext();
  const {id} = useParams();
  const remarksUIProps = useMemo(() => {
    return {
      ids: remarksUIContext.ids,
      setIds: remarksUIContext.setIds,
      queryParams: remarksUIContext.queryParams,
      setQueryParams: remarksUIContext.setQueryParams,
      productId: remarksUIContext.productId,
      openEditRemarkDialog: remarksUIContext.openEditRemarkDialog,
      openDeleteRemarkDialog: remarksUIContext.openDeleteRemarkDialog,
    };
  }, [remarksUIContext]);

  // Getting curret state of products list from store (Redux)
  const { comments,totalCount,commentsLoading } = useSelector(
    (state) => ({  
      comments: state.products.comments,
      totalCount: state.products.totalCount,
      commentsLoading: state.products.commentsLoading,

     }),
    shallowEqual
  );
  // const { totalCount, comments, commentsLoading } = currentState;
  const dispatch = useDispatch();

  const columns = [
    {
      dataField: "user.displayName",
      text: "User Name",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "user.displayName",
      text: "Logo",
      sort: true,
      formatter:ImageColumnFormatter,
    },
    {
      dataField: "text",
      text: "Comment",
      sort: true,
    },
    {
      dataField: "likes.length",
      text: "Likes",
      sort: false,
    },
    {
      dataField: "downVotesCount",
      text: "Down Votes",
      sort: false,
    },
    {
      dataField: "upVotesCount",
      text: "Up votes",
      sort: false,
    },
   
  ];
  useEffect(() => {
    dispatch(actions.fetchComments(id));
  }, [id, dispatch]);
  console.log(comments,totalCount,commentsLoading);

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: remarksUIProps.queryParams.pageSize,
    page: remarksUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={commentsLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bordered={false}
                bootstrap4
                remote
                keyField="id"
                data={comments === null ? [] : comments}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  remarksUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   comments,
                //   ids: remarksUIProps.ids,
                //   setIds: remarksUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage comments={comments} />
                <NoRecordsFoundMessage comments={comments} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
