import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./CollectionsUIHelpers";

const CollectionsUIContext = createContext();

export function useCollectionsUIContext() {
  return useContext(CollectionsUIContext);
}

export const CollectionsUIConsumer = CollectionsUIContext.Consumer;

export function CollectionsUIProvider({ collectionsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    newCollectionButtonClick: collectionsUIEvents.newCollectionButtonClick,
    openEditCollectionPage: collectionsUIEvents.openEditCollectionPage,
    openDeleteCollectionDialog: collectionsUIEvents.openDeleteCollectionDialog,
    openDeleteCollectionsDialog: collectionsUIEvents.openDeleteCollectionsDialog,
    opencollectionShowsite:collectionsUIEvents.opencollectionShowsite,
    opencollectionHidesite:collectionsUIEvents.opencollectionHidesite,
    openFetchCollectionsDialog: collectionsUIEvents.openFetchCollectionsDialog,
    openUpdateCollectionsStatusDialog:
      collectionsUIEvents.openUpdateCollectionsStatusDialog,
      openVerifyCollectionDialog:collectionsUIEvents.openVerifyCollectionDialog
  };

  return (
    <CollectionsUIContext.Provider value={value}>
      {children}
    </CollectionsUIContext.Provider>
  );
}
