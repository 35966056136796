import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { WithdrawFilter } from "./withdraws-filter/WithdrawsFilter";
import { WithdrawTable } from "./withdraws-table/WithdrawsTable";
import { WithdrawGrouping } from "./withdraws-grouping/WithdrawsGrouping";
import { useWithdrawsUIContext } from "./WithdrawsUIContext";
// import { Link } from "react-router-dom";
// import { DropdownMenu5, DropdownCustomToggler } from "../../dropdowns";
import { Dropdown } from "react-bootstrap";
import { useRef } from 'react';
import { DropdownMenu6 } from "../../../../../_metronic/_partials/dropdowns/DropdownMenu6";

export function WithdrawCard() {
  const withdrawUIContext = useWithdrawsUIContext();
  const targetRef = useRef();

  const withdrawUIProps = useMemo(() => {
    return {
      ids: withdrawUIContext.ids,
      queryParams: withdrawUIContext.queryParams,
      setQueryParams: withdrawUIContext.setQueryParams,
      newWithdrawButtonClick: withdrawUIContext.newWithdrawButtonClick,
      openDeleteWithdrawDialog: withdrawUIContext.openDeleteWithdrawDialog,
      openEditWithdrawPage: withdrawUIContext.openEditWithdrawPage,
      openUpdateWithdrawStatusDialog:
        withdrawUIContext.openUpdateWithdrawStatusDialog,
      openFetchWithdrawDialog: withdrawUIContext.openFetchWithdrawDialog,
      openVerifyWithdrawDialog: withdrawUIContext.openVerifyWithdrawDialog,
      openwithdrawShowsite: withdrawUIContext.openwithdrawShowsite,
      openwithdrawHidesite: withdrawUIContext.openwithdrawHidesite


    };
  }, [withdrawUIContext]);

  return (
    <Card>
      <CardHeader title="Withdraw list">
        <CardHeaderToolbar>
          <Dropdown className="dropdown-inline" drop="down" alignRight>
            <Dropdown.Toggle
              className="btn btn-clean btn-hover-light-primary"
              id="dropdown-toggle-top">
              Export
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <DropdownMenu6 targetRef={targetRef} />
            </Dropdown.Menu>
          </Dropdown>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <WithdrawFilter />
        {/* {withdrawUIProps.ids.length > 0 && (
          <>
            <WithdrawGrouping />
          </>
        )} */}
        <div ref={targetRef}>
          <WithdrawTable />
        </div>
      </CardBody>
    </Card>
  );
}
