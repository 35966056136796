import React from "react";
import { Route } from "react-router-dom";
import { AffiliatesLoadingDialog } from "./affiliates-loading-dialog/AffiliatesLoadingDialog";
import { AffiliateEditDialog } from "./affiliate-edit-dialog/AffiliateEditDialog";
import { AffiliateDeleteDialog } from "./affiliate-delete-dialog/AffiliateDeleteDialog";
import { AffiliatesDeleteDialog } from "./affiliates-delete-dialog/AffiliatesDeleteDialog";
import { AffiliatesFetchDialog } from "./affiliates-fetch-dialog/AffiliatesFetchDialog";
import { AffiliatesUpdateStateDialog } from "./affiliates-update-status-dialog/AffiliatesUpdateStateDialog";
import { AffiliatesUIProvider } from "./AffiliatesUIContext";
import { AffiliatesCard } from "./AffiliatesCard";

export function AffiliatesPage({ history }) {
  const affiliatesUIEvents = {
    newAffiliateButtonClick: () => {
      history.push("/cock-boxing/operators/new");
    },
    openEditAffiliateDialog: (id) => {
      history.push(`/cock-boxing/operators/${id}/edit`);
    },
    openDeleteAffiliateDialog: (id) => {
      history.push(`/cock-boxing/operators/${id}/delete`);
    },
    openDeleteAffiliatesDialog: () => {
      history.push(`/cock-boxing/operators/deleteAffiliates`);
    },
    openFetchAffiliatesDialog: () => {
      history.push(`/cock-boxing/operators/fetch`);
    },
    openUpdateAffiliatesStatusDialog: () => {
      history.push("/cock-boxing/operators/updateStatus");
    },
  };

  return (
    <AffiliatesUIProvider affiliatesUIEvents={affiliatesUIEvents}>
      <AffiliatesLoadingDialog />
      <Route path="/cock-boxing/operators/new">
        {({ history, match }) => (
          <AffiliateEditDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/operators");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/operators/:id/edit">
        {({ history, match }) => (
          <AffiliateEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/operators");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/operators/deleteAffiliates">
        {({ history, match }) => (
          <AffiliatesDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/operators");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/operators/:id/delete">
        {({ history, match }) => (
          <AffiliateDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/operators");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/operators/fetch">
        {({ history, match }) => (
          <AffiliatesFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/operators");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/operators/updateStatus">
        {({ history, match }) => (
          <AffiliatesUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/operators");
            }}
          />
        )}
      </Route>
      <AffiliatesCard />
    </AffiliatesUIProvider>
  );
}
