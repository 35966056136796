import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";
import { EventsPage } from "./Events/EventsPage";
import { FightsPage } from "./Fights/FightsPage";
import { UsersPage } from "./Users/UsersPage";

import { DepositsPage } from "./Deposits/DepositsPage";
import { CockPicksPage } from "./CockPick/CockPicksPage";
import { WithdrawsPage } from "./Withdraw/WithdrawsPage";
import { AffiliatesPage } from "./affiliates/AffiliatesPage";
import { EventDetails } from "./Events/event-details/EventDetails";
import { UserDetails } from "./Users/user-details/UserDetails";
import { StakedPage } from "./Staked/StakedPage";

import { shallowEqual, useSelector } from "react-redux";
import { StakingPage } from "./Staking/StakingPage";
import { OperatorsPage } from "./operators/OperatorsPage";
import { MediaServices } from "./MediaServices";
import { SolanasPage } from "./Solana/SolanasPage";
import { ReferralsPage } from "./Referral/ReferralsPage";

export default function CockBoxingPage() {
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>

        {user.role === "declarator" &&
          <>
            <ContentRoute path="/cock-boxing/events" component={EventsPage} />
            <ContentRoute path="/cock-boxing/fights" component={FightsPage} />
          </>}
        {user.role === "operator" &&
          <>
            <ContentRoute path="/cock-boxing/operatorincome" component={OperatorsPage} />
            <ContentRoute path="/cock-boxing/players" component={UsersPage} />
            <ContentRoute path="/cock-boxing/staking" component={StakingPage} />
            <ContentRoute path="/cock-boxing/staked" component={StakedPage} />
            <ContentRoute path="/cock-boxing/cockPicks" component={CockPicksPage} />
            <ContentRoute path="/cock-boxing/operators" component={AffiliatesPage} />
            <ContentRoute
              path="/cock-boxing/player/:id"
              component={UserDetails}
            />
            <ContentRoute path="/cock-boxing/deposits" component={DepositsPage} />
            <ContentRoute path="/cock-boxing/solana" component={SolanasPage} />
            <ContentRoute path="/cock-boxing/referrals" component={ReferralsPage} />

          </>}
        {user.role === "withdraw" && <>
          <ContentRoute path="/cock-boxing/withdraw" component={WithdrawsPage} />
        </>}
        {user.role === "admin" && <>
          <ContentRoute path="/cock-boxing/events" component={EventsPage} />
          <ContentRoute path="/cock-boxing/players" component={UsersPage} />
          <ContentRoute path="/cock-boxing/staking" component={StakingPage} />
          <ContentRoute path="/cock-boxing/staked" component={StakedPage} />
          <ContentRoute path="/cock-boxing/cockPicks" component={CockPicksPage} />
          <ContentRoute path="/cock-boxing/operators" component={AffiliatesPage} />
          <ContentRoute path="/cock-boxing/operatorincome" component={OperatorsPage} />
          <ContentRoute path="/cock-boxing/mediaServices" component={MediaServices} />
          <ContentRoute path="/cock-boxing/solana" component={SolanasPage} />
          <ContentRoute path="/cock-boxing/referrals" component={ReferralsPage} />

          <ContentRoute
            path="/cock-boxing/player/:id"
            component={UserDetails}
          />
          <ContentRoute path="/cock-boxing/fights" component={FightsPage} />
          <ContentRoute path="/cock-boxing/event/:id" component={EventDetails} />

          <ContentRoute path="/cock-boxing/deposits" component={DepositsPage} />
          <ContentRoute path="/cock-boxing/withdraw" component={WithdrawsPage} />
        </>}
      </Switch>
    </Suspense>
  );
}
