import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { ReferralStatusCssClasses } from "../ReferralsUIHelpers";
import { useReferralsUIContext } from "../ReferralsUIContext";

const selectedReferrals = (entities, ids) => {
  const _referral = [];
  ids.forEach((id) => {
    const referral = entities.find((el) => el.id === id);
    if (referral) {
      _referral.push(referral);
    }
  });
  return _referral;
};

export function ReferralsFetchDialog({ show, onHide }) {
  // Referrals UI Context
  const referralUIContext = useReferralsUIContext();
  const referralUIProps = useMemo(() => {
    return {
      ids: referralUIContext.ids,
      queryParams: referralUIContext.queryParams,
    };
  }, [referralUIContext]);

  // Referrals Redux state
  const { referral } = useSelector(
    (state) => ({
      referral: selectedReferrals(state.referrals.entities, referralUIProps.ids),
    }),
    shallowEqual
  );

  // if there weren't selected ids we should close modal
  useEffect(() => {
    if (!referralUIProps.ids || referralUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralUIProps.ids]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Fetch selected elements
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="list-timeline list-timeline-skin-light padding-30">
          <div className="list-timeline-items">
            {referral.map((referral) => (
              <div className="list-timeline-item mb-3" key={referral.id}>
                <span className="list-timeline-text">
                  <span
                    className={`label label-lg label-light-${ReferralStatusCssClasses[referral.status]
                      } label-inline`}
                    style={{ width: "60px" }}
                  >
                    ID: {referral.id}
                  </span>{" "}
                  <span className="ml-5">
                    {referral.manufacture}, {referral.model}
                  </span>
                </span>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-primary btn-elevate"
          >
            Ok
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
