import React, {useMemo} from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { CollectionsFilter } from "./collections-filter/CollectionsFilter";
import { CollectionsTable } from "./collections-table/CollectionsTable";
import { CollectionsGrouping } from "./collections-grouping/CollectionsGrouping";
import { useCollectionsUIContext } from "./CollectionsUIContext";
import { Link } from "react-router-dom";

export function CollectionsCard() {
console.log("object");

  const collectionsUIContext = useCollectionsUIContext();
  const collectionsUIProps = useMemo(() => {
    return {
      ids: collectionsUIContext.ids,
      queryParams: collectionsUIContext.queryParams,
      setQueryParams: collectionsUIContext.setQueryParams,
      newCollectionButtonClick: collectionsUIContext.newCollectionButtonClick,
      openDeleteCollectionsDialog: collectionsUIContext.openDeleteCollectionsDialog,
      openEditCollectionPage: collectionsUIContext.openEditCollectionPage,
      openUpdateCollectionsStatusDialog:
        collectionsUIContext.openUpdateCollectionsStatusDialog,
      openFetchCollectionsDialog: collectionsUIContext.openFetchCollectionsDialog,
      openVerifyCollectionDialog:collectionsUIContext.openVerifyCollectionDialog
    };
  }, [collectionsUIContext]);
  return (
    <Card>
      <CardHeader title="Financial Assets ">
        <CardHeaderToolbar>
          <Link
          to="/nft/financialAssets/new"
            // type="button"
            className="btn btn-primary"
            onClick={collectionsUIProps.newCollectionButtonClick}
          >
            Add 
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CollectionsFilter />
        {collectionsUIProps.ids.length > 0 && (
          <>
            <CollectionsGrouping />
          </>
        )}
        <CollectionsTable />
      </CardBody>
    </Card>
  );
}
