import { createSlice } from "@reduxjs/toolkit";

const initialReferralsState = {
  listLoading: true,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  referralForEdit: undefined,
  referral: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const referralsSlice = createSlice({
  name: "referrals",
  initialState: initialReferralsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getReferralById
    referralFetched: (state, action) => {
      state.actionsLoading = false;
      state.referralForEdit = action.payload.referral;
      state.referrals = action.payload.referral;
      state.error = null;
    },
    idoFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findReferrals
    referralsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;

      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createReferral
    referralCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.referral);
    },
    // updateReferral
    referralUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.referral.id) {
          return action.payload.referral;
        }
        return entity;
      });
    },
    // deleteReferral
    referralDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteReferrals
    referralsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // referralsUpdateState
    referralsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
