import * as requestFromServer from "./fightsCrud";
import { fightsSlice, callTypes } from "./fightsSlice";
import {
  GetAsset,
  GetFight,
  GetFights,
  GetIdoToken,
  GetRewards,
  GetStake,
  VerifyFight,
} from "../../../../../services/ApiServices";
const { actions } = fightsSlice;

export const fetchFights = (queryParams,token) => async (dispatch) => {
  let query = "?";
  let body = { sort: {} };
  for (const key in queryParams) {
    switch (key) {
      case "sortOrder":
        body.sort[queryParams.sortField] = queryParams.sortOrder;
        // query = query.concat(queryParams.sortField+"=" + queryParams.sortField);
        break;
      case "pageNumber":
        query = query.concat("&page=" + queryParams.pageNumber);
        break;
      case "pageSize":
        query = query.concat("&limit=" + queryParams.pageSize);
        break;
      case "filter":
        if (queryParams.filter) body.filter = queryParams.filter;
        break;
      case "filterField":
        if (queryParams.filterField) body.filterField = queryParams.filterField;
        break;
      default:
        break;
    }
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer
  let result;
  try {
    result = await GetFights(query, body,token);
  } catch (error) {
    error.clientMessage = "Can't find fights";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      let totalCount = result.fights.totalDocs;
      let entities = result.fights.docs;
      dispatch(actions.fightsFetched({ totalCount, entities }));
    }
  } catch (error) {
    error.clientMessage = "Can't find fights";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const fetchFight = (id) => async (dispatch) => {
  if (!id) {
    return dispatch(actions.fightFetched({ fightForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));

  let result;
  try {
    result = await GetFight(id);
  } catch (error) {
    error.clientMessage = "Can't find fight";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      const fight = result.fight;
      dispatch(actions.fightFetched({ fight: fight }));
    }
  } catch (error) {
    error.clientMessage = "Can't find fight";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};
export const verifyFight = (id, token) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  // return requestFromServer
  let result;
  try {
    result = await VerifyFight(id, token);
  } catch (error) {
    error.clientMessage = "Can't verify fight";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      dispatch(actions.fightDeleted({ id }));
    }
  } catch (error) {
    error.clientMessage = "Can't verify fight";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }

  // .deleteFight(id)
  // .then((response) => {
  //   dispatch(actions.fightDeleted({ id }));
  // })
  // .catch((error) => {
  //   error.clientMessage = "Can't delete fight";
  //   dispatch(actions.catchError({ error, callType: callTypes.action }));
  // });
};
export const deleteFight = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteFight(id)
    .then((response) => {
      dispatch(actions.fightDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete fight";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createFight = (fightForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createFight(fightForCreation)
    .then((response) => {
      const { fight } = response.data;
      dispatch(actions.fightCreated({ fight }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create fight";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateFight = (fight) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateFight(fight)
    .then(() => {
      dispatch(actions.fightUpdated({ fight }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update fight";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateFightsStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForFights(ids, status)
    .then(() => {
      dispatch(actions.fightsStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update fights status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteFights = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteFights(ids)
    .then(() => {
      dispatch(actions.fightsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete fights";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchStake = (queryParams,id,name) => async (dispatch) => {
  let query = "?";
  let body = { sort: {} };
  for (const key in queryParams) {
    switch (key) {
      case "sortOrder":
        body.sort[queryParams.sortField] = queryParams.sortOrder=="asc"?1:-1;
        // query = query.concat(queryParams.sortField+"=" + queryParams.sortField);
        break;
      case "pageNumber":
        query = query.concat("&page=" + queryParams.pageNumber);
        break;
      case "pageSize":
        query = query.concat("&limit=" + queryParams.pageSize);
        break;
      case "filter":
        if (queryParams.filter) body.filter = queryParams.filter;
        break;
      case "filterField":
        if (queryParams.filterField) body.filterField = queryParams.filterField;
        break;
      default:
        break;
    }
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer
  let result;
  try {
    result = await GetStake(id,name,query,body);
  } catch (error) {
    error.clientMessage = "Can't find fights";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      let totalCount = result.stake.totalDocs;
      let entities = result.stake.docs;
      dispatch(actions.stakeFetched({ totalCount, entities }));
    }
  } catch (error) {
    error.clientMessage = "Can't find fights";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const fetchRewards = (queryParams,id) => async (dispatch) => {
  let query = "?";
  let body = { sort: {} };
  for (const key in queryParams) {
    switch (key) {
      case "sortOrder":
        body.sort[queryParams.sortField] = queryParams.sortOrder=="asc"?1:-1;
        // query = query.concat(queryParams.sortField+"=" + queryParams.sortField);
        break;
      case "pageNumber":
        query = query.concat("&page=" + queryParams.pageNumber);
        break;
      case "pageSize":
        query = query.concat("&limit=" + queryParams.pageSize);
        break;
      case "filter":
        if (queryParams.filter) body.filter = queryParams.filter;
        break;
      case "filterField":
        if (queryParams.filterField) body.filterField = queryParams.filterField;
        break;
      default:
        break;
    }
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer
  let result;
  try {
    result = await GetRewards(id,query,body);
  } catch (error) {
    error.clientMessage = "Can't find fights";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      let totalCount = result.rewards.totalDocs;
      let entities = result.rewards.docs;
      dispatch(actions.rewardFetched({ totalCount, entities }));
    }
  } catch (error) {
    error.clientMessage = "Can't find fights";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};