/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/fights/fightsActions";
import { useFightsUIContext } from "../FightsUIContext";

export function FightsDeleteDialog({ show, onHide }) {
  // Fights UI Context
  const fightsUIContext = useFightsUIContext();
  const fightsUIProps = useMemo(() => {
    return {
      ids: fightsUIContext.ids,
      setIds: fightsUIContext.setIds,
      queryParams: fightsUIContext.queryParams,
    };
  }, [fightsUIContext]);

  // Fights Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.fights.actionsLoading }),
    shallowEqual
  );
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  // looking for loading/dispatch
  useEffect(() => { }, [isLoading, dispatch]);

  // if there weren't selected fights we should close modal
  useEffect(() => {
    if (!fightsUIProps.ids || fightsUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fightsUIProps.ids]);

  const deleteFights = () => {
    // server request for deleting fight by seleted ids
    dispatch(actions.deleteFights(fightsUIProps.ids)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchFights(fightsUIProps.queryParams, authToken)).then(() => {
        // clear selections list
        fightsUIProps.setIds([]);
        // closing delete modal
        onHide();
      });
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {isLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Fights Delete
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>Are you sure to permanently delete selected fights?</span>
        )}
        {isLoading && <span>Fights are deleting...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteFights}
            className="btn btn-primary btn-elevate"
          >
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
