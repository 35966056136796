/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { DatePickerField } from "../../../../../_metronic/_partials/controls";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { GetSummary, stackchart } from "../../../../../services/ApiServices";
import { shallowEqual, useSelector } from "react-redux";


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export function UsersCard() {
  const [loading, setLoading] = useState(false);
  const [stakeData, setStakeData] = useState([]);
  // const [chartData, setChartData] = useState({});
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  const getFirstAndLastDatesOfMonth = () => {
    const now = new Date();
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    return { firstDay, lastDay };
  };

  const { firstDay, lastDay } = getFirstAndLastDatesOfMonth();

  const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [month, day, year].join('-');
  };

  const getFightDetail = async (formattedStartDate, formattedEndDate) => {
    setLoading(true);

    let result;
    const body = {
      start: formattedStartDate,
      end: formattedEndDate,
    };

    try {
      result = await GetSummary(body, authToken);
      if (result.success) {
        setStakeData(result.summary);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFightDetail(formatDate(firstDay), formatDate(lastDay));
  }, []);

  const colors = ["lightblue", "grey", "orange", "red"];
  const colors1 = ["orange", "pink", "orange", "red"];

  const chartData = {
    labels: stakeData.map(item => item.affiliate.refId),
    datasets: [
      {
        label: "Stake in USDT",
        backgroundColor: ["lightblue"],
        borderColor: ["lightblue"],
        borderWidth: 1,
        barThickness: 18,
        hoverBackgroundColor: "rgba(75,192,192,0.6)",
        hoverBorderColor: "rgba(75,192,192,1)",
        data: stakeData.map(item => Math.abs(item.totalStakeAmount)),
      },
      {
        label: "Last 2 digit in USDT",
        backgroundColor: ["pink"],
        borderColor: ["pink"],
      borderWidth: 1,
      barThickness: 18,
      hoverBackgroundColor: "rgba(75,192,192,0.6)",
      hoverBorderColor: "rgba(75,192,192,1)",
      data: stakeData.map(item => Math.abs(item.totalCockpickAmount)),
      },
    ],
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      beginAtZero: true,
    },
    y: {
      beginAtZero: true,
    },
  }
};

return (
  <Card>
    <CardHeader title="Summary">
      <CardHeaderToolbar>
      </CardHeaderToolbar>
    </CardHeader>
    <CardBody>
      <Formik
        initialValues={{
          startDate: firstDay.toISOString().split('T')[0],
          endDate: lastDay.toISOString().split('T')[0],
        }}
        onSubmit={(values) => {
          const formattedStartDate = formatDate(values.startDate);
          const formattedEndDate = formatDate(values.endDate);

          console.log("Submitted values:", values);
          getFightDetail(formattedStartDate, formattedEndDate);
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <div>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "16px", gap: '10px' }}>
              <DatePickerField
                name="startDate"
                value={values.startDate}
                onChange={(date) => {
                  setFieldValue("startDate", date);
                  handleSubmit();
                }}
                placeholderText="Select Start Date"
                style={{ marginRight: "8px" }}
              />
              <DatePickerField
                name="endDate"
                value={values.endDate}
                onChange={(date) => {
                  setFieldValue("endDate", date);
                  handleSubmit();
                }}
                placeholderText="Select End Date"
              />
            </div>
            <div style={{ height: "500px" }}>
              <Bar data={chartData} options={options} />
            </div>
          </div>
        )}
      </Formik>
    </CardBody>
  </Card>
);
}
