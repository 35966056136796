import React from "react";
import { Link } from "react-router-dom";

export const PriceColumnFormatter = (cellContent, row) => <>
 
<span style={{fontWeight:"300",color:"black", textAlign:"left" }} >
{row?.user?.firstName}{row?.user?.lastName}

</span>
</>;
