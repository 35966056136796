import React from "react";
import {
  WithdrawStatusCssClasses,
} from "../../WithdrawsUIHelpers";

export const StatusColumnFormatter = (cellContent, row) => (
  <span
    className={`label label-lg label-light-${WithdrawStatusCssClasses[row.status === "initialized" ? 0 : 1]
      } label-inline`}
  >
    {row.status === "initialized" ? "Initialized" : "Completed"}
  </span>
);
