import React, { useCallback, useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../_helpers';
import SVG from "react-inlinesvg";
import { useToasts } from 'react-toast-notifications';
import { shallowEqual, useSelector } from "react-redux";
import { Divider } from '@material-ui/core';
import { GetAffiliateByRefId, UpdateAffiliate } from '../../../services/ApiServices';

export function Operator(props) {
    const { addToast } = useToasts();
    const [percentage, setPercentage] = useState(0);
    const [affiliate, setAffiliate] = useState({})
    const [isEdit, setIsEdit] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const { user, authToken } = useSelector(
        ({ auth }) => ({
            user: auth.user,
            authToken: auth.authToken,
        }),
        shallowEqual
    );
    const updateOperator = async () => {
        if (percentage < 1)
            return addToast('Referral commission persentange is required ', {
                appearance: "error",
                autoDismiss: "true",
            })
        let result;
        let body = { referral_commision: percentage };
        setIsLoading(true)
        try {
            result = await UpdateAffiliate(affiliate._id, body, authToken);
            if (result.success) {
                setIsLoading(false)

                setIsEdit(true)
                return addToast('Referral commission updated', {
                    appearance: "success",
                    autoDismiss: "true",
                })
            }
        }
        catch (e) {
            console.log(e);
            setIsLoading(false)

            return addToast(e.message, {
                appearance: "error",
                autoDismiss: "true",
            })
        }
    }
    const getAffiliate = useCallback(async () => {
        let result;
        try {
            result = await GetAffiliateByRefId(user.affiliateRefId)
            if (result.success)
                setAffiliate(result.affiliate)
            setPercentage(result.affiliate?.referral_commision || 0)

        }
        catch (e) {
            console.log(e);
        }
    }, [user.affiliateRefId])
    useEffect(() => {
        getAffiliate()
    }, [getAffiliate])
    return (
        <>
            <h6>Operator signup link</h6>
            <p
                className="d-flex align-items-center font-bold gap-3"
            >https://cockboxing.live/auth/signup/{user.affiliateRefId}
                <span
                    onClick={() => {
                        navigator.clipboard.writeText("https://cockboxing.live/auth/signup/" + user.affiliateRefId);
                        addToast('Copied', {
                            appearance: "success",
                            autoDismiss: "true",
                        })
                    }}
                    className="svg-icon svg-icon-lg svg-icon-primary ml-1 svg-icon-sort cursor-pointer">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")} />
                </span>
            </p>
            <Divider />
            <h6 className='mt-5 '>Referral Commission (%)</h6>
            <div className='d-flex align-items-center gap-5'>
                <input className="form-control w-50 me-5" value={percentage}
                    type="number"
                    disabled={isEdit}
                    onChange={(e) => { setPercentage(e.target.value) }} />
                {isEdit ?
                    <button
                        type="button"
                        onClick={() => setIsEdit(false)}
                        className="btn btn-primary btn-elevate ml-5 "
                        style={{ width: "80px" }}

                    >
                        Edit
                    </button> :
                    <button
                        type="button"
                        onClick={updateOperator}
                        className="btn btn-primary btn-elevate ml-5 d-flex align-items-center gap-2 justify-content-center"
                        style={{ width: "85px" }}
                        disabled={isLoading}

                    >
                        Save
                        {isLoading && <div className="ml-3 spinner spinner-white"></div>}
                    </button>}
            </div>

        </>
    )
}
