import React from "react";
import {
  EventStatusCssClasses,
  EventStatusTitles
} from "../../UsersUIHelpers";

export const StatusColumnFormatter = (cellContent, row) => (
  <span
    className={`label label-lg label-light-${
      EventStatusCssClasses[row.soldOut?1:0]
    } label-inline`}
  >
    {EventStatusTitles[row.soldOut?1:0]}
  </span>
);
