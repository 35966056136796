import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { useFightsUIContext } from "../FightsUIContext";
import * as XLSX from "xlsx";
import { CreateFights } from "../../../../../../services/ApiServices";
import Select from "react-select";
import { GetSelectEvents } from "../../../../../../services/ApiServices";

export function FightImportDialog({ show, onHide }) {
  // Fights UI Context
  const fightsUIContext = useFightsUIContext();
  const [events, setEvents] = useState([]);

  const [items, setItems] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const fightsUIProps = useMemo(() => {
    return {
      ids: fightsUIContext.ids,
      queryParams: fightsUIContext.queryParams,
    };
  }, [fightsUIContext]);

  const [selectedEvent, setSelectedEvent] = useState("");

  const handleCategory = (event) => {
    setSelectedEvent(event);
  };

  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  // if there weren't selected ids we should close modal
  useEffect(() => {
    if (!fightsUIProps.ids || fightsUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fightsUIProps.ids]);

  const getSelectEvent = async () => {
    try {
      const result = await GetSelectEvents();

      console.log("result", result);
      if (result.success) {
        result.events.map((event) => {
          let value = { label: event.name, value: event._id };
          setEvents((arenas) => arenas.concat(value));
        });
        setSelectedEvent({
          label: result.events[0].name,
          value: result.events[0]._id,
        });
      }
    } catch (e) {
      console.log("exception in get selectevent", e);
    }
  };

  useEffect(() => {
    setItems([]);
    getSelectEvent();
  }, []);

  const readExcel = (file) => {
    if (file) {
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
          const bufferArray = e.target.result;

          const wb = XLSX.read(bufferArray, { type: "buffer" });

          const wsname = wb.SheetNames[0];

          const ws = wb.Sheets[wsname];

          const data = XLSX.utils.sheet_to_json(ws);

          resolve(data);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });

      promise.then((d) => {
        setItems(d);
      });
    } else {
      setItems([]);
    }
  };
  const createFights = async () => {
    let result;
    let body = {
      fights: items,
      event: selectedEvent.value,
    };

    console.log("body", body);
    try {
      result = await CreateFights(body, authToken);

      if (result.success) {
        setSuccess(`${result.fights.length} Fights added successfully`);
        setIsLoading(false);
      } else {
        setError(result);
        setIsLoading(false);
        console.log("res", result);
      }
    } catch (e) {
      setError(e);
      setIsLoading(false);
      return console.log(e);
    }
  };

  const ImportFunction = async () => {
    setError("");
    setSuccess("");
    console.log("items", items.length);
    if (items.length !== 0) {
      let arr = [];
      let variables = [
        "FIGHT_NO",
        "WHITE_ENTRY",
        "WHITE_WEIGHT",
        "RED_ENTRY",
        "RED_WEIGHT",
      ];
      Object.entries(items[0]).forEach(([key, value]) => {
        arr.push(key);
      });

      let requests = [];
      let nullValidate = true;
      let duplicateValidate = true;

      requests.push(
        new Promise((resolve, reject) => {
          items.map((value, index) => {
            if (value.FIGHT_NO === undefined) {
              nullValidate = false;
              return setError(`FIGHT_NO field is missing in row ${index + 1}`);
            }
            if (value.WHITE_ENTRY === undefined) {
              nullValidate = false;
              return setError(
                `WHITE_ENTRY field is missing in row ${index + 1}`
              );
            }
            if (value.WHITE_WEIGHT === undefined) {
              nullValidate = false;
              return setError(
                `WHITE_WEIGHT field is missing in row ${index + 1}`
              );
            }
            if (value.RED_ENTRY === undefined) {
              nullValidate = false;
              return setError(`RED_ENTRY field is missing in row ${index + 1}`);
            }
            if (value.RED_WEIGHT === undefined) {
              nullValidate = false;
              return setError(
                `RED_WEIGHT field is missing in row ${index + 1}`
              );
            }
          });
          items.map((value, index) => {
            const noOccurenceFightNo = items.filter(
              (obj) => obj.FIGHT_NO === value.FIGHT_NO
            ).length;
            // const noOccurenceWhiteEntry = items.filter(
            //   (obj) => obj.WHITE_ENTRY === value.WHITE_ENTRY
            // ).length;

            // const noOccurenceRedEntry = items.filter(
            //   (obj) => obj.RED_ENTRY === value.RED_ENTRY
            // ).length;

            if (noOccurenceFightNo > 1) {
              duplicateValidate = false;
              return setError(
                `FIGHT_NO field has duplicate value in row ${index + 1}`
              );
            }
            // if (noOccurenceWhiteEntry > 1) {
            //   duplicateValidate = false;
            //   return setError(
            //     `WHITE_ENTRY field has duplicate value in row ${index + 1}`
            //   );
            // }

            // if (noOccurenceRedEntry > 1) {
            //   duplicateValidate = false;
            //   return setError(
            //     `RED_ENTRY field has duplicate value in row ${index + 1}`
            //   );
            // }
          });

          return resolve(true);
        })
      );
      if (!nullValidate || !duplicateValidate) return;
      let generic = variables.every((i) => arr.includes(i));
      console.log(generic, variables, items);
      if (!generic) {
        return setError("File is not match please use the template!");
      }

      Promise.all(requests).then(async () => {
        createFights();
        setIsLoading(true);
      });
    } else {
      setError("Please Select the file!");
    }
  };
console.log(success);
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-md"
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-md">
          Import Fights
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {/* <ExcelFile
            filename="CockFightTemplate"
            element={
              <button
                type="link"
                icon={<TbDownload />}
                className="btn btn-outline-secondary d-flex justify-content-center"
              >
                Download Template
              </button>
            }
          >
            <ExcelSheet dataSet={multiDataSet} name="CockFight" />
          </ExcelFile> */}

          <div className="space-y-3">
            <p className="fw-bold">Select an Event</p>
            <div className="d-lg-flex ">
              <div>
                {" "}
                <Select
                  className="category-select d-inline-block bg-white mb-4"
                  placeholder="Select an event"
                  options={events}
                  name="event"
                  onChange={handleCategory}
                  value={selectedEvent || ""}
                  isDisabled={events.length === 0}
                  isSearchable={false}
                />
              </div>
            </div>
          </div>
          <div className="space-y-3">
            <p className="fw-bold">Import file</p>
            <div className="mx-auto row gap align-center justify-center">
              <label id="file-upload" className="custom-file-upload mt-1">
                Select Excel File
              </label>
              <input
                id="file-upload"
                type="file"
                accept=".xls,.xlsx"
                onChange={(e) => {
                  const file = e.target.files[0];
                  readExcel(file);
                }}
              />
            </div>
          </div>
        </div>
        <div className="text-red-500 mt-4" style={{ color: "green" }}>
          {success && success}
        </div>
        <div className="text-red-500 mt-4" style={{ color: "red" }}>
          {error && error}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          {isLoading ? (
            <button type="button" className="btn btn-primary btn-elevate">
              Please wait...
            </button>
          ) : events.length === 0 ? (
            <button
              type="button"
              onClick={() => setError("Create a new event to add fights")}
              className="btn btn-primary btn-elevate"
            >
              Import
            </button>
          ) : !success ? (
            <button
              type="button"
              onClick={() => ImportFunction()}
              className="btn btn-primary btn-elevate"
            >
              Import
            </button>
          ) : (
            <button
              disabled
              type="button"
              className="btn btn-primary btn-elevate"
            >
              Import
            </button>
          )}

        </div>
      </Modal.Footer>
    </Modal>
  );
}
