import axios from "axios";

export const FightS_URL = "api/Fights";

// CREATE =>  POST: add a new Fight to the server
export function createFight(Fight) {
  return axios.post(FightS_URL, { Fight });
}

// READ
export function getAllFights() {
  return axios.get(FightS_URL);
}

export function getFightById(FightId) {
  return axios.get(`${FightS_URL}/${FightId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findFights(queryParams) {
  return axios.post(`${FightS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateFight(Fight) {
  return axios.put(`${FightS_URL}/${Fight.id}`, { Fight });
}

// UPDATE Status
export function updateStatusForFights(ids, status) {
  return axios.post(`${FightS_URL}/updateStatusForFights`, {
    ids,
    status
  });
}

// DELETE => delete the Fight from the server
export function deleteFight(FightId) {
  return axios.delete(`${FightS_URL}/${FightId}`);
}

// DELETE Fights by ids
export function deleteFights(ids) {
  return axios.post(`${FightS_URL}/deleteFights`, { ids });
}
