import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./AffiliatesUIHelpers";

const AffiliatesUIContext = createContext();

export function useAffiliatesUIContext() {
  return useContext(AffiliatesUIContext);
}

export const AffiliatesUIConsumer = AffiliatesUIContext.Consumer;

export function AffiliatesUIProvider({ affiliatesUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initAffiliate = {

    name: "",
    country: "",
    refId: '',
    percentage: 0


  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initAffiliate,
    newAffiliateButtonClick: affiliatesUIEvents.newAffiliateButtonClick,
    openEditAffiliateDialog: affiliatesUIEvents.openEditAffiliateDialog,
    openDeleteAffiliateDialog: affiliatesUIEvents.openDeleteAffiliateDialog,
    openDeleteAffiliatesDialog: affiliatesUIEvents.openDeleteAffiliatesDialog,
    openFetchAffiliatesDialog: affiliatesUIEvents.openFetchAffiliatesDialog,
    openUpdateAffiliatesStatusDialog: affiliatesUIEvents.openUpdateAffiliatesStatusDialog
  };

  return <AffiliatesUIContext.Provider value={value}>{children}</AffiliatesUIContext.Provider>;
}