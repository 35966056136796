import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../../../_metronic/_partials/controls";
import Select from 'react-select';
import { useEffect, useState } from "react";

// Validation schema
const CustomerEditSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("First Name is required"),
  lastName: Yup.string()
    .required("Last Name is required"),
  email: Yup.string()
    .email("Invalid Email")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Minimum 8 symbols")
    .max(50, "Maximum 50 symbols")
    .when("id", {
      is: (id) => !id, // Only required if no id
      then: Yup.string().required("Password is required"),
    }),
  role: Yup.string()
    .required("Role is required"),
  affiliateId: Yup.string()
    .when('role', {
      is: 'operator',
      then: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(10, "Maximum 10 symbols")
        .required("Affiliate Id is required"),
      otherwise: Yup.string().notRequired()
    }),
});

export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
  isLoading,
  id
}) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const roles = [
    { label: 'Admin', value: 'admin' },
    { label: 'Declarator', value: 'declarator' },
    { label: 'Operator', value: 'operator' },
    { label: 'Withdraw', value: 'withdraw' }
  ];
  const [admin, setAdmin] = useState({})
  useEffect(() => {
    if (id) {
      const updatedAdmin = { ...customer, affiliateId: customer.affiliateRefId, role: roles.find(r => r.value === customer.role) };
      setAdmin(updatedAdmin);
    } else {
      setAdmin(customer);
    }
  }, [customer]);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...admin, id }}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          saveCustomer(values);
        }}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="firstName"
                      component={Input}
                      className="form-control"
                      placeholder="First Name"
                      label="First Name"
                      customFeedbackLabel=" "
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-lg-12">
                    <Field
                      name="lastName"
                      component={Input}
                      className="form-control"
                      placeholder="Last Name"
                      label="Last Name"
                      customFeedbackLabel=" "
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="col-lg-12">
                    <Field
                      name="email"
                      component={Input}
                      className="form-control"
                      placeholder="Email"
                      label="Email"
                      customFeedbackLabel=" "
                      disabled={!!id}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  {!id && (
                    <div className="col-lg-12">
                      <Field
                        name="password"
                        component={Input}
                        className="form-control"
                        placeholder="Password"
                        label="Password"
                        customFeedbackLabel=" "
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  )}
                  <div className="col-lg-12">
                    <label>Select Role</label><br />
                    <Select
                      options={roles}
                      name="role"
                      value={values.role}
                      onChange={(e) => setFieldValue('role', e)}
                    />
                    <ErrorMessage name="role" component="div" className="text-danger" />
                  </div>
                  {values.role?.value === 'operator' && (
                    <div className="col-lg-12">
                      <Field
                        name="affiliateId"
                        component={Input}
                        className="form-control"
                        placeholder="Operator Id"
                        label="Operator Id"
                        customFeedbackLabel=" "
                      />
                      <ErrorMessage
                        name="affiliateId"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                  )}
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate justify-content-center"
                style={{ width: "81px" }}
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate d-flex align-items-center justify-content-center"
                style={{ width: "81px" }}
              >
                Save
                {isLoading && (
                  <span
                    className="ml-3 spinner spinner-white"
                    style={{ width: "inherit" }}
                  ></span>
                )}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
