import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { DepositsFilter } from "./deposits-filter/DepositsFilter";
import { DepositsTable } from "./deposits-table/DepositsTable";
import { DepositsGrouping } from "./deposits-grouping/DepositsGrouping";
import { useDepositsUIContext } from "./DepositsUIContext";
// import { Link } from "react-router-dom";
// import { DropdownMenu5, DropdownCustomToggler } from "../../dropdowns";
import { Dropdown } from "react-bootstrap";
import { DropdownMenu5 } from "../../../../../_metronic/_partials/dropdowns/DropdownMenu5";
import { useRef } from 'react';

export function DepositsCard() {
  const depositsUIContext = useDepositsUIContext();
  const targetRef = useRef();

  const depositsUIProps = useMemo(() => {
    return {
      ids: depositsUIContext.ids,
      queryParams: depositsUIContext.queryParams,
      setQueryParams: depositsUIContext.setQueryParams,
      newDepositButtonClick: depositsUIContext.newDepositButtonClick,
      openDeleteDepositsDialog: depositsUIContext.openDeleteDepositsDialog,
      openEditDepositPage: depositsUIContext.openEditDepositPage,
      openUpdateDepositsStatusDialog:
        depositsUIContext.openUpdateDepositsStatusDialog,
      openFetchDepositsDialog: depositsUIContext.openFetchDepositsDialog,
      openVerifyDepositDialog: depositsUIContext.openVerifyDepositDialog,
      opendepositShowsite: depositsUIContext.opendepositShowsite,
      opendepositHidesite: depositsUIContext.opendepositHidesite


    };
  }, [depositsUIContext]);

  return (
    <Card>
      <CardHeader title="Deposits list">
        <CardHeaderToolbar>
          <Dropdown className="dropdown-inline" drop="down" alignRight>
            <Dropdown.Toggle
              className="btn btn-clean btn-hover-light-primary"
              id="dropdown-toggle-top">
              Export
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <DropdownMenu5  />
            </Dropdown.Menu>
          </Dropdown>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <DepositsFilter />
        {depositsUIProps.ids.length > 0 && (
          <>
            <DepositsGrouping />
          </>
        )}
          <DepositsTable />
      </CardBody>
    </Card>
  );
}
