import React, {useEffect, useMemo} from "react";
import { useDispatch, useSelector } from "react-redux";
import { shallowEqual } from "react-redux";
import * as actions from "../../../_redux/products/productsActions";
import { useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { useCollectionsUIContext } from "../CollectionsUIContext";
import NFTCollection from "./financialAssetEditForm";

export function financialAssetEditCard() {
  // const collectionsUIContext = useCollectionsUIContext();
  

  return (
    <Card>
      <CardHeader title="Edit Asset">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            // onClick={collectionsUIProps.newCollectionButtonClick}
          >
            Clear All
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
     <NFTCollection />
      </CardBody>
    </Card>
  );
}
