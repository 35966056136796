import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { SolanaFilter } from "./solanas-filter/SolanasFilter";
import { SolanaTable } from "./solanas-table/SolanasTable";
import { SolanaGrouping } from "./solanas-grouping/SolanasGrouping";
import { useSolanasUIContext } from "./SolanasUIContext";
// import { Link } from "react-router-dom";
// import { DropdownMenu5, DropdownCustomToggler } from "../../dropdowns";
import { Dropdown } from "react-bootstrap";
import { SolanaDropdownMenu5 } from "../../../../../_metronic/_partials/dropdowns/SolanaDropdownMenu5";

export function SolanaCard() {
  const solanaUIContext = useSolanasUIContext();

  const solanaUIProps = useMemo(() => {
    return {
      ids: solanaUIContext.ids,
      queryParams: solanaUIContext.queryParams,
      setQueryParams: solanaUIContext.setQueryParams,
      newSolanaButtonClick: solanaUIContext.newSolanaButtonClick,
      openDeleteSolanaDialog: solanaUIContext.openDeleteSolanaDialog,
      openEditSolanaPage: solanaUIContext.openEditSolanaPage,
      openUpdateSolanaStatusDialog:
        solanaUIContext.openUpdateSolanaStatusDialog,
      openFetchSolanaDialog: solanaUIContext.openFetchSolanaDialog,
      openVerifySolanaDialog: solanaUIContext.openVerifySolanaDialog,
      opensolanaShowsite: solanaUIContext.opensolanaShowsite,
      opensolanaHidesite: solanaUIContext.opensolanaHidesite


    };
  }, [solanaUIContext]);

  return (
    <Card>
      <CardHeader title="Solana Deposits list">
        <CardHeaderToolbar>
          <Dropdown className="dropdown-inline" drop="down" alignRight>
            <Dropdown.Toggle
              className="btn btn-clean btn-hover-light-primary"
              id="dropdown-toggle-top">
              Export
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <SolanaDropdownMenu5 />
            </Dropdown.Menu>
          </Dropdown>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <SolanaFilter />
        {/* {solanaUIProps.ids.length > 0 && (
          <>
            <SolanaGrouping />
          </>
        )} */}
        <SolanaTable />
      </CardBody>
    </Card>
  );
}
