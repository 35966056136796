import { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useReferralsUIContext } from "../ReferralsUIContext";
// import { shallowEqual, useSelector } from "react-redux";
import { DatePickerField } from "../../../../../../_metronic/_partials/controls";
import { useEffect, useState } from "react";
import { GetAllAffiliates } from "../../../../../../services/ApiServices";
import Select from "react-select";
import { shallowEqual, useSelector } from "react-redux";

const prepareFilter = (queryParams, values) => {
  const { winner, status, searchText, startDate, endDate, affiliateId } = values;
  const newQueryParams = { ...queryParams };
  const filter = searchText;
  const filterField = {};
  const filterFields = {};

  // Filter by status
  // filterField.status = status !== "" ? status : undefined;
  // filterField.winner = winner !== "" ? winner : undefined;
  // Filter by category
  // filter.category = category !== "" ? category : "";
  // Filter by all fields
  if (startDate && endDate) {
    // Add 1 day to endDate
    const endDateWithAddedDay = new Date(endDate);
    endDateWithAddedDay.setDate(endDateWithAddedDay.getDate() + 1);

    // Convert startDate and endDateWithAddedDay to ISO string
    filterField.startDate = startDate.toISOString();
    filterField.endDate = endDateWithAddedDay.toISOString();
  }

  if (affiliateId)
    filterFields.affiliateId = affiliateId.value;

  newQueryParams.filter = filter;
  newQueryParams.filterFields = filterFields;
  newQueryParams.filterField = filterField;
  return newQueryParams;
};

export function ReferralFilter() {
  // Referral UI Context
  const referralUIContext = useReferralsUIContext();
  const [affiliates, setAffiliates] = useState([]);
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );
  const referralUIProps = useMemo(() => {
    return {
      setQueryParams: referralUIContext.setQueryParams,
      queryParams: referralUIContext.queryParams,
    };
  }, [referralUIContext]);

  // const { currentState } = useSelector(
  //   (state) => ({ currentState: state.referrals }),
  //   shallowEqual
  // );
  // const { totalCount, entities, listLoading } = currentState;

  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(actions.fetchCategories());
  // }, [ dispatch]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(referralUIProps.queryParams, values);
    if (!isEqual(newQueryParams, referralUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      referralUIProps.setQueryParams(newQueryParams);
    }
  };
  const getSelectEvent = async () => {
    try {
      const result = await GetAllAffiliates();

      if (result.success) {
        result.affiliates.map((affiliate) => {
          let value = { label: affiliate.name + " - " + affiliate.refId, value: affiliate.refId };
          setAffiliates((affiliate) => affiliate.concat(value));
        });

      }
    } catch (e) {
      console.log("exception in get selectevent", e);
    }
  };

  useEffect(() => {
    getSelectEvent();
  }, []);
  return (
    <>
      <Formik
        initialValues={{
          winner: "",
          status: "", // values => All=""/Selling=0/Sold=1
          category: "", // values => All=""/New=0/Used=1
          searchText: "",
          startDate: null,
          endDate: null,
          affiliateId: ''

        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-4">
                {<label>Date Filter</label>}
                <div className=" d-flex gap-2">
                  <DatePickerField
                    name='startDate'
                    // label='Start date'
                    value={values.startDate}
                    onChange={(e) => {
                      setFieldValue("startDate", e);
                    }}
                    placeholderText="Select Start date"
                  /><div className="mx-2"></div>
                  <DatePickerField
                    name="endDate"
                    selected={values.endDate}
                    onChange={(date) => {
                      setFieldValue("endDate", date);
                      handleSubmit();
                    }}
                    placeholderText="Select End date"
                    className="form-control"
                    minDate={values.startDate} // Ensure end date cannot be before start date
                    disabled={!values.startDate} // Disable end date until start date is selected
                  />
                </div>

                {/* <select
                  className="form-control"
                  name="winner"
                  placeholder="Filter by Winner"
                  onChange={(e) => {
                    setFieldValue("winner", e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.winner}
                >
                  <option value="">All</option>
                  <option value="red">Red</option>
                  <option value="white">White</option>
                  <option value="cancel">Cancel</option>
                </select>
                <small className="form-text text-muted ml-1">
                <b>Filter</b> by Winner
                </small> */}
              </div>
              {user.role !== 'operator' && <div className='me-5 mr-5'>
                {<label>Filter by operator</label>}<br />
                <Select
                  className="category-select d-inline-block bg-white "
                  placeholder="Select an Operator"
                  options={affiliates}
                  name="affiliate"
                  onChange={(e) => {
                    setFieldValue("affiliateId", e);
                    handleSubmit()
                  }}
                  isClearable
                  value={values.affiliateId || ""}
                  isDisabled={affiliates.length === 0}
                  isSearchable={false}

                />
              </div>}
              {/* <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Status"
                  name="status"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("status", e.target.value);
                    handleSubmit();
                  }}
                  value={values.status}
                >
                  <option value="">All</option>
                  <option value="live">Live</option>
                  <option value="finished">Ended</option>
                  <option value="soon">Upcoming</option>
                </select>
                <small className="form-text text-muted ml-1" >
                  <b>Filter</b> by Status
                </small>
              </div> */}
              <div>
                <small className="form-text text-muted ml-1">
                  {<label><b>Search</b> in all fields</label>}<br />
                </small>
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />

              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
