import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useAffiliatesUIContext } from "../AffiliatesUIContext";
import Select from 'react-select'
import countryList from 'react-select-country-list'
const prepareFilter = (queryParams, values) => {
  const { country, type, searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = searchText;
  const filterField = {};
  const filterFields = {};
  if (country)
    filterFields.country = country.label;


  newQueryParams.filter = filter;
  newQueryParams.filterFields = filterFields;
  newQueryParams.filterField = filterField;
  return newQueryParams;
};

export function AffiliatesFilter({ listLoading }) {
  // Affiliates UI Context
  const affiliatesUIContext = useAffiliatesUIContext();
  const options = useMemo(() => countryList().getData(), [])

  const affiliatesUIProps = useMemo(() => {
    return {
      queryParams: affiliatesUIContext.queryParams,
      setQueryParams: affiliatesUIContext.setQueryParams,
    };
  }, [affiliatesUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(affiliatesUIProps.queryParams, values);
    if (!isEqual(newQueryParams, affiliatesUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      affiliatesUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          country: "", // values => All=""/Susspended=0/Active=1/Pending=2
          type: "", // values => All=""/Business=0/Individual=1
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-2">
                <Select options={options} name='country' isClearable value={values.country}
                  onChange={(e) => { setFieldValue('country', e); handleSubmit(); }} />
                <small className="form-text text-muted">
                  <b>Filter</b> by Country
                </small>
              </div>
              {/* <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Type"
                  name="type"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("type", e.target.value);
                    handleSubmit();
                  }}
                  value={values.type}
                >
                  <option value="">All</option>
                  <option value="0">Business</option>
                  <option value="1">Individual</option>
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Type
                </small>
              </div> */}
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
