import React from "react";
import { UsersCard } from "./StakingCard";

export function StakedPage({ history }) {


  return (
    <>
      <UsersCard />
    </>
  );
}
