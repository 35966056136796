import axios from "axios";

export const SolanaS_URL = "api/Solanas";

// CREATE =>  POST: add a new Solana to the server
export function createSolana(Solana) {
  return axios.post(SolanaS_URL, { Solana });
}

// READ
export function getAllSolanas() {
  return axios.get(SolanaS_URL);
}

export function getSolanaById(SolanaId) {
  return axios.get(`${SolanaS_URL}/${SolanaId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSolanas(queryParams) {
  return axios.post(`${SolanaS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateSolana(Solana) {
  return axios.put(`${SolanaS_URL}/${Solana.id}`, { Solana });
}

// UPDATE Status
export function updateStatusForSolanas(ids, status) {
  return axios.post(`${SolanaS_URL}/updateStatusForSolanas`, {
    ids,
    status
  });
}

// DELETE => delete the Solana from the server
export function deleteSolana(SolanaId) {
  return axios.delete(`${SolanaS_URL}/${SolanaId}`);
}

// DELETE Solanas by ids
export function deleteSolanas(ids) {
  return axios.post(`${SolanaS_URL}/deleteSolanas`, { ids });
}
