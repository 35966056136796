import React, { useMemo, useState, useEffect } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useSubOperatorsUIContext } from "../SubOperatorIncomesUIContext";
import { DatePickerField } from "../../../../../../_metronic/_partials/controls";
import { shallowEqual, useSelector } from "react-redux";
import { GetAllAffiliates } from "../../../../../../services/ApiServices";



import Select from 'react-select'
import countryList from 'react-select-country-list'
const prepareFilter = (queryParams, values) => {
  const { winner, status, searchText, startDate, endDate, affiliateId } = values;
  const newQueryParams = { ...queryParams };
  const filter = searchText;
  const filterField = {};
  const filterFields = {};
  // Filter by status
  // filterField.status = status !== "" ? status : undefined;
  // filterField.winner = winner !== "" ? winner : undefined;
  // Filter by category
  // filter.category = category !== "" ? category : "";
  // Filter by all fields
  if (startDate && endDate) {
    // Add 1 day to endDate
    const endDateWithAddedDay = new Date(endDate);
    endDateWithAddedDay.setDate(endDateWithAddedDay.getDate() + 1);

    // Convert startDate and endDateWithAddedDay to ISO string
    filterField.startDate = startDate.toISOString();
    filterField.endDate = endDateWithAddedDay.toISOString();
  }

  if (affiliateId)
    filterFields.affiliateId = affiliateId.value;

  newQueryParams.filter = filter;
  newQueryParams.filterFields = filterFields;
  newQueryParams.filterField = filterField;
  return newQueryParams;
};




export function SubOperatorsFilter({ listLoading }) {
  // Affiliates UI Context
  const subOperatorsUIContext = useSubOperatorsUIContext();
  const options = useMemo(() => countryList().getData(), [])
  const [affiliates, setAffiliates] = useState([]);


  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  const subOperatorsUIProps = useMemo(() => {
    return {
      queryParams: subOperatorsUIContext.queryParams,
      setQueryParams: subOperatorsUIContext.setQueryParams,
    };
  }, [subOperatorsUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(subOperatorsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, subOperatorsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      subOperatorsUIProps.setQueryParams(newQueryParams);
    }
  };

  const getSelectEvent = async () => {
    try {
      const result = await GetAllAffiliates();

      if (result.success) {
        result.affiliates.map((affiliate) => {
          let value = { label: affiliate.name + " - " + affiliate.refId, value: affiliate._id };
          setAffiliates((affiliate) => affiliate.concat(value));
        });

      }
    } catch (e) {
      console.log("exception in get selectevent", e);
    }
  };
  useEffect(() => {
    getSelectEvent();
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          country: "",
          type: "",
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">

              <div className="col-lg-6">
                {<label>Date Filter</label>}
                <div className=" d-flex gap-2">
                  <DatePickerField
                    name='startDate'
                    // label='Start date'
                    value={values.startDate}
                    onChange={(e) => {
                      setFieldValue("startDate", e);
                    }}
                    placeholderText="Select Start date"
                  /><div className="mx-2"></div>
                  <DatePickerField
                    name="endDate"
                    selected={values.endDate}
                    onChange={(date) => {
                      setFieldValue("endDate", date);
                      handleSubmit();
                    }}
                    placeholderText="Select End date"
                    className="form-control"
                    minDate={values.startDate}
                    disabled={!values.startDate}
                  />
                </div>

              </div>
              <div className="col-lg-6 row">
                {user.role !== 'operator' && <div className='me-5 mr-5'>
                  {<label>Filter by operator</label>}<br />
                  <Select
                    className="category-select d-inline-block bg-white "
                    placeholder="Select an Operator"
                    options={affiliates}
                    name="affiliate"
                    onChange={(e) => {
                      setFieldValue("affiliateId", e);
                      handleSubmit()
                    }}
                    isClearable
                    value={values.affiliateId || ""}
                    isDisabled={affiliates.length === 0}
                    isSearchable={false}

                  />
                </div>}
                <div className="">
                  <small className="form-text text-muted">
                    <b>Search</b> in all fields
                  </small>
                  <input
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder="Search"
                    onBlur={handleBlur}
                    value={values.searchText}
                    onChange={(e) => {
                      setFieldValue("searchText", e.target.value);
                      handleSubmit();
                    }}
                  />

                </div>
              </div>

            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
