import React from "react";

export const DetailsColumnFormatter = (cellContent, row) => (
  <span>
    {row.walletAddress !== undefined ? (
      <span>{row.walletAddress.slice(0, 6) + "...." + row.walletAddress.toString().slice(-4)}</span>
    ) : (
      "--"
    )}
  </span>
);
