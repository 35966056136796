import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./SubOperatorIncomesUIHelpers";

const SubOperatorsUIContext = createContext();

export function useSubOperatorsUIContext() {
  return useContext(SubOperatorsUIContext);
}

export const SubOperatorsUIConsumer = SubOperatorsUIContext.Consumer;

export function SubOperatorsUIProvider({ subOperatorsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initSubOperator = {

    name: "",
    country: "",
    refId: ''

  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initSubOperator,
    newSubOperatorButtonClick: subOperatorsUIEvents.newSubOperatorButtonClick,
    openEditSubOperatorDialog: subOperatorsUIEvents.openEditSubOperatorDialog,
    openDeleteSubOperatorDialog: subOperatorsUIEvents.openDeleteSubOperatorDialog,
    openDeleteSubOperatorsDialog: subOperatorsUIEvents.openDeleteSubOperatorsDialog,
    openFetchSubOperatorsDialog: subOperatorsUIEvents.openFetchSubOperatorsDialog,
    openUpdateSubOperatorsStatusDialog: subOperatorsUIEvents.openUpdateSubOperatorsStatusDialog
  };

  return <SubOperatorsUIContext.Provider value={value}>{children}</SubOperatorsUIContext.Provider>;
}