import React from "react";
import { Link } from "react-router-dom";

export const VolumeColumnFormatter = (cellContent, row) => (
  <>
    
      <span style={{fontWeight:"300",color:"black"}} >
        {row?.fight?.fightNumber }
      </span>
  </>
);
