import React from "react";
import {
  SolanaStatusCssClasses,
} from "../../SolanasUIHelpers";

export const StatusColumnFormatter = (cellContent, row) => (
  <span
    className={`label label-lg label-light-${SolanaStatusCssClasses[row.status === "initialized" ? 0 : 1]
      } label-inline`}
  >
    {row.status === "initialized" ? "Initialized" : "Completed"}
  </span>
);
