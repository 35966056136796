import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";


export const DateColumnFormatter = (cellContent, row) => (
  <span>
    
      <span style={{ fontWeight: "300", color: "black" }}>
        {moment(row?.createdAt).utc().format("MMM,DD YYYY")}</span>
      <br />
  </span>

);
