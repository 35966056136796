import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { UsersFilter } from "./users-filter/UsersFilter";
import { EventsTable } from "./events-table/EventsTable";
import { UsersGrouping } from "./users-grouping/UsersGrouping";
import { useEventsUIContext } from "./UsersUIContext";
import { Link } from "react-router-dom";

export function UsersCard() {
  const usersUIContext = useEventsUIContext();
  const eventsUIProps = useMemo(() => {
    return {
      ids: usersUIContext.ids,
      queryParams: usersUIContext.queryParams,
      setQueryParams: usersUIContext.setQueryParams,
      newEventButtonClick: usersUIContext.newEventButtonClick,
      openDeleteEventsDialog: usersUIContext.openDeleteEventsDialog,
      openEditEventPage: usersUIContext.openEditEventPage,
      openUpdateEventsStatusDialog:
      usersUIContext.openUpdateEventsStatusDialog,
      openFetchEventsDialog: usersUIContext.openFetchEventsDialog,
      openVerifyEventDialog: usersUIContext.openVerifyEventDialog,
      openeventShowsite: usersUIContext.openeventShowsite,
      openeventHidesite: usersUIContext.openeventHidesite,
    };
  }, [usersUIContext]);

  return (
    <Card>
      <CardHeader title="Players list">
        <CardHeaderToolbar>
          {/* <Link
            // type="button"
            className="btn btn-primary"
            // onClick={eventsUIProps.newEventButtonClick}
          >
            Add Event
          </Link> */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <UsersFilter />
        {eventsUIProps.ids.length > 0 && (
          <>
            <UsersGrouping />
          </>
        )}
        <EventsTable />
      </CardBody>
    </Card>
  );
}
