/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { DropdownCustomToggler, DropdownMenu2 } from "../../dropdowns";
import { useHtmlClassService } from "../../../layout";
import {
  GetDepositcount, GetIncome, GetWithdrawcount

} from "../../../../services/ApiServices";
import { shallowEqual, useSelector } from "react-redux";

export function MixedWidget4({ className, chartColor = "danger" }) {
  const uiService = useHtmlClassService();
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsThemeBaseColor: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${chartColor}`
      ),
      colorsThemeLightColor: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${chartColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, chartColor]);

  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_4_chart");

    if (!element) {
      return;
    }

    const options = getChartOption(layoutProps);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps]);
  const [loading, setLoading] = useState(false);
  const [income, setIncome] = useState({});
  const [desposit, setdesposit] = useState({});
  const [withdraw, setwithdraw] = useState({});

  const getTranscation = async () => {
    setLoading(true);
    let result;
    try {
      result = await GetDepositcount(authToken);
      if (result.success) {
        setdesposit(result);
        setLoading(false)
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getTranscation();

  }, []);
  const getIncome = async () => {
    let result;
    try {
      result = await GetIncome(authToken);
      if (result.success) {
        setIncome(result.income);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getIncome();

  }, []);

  const getWithdraw = async () => {
    setLoading(true);
    let result;
    try {
      result = await GetWithdrawcount(authToken);
      if (result.success) {
        setwithdraw(result);
        setLoading(false)


      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getWithdraw();

  }, []);

  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div
        className={`card card-custom bg-radial-gradient-danger ${className}`}
      >
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body d-flex  flex-column p-0">
          <div className="d-flex flex-column gap-5 align-items-center justify-content-center ">

            <h1 className="card-title text-center font-weight-bolder text-white">
              OPERATOR TOTAL EARNINGS
            </h1>
            <h3 className="text-center font-weight-bolder text-white">
              USDT:   {income?.stake && income?.cockpick ? (Math.abs(income?.stake + income?.cockpick)).toFixed(2) : "0.00"}
            </h3>
          </div>

        </div>
        {/* begin::Chart */}
        <div
          id="kt_mixed_widget_6_chart"
          data-color={chartColor}
          style={{ height: "200px" }}
        />
        {/* end::Chart */}
        {/* begin::Stats */}
        <div className="card-spacer bg-white card-rounded flex-grow-1">
          {/* begin::Row */}
          <div className="row m-0">
            <div className="col px-8 py-6 mr-8">
              <div className="font-size-sm text-muted font-weight-bold">
                Staking Income
              </div>
              <div className="font-size-h4 font-weight-bolder">USDT: {income?.stake ? Math.abs(income?.stake).toFixed(2) : "0.00"}</div>
            </div>
            <div className="col px-8 py-6">
              <div className="font-size-sm text-muted font-weight-bold">
                Total LAst 2 Digit Income
              </div>
              <div className="font-size-h4 font-weight-bolder">USDT: {income?.cockpick ? Math.abs(income?.cockpick).toFixed(2) : "0.00"}</div>

            </div>
          </div>
          {/* end::Row */}
          {/* begin::Row */}
          <div className="row m-0">
            <div className="col px-8 py-6 mr-8">
              <div className="font-size-sm text-muted font-weight-bold">
                Total Deposits
              </div>
              <div className="font-size-h4 font-weight-bolder">${desposit?.overallAmount?.toFixed(2)}</div>
            </div>
            <div className="col px-8 py-6">
              <div className="font-size-sm text-muted font-weight-bold">
                Total Withdraw
              </div>
              <div className="font-size-h4 font-weight-bolder">${withdraw?.overallWithdraw?.toFixed(2)}</div>
            </div>
          </div>
          {/* end::Row */}
        </div>
        {/* end::Stats */}
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}
    </>
  );
}

function getChartOption(layoutProps) {
  const options = {
    series: [
      {
        name: "Net Profit",
        data: [35, 65, 75, 55, 45, 60, 55],
      },
      {
        name: "Revenue",
        data: [40, 70, 80, 60, 50, 65, 60],
      },
    ],
    chart: {
      type: "bar",
      height: "200px",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: ["30%"],
        endingShape: "rounded",
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      labels: {
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    fill: {
      type: ["solid", "solid"],
      opacity: [0.25, 1],
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
      y: {
        formatter: function (val) {
          return "$" + val + " thousands";
        },
      },
      marker: {
        show: false,
      },
    },
    colors: ["#ffffff", "#ffffff"],
    grid: {
      borderColor: layoutProps.colorsGrayGray200,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        left: 20,
        right: 20,
      },
    },
  };
  return options;
}
