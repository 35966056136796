import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { WithdrawStatusCssClasses } from "../WithdrawsUIHelpers";
import * as actions from "../../../_redux/withdraws/withdrawsActions";
import { useWithdrawsUIContext } from "../WithdrawsUIContext";
import { useToasts } from 'react-toast-notifications';

const selectedWithdraw = (entities, id) => {
  let withdraw;
  // ids.forEach((id) => {
  if (entities?.length)
    withdraw = entities.find((el) => el._id === id);
  // if (withdraw) {
  //   _withdraw.push(withdraw);
  // }
  // });
  return withdraw;
};

export function WithdrawUpdateStatusDialog({ show, onHide }) {
  // Withdraw UI Context
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  const withdrawUIContext = useWithdrawsUIContext();
  const withdrawUIProps = useMemo(() => {
    return {
      id: withdrawUIContext.id,
      setId: withdrawUIContext.setId,
      queryParams: withdrawUIContext.queryParams,
    };
  }, [withdrawUIContext]);

  // Withdraw Redux state
  const { withdraw, isLoading } = useSelector(
    (state) => ({
      withdraw: selectedWithdraw(state.withdraws?.entities, withdrawUIProps.id),
      isLoading: state.withdraws.actionsLoading,
    }),
    shallowEqual
  );
  console.log(withdraw, withdrawUIProps.id);
  // if there weren't selected withdraw we should close modal
  useEffect(() => {
    if (!withdrawUIProps.id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawUIProps.id]);

  const [text, setText] = useState('');
  const { addToast } = useToasts();

  const dispatch = useDispatch();
  const updateStatus = () => {
    if (!text)
      return    addToast('Please enter TX hash', {
        appearance: "error",
        autoDismiss: "true",
      });

    // server request for updateing withdraw by ids
    dispatch(actions.updateWithdrawsStatus(withdrawUIProps.id, { status: 'completed', txHash: text }, authToken)).then(
      () => {
        // refresh list after deletion
        dispatch(actions.fetchWithdraws(withdrawUIProps.queryParams, authToken)).then(
          () => {
            // clear selections list
            withdrawUIProps.setId([]);
            // closing delete modal
             addToast('Withdraw completed', {
              appearance: "success",
              autoDismiss: "true",
            });
            onHide();
          }
        );
      }
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton className="form">
        <Modal.Title id="example-modal-sizes-title-lg">
          Withdraw Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default"
        style={{ width: '90%' }}
      >
        {isLoading && (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-warning" />
          </div>
        )}
        <div className="list-timeline list-timeline-skin-light" style={{ padding: '0px' }}>
          <div className="list-timeline-items">
            <div className="list-timeline-item mb-3" key={withdraw?._id}>
              <div className="list-timeline-text" style={{ textAlign: 'center' }}>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%", }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Status </div>
                  <div
                    style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }}
                    className={`label label-lg padding-8 label-light-${WithdrawStatusCssClasses[withdraw?.status]} label-inline`}
                  >
                    {withdraw?.status}
                  </div>
                </div>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>First Name </div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }}  > {withdraw?.user?.firstName}</div>

                </div>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Last Name </div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }}  >{withdraw?.user?.lastName}</div>
                </div>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Email</div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }} > {withdraw?.user?.email}</div>
                </div>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Address</div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }} >{withdraw?.walletAddress}</div>
                </div>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Chain </div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }} > {withdraw?.chain}</div>
                </div>
                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }}>
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Amount </div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }} > {withdraw?.amount.toFixed(2)}</div>
                </div>

                <div style={{ display: 'flex', marginBottom: '10px', width: "80%" }} >
                  <div style={{ fontWeight: 'bold', width: "40%", textAlign: 'left' }}>Tx Hash</div>
                  <div style={{ fontWeight: '16px', width: "40%", textAlign: 'left' }} >
                    <input
                      type="text"
                      className="form-control"
                      name="searchText"
                      placeholder="Enter TX Hash"
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                      style={{ width: '320px' }}

                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer >

        <div className="form-group">
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Close
          </button>
          <> </>
          <button
            type="button"
            onClick={updateStatus}
            className="btn btn-primary btn-elevate"
          >
            Mark as Completed
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
