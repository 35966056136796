import {createSlice} from "@reduxjs/toolkit";

const initialFightsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  stakeCount: 0,
  rewardCount: 0,
  entities: null,
  fightForEdit: undefined,
  fight: undefined,
  lastError: null,
  stake:null,
  reward:null,  
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const fightsSlice = createSlice({
  name: "fights",
  initialState: initialFightsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getFightById
    fightFetched: (state, action) => {
      state.actionsLoading = false;
      state.fightForEdit = action.payload.fight;
      state.fight = action.payload.fight;
      state.error = null;
    },
    idoFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findFights
    fightsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    stakeFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.stake = entities;
      state.stakeCount = totalCount;
    },
    rewardFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.reward = entities;
      state.rewardCount = totalCount;
    },
    // createFight
    fightCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.fight);
    },
    // updateFight
    fightUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.fight.id) {
          return action.payload.fight;
        }
        return entity;
      });
    },
    // deleteFight
    fightDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteFights
    fightsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // fightsUpdateState
    fightsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
