import {createSlice} from "@reduxjs/toolkit";

const initialCategoriesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  categoryForEdit: undefined,
  category: undefined,
  lastError: null,
  commentsLoading: false,
  comments: null
};
export const callTypes = {
  list: "list",
  action: "action",
  comment:"comment"
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState: initialCategoriesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if(action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      }
      else{
        state.commentsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if(action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      }
      else{
        state.commentsLoading = true;
      }
    },
    // getCategoryById
    categoryFetched: (state, action) => {
      state.actionsLoading = false;
      state.categoryForEdit = action.payload.categoryForEdit;
      state.category = action.payload.category;
      state.error = null;
    },
    //Get Category Comments
    commentsFetched: (state, action) => {
      state.commentsLoading = false;
      state.totalCount=action.payload.totalCount
      state.comments = action.payload.comments;
      state.error = null;
    },
    // findCategories
    categoriesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
       // findCategoriesBy collection
       categoriesFetchedByCollection: (state, action) => {
        const { totalCount, entities } = action.payload;
        state.listLoading = false;
        state.error = null;
        state.entities = entities;
        state.totalCount = totalCount;
      },
    // createCategory
    categoryCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.category);
    },
    // updateCategory
    categoryUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.category.id) {
          return action.payload.category;
        }
        return entity;
      });
    },
    // deleteCategory
    categoryDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteCategories
    categoriesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // categoriesUpdateState
    categoriesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
