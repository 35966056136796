import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { EventsFilter } from "./events-filter/EventsFilter";
import { EventsTable } from "./events-table/EventsTable";
import { EventsGrouping } from "./events-grouping/EventsGrouping";
import { useEventsUIContext } from "./EventsUIContext";
import { Link } from "react-router-dom";

export function EventsCard() {
  const eventsUIContext = useEventsUIContext();
  const eventsUIProps = useMemo(() => {
    return {
      ids: eventsUIContext.ids,
      queryParams: eventsUIContext.queryParams,
      setQueryParams: eventsUIContext.setQueryParams,
      newEventButtonClick: eventsUIContext.newEventButtonClick,
      openDeleteEventsDialog: eventsUIContext.openDeleteEventsDialog,
      openEditEventPage: eventsUIContext.openEditEventPage,
      openUpdateEventsStatusDialog:
        eventsUIContext.openUpdateEventsStatusDialog,
      openFetchEventsDialog: eventsUIContext.openFetchEventsDialog,
      openVerifyEventDialog: eventsUIContext.openVerifyEventDialog,
      openeventShowsite: eventsUIContext.openeventShowsite,
      openeventHidesite: eventsUIContext.openeventHidesite,
    };
  }, [eventsUIContext]);

  return (
    <Card>
      <CardHeader title="Events list">
        <CardHeaderToolbar>
          <Link
            to="/cock-boxing/events/new"
            // type="button"
            className="btn btn-primary"
            // onClick={eventsUIProps.newEventButtonClick}
          >
            Add Event
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <EventsFilter />
        {eventsUIProps.ids.length > 0 && (
          <>
            <EventsGrouping />
          </>
        )}
        <EventsTable />
      </CardBody>
    </Card>
  );
}
