import React from "react";
import {
  CollectionStatusCssClasses,
  CollectionStatusTitles
} from "../../CollectionsUIHelpers";

export const StatusColumnFormatter = (cellContent, row) => (
  <span
    className={`label label-lg label-light-${
      CollectionStatusCssClasses[row.soldOut?1:0]
    } label-inline`}
  >
    {CollectionStatusTitles[row.soldOut?1:0]}
  </span>
);
