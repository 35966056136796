/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { assetActive, assetDeactive, Idodelete } from "../../../../../../services/ApiServices";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/collections/collectionsActions";
import { useCollectionsUIContext } from "../CollectionsUIContext";
import { useToasts } from "react-toast-notifications";
import * as actionss from "../../../_redux/IDOtoken/collectionsActions";

export function CollectionHide({ id, show, onHide }) {
  // Collections UI Context
  const collectionsUIContext = useCollectionsUIContext();
  const collectionsUIProps = useMemo(() => {
    return {
      setIds: collectionsUIContext.setIds,
      queryParams: collectionsUIContext.queryParams,
    };
  }, [collectionsUIContext]);

  // Collections Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.collections.actionsLoading }),
    shallowEqual
  );
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);
  const { addToast } = useToasts();

  const activeItem = async (id) => {
    let result;

    try {
      result = await assetDeactive(id);
    } catch (e) {
      console.log(e, id);
    }
    try {
      if (result) {
        // setIsLoading(false);
        // dispatch(actions.fetchCollections(collectionsUIProps.queryParams));
      dispatch(actionss.fetchIdoCollections("62e8b10e0e72ed8bc55a6964",collectionsUIProps.queryParams));
        collectionsUIProps.setIds([]);
        onHide();
        return addToast("Successfully Deactivated", {
          appearance: "success",
          autoDismiss: "true",
        });
        console.log(result,"rt");
        // fetchData();
        // Swal.fire(
        //   "Deactivated!",
        //   "Event has been Deactivated Successfully.",
        //   "info"
        // );
      }
    } catch (e) {
      //   setIsLoading(false);
      console.log(e, id);
    }
  };

  const VerifyCollection = () => {
    // server request for deleting collection by id
    dispatch(actions.verifyCollection(id,authToken)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchCollections(collectionsUIProps.queryParams));
      // clear selections list
      collectionsUIProps.setIds([]);
      // closing delete modal
      onHide();
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
        Hide in Site
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>Are you sure you want to hide this assets in site ?</span>
        )}
        {isLoading && <span>Collection is removing...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={()=> activeItem(id)}
            className="btn btn-dark btn-elevate"
          >
          Yes
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
