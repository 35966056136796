import * as requestFromServer from "./collectionsCrud";
import { collectionsSlice, callTypes } from "./collectionsSlice";
import { GetAsset, GetCollection, GetCollections, GetIdoToken, VerifyCollection } from "../../../../../services/ApiServices";
const { actions } = collectionsSlice;

export const fetchCollections = (queryParams) => async (dispatch) => {
  // let query = "?";
  // let body = { sort: "asc" };
  // for (const key in queryParams) {
  //   switch (key) {
  //     case "sortOrder":
  //       query = query.concat(queryParams.sortField+"=" + queryParams.sortOrder);
  //       break;
  //     case "pageNumber":
  //       query = query.concat("&page=" + queryParams.pageNumber);
  //       break;
  //     case "pageSize":
  //       query = query.concat("&limit=" + queryParams.pageSize);
  //       break;

      // case "filter":
      //   if (queryParams.filter.model) body = { name: queryParams.filter.model };
      //   if (queryParams.filter.category)
      //     body = { category: queryParams.filter.category };
      //   break;
  //     default:
  //       break;
  //   }
  // }

  dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer
  let result;
  try {
    result = await GetCollection();
  } catch (error) {
    error.clientMessage = "Can't find collections";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      let totalCount = result.fights.totalDocs;
      let entities = result.fights.docs;
      console.log(entities)
      dispatch(actions.collectionsFetched({ totalCount, entities }));
    }
  } catch (error) {
    error.clientMessage = "Can't find collections";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const fetchCollection = (id) => async (dispatch) => {
  if (!id) {
    return dispatch(
      actions.collectionFetched({ collectionForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));

  let result;
  try {
    result = await GetCollections(id);
  } catch (error) {
    error.clientMessage = "Can't find collection";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      const collection = result.collection;
      dispatch(actions.collectionFetched({ collection: collection }));
    }
  } catch (error) {
    error.clientMessage = "Can't find collection";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};
export const verifyCollection = (id, token) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  // return requestFromServer
  let result;
  try {
    result = await VerifyCollection(id, token);
  } catch (error) {
    error.clientMessage = "Can't verify collection";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      dispatch(actions.collectionDeleted({ id }));
    }
  } catch (error) {
    error.clientMessage = "Can't verify collection";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }

  // .deleteCollection(id)
  // .then((response) => {
  //   dispatch(actions.collectionDeleted({ id }));
  // })
  // .catch((error) => {
  //   error.clientMessage = "Can't delete collection";
  //   dispatch(actions.catchError({ error, callType: callTypes.action }));
  // });
};
export const deleteCollection = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCollection(id)
    .then((response) => {
      dispatch(actions.collectionDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete collection";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createCollection = (collectionForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCollection(collectionForCreation)
    .then((response) => {
      const { collection } = response.data;
      dispatch(actions.collectionCreated({ collection }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create collection";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCollection = (collection) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCollection(collection)
    .then(() => {
      dispatch(actions.collectionUpdated({ collection }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update collection";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCollectionsStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForCollections(ids, status)
    .then(() => {
      dispatch(actions.collectionsStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update collections status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCollections = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCollections(ids)
    .then(() => {
      dispatch(actions.collectionsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete collections";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
