/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink, Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { LOGO } from "../../../../../Config/config";
import * as auth from "../../../../../app/modules/Auth/_redux/authRedux";
import { shallowEqual, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler } from "../../../../_partials/dropdowns";
import { DropdownMenu7 } from "../../../../_partials/dropdowns/DropdownMenu7";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );
  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <Link to="/" className="flex-column-auto pb-lg-0 pb-10">
          <img
            alt="Logo"
            className="d-flex mx-auto mb-5"
            src={toAbsoluteUrl(LOGO ? LOGO : "/media/logos/emporium-logo.png")}
            style={{ width: "80%" }}
          />
        </Link>
        {(user.role === 'admin') && <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>}



        <li className="menu-section ">
          <h4 className="menu-text">Applications</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/* eCommerce */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >

        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/cock-boxing/events">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Layout/Layout-4-blocks.svg"
                )}
              />
            </span>
            <span className="menu-text">Digital Gaming</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {(user.role !== 'withdraw' && user.role !== 'operator') && <li
                className={`menu-item ${getMenuItemActive(
                  "/cock-boxing/events"
                )},
                ${getMenuItemActive(`/cock-boxing/events`)}  `}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/cock-boxing/events">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Events</span>
                </NavLink>
              </li>}
              {(user.role !== 'withdraw' && user.role !== 'operator') && <li
                className={`menu-item ${getMenuItemActive(
                  "/cock-boxing/fights"
                )},
                ${getMenuItemActive(`/cock-boxing/fights`)}  `}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/cock-boxing/fights">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Fights</span>
                </NavLink>
              </li>}
              {(user.role === 'admin' || (user.role === 'operator' && !user.affiliate?.parent_operator)) && <li
                className={`menu-item ${getMenuItemActive(
                  "/cock-boxing/operators"
                )},
                ${getMenuItemActive(`/cock-boxing/operators`)}  `}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/cock-boxing/operators">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">{(user.role === "operator" && !user?.affiliate?.parent_operator) ? "Sub-Operator" : "Operator"}</span>
                </NavLink>
              </li>}
              {(user.role !== 'withdraw' && user.role !== 'declarator') && <li
                className={`menu-item ${getMenuItemActive(
                  "/cock-boxing/operatorincome"
                )},
                ${getMenuItemActive(`/cock-boxing/operatorincome`)}  `}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/cock-boxing/operatorincome">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text"> Operator Income</span>
                </NavLink>
              </li>}

              {/* {user.role === 'admin' && <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/cock-boxing/operators">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Layout/Layout-horizontal.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Operator</span>
                </NavLink>
                <div className="menu-submenu">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/cock-boxing/operators"
                      )},
                ${getMenuItemActive(`/cock-boxing/operators`)}  `}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/cock-boxing/operators">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Operator</span>
                      </NavLink>
                    </li>{" "}
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/cock-boxing/operatorincome"
                      )},
                ${getMenuItemActive(`/cock-boxing/operatorincome`)}  `}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/cock-boxing/operatorincome">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Operator Income</span>
                      </NavLink>
                    </li>{" "}

                  </ul>
                </div>
              </li>} */}
              {(user.role !== 'declarator' && user.role !== 'withdraw') && <>
                <li
                  className={`menu-item 
                ${getMenuItemActive(`/cock-boxing/players`)}  `}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/cock-boxing/players">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Players</span>
                  </NavLink>
                </li>{" "}

                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    "/",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="/cock-boxing/staking">
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Layout/Layout-grid.svg"
                        )}
                      />
                    </span>
                    <span className="menu-text">Staking</span>
                  </NavLink>
                  <div className="menu-submenu">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/cock-boxing/staking"
                        )},
                ${getMenuItemActive(`/cock-boxing/staking`)}  `}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/cock-boxing/staking">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Staking</span>
                        </NavLink>
                      </li>{" "}
                      {(user.role !== 'withdraw' && user.role !== 'declarator') && <li
                        className={`menu-item ${getMenuItemActive(
                          "/cock-boxing/cockPicks"
                        )},
                ${getMenuItemActive(`/cock-boxing/cockPicks`)}  `}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/cock-boxing/cockPicks">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Last 2 Digit</span>
                        </NavLink>
                      </li>}
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/cock-boxing/staked"
                        )},
                ${getMenuItemActive(`/cock-boxing/staked`)}  `}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/cock-boxing/staked">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Summary</span>
                        </NavLink>
                      </li>{" "}

                    </ul>
                  </div>
                </li>

                <li
                  className={`menu-item ${getMenuItemActive(
                    "/cock-boxing/deposits"
                  )},
                ${getMenuItemActive(`/cock-boxing/deposits`)}  `}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/cock-boxing/deposits">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Deposits</span>
                  </NavLink>

                </li>

                <li
                  className={`menu-item  ${getMenuItemActive(
                    "/cock-boxing/solana"
                  )},
            ${getMenuItemActive(`/cock-boxing/solana`)}  `}
                  aria-haspopup="true"
                  style={{ marginLeft: '12px' }}
                >
                  <NavLink className="menu-link" to="/cock-boxing/solana">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Solana Deposits</span>
                  </NavLink>
                </li>
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/cock-boxing/referrals"
                  )},
                ${getMenuItemActive(`/cock-boxing/referrals`)}  `}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/cock-boxing/referrals">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Referrals</span>
                  </NavLink>

                </li>
              </>}

              {(user.role !== 'declarator' && user.role !== 'operator') && <li
                className={`menu-item ${getMenuItemActive(
                  "/cock-boxing/withdraw"
                )},
                ${getMenuItemActive(`/cock-boxing/withdraw`)}  `}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/cock-boxing/withdraw">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Withdraw</span>
                </NavLink>
              </li>}
              {(user.role === 'admin') && <li
                className={`menu-item ${getMenuItemActive(
                  "/cock-boxing/mediaServices"
                )},
                ${getMenuItemActive(`/cock-boxing/mediaServices`)}  `}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/cock-boxing/mediaServices">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Media Services </span>
                </NavLink>
              </li>}

            </ul>
          </div>
          {/* <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">NFT</span>
                </span>
              </li>
              {/*begin::2 Level*/}
          {/* className={`nav-link btn btn-icon btn-clean btn-lg ${activeTab ===
                      tabs.tabId1 && "active"}`} 
              <li
                className={`menu-item ${getMenuItemActive("/nft/IDOlists")},
                ${getMenuItemActive(`/nft/IDOTokenLaunches/product/`)},
                ${getMenuItemActive("/nft/Idolists/new")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/nft/IDOlists">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">IDO Launches</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/nft/InitialNFTofferings"
                )},
                ${getMenuItemActive(`/nft/INOTokenLaunches/token/`)},
                ${getMenuItemActive("/nft/InitialNFTofferings/new")}
                ${getMenuItemActive("/nft/InitialNFTofferings/:id/edit")}
                ${getMenuItemActive("/nft/INOTokenLaunches/token/")}
                
                `}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/nft/InitialNFTofferings">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">INO Launches</span>
                </NavLink>
              </li>{" "}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/nft/financialAssets"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/nft/financialAssets">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Financial assets </span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive(
                  "/nft/ManagedFunds"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/nft/ManagedFunds">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Managed Funds </span>
                </NavLink>
              </li>
              {/* <li
                className={`menu-item ${getMenuItemActive("")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Crowd Funding</span>
                </NavLink>
              </li> 
            </ul>
          </div> */}
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}

        {/* <li
          className={`menu-item ${getMenuItemActive("/user-profile", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/user-profile">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              />
            </span>
            <span className="menu-text">User Profile</span>
          </NavLink>
        </li> */}

        {/*end::1 Level*/}

        {/* Custom */}
        {/* begin::section */}
        {/* <li className="menu-section ">
          <h4 className="menu-text">Custom</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li> */}
        {/* end:: section */}

        {/* Error Pages */}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/error",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/error">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Error-circle.svg")}
              />
            </span>
            <span className="menu-text">Error Pages</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Error Pages</span>
                </span>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/error/error-v1")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/error/error-v1">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Error Page - 1</span>
                </NavLink>
              </li>
             
              <li
                className={`menu-item ${getMenuItemActive("/error/error-v2")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/error/error-v2">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Error Page -2</span>
                </NavLink>
              </li>
           
              <li
                className={`menu-item ${getMenuItemActive("/error/error-v3")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/error/error-v3">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Error Page - 3</span>
                </NavLink>
              </li>
             
              <li
                className={`menu-item ${getMenuItemActive("/error/error-v4")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/error/error-v4">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Error Page - 4</span>
                </NavLink>
              </li>
        
              <li
                className={`menu-item ${getMenuItemActive("/error/error-v5")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/error/error-v5">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Error Page - 5</span>
                </NavLink>
              </li>
           
              <li
                className={`menu-item ${getMenuItemActive("/error/error-v6")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/error/error-v6">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Error Page - 6</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li> */}

        {user.role === "admin" && <li
          className={`menu-item ${getMenuItemActive("/nft/customers", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/nft/customers">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              />
            </span>
            <span className="menu-text">Users</span>
          </NavLink>
        </li>}
        <li
          className={`menu-item ${getMenuItemActive("/profile", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/profile">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/General/Settings-2.svg"
                )}
              />
            </span>
            <span className="menu-text">Profile</span>
          </NavLink>
        </li>
        {/* <li className="menu-link d-flex">
          <Dropdown className=" d-flex" alignRight>
            <Dropdown.Toggle
              className="border-0 d-flex align-items-center"
              variant="transparent"
              id="dropdown-toggle-top"
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
                />
              </span>
              <span className="menu-text">Settings</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <DropdownMenu7 />
            </Dropdown.Menu>
          </Dropdown>
        </li> */}
        <hr />

        <li
          className={`menu-item ${getMenuItemActive("/logout", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/logout">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Sign-out.svg")}
              />
            </span>
            <span className="menu-text">Sign Out</span>
          </NavLink>
          {/* <NavLink
              to="/logout"
              className="btn btn-light-primary font-weight-bold"
            >
              Sign Out
            </NavLink> */}
        </li>

      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
