import { useState, useEffect } from "react";
import "./index.css";
import Select from "react-select";
import { DatePicker, message, Modal } from "antd";

import { CreateEvent } from "../../../../../../services/ApiServices";
import { SyncOutlined } from "@ant-design/icons";
import * as actions from "../../../_redux/arenas/arenasActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
const CreateEventForm = ({ onHide, refresh }) => {
  const [usernameError, setUserNameError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [inputs, setInputs] = useState({
    minimumBet: 50,
    minimumLoad: 50,
    estimatedFights: 200,
  });

  const [linksValue, setLinksValue] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [categoryvalue, SetCategoryValue] = useState(null);

  const [arenas, setArenas] = useState([]);
  const [selectedArena, setSelectedArena] = useState("");
  const [date, setDate] = useState("");
  const dispatch = useDispatch();

  const { addToast } = useToasts();

  const dateFormat = "YYYY/MM/DD";
  useEffect(() => {}, [inputs]);
  const { currentState } = useSelector(
    (state) => ({ currentState: state.arenas }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  console.log(totalCount, entities, listLoading);
  useEffect(() => {
    dispatch(actions.fetchArenas());
  }, []);
  useEffect(() => {
    if (entities?.length) {
      setArenas([]);
      setInputs((values) => ({ ...values, arena: entities[0]._id }));

      entities.map((arena) => {
        let value = { label: arena.name, value: arena._id };
        setArenas((arenas) => arenas.concat(value));
      });
    }
  }, [entities]);
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  const success = (msg) => {
    message.success(msg);
  };

  const error = (msg) => {
    message.error(msg);
  };

  const warning = (msg) => {
    message.warning(msg);
  };

  const submit = async () => {
    setIsLoading(true);

    const post = {
      name: inputs.name,
      arena: inputs.arena,
      dateTime: date,
      minimumBet: inputs.minimumBet,
      minimumLoad: inputs.minimumLoad,
      mediaServer: "server",
      defaultBM: "defaul msg",
      BMAfterOpenBet: "after open bet",
      BMAfterClosedBet: "after closed bet",
      BMAfterDeclaration: "declaration",
      status: "soon",
    };

    let result;
    try {
      result = await CreateEvent(post, authToken);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("create data pool exception", e);
    }
    try {
      if (result.success) {
        console.log("result", result);
        refresh();
        addToast("New event created successfully", {
          appearance: "success",
          autoDismiss: "true",
        });
      }
    } catch (e) {
      setIsLoading(false);
      console.log("create data pool exception", e);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setLinksValue(false);

    if (linksValue !== true) {
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };

  const handleCategory = (arena) => {
    setSelectedArena(arena);
    const name = "arena";
    const value = arena.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleclose = () => {
    SetCategoryValue(null);
  };

  const validation = () => {
    if (!inputs.name) {
      setErrorMessage("Event name is required!");
    } else if (!date) {
      setErrorMessage("Date is required!");
    } else {
      submit();
    }
  };

  function showAddConfirm() {
    Modal.confirm({
      title: "Are you ready to Submit?",
      onCancel: "No",
      onOk() {
        submit();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }
  const onChangeDate = (date, dateString) => {
    console.log(date, dateString, moment().isAfter(dateString));

    setDate(moment.utc(dateString).format("x"));
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return (
      current &&
      current <
        moment()
          .subtract(1, "day")
          .endOf("day")
    );
  };
  return (
    <>
      <div>
        <div className="lg:w-9/12 lg:px-0 w-full px-4 mx-auto space-y-2 mt-2">
          <div>
            <p className="text-xs-secondary-500 pb-3 fw-lighter">
              <span className="text-danger">*</span> Required fields
            </p>

            <div>
              <label class="text-base fw-bold d-flex my-2">
                Name
                <span class="text-danger leading-10 text-base pl-1 ">*</span>
              </label>
              <input
                class="shadow text-input appearance-none border border-secondary h-12 rounded  w-100 py-2 px-3 text-secondary-700 leading-tight focus:outline-none focus:shadow-outline focus:outline-none"
                name="name"
                id="username"
                type="text"
                value={inputs.name || ""}
                onChange={handleChange}
                placeholder="Example: Battle"
                required
              />
              <div class="text-red-500 text-xs tracking-wide mt-1">
                {usernameError}
              </div>
            </div>
            <div className="my-2">
              <p className="fw-bold">
                Date
                <span class="text-danger leading-10 text-base pl-1 ">*</span>
              </p>

              <DatePicker
                // defaultValue={moment(new Date(), dateFormat)}

                format={dateFormat}
                disabledDate={disabledDate}
                onChange={onChangeDate}
              />
            </div>
            <div>
              <div className="space-y-3">
                <p className="fw-bold">Arena</p>
                <div class="d-lg-flex ">
                  <div>
                    {" "}
                    <Select
                      className="category-select d-inline-block bg-white mb-4"
                      placeholder="Arena"
                      options={arenas}
                      name="arena"
                      onChange={handleCategory}
                      value={selectedArena || arenas[0]}
                      isDisabled={arenas.length == 0}
                      isSearchable={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <label class="text-base fw-bold d-flex my-2">Minimum Load</label>
              <input
                class="shadow text-input appearance-none border border-secondary h-12 rounded  w-100 py-2 px-3 text-secondary-700 leading-tight focus:outline-none focus:shadow-outline focus:outline-none"
                name="minimumLoad"
                // id="username"
                type="number"
                value={inputs.minimumLoad}
                onChange={handleChange}
                placeholder="10"
                required
              />
              <div class="text-red-500 text-xs tracking-wide mt-1">
                {usernameError}
              </div>
            </div>
            <div>
              <label class="text-base fw-bold d-flex my-2">Minimum Bet</label>
              <input
                class="shadow text-input appearance-none border border-secondary h-12 rounded  w-100 py-2 px-3 text-secondary-700 leading-tight focus:outline-none focus:shadow-outline focus:outline-none"
                name="minimumBet"
                // id="username"
                type="number"
                value={inputs.minimumBet}
                onChange={handleChange}
                placeholder="50"
                required
              />
              <div class="text-red-500 text-xs tracking-wide mt-1">
                {usernameError}
              </div>
            </div>
            <div>
              <label class="text-base fw-bold d-flex my-2">
                Estimated Fights
              </label>
              <input
                class="shadow text-input appearance-none border border-secondary h-12 rounded  w-100 py-2 px-3 text-secondary-700 leading-tight focus:outline-none focus:shadow-outline focus:outline-none"
                name="estimatedFights"
                // id="username"
                type="number"
                value={inputs.estimatedFights}
                onChange={handleChange}
                placeholder="200"
                required
              />
              <div class="text-red-500 text-xs tracking-wide mt-1">
                {usernameError}
              </div>
            </div>

            <div className="text-danger">{errorMessage}</div>

            <div className="d-flex justify-content-end gap-lg">
              <div>
                <button
                  type="button"
                  className="btn btn-outline-primary mt-4 d-flex align-items-center justify-content-center"
                  onClick={onHide}
                >
                  Cancel
                </button>
              </div>
              <div>
                {!isLoading ? (
                  <button
                    onClick={validation}
                    type="button"
                    className="btn btn-primary mt-4"
                  >
                    Create
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary mt-4 d-flex align-items-center justify-content-center"
                  >
                    <SyncOutlined spin style={{ marginRight: "5px" }} />
                    Create
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateEventForm;
