/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { GoUnverified, GoVerified } from "react-icons/go";
import { ImTrophy } from "react-icons/im";
import { BiTrophy } from "react-icons/bi";
import { Link } from "react-router-dom";
export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  {
    openDetailsDialog,
    openFightEndEditDialog,
    openFightBetEndEditDialog,
    ended,
    user,
  }
) => (
  <div className="d-flex justify-content-center align-items-center gap">
    {user.role !== 'operator' && <>  {row.isEnded || row.status === "finished" ? (
      <>
        <button className="btn btn-icon btn-light btn-hover-danger btn-sm">
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Stop.svg")} />
          </span>
        </button>
      </>
    ) : row.betStatus === "closed" ? (
      <>
        <button
          className="btn btn-icon btn-light btn-hover-warning btn-sm"
          onClick={() => openFightBetEndEditDialog(row.id, "open")}
        >
          <span className="svg-icon svg-icon-md svg-icon-warning">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Play.svg")} />
          </span>
        </button>
      </>
    ) : (
      <>

        <button
          className="btn btn-icon btn-light btn-hover-secondary btn-sm"
          onClick={() => openFightBetEndEditDialog(row.id, "close")}
        >
          <span className="svg-icon svg-icon-md svg-icon-info">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Pause.svg")} />
          </span>
        </button>
      </>
    )}</>}
    {/* {user.superAdmin && ( */}
    {user.role !== 'operator' && <button
      className="btn btn-icon btn-light btn-hover-primary btn-sm"
      onClick={() => openDetailsDialog(row.id)}
    >
      <span className="svg-icon svg-icon-md svg-icon-primary">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
      </span>
    </button>}
    {/* )} */}
    {/* <Link to={"/cock-boxing/fight/edit/"+row._id}
      className="btn btn-icon btn-light btn-hover-primary btn-sm"
      // onClick={() => openDeleteCollectionDialog(row.id)}
    >
      <span className="svg-icon svg-icon-md svg-icon-primary">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
      </span>
    </Link> */}
    {user.role !== "affiliate" && <>{!row.isEnded ? (
      <button
        className="btn btn-icon btn-light btn-hover-primary btn-sm svgIcon"
        onClick={() => openFightEndEditDialog(row.id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary svgIcon">
          <ImTrophy className="text-primary svgIcon" />
        </span>
      </button
      >
    ) : (
      <button
        className="btn btn-icon btn-light btn-hover-danger btn-sm svgIcon"
        onClick={ended}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger svgIcon">
          {/* <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Flag.svg")} /> */}
          <BiTrophy className="text-danger svgIcon" />
        </span>
      </button>
    )}</>}
    {user.role === "affiliate" && '--'}
  </div>
);
