import axios from "axios";

export const CollectionS_URL = "api/Collections";

// CREATE =>  POST: add a new Collection to the server
export function createCollection(Collection) {
  return axios.post(CollectionS_URL, { Collection });
}

// READ
export function getAllCollections() {
  return axios.get(CollectionS_URL);
}

export function getCollectionById(CollectionId) {
  return axios.get(`${CollectionS_URL}/${CollectionId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCollections(queryParams) {
  return axios.post(`${CollectionS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateCollection(Collection) {
  return axios.put(`${CollectionS_URL}/${Collection.id}`, { Collection });
}

// UPDATE Status
export function updateStatusForCollections(ids, status) {
  return axios.post(`${CollectionS_URL}/updateStatusForCollections`, {
    ids,
    status
  });
}

// DELETE => delete the Collection from the server
export function deleteCollection(CollectionId) {
  return axios.delete(`${CollectionS_URL}/${CollectionId}`);
}

// DELETE Collections by ids
export function deleteCollections(ids) {
  return axios.post(`${CollectionS_URL}/deleteCollections`, { ids });
}
