import * as requestFromServer from "./eventsCrud";
import { eventsSlice, callTypes } from "./eventsSlice";
import { GetAsset, GetEvent, GetEvents, GetIdoToken, VerifyEvent } from "../../../../../services/ApiServices";
const { actions } = eventsSlice;

export const fetchEvents = (queryParams, token) => async (dispatch) => {

  let query = "?";
  let body = { sort: "asc" };
  for (const key in queryParams) {
    switch (key) {
      case "sortOrder":
        query = query.concat(queryParams.sortField + "=" + queryParams.sortOrder);
        break;
      case "pageNumber":
        query = query.concat("&page=" + queryParams.pageNumber);
        break;
      case "pageSize":
        query = query.concat("&limit=" + queryParams.pageSize);
        break;

      case "filter":
        if (queryParams.filter.model) body = { name: queryParams.filter.model };
        if (queryParams.filter.category)
          body = { category: queryParams.filter.category };
        break;
      default:
        break;
    }
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer
  let result;
  try {
    result = await GetEvents(query, token);
  } catch (error) {
    error.clientMessage = "Can't find events";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      let totalCount = result.events.totalDocs;
      let entities = result.events.docs;
      dispatch(actions.eventsFetched({ totalCount, entities }));
    }
  } catch (error) {
    error.clientMessage = "Can't find events";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const fetchEvent = (id,token) => async (dispatch) => {
  if (!id) {
    return dispatch(
      actions.eventFetched({ eventForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));

  let result;
  try {
    result = await GetEvent(id, token);
  } catch (error) {
    error.clientMessage = "Can't find event";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      const event = result.event;
      dispatch(actions.eventFetched({ event: event }));
    }
  } catch (error) {
    error.clientMessage = "Can't find event";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};
export const verifyEvent = (id, token) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  // return requestFromServer
  let result;
  try {
    result = await VerifyEvent(id, token);
  } catch (error) {
    error.clientMessage = "Can't verify event";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      dispatch(actions.eventDeleted({ id }));
    }
  } catch (error) {
    error.clientMessage = "Can't verify event";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }

  // .deleteEvent(id)
  // .then((response) => {
  //   dispatch(actions.eventDeleted({ id }));
  // })
  // .catch((error) => {
  //   error.clientMessage = "Can't delete event";
  //   dispatch(actions.catchError({ error, callType: callTypes.action }));
  // });
};
export const deleteEvent = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteEvent(id)
    .then((response) => {
      dispatch(actions.eventDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete event";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createEvent = (eventForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createEvent(eventForCreation)
    .then((response) => {
      const { event } = response.data;
      dispatch(actions.eventCreated({ event }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create event";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateEvent = (event) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateEvent(event)
    .then(() => {
      dispatch(actions.eventUpdated({ event }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update event";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateEventsStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForEvents(ids, status)
    .then(() => {
      dispatch(actions.eventsStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update events status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteEvents = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteEvents(ids)
    .then(() => {
      dispatch(actions.eventsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete events";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
