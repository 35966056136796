import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./DepositsUIHelpers";

const DepositsUIContext = createContext();

export function useDepositsUIContext() {
  return useContext(DepositsUIContext);
}

export const DepositsUIConsumer = DepositsUIContext.Consumer;

export function DepositsUIProvider({ depositsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);
  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    newDepositButtonClick: depositsUIEvents.newDepositButtonClick,
    openEditDepositPage: depositsUIEvents.openEditDepositPage,
    openDeleteDepositDialog: depositsUIEvents.openDeleteDepositDialog,
    openDepositEndEditDialog: depositsUIEvents.openDepositEndEditDialog,
    openDeleteDepositsDialog: depositsUIEvents.openDeleteDepositsDialog,
    openFetchDepositsDialog: depositsUIEvents.openFetchDepositsDialog,
    openDepositBetEndEditDialog: depositsUIEvents.openDepositBetEndEditDialog,
    openUpdateDepositsStatusDialog:
      depositsUIEvents.openUpdateDepositsStatusDialog,
      openVerifyDepositDialog:depositsUIEvents.openVerifyDepositDialog,
      initDeposit:depositsUIEvents.initDeposit,

  };

  return (
    <DepositsUIContext.Provider value={value}>
      {children}
    </DepositsUIContext.Provider>
  );
}
