import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { getUserByToken, login, logout } from "../_redux/authCrud";

import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { Getis2FAEnabled, PostVerify2FA } from "../../../../services/ApiServices";
import { useToasts } from "react-toast-notifications";

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { intl } = props;
  const dispatch = useDispatch();
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [buttonLoader, setButtonLoader] = useState(false);
  const { addToast } = useToasts();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(8, "Minimum 8 Characters")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const getUser = async (authToken) => {
    try {
      const user = await getUserByToken(authToken);
      if (user.success) {
        dispatch(props.fulfillUser(user.admin));
        dispatch(props.setUser(user.admin));
      } else {
        dispatch(props.logout());
      }
    } catch (error) {
      console.error(error);
      dispatch(props.logout());
    } finally {
    }
  };

  const onSubmitVerification = async () => {
    if (code.toString().length < 6) {
      return addToast('Verification code should be greater than or equal to 6', {
        appearance: "error",
        autoDismiss: "true",
      });
    }

    setButtonLoader(true);
    if (email === '') {
      return addToast('Try to login again', {
        appearance: "error",
        autoDismiss: "true",
      });
    }

    if (password === '') {
      return addToast('Try to login again', {
        appearance: "error",
        autoDismiss: "true",
      });
    }
    enableLoading();
    let result;
    try {
      result = await PostVerify2FA(email, code);
      if (result.success) {
        login(email, password).then(data => {
          disableLoading();
          getUser(data.user.accessToken);
          props.login(data.user.accessToken);
        });
      } else {
        addToast(result.error, {
          appearance: "error",
          autoDismiss: "true",
        });
        setButtonLoader(false);
      }
    } catch (e) {
      setButtonLoader(false);
      console.log(e);
      addToast(e.message, {
        appearance: "error",
        autoDismiss: "true",
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (!token) {
        setError('Please check I am not Robot');
        return;
      }
      enableLoading();
      setTimeout(async () => {
        const is2FaEnable = await getIsEnabled(values.email);
        login(values.email, values.password)
          .then(async (data) => {
            if (data) {
              if (is2FaEnable) {
                await logout();
                setEmail(values.email);
                setPassword(values.password);
                setIs2FAEnabled(true);
                disableLoading();
                setSubmitting(false);
                return;
              }
              disableLoading();
              getUser(data.user.accessToken);
              props.login(data.user.accessToken);
            } else {
              setStatus(
                intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_LOGIN",
                })
              );
              disableLoading();
              setSubmitting(false);
            }
          })
          .catch((e) => {
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
    },
  });

  const getIsEnabled = async (email) => {
    let resultTwo;
    try {
      resultTwo = await Getis2FAEnabled(email);
      if (resultTwo.success) {
        if (resultTwo.enabled) {
          props.setTFA(true);
          return true;
        } else {
          return false;
        }
      }
    } catch (e) {
      console.log('get is enable exception', e);
    }
  };

  return (
    <div className="login-form login-signin bg-white border p-10" id="kt_login_signin_form">
      {!is2FAEnabled ? (
        <>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
          </div>
          <form
            id="kt_login_signin_form"
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            onSubmit={formik.handleSubmit}
          >
            {/* begin: Alert */}
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{formik.status}</div>
              </div>
            )}
            {/* end: Alert */}
            {/* begin: Email */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Email"
                type="email"
                className={`form-control form-control-solid border h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            {/* end: Email */}

            {/* begin: Password */}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Password"
                type="password"
                className={`form-control form-control-solid border h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
            {/* end: Password */}
            <ReCAPTCHA
              sitekey={'6LfPyhIqAAAAAOvfn1pc4WkjVDKme6NsQEgbV6VM'}
              onChange={(e) => {
                setToken(e);
                setError('');
              }}
            />
            {error ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{error}</div>
              </div>
            ) : null}
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                type="submit"
                disabled={
                  formik.isSubmitting ||
                  !formik.isValid
                }
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                <span>Login</span>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </div>
          </form>
        </>
      ) : (
        <>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
              <FormattedMessage id="AUTH.TFA.TITLE" />
            </h3>
          </div>
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Code"
              type="text"
              className="form-control form-control-solid border h-auto py-5 px-6"
              name="code"
              onChange={(e) => setCode(e.target.value)}
            />
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                type="submit"
                disabled={buttonLoader}
                onClick={onSubmitVerification}
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                <span>Verify</span>
                {buttonLoader && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
