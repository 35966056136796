// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/withdraws/withdrawsActions";
import * as uiHelpers from "../WithdrawsUIHelpers";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useWithdrawsUIContext } from "../WithdrawsUIContext";
import { useToasts } from "react-toast-notifications";
export function WithdrawTable() {
  // Withdraw UI Context
  const withdrawUIContext = useWithdrawsUIContext();
  const { addToast } = useToasts();
  const withdrawUIProps = useMemo(() => {
    return {
      ids: withdrawUIContext.ids,
      setIds: withdrawUIContext.setIds,
      queryParams: withdrawUIContext.queryParams,
      setQueryParams: withdrawUIContext.setQueryParams,
      openEditWithdrawPage: withdrawUIContext.openEditWithdrawPage,
      openDeleteWithdrawDialog: withdrawUIContext.openDeleteWithdrawDialog,
      openVerifyWithdrawDialog: withdrawUIContext.openVerifyWithdrawDialog,
      openWithdrawEndEditDialog: withdrawUIContext.openWithdrawEndEditDialog,
      openWithdrawBetEndEditDialog:
        withdrawUIContext.openWithdrawBetEndEditDialog,
    };
  }, [withdrawUIContext]);
  // Getting curret state of withdraw list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.withdraws }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  // Withdraw Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    withdrawUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchWithdraws(withdrawUIProps.queryParams, authToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawUIProps.queryParams, dispatch]);
  // Table columns
  const ended = () => {
    addToast("Withdraw already ended", {
      appearance: "error",
      autoDismiss: "true",
    });
  };
  const serialNumer = (index) => {
    const startIndex = (withdrawUIProps.queryParams.pageNumber - 1) * withdrawUIProps.queryParams.pageSize;
    return startIndex + index + 1
  }
  const columns = [
    {
      text: "S.No",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return serialNumer(rowIndex);
      },
    },
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.DateColumnFormatter,
    },
    {
      dataField: "affiliateId",
      text: "Operator Id",
      formatter: columnFormatters.AffiliateColumnFormatter,

    },
    {
      dataField: "user.firstName",
      text: "Name",
      style: {
        minWidth: "100px",
        textAlign: "left"
      },
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        const { firstName, lastName } = row.user;
        return `${firstName} ${lastName}`;
      }
    },
    {
      dataField: "user.email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "100px",
        textAlign: "left"
      },
    },
    {
      dataField: "walletAddress",
      text: "Wallet Address",
      formatter: columnFormatters.DetailsColumnFormatter,
      style: {
        minWidth: "100px",
        textAlign: "center"
      },
    },
    {
      dataField: "chain",
      text: "Chain",
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: columnFormatters.AmountColumnFormatter,
      formatExtraData: "amountIn",
      style: {
        minWidth: "100px",
        textAlign: "right"
      },
    },
    // {
    //   dataField: "amount",
    //   text: "Amount Out",
    //   sort: true,
    //   formatter: columnFormatters.AmountColumnFormatter,
    //   formatExtraData: "amountOut"

    // },

    {
      dataField: "status",
      text: "Status",
      formatter: columnFormatters.StatusColumnFormatter,
    },
    // {
    //   dataField: "betStatus",
    //   text: "Bet Status",
    //   style:{
    //     textTransform: "capitalize",
    //   }
    // },
    // {

    // {
    //   dataField: "ticketNumber",
    //   text: "Ticket Number",
    // },

    // {
    //   dataField: "declareTime",
    //   text: "Declare Time",
    //   formatter: columnFormatters.DateColumnFormatter,
    // },

    // {
    //   dataField: "recordId",
    //   text: "Record Id",
    // },

    // {
    //   dataField: "active",
    //   text: "Active",
    //   sort: true,
    //   sortCaret: sortCaret,
    // },

    {
      dataField: "action",
      text: "Details",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openVerifyWithdrawDialog: withdrawUIProps.openVerifyWithdrawDialog,
        openUpdateWithdrawStatusDialog: withdrawUIContext.openUpdateWithdrawStatusDialog,
        ended: ended,
        setId: withdrawUIContext.setId
        // openVerifyWithdrawPage: withdrawUIProps.openVerifyWithdrawPage,
      },
      // classes: "text-right pr-0",
      // headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
        textAlign: "center",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: withdrawUIProps.queryParams.pageSize,
    page: withdrawUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"

                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="_id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  withdrawUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: withdrawUIProps.ids,
                //   setIds: withdrawUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
