import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { CustomersPage } from "./customers/CustomersPage";
import { CollectionsPage } from "./Collections/CollectionsPage";
import { ProductsPage } from "./products/ProductsPage";
import { CollectionProductsPage } from "./collectionProducts/ProductsPage";
import { ProductEdit } from "./products/product-edit/ProductEdit";
import { ProductDetails } from "./collectionProducts/product-details/ProductDetails";
import { LayoutSplashScreen, ContentRoute } from "../../../../_metronic/layout";
import { ReportsPage } from "./report/ReportsPage";
import { IODPage } from "./IDOList/CollectionsPage";

import { FinancialAssets } from "./financialAssets/CollectionsPage";
import { CollectionsCard } from "./Collections/CollectionsCard";
// import { IDOAddCard } from "./Collections/collection-new1/IDOaddCard";
import { CollectionsAddCard } from "./Collections/collection-new/CollectionAddCard";
import { CollectionEditCard } from "./Collections/collection-edit/collectionEdit";
// import { IDOAddCard } from "./financialAssets/collection-new/IDOaddCard";
// import { CollectionsAddCard } from "./IDOList/collection-new/CollectionAddCard";
import { IDOAddCard } from "./IDOList/collection-new ido/IDOaddCard";
import { IdoEditCard } from "./IDOList/collection-edit/idoEdit";
// import { CollectionsAddCard } from "./Collections/collection-new/CollectionAddCard";
// import { IDOAddCard } from "./financialAssets/collection-new/IDOaddCard";
// import { IdoEditCard } from "./Collections/collection-edit/idoEdit";
import { InitialNFTofferingsAddCard } from "./initaialNFTOfferings/collection-new/CollectionAddCard";
import { InoPage } from "./initaialNFTOfferings/CollectionsPage";
import { ManagedFunds } from "./managedFunds/CollectionsPage";
import { ManagedFundsAdd } from "./managedFunds/collection-new/IDOaddCard";
import { TokenPage } from "./tokenProducts/ProductsPage";
// import { FinancialAddCard } from "./Collections/collection-new/CollectionAddCard";
import { InoDetailPage } from "./inoproducts/ProductsPage";

import { InoEditCard } from "./initaialNFTOfferings/collection-edit/inoEdit";
import { ManageEditCard } from "./managedFunds/collection-edit/inoEdit";

import { FinancialAssetAddCard } from "./financialAssets/collection-new/financialAssetCard";
import { financialDetailPage } from "./financialProducts/ProductsPage";
import { managedDetailPage } from "./managedFundsProduct/ProductsPage";
// import {ManagedFunds} from "./managedFunds/CollectionsPage";
import { financialAssetEditCard } from "./financialAssets/collection-edit/financialAssetEdit";
import { shallowEqual, useSelector } from "react-redux";

export default function ECommercePage() {
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from eCommerce root URL to /customers */
          // <Redirect exact={true} from="/nft" to="/nft/customers" />
        }
        {user.role === "admin" && <ContentRoute path="/nft/customers" component={CustomersPage} />}
        <ContentRoute path="/nft/collections" component={CollectionsPage} />
        <ContentRoute
          path="/nft/collection/new"
          component={CollectionsAddCard}
        />
        <ContentRoute
          path="/nft/collection/edit/:id"
          component={CollectionEditCard}
        />
        <ContentRoute
          path="/nft/financialAssets/new"
          component={FinancialAssetAddCard}
        />
        <ContentRoute path="/nft/Idolists/new" component={IDOAddCard} />
        <ContentRoute path="/nft/IDOlists/:id/edit" component={IdoEditCard} />
        <ContentRoute path="/nft/InitialNFTofferings/:id/edit" component={InoEditCard} />
        <ContentRoute path="/nft/ManagedFunds/:id/edit" component={ManageEditCard} />

        <ContentRoute
          path="/nft/financialAsset/:id/edit"
          component={financialAssetEditCard}
        />
        <ContentRoute
          path="/nft/InitialNFTofferings/new"
          component={InitialNFTofferingsAddCard}
        />{" "}
        <ContentRoute
          path="/nft/ManagedFunds/new"
          component={ManagedFundsAdd}
        />
        <ContentRoute path="/nft/reports" component={ReportsPage} />
        <ContentRoute
          path="/nft/nftcollection/products/:id"
          component={CollectionProductsPage}
        />
        <ContentRoute
          path="/nft/INOTokenLaunches/token/:id"
          component={InoDetailPage}
        />
        <ContentRoute
          path="/nft/financialAssets/view/:id"
          component={financialDetailPage}
        />
        <ContentRoute
          path="/nft/managedFunds/view/:id"
          component={managedDetailPage}
        />
        <ContentRoute
          path="/nft/IDOTokenLaunches/product/:id"
          component={TokenPage}
        />
        <ContentRoute path="/nft/products/new" component={ProductEdit} />
        <ContentRoute path="/nft/products/:id/edit" component={ProductEdit} />
        <ContentRoute
          path="/nft/nftcollection/product/:id"
          component={ProductDetails}
        />
        <ContentRoute path="/nft/products" component={ProductsPage} />
        <ContentRoute path="/nft/IDOlists" component={IODPage} />
        <ContentRoute path="/nft/InitialNFTofferings" component={InoPage} />
        <ContentRoute path="/nft/ManagedFunds" component={ManagedFunds} />
        <ContentRoute path="/nft/financialAssets" component={FinancialAssets} />
      </Switch>
    </Suspense>
  );
}
