import * as requestFromServer from "./depositsCrud";
import { depositsSlice, callTypes } from "./depositsSlice";
import {
  GetAsset,
  GetDeposit,
  GetDeposits,
  GetIdoToken,
  VerifyDeposit,
} from "../../../../../services/ApiServices";
const { actions } = depositsSlice;

export const fetchDeposits = (queryParams, token) => async (dispatch) => {
  let query = "?";
  let body = { sort: {} };
  for (const key in queryParams) {
    switch (key) {
      case "sortOrder":
        body.sort[queryParams.sortField] = queryParams.sortOrder === "desc" ? -1 : 1;
        // query = query.concat(queryParams.sortField+"=" + queryParams.sortField);
        break;
      case "pageNumber":
        query = query.concat("&page=" + queryParams.pageNumber);
        break;
      case "pageSize":
        query = query.concat("&limit=" + queryParams.pageSize);
        break;
      case "filter":
        if (queryParams.filter) body.searchFields = queryParams.filter;
        break;
      case "filterField":
        if (queryParams.filterField) body.filterField = queryParams.filterField;
        break;
      case "filterFields":
        if (queryParams.filterFields) body.filterFields = queryParams.filterFields;
        break;
      default:
        break;
    }
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer
  let result;
  try {
    result = await GetDeposits(query, body, token);
  } catch (error) {
    error.clientMessage = "Can't find deposits";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      let totalCount = result.deposits.totalDocs;
      let entities = result.deposits.docs;
      dispatch(actions.depositsFetched({ totalCount, entities }));
    }
  } catch (error) {
    error.clientMessage = "Can't find deposits";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const fetchDeposit = (id) => async (dispatch) => {
  if (!id) {
    return dispatch(actions.depositFetched({ depositForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));

  let result;
  try {
    result = await GetDeposit(id);
  } catch (error) {
    error.clientMessage = "Can't find deposit";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      const deposit = result.deposit;
      dispatch(actions.depositFetched({ deposit: deposit }));
    }
  } catch (error) {
    error.clientMessage = "Can't find deposit";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};
export const verifyDeposit = (id, token) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  // return requestFromServer
  let result;
  try {
    result = await VerifyDeposit(id, token);
  } catch (error) {
    error.clientMessage = "Can't verify deposit";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      dispatch(actions.depositDeleted({ id }));
    }
  } catch (error) {
    error.clientMessage = "Can't verify deposit";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }

  // .deleteDeposit(id)
  // .then((response) => {
  //   dispatch(actions.depositDeleted({ id }));
  // })
  // .catch((error) => {
  //   error.clientMessage = "Can't delete deposit";
  //   dispatch(actions.catchError({ error, callType: callTypes.action }));
  // });
};
export const deleteDeposit = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteDeposit(id)
    .then((response) => {
      dispatch(actions.depositDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete deposit";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createDeposit = (depositForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createDeposit(depositForCreation)
    .then((response) => {
      const { deposit } = response.data;
      dispatch(actions.depositCreated({ deposit }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create deposit";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateDeposit = (deposit) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateDeposit(deposit)
    .then(() => {
      dispatch(actions.depositUpdated({ deposit }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update deposit";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateDepositsStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForDeposits(ids, status)
    .then(() => {
      dispatch(actions.depositsStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update deposits status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteDeposits = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteDeposits(ids)
    .then(() => {
      dispatch(actions.depositsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete deposits";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
