import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { OperatorsFilter } from "./operators-filter/OperatorsFilter";
import { OperatorsTable } from "./affiliates-table/OperatorsTable";
import { OperatorsGrouping } from "./operators-grouping/OperatorsGrouping";
import { useOperatorsUIContext } from "./OperatorsUIContext";
import { shallowEqual, useSelector } from "react-redux";

export function OperatorsCard() {
  const affiliatesUIContext = useOperatorsUIContext();
  const affiliatesUIProps = useMemo(() => {
    return {
      ids: affiliatesUIContext.ids,
      newAffiliateButtonClick: affiliatesUIContext.newAffiliateButtonClick,
    };
  }, [affiliatesUIContext]);
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );
  return (
    <Card>
      <CardHeader title={(user.role === "operator" && !user?.affiliate?.parent_operator) ? "Income from Direct Players" : 'Operator Income'} >
        <CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader>

      <CardBody>
        <OperatorsFilter />
        {affiliatesUIProps.ids.length > 0 && <OperatorsGrouping />}
        <OperatorsTable />
      </CardBody>
    </Card>
  );
}
