import axios from "axios";

export const WithdrawS_URL = "api/Withdraws";

// CREATE =>  POST: add a new Withdraw to the server
export function createWithdraw(Withdraw) {
  return axios.post(WithdrawS_URL, { Withdraw });
}

// READ
export function getAllWithdraws() {
  return axios.get(WithdrawS_URL);
}

export function getWithdrawById(WithdrawId) {
  return axios.get(`${WithdrawS_URL}/${WithdrawId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findWithdraws(queryParams) {
  return axios.post(`${WithdrawS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateWithdraw(Withdraw) {
  return axios.put(`${WithdrawS_URL}/${Withdraw.id}`, { Withdraw });
}

// UPDATE Status
export function updateStatusForWithdraws(ids, status) {
  return axios.post(`${WithdrawS_URL}/updateStatusForWithdraws`, {
    ids,
    status
  });
}

// DELETE => delete the Withdraw from the server
export function deleteWithdraw(WithdrawId) {
  return axios.delete(`${WithdrawS_URL}/${WithdrawId}`);
}

// DELETE Withdraws by ids
export function deleteWithdraws(ids) {
  return axios.post(`${WithdrawS_URL}/deleteWithdraws`, { ids });
}
