import React from "react";
import { Link } from "react-router-dom";


export const ImageColumnFormatter = (cellContent, row) => (
  <>
   
      <span style={{ fontWeight: "400", color: "black" }}  >{row?.name}</span>
  </>
);
