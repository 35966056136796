import axios from "axios";

export const ReferralS_URL = "api/Referrals";

// CREATE =>  POST: add a new Referral to the server
export function createReferral(Referral) {
  return axios.post(ReferralS_URL, { Referral });
}

// READ
export function getAllReferrals() {
  return axios.get(ReferralS_URL);
}

export function getReferralById(ReferralId) {
  return axios.get(`${ReferralS_URL}/${ReferralId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findReferrals(queryParams) {
  return axios.post(`${ReferralS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateReferral(Referral) {
  return axios.put(`${ReferralS_URL}/${Referral.id}`, { Referral });
}

// UPDATE Status
export function updateStatusForReferrals(ids, status) {
  return axios.post(`${ReferralS_URL}/updateStatusForReferrals`, {
    ids,
    status
  });
}

// DELETE => delete the Referral from the server
export function deleteReferral(ReferralId) {
  return axios.delete(`${ReferralS_URL}/${ReferralId}`);
}

// DELETE Referrals by ids
export function deleteReferrals(ids) {
  return axios.post(`${ReferralS_URL}/deleteReferrals`, { ids });
}
