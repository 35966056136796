import React from "react";
import { Route } from "react-router-dom";
import { SolanaLoadingDialog } from "./solanas-loading-dialog/SolanasLoadingDialog";
import { SolanaDeleteDialog } from "./solana-delete-dialog/SolanaDeleteDialog";
// import { SolanaDeleteDialog } from "./solanas-delete-dialog/SolanaDeleteDialog";
import { SolanasFetchDialog } from "./solanas-fetch-dialog/SolanasFetchDialog";
import { SolanaUpdateStatusDialog } from "./solanas-update-status-dialog/SolanasUpdateStatusDialog";
// import { SolanaVerifyDialog } from "./solanas-verify-dialog/solanaVerifyDialog";
import { SolanaCard } from "./SolanasCard";
import { SolanaUIProvider } from "./SolanasUIContext";
// import { SolanaEndEditBetStatusDialog } from "./solanas-edit-betstatus-dialog/SolanaEditBetStatusDialog";

export function SolanasPage({ history }) {
  const solanaUIEvents = {
    newSolanaButtonClick: () => {
      history.push("/cock-boxing/solana/new");
    },
    openSolanaBetEndEditDialog: (id) => {
      history.push(`/cock-boxing/solana/betend/${id}`);
    },
    openEditSolanaPage: (id) => {
      history.push(`/cock-boxing/solana/${id}/edit`);
    },
    openVerifySolanaDialog: (id) => {
      history.push(`/cock-boxing/solana/${id}/verify`);
    },
    openVerifySolanaDialog: (id) => {
      history.push(`/cock-boxing/solana/${id}/verify`);
    },
    openDeleteSolanaDialog: (id) => {
      history.push(`/cock-boxing/solana/${id}/delete`);
    },
    openDeleteSolanaDialog: () => {
      history.push(`/cock-boxing/solana/deleteSolana`);
    },
    openFetchSolanaDialog: () => {
      history.push(`/cock-boxing/solana/fetch`);
    },
    openUpdateSolanaStatusDialog: () => {
      history.push("/cock-boxing/solana/updateStatus");
    },
  };

  return (
    <SolanaUIProvider solanaUIEvents={solanaUIEvents}>
      <SolanaLoadingDialog />
      {/* <Route path="/cock-boxing/solana/deleteSolana">
        {({ history, match }) => (
          <SolanaDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/solana");
            }}
          />
        )}
      </Route> */}
      {/* <Route path="/cock-boxing/solana/:id/verify">
        {({ history, match }) => (
          <SolanaVerifyDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/solana");
            }}
          />
        )}
      </Route> */}
      <Route path="/cock-boxing/solana/:id/delete">
        {({ history, match }) => (
          <SolanaDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/solana");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/solana/fetch">
        {({ history, match }) => (
          <SolanasFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/solana");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/solana/updateStatus">
        {({ history, match }) => (
          <SolanaUpdateStatusDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/solana");
            }}
          />
        )}
      </Route>
      {/* <Route path='/cock-boxing/solana/betend/:id'>
        {({ history, match }) => (
          <SolanaEndEditBetStatusDialog
            id={match && match.params.id}
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/solana");
            }}
          />
        )}
      </Route> */}
      <SolanaCard />
    </SolanaUIProvider>
  );
}
