import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { SolanaStatusCssClasses } from "../SolanasUIHelpers";
import { useSolanasUIContext } from "../SolanasUIContext";

const selectedSolanas = (entities, ids) => {
  const _solana = [];
  ids.forEach((id) => {
    const solana = entities.find((el) => el.id === id);
    if (solana) {
      _solana.push(solana);
    }
  });
  return _solana;
};

export function SolanasFetchDialog({ show, onHide }) {
  // Solanas UI Context
  const solanaUIContext = useSolanasUIContext();
  const solanaUIProps = useMemo(() => {
    return {
      ids: solanaUIContext.ids,
      queryParams: solanaUIContext.queryParams,
    };
  }, [solanaUIContext]);

  // Solanas Redux state
  const { solana } = useSelector(
    (state) => ({
      solana: selectedSolanas(state.solanas.entities, solanaUIProps.ids),
    }),
    shallowEqual
  );

  // if there weren't selected ids we should close modal
  useEffect(() => {
    if (!solanaUIProps.ids || solanaUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solanaUIProps.ids]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Fetch selected elements
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="list-timeline list-timeline-skin-light padding-30">
          <div className="list-timeline-items">
            {solana.map((solana) => (
              <div className="list-timeline-item mb-3" key={solana.id}>
                <span className="list-timeline-text">
                  <span
                    className={`label label-lg label-light-${SolanaStatusCssClasses[solana.status]
                      } label-inline`}
                    style={{ width: "60px" }}
                  >
                    ID: {solana.id}
                  </span>{" "}
                  <span className="ml-5">
                    {solana.manufacture}, {solana.model}
                  </span>
                </span>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-primary btn-elevate"
          >
            Ok
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
