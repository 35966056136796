import { Route } from "react-router-dom";
import { FightsLoadingDialog } from "./fights-loading-dialog/FightsLoadingDialog";
import { FightDeleteDialog } from "./fight-delete-dialog/fightDeleteDialog";
import { FightsDeleteDialog } from "./fights-delete-dialog/FightsDeleteDialog";
import { FightsFetchDialog } from "./fights-fetch-dialog/FightsFetchDialog";
import { FightsUpdateStatusDialog } from "./fights-update-status-dialog/FightsUpdateStatusDialog";
import { FightsVerifyDialog } from "./fight-verify-dialog/fightVerifyDialog";
import { FightsCard } from "./FightsCard";
import { FightsUIProvider } from "./FightsUIContext";
import { FightEndEditDialog } from "./fight-editstatus-dialog/FightEditDialog";
import { FightEndEditBetStatusDialog } from "./fight-edit-betstatus-dialog/FightEditBetStatusDialog";
import { FightImportDialog } from "./fights-import-dialog/FightsImportDialog";

export function FightsPage({ history }) {
  const fightsUIEvents = {
    newFightButtonClick: () => {
      history.push("/cock-boxing/fights/new");
    },
    openDetailsDialog: (id) => {
      history.push(`/cock-boxing/fights/match/details/history/${id}`);
    },
    openImportDialog: () => {
      history.push(`/cock-boxing/fights/import`);
    },
    openFightEndEditDialog: (id) => {
      history.push(`/cock-boxing/fights/end/staking/${id}`);
    },
    openFightBetEndEditDialog: (id, action) => {
      history.push(`/cock-boxing/fights/${action}/${id}`);
    },
    openEditFightPage: (id) => {
      history.push(`/cock-boxing/fights/${id}/edit`);
    },
    openVerifyFightDialog: (id) => {
      history.push(`/cock-boxing/fights/${id}/verify`);
    },

    openDeleteFightDialog: (id) => {
      history.push(`/cock-boxing/fights/${id}/delete`);
    },
    openDeleteFightsDialog: () => {
      history.push(`/cock-boxing/fights/deleteFights`);
    },
    openFetchFightsDialog: () => {
      history.push(`/cock-boxing/fights/fetch`);
    },
    openUpdateFightsStatusDialog: () => {
      history.push("/cock-boxing/fights/updateStatus");
    },
  };

  return (
    <FightsUIProvider fightsUIEvents={fightsUIEvents}>
      <FightsLoadingDialog />
      <Route path="/cock-boxing/fights/deleteFights">
        {({ history, match }) => (
          <FightsDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/fights");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/fights/:id/verify">
        {({ history, match }) => (
          <FightsVerifyDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/fights");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/fights/:id/delete">
        {({ history, match }) => (
          <FightDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/fights");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/fights/updateStatus">
        {({ history, match }) => (
          <FightsUpdateStatusDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/fights");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/fights/end/staking/:id" exact>
        {({ history, match }) => (
          <FightEndEditDialog
            id={match && match.params.id}
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/fights");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/fights/:action/:id" exact>
        {({ history, match }) => (
          <FightEndEditBetStatusDialog
            id={match && match.params.id}
            action={match && match.params.action}
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/fights");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/fights/match/details/history/:id" exact>
        {({ history, match }) => (
          <FightsFetchDialog
            id={match && match.params.id}
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/fights");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/fights/import">
        {({ history, match }) => (
          <FightImportDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/fights");
            }}
          />
        )}
      </Route>
      <FightsCard />
    </FightsUIProvider>
  );
}
