import * as requestFromServer from "./referralsCrud";
import { referralsSlice, callTypes } from "./referralsSlice";
import {
  GetAsset,
  GetReferral,
  GetReferrals,
  // UpdateReferral,
  // VerifyReferral,
} from "../../../../../services/ApiServices";
const { actions } = referralsSlice;

export const fetchReferrals = (queryParams, token) => async (dispatch) => {
  let query = "?";
  let body = { sort: {} };

  for (const key in queryParams) {
    switch (key) {
      case "sortOrder":
        body.sort[queryParams.sortField] = queryParams.sortOrder === "desc" ? -1 : 1;
        // query = query.concat(queryParams.sortField+"=" + queryParams.sortField);
        break;
      case "pageNumber":
        query = query.concat("&page=" + queryParams.pageNumber);
        break;
      case "pageSize":
        query = query.concat("&limit=" + queryParams.pageSize);
        break;
      case "filter":
        if (queryParams.filter) body.searchFields = queryParams.filter;
        break;
      case "filterField":
        if (queryParams.filterField) body.filterField = queryParams.filterField;
        break;
      case "filterFields":
        if (queryParams.filterFields) body.filterFields = queryParams.filterFields;
        break;
      default:
        break;
    }
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer

  let result;
  try {
    result = await GetReferrals(query, body, token);
  } catch (error) {
    error.clientMessage = "Can't find referrals";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      let totalCount = result.referrals.totalDocs;
      let entities = result.referrals.docs;
      dispatch(actions.referralsFetched({ totalCount, entities }));
    }
  } catch (error) {
    error.clientMessage = "Can't find referrals";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};

export const fetchReferral = (id) => async (dispatch) => {
  if (!id) {
    return dispatch(actions.referralFetched({ referralForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));

  let result;
  try {
    result = await GetReferral(id);
  } catch (error) {
    error.clientMessage = "Can't find referral";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      const referral = result.referral;
      dispatch(actions.referralFetched({ referral: referral }));
    }
  } catch (error) {
    error.clientMessage = "Can't find referral";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};
export const verifyReferral = (id, token) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  // return requestFromServer
  let result;
  try {
    // result = await VerifyReferral(id, token);
  } catch (error) {
    error.clientMessage = "Can't verify referral";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      dispatch(actions.referralDeleted({ id }));
    }
  } catch (error) {
    error.clientMessage = "Can't verify referral";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }

  // .deleteReferral(id)
  // .then((response) => {
  //   dispatch(actions.referralDeleted({ id }));
  // })
  // .catch((error) => {
  //   error.clientMessage = "Can't delete referral";
  //   dispatch(actions.catchError({ error, callType: callTypes.action }));
  // });
};
export const deleteReferral = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteReferral(id)
    .then((response) => {
      dispatch(actions.referralDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete referral";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createReferral = (referralForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createReferral(referralsSlice)
    .then((response) => {
      const { referral } = response.data;
      dispatch(actions.referralCreated({ referral }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create referral";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateReferral = (referral) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateReferral(referral)
    .then(() => {
      dispatch(actions.referralUpdated({ referral }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update referral";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateReferralsStatus = (ids, status, token) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  let result;
  try {
    // result = await UpdateReferral(ids, status,token);
  } catch (error) {
    error.clientMessage = "Can't verify referral";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }

  try {
    if (result.success)
      dispatch(actions.referralsStatusUpdated({ ids, status }));
  }
  catch (error) {
    error.clientMessage = "Can't update referrals status";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  };
};

export const deleteReferrals = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteReferrals(ids)
    .then(() => {
      dispatch(actions.referralsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete referrals";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
