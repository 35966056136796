import axios from "axios";

export const EventS_URL = "api/Events";

// CREATE =>  POST: add a new Event to the server
export function createEvent(Event) {
  return axios.post(EventS_URL, { Event });
}

// READ
export function getAllEvents() {
  return axios.get(EventS_URL);
}

export function getEventById(EventId) {
  return axios.get(`${EventS_URL}/${EventId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findEvents(queryParams) {
  return axios.post(`${EventS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateEvent(Event) {
  return axios.put(`${EventS_URL}/${Event.id}`, { Event });
}

// UPDATE Status
export function updateStatusForEvents(ids, status) {
  return axios.post(`${EventS_URL}/updateStatusForEvents`, {
    ids,
    status
  });
}

// DELETE => delete the Event from the server
export function deleteEvent(EventId) {
  return axios.delete(`${EventS_URL}/${EventId}`);
}

// DELETE Events by ids
export function deleteEvents(ids) {
  return axios.post(`${EventS_URL}/deleteEvents`, { ids });
}
