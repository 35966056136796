import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";

export function AffiliateEditDialogHeader({ id }) {
  // Affiliates Redux state
  const { affiliateForEdit, actionsLoading } = useSelector(
    (state) => ({
      affiliateForEdit: state.affiliates.affiliateForEdit,
      actionsLoading: state.affiliates.actionsLoading,
    }),
    shallowEqual
  );

  const [title, setTitle] = useState("");
  // Title couting
  useEffect(() => {
    let _title = id ? "Edit Operator" : "Add Operator";
    // if (affiliateForEdit && id) {
    //   _title = `Edit Operator '${affiliateForEdit.firstName} ${affiliateForEdit.lastName}'`;
    // }

    setTitle(_title);
    // eslint-disable-next-line
  }, [affiliateForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg" className="mx-auto">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
