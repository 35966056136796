export const AffiliateStatusCssClasses = ["danger", "success", "info", ""];
export const AffiliateStatusTitles = ["Suspended", "Active", "Pending", ""];
export const AffiliateTypeCssClasses = ["success", "primary", ""];
export const AffiliateTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "50", value: 50 },
  { text: "100", value: 100 },
  { text: "200", value: 200 }
];
export const initialFilter = {
  filter: '',
  filterFiled: {
    startDate: '',
    endDate: ''
  },
  sortOrder: "desc", // asc||desc
  sortField: "createdAt",
  pageNumber: 1,
  pageSize: 50
};
