/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import generatePDF from 'react-to-pdf';
import ReactExport from "react-export-excel";
import moment from "moment";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { GetAllDeposits } from "../../../services/ApiServices";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
export function DropdownMenu5({ targetRef }) {
    const [deposits, setDeposits] = useState([]);


    const { authToken } = useSelector(
        ({ auth }) => ({
            authToken: auth.authToken,
        }),
        shallowEqual
    );

    const getDeposit = useCallback(async () => {
        let result;
        try {
            result = await GetAllDeposits(authToken);
            if (result.success)
                setDeposits(result.transactions);
        }
        catch (e) {
            console.log(e);
        }
    }, [authToken])
    useEffect(() => {
        getDeposit()
    }, [getDeposit])
    const generatePdf = () => {
        const doc = new jsPDF();

        // Example data format
        const tableColumn = ["Operator Id", "First Name", "Last Name", "Email", "Date", "Amount", "Trasaction Hash"];
        const tableRows = [];

        deposits.forEach(item => {
            const itemData = [
                item.operatorId, // assuming 'name' is a key in your data object
                item.firstName, // assuming 'description' is another key in your data object
                item.lastName, // assuming 'description' is another key in your data object
                item.email,
                moment(item.createdAt).format('DD/MM/YYYY'),
                '$ ' + item.amount,
                item.txHash
            ];
            tableRows.push(itemData);
        });

        // Start the table
        doc.autoTable(tableColumn, tableRows, {
            startY: 20,
            styles: { fontSize: 6 },
        });

        // Save the generated PDF
        doc.save('deposits.pdf');
    };
    return <>
        {/*begin::Navigation*/}
        <ul className="navi navi-hover py-5">
            <li className="navi-item">
                <ExcelFile element={<div href="#" className="navi-link">
                    <span className="navi-icon"><i className="flaticon2-file"></i></span>
                    <span className="navi-text">Excel</span>
                </div>}>
                    <ExcelSheet data={deposits} name="Deposits">
                        <ExcelColumn label="First Name" value={(col) => {
                            return col.firstName
                        }} />
                        <ExcelColumn label="Last Name" value={(col) => col.lastName} />
                        <ExcelColumn label="Email" value={(col) => col.email} />
                        <ExcelColumn label="Operator" value={(col) => col.operatorId ? col.operatorId : '-'} />
                        <ExcelColumn label="Amount" value={(col) => '$' + col.amount?.toFixed(2)} />
                        <ExcelColumn label="TX Hash" value={(col) => col.txHash} />
                        <ExcelColumn label="Date" value={(col) => moment(col.date).format('DD/MM/YYYY')} />
                    </ExcelSheet>

                </ExcelFile>

            </li>
            <li className="navi-item">
                <div href="#" className="navi-link"
                    onClick={() => generatePdf()}
                >
                    <span className="navi-icon"><i className="flaticon2-crisp-icons"></i></span>
                    <span className="navi-text">PDF</span>
                </div>
            </li>

        </ul>
        {/*end::Navigation*/}

    </>
}
