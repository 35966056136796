/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Link } from "react-router-dom";

export const TitleColumnFormatter = (cellContent, row) => (
  <Link
    to={"/nft/financialAssets/view/" + row._id}
    className="d-flex justify-content-start align-items-center gap text-primary"
    styles={{ paddingLeft: "10px" }}
  >
    <img
      src={row.assetsId?.previewImage}
      height="65px"
      width="65px"
      style={{ borderRadius: "50%", objectFit: "cover" }}
    />

    <div className="text-primary"> {row.title}</div>
  </Link>
);
