import * as requestFromServer from "./categoriesCrud";
import { categoriesSlice, callTypes } from "./categoriesSlice";
import {
  GetAsset,
  GetAssets,
  GetAssetsByCollection,
  GetCategories,
  GetComments,
} from "../../../../../services/ApiServices";
const { actions } = categoriesSlice;

export const fetchCategories = (queryParams) => async (dispatch) => {
  
  dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer
  let result;
  try {
    result = await GetCategories();
  } catch (error) {
    error.clientMessage = "Can't find categories";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      let totalCount = result.assets.totalDocs;
      let entities = result.assets.docs;
      dispatch(actions.categoriesFetched({ totalCount, entities }));
    }
  } catch (error) {
    error.clientMessage = "Can't find categories";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};
export const fetchCategoriesByCollection = (id, queryParams) => async (
  dispatch
) => {
  let query = "?";
  let body = {};
  for (const key in queryParams) {
    switch (key) {
      case "pageNumber":
        query = query.concat("page=" + queryParams.pageNumber);
        console.log(query);
        break;
      case "pageSize":
        query = query.concat("&limit=" + queryParams.pageSize);
        console.log(query);
        break;
      case "filter":
        if (queryParams.filter.model) body = { name: queryParams.filter.model };
        break;
      default:
        break;
    }
  }
  dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer
  let result;
  try {
    result = await GetAssetsByCollection(id, query,body);
  } catch (error) {
    error.clientMessage = "Can't find categories";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
  try {
    if (result.success) {
      let totalCount = result.assets.totalDocs;
      let entities = result.assets.docs;
      dispatch(actions.categoriesFetchedByCollection({ totalCount, entities }));
    }
  } catch (error) {
    error.clientMessage = "Can't find categories";
    dispatch(actions.catchError({ error, callType: callTypes.list }));
  }
};
export const fetchCategory = (id) => async(dispatch) => {
  if (!id) {
    return dispatch(actions.categoryFetched({ category: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  // return requestFromServer
  let result;
  try {
    result = await GetAsset(id);
  } catch (error) {
    error.clientMessage = "Can't find category";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
  try {
    if (result.success) {
      const category = result.asset;
      dispatch(actions.categoryFetched({ category: category }));
    }
  } catch (error) {
    error.clientMessage = "Can't find category";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
  }

    
};
export const fetchComments = (id) => async(dispatch) => {
  if (!id) {
    return dispatch(actions.commentsFetched({ comments: null }));
  }

  dispatch(actions.startCall({ callType: callTypes.comment }));
  // return requestFromServer
  let result;
  try {
    result = await GetComments(id);
  } catch (error) {
    error.clientMessage = "Can't find comments";
    dispatch(actions.catchError({ error, callType: callTypes.comment }));
  }
  try {
    if (result.success) {
      console.log(result);
      const comments = result.comments.docs;
      let totalCount = result.comments.totalDocs;
      dispatch(actions.commentsFetched({ comments: comments,totalCount:totalCount }));
    }
  } catch (error) {
    error.clientMessage = "Can't find comments";
      dispatch(actions.catchError({ error, callType: callTypes.comment }));
  }

    
};
export const deleteCategory = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCategory(id)
    .then((response) => {
      dispatch(actions.categoryDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete category";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createCategory = (categoryForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCategory(categoryForCreation)
    .then((response) => {
      const { category } = response.data;
      dispatch(actions.categoryCreated({ category }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create category";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCategory = (category) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCategory(category)
    .then(() => {
      dispatch(actions.categoryUpdated({ category }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update category";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCategoriesStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForCategories(ids, status)
    .then(() => {
      dispatch(actions.categoriesStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update categories status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCategories = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCategories(ids)
    .then(() => {
      dispatch(actions.categoriesDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete categories";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
