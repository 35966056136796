/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCollectionsUIContext } from "../CollectionsUIContext";
import * as actions from "../../../_redux/category/categoriesActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

const prepareFilter = (queryParams, values) => {
 
  const { status, category, searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  // Filter by status
  filter.status = status !== "" ? +status : undefined;
  // Filter by category
  filter.category = category !== "" ? category : "";
  // Filter by all fields
  filter.model = searchText;
  if (searchText) {
    filter.manufacture = searchText;
    filter.VINCode = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};




export function CollectionsFilter({ }) {
  // Collections UI Context
  const collectionsUIContext = useCollectionsUIContext();
  const collectionsUIProps = useMemo(() => {
    return {
      setQueryParams: collectionsUIContext.setQueryParams,
      queryParams: collectionsUIContext.queryParams,
    };
  }, [collectionsUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.collections }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(actions.fetchCategories());
  // }, [ dispatch]);


  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(collectionsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, collectionsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      collectionsUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          status: "", // values => All=""/Selling=0/Sold=1
          category: "", // values => All=""/New=0/Used=1
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              {/* <div className="col-lg-2">
                <select
                  className="form-control"
                  name="status"
                  placeholder="Filter by Status"
                  onChange={(e) => {
                    setFieldValue("status", e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.status}
                >
                  <option value="">Recently Created</option>
                  <option value="0">Selling</option>
                  <option value="1">Sold</option>
                </select>
                <small className="form-text text-muted">
                  <b>Sort</b>
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  placeholder="Filter by Type"
                  name="category"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("category", e.target.value);
                    handleSubmit();
                  }}
                  value={values.category}
                >
                  <option value="">All</option>
                  <option value="Art">Art</option>
                  <option value="Photography">Photography</option>
                  <option value="Collectibles">Collectibles</option>
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Categories
                </small>
              </div> */}
              <div className="col-lg-2">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search By Title.."
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  {/* <b>Search</b> in all fields */}
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
