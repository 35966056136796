import React from "react";
import { Route } from "react-router-dom";
import { UsersLoadingDialog } from "./staking-loading-dialog/stakingLoadingDialog";
import { UserDeleteDialog } from "./staking-delete-dialog/stakingDeleteDialog";
import { UsersDeleteDialog } from "./stakings-delete-dialog/StakingsDeleteDialog";
import { UsersFetchDialog } from "./staking-fetch-dialog/StakingFetchDialog";
import { UsersUpdateStatusDialog } from "./user-update-status-dialog/UsersUpdateStatusDialog";
import { UsersVerifyDialog } from "./user-verify-dialog/userVerifyDialog";
import { UsersCard } from "./StakingCard";
import { EventsUIProvider } from "./StakingUIContext";
import { UserAddDialog } from "./staking-new/StakingAddModal";

export function StakingPage({ history }) {
  const eventsUIEvents = {
    newEventButtonClick: () => {
      history.push("/cock-boxing/staking/new");
    },
    openEditEventPage: (id) => {
      history.push(`/cock-boxing/staking/${id}/edit`);
    },
    openVerifyEventDialog: (id) => {
      history.push(`/cock-boxing/staking/${id}/verify`);
    },

    openDeleteEventDialog: (id) => {
      history.push(`/cock-boxing/staking/${id}/delete`);
    },
    openDeleteEventsDialog: () => {
      history.push(`/cock-boxing/staking/deleteEvents`);
    },
    openFetchEventsDialog: () => {
      history.push(`/cock-boxing/staking/fetch`);
    },
    openUpdateEventsStatusDialog: () => {
      history.push("/cock-boxing/staking/updateStatus");
    },
  };

  return (
    <EventsUIProvider eventsUIEvents={eventsUIEvents}>
      <UsersLoadingDialog />
      <Route path="/cock-boxing/staking/deleteEvents">
        {({ history, match }) => (
          <UsersDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/staking");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/staking/:id/verify">
        {({ history, match }) => (
          <UsersVerifyDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/staking");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/staking/:id/delete">
        {({ history, match }) => (
          <UserDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/cock-boxing/staking");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/staking/fetch">
        {({ history, match }) => (
          <UsersFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/staking");
            }}
          />
        )}
      </Route>
      <Route path="/cock-boxing/staking/updateStatus">
        {({ history, match }) => (
          <UsersUpdateStatusDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/staking");
            }}
          />
        )}
      </Route>
      {/* <Route path="/cock-boxing/players/:id/edit">
        {({ history, match }) => (
          <UserDetails
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/players");
            }}
          />
        )}
      </Route> */}

      <Route path="/cock-boxing/staking/new">
        {({ history, match }) => (
          <UserAddDialog
            show={match != null}
            onHide={() => {
              history.push("/cock-boxing/staking");
            }}
          />
        )}
      </Route>
      <UsersCard />
    </EventsUIProvider>
  );
}
