/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { GoUnverified, GoVerified, IconName } from "react-icons/go";
import { Link } from "react-router-dom";
export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { openVerifyCollectionDialog, openUpdateSolanaStatusDialog, setId, ended, user }
) => (

  <div className="d-flex justify-content-center align-items-center gap">

    {/* <button type="button" className="btn btn-outline-primary d-flex mx-auto"  onClick={() => openVerifyCollectionDialog(row._id)}>Verify</button> */}

    {row.status === 'initialized' && user.role !== "operator" ? <button
      className="btn btn-icon btn-light btn-hover-secondary btn-sm"
      onClick={() => {
        setId(row._id);
        openUpdateSolanaStatusDialog(row._id);
      }}
    >
      <span className="svg-icon svg-icon-md svg-icon-info">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
      </span>
    </button> :
      <button
        className="btn btn-icon btn-light btn-hover-info btn-sm"
        onClick={() => {
          setId(row._id);
          openUpdateSolanaStatusDialog(row._id);
        }}
      >
        <span className="svg-icon svg-icon-md svg-icon-info">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Visible.svg")} />
        </span>
      </button>}
    {/*     
    <Link to={"/cock-boxing/fight/edit/"+row._id}
      className="btn btn-icon btn-light btn-hover-primary btn-sm"
      // onClick={() => openDeleteCollectionDialog(row.id)}
    >
      <span className="svg-icon svg-icon-md svg-icon-primary">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
      </span>
    </Link> */}
    {/* {!row.isEnded ?
<button
      className="btn btn-icon btn-light btn-hover-primary btn-sm"
      // onClick={() => openFightEndEditDialog(row.id)}
    >
      <span className="svg-icon svg-icon-md svg-icon-primary">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Flag.svg")} />
      </span>
    </button>:
    <button
      className="btn btn-icon btn-light btn-hover-danger btn-sm"
      onClick={ended}
    
    >
      <span className="svg-icon svg-icon-md svg-icon-danger">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Flag.svg")} />
      </span>
    </button>} */}
  </div >
);
