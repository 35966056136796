import React from "react";
import { Link } from "react-router-dom";


export const ImageColumnFormatter = (cellContent, row) => (
  <>
    <Link
      to={`/cock-boxing/player/${row._id}`}
    >
      <span style={{ fontWeight: "400", color: "black" }}  >${parseFloat(row?.totalAmount).toFixed(2)}</span>
    </Link>
  </>
);
